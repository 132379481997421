import {type PayloadAction} from "@reduxjs/toolkit";
import {type IOrderData} from "state/redux/slices/order/types";

export const setIsAddressSelectionActive = (
  state: IOrderData,
  action: PayloadAction<boolean>
) => {
  state.drawersStates.isAddressSelectionDrawerOpened = action.payload;
  state.orderBuilder.addresses.addressInput = null;

  if (!action.payload) {
    state.drawersStates.isAddressDetailsDrawerOpened = action.payload;
  }
};
