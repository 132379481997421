import * as Sentry from "@sentry/browser";
import {useMutation} from "@tanstack/react-query";
import {fetchSendSelfServeOrder} from "features/order/self-serve/api/selfServe";
import type {ISelfServeOrder} from "features/order/self-serve/types";
import {toast} from "react-toastify";
import {ERROR_MESSAGES} from "constants/constants";
import type {ApiError} from "types/api";

export type SuccessCallbackPayload = Awaited<
  ReturnType<typeof fetchSendSelfServeOrder>
>["data"];

export interface UseRunMachinePayload {
  onSuccessCallback?: (
    payload: SuccessCallbackPayload
  ) => void | ((payload: SuccessCallbackPayload) => Promise<void>);
}

export const useRunMachine = ({onSuccessCallback}: UseRunMachinePayload) => {
  return useMutation<unknown, ApiError, ISelfServeOrder>({
    mutationFn: async ({
      machineId,
      topOffQuantity,
      topOffPriceInCents,
      basePriceInCents,
    }) => {
      if (!machineId) {
        return null;
      }

      const res = await fetchSendSelfServeOrder({
        machineId,
        topOffQuantity,
        topOffPriceInCents,
        basePriceInCents,
      });

      if (onSuccessCallback) {
        await onSuccessCallback(res.data);
      }
    },
    onSuccess: async () => {
      toast.success("You have enabled start laundry machine!");
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast.error(error?.response?.data?.error ?? ERROR_MESSAGES.RUN_MACHINE);

      return error;
    },
  });
};
