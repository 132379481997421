export const styles = {
  contentWrapper: {
    flexDirection: "column",
    height: "var(--app-height)",
    fontFamily: "Inter",
  },
  customerName: {
    pl: "20px",
    py: "40px",
    mb: "8px",
    fontSize: "24px",
    fontWeight: "700",
    fontFamily: "Sora, Inter",
    lineHeight: "28px",
    color: "NEW_TEXT_PRIMARY_BLUE",
    sx: {
      border: "1px solid",
      borderColor: "BORDER_GREY",
    },
  },
  separator: {
    marginTop: "auto",
    height: "2px",
    width: "50px",
    bg: "black",
    mx: "20px",
    mb: "6.5px",
  },
  routeContainer: {
    alignItems: "center",
  },
  routeIcon: {
    marginLeft: "20px",
    width: "20px",
    height: "20px",
  },
  route: {
    color: "NEW_TEXT_PRIMARY_BLUE",
    px: "16px",
    py: "16px",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "22px",
    sx: {
      cursor: "pointer",
    },
  },
  footerRoutes: {
    color: "TEXT_LIGHT_GREY",
    fontSize: "14px",
    fontWeight: "400",
    px: "20px",
    py: "12px",
    sx: {
      textTransform: "uppercase",
      textDecoration: "none",
    },
  },
  poweredByCents: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    py: "1.5rem",
    color: "TEXT_GREY",
    justifyContent: "center",
    alignItems: "flex-end",
  },
} as const;
