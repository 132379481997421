import {type PayloadAction} from "@reduxjs/toolkit";
import {IPaymentMethod} from "types/customer";
import {type ICustomerInitialData} from "../types/initialData";

export const setPaymentMethods = (
  state: ICustomerInitialData,
  action: PayloadAction<IPaymentMethod[]>
) => {
  state.paymentMethods = action.payload;
};
