import {DateTime} from "luxon";

export const formatTimeWindow = (window, timeZone, options = {}) => {
  return window?.length === 2
    ? [
        DateTime.fromMillis(Number(window[0]))
          .setZone(timeZone)
          .toFormat(`ccc${options.monthShort ? "" : "c"}, LLL dd, h:mma`),
        DateTime.fromMillis(Number(window[1])).setZone(timeZone).toFormat("h:mma"),
      ].join(" - ")
    : null;
};

export const formatJSTimeToReadableTime = (date, timeZone, options = {}) => {
  return DateTime.fromJSDate(new Date(date))
    .setZone(timeZone)
    .toFormat(`ccc${options.monthShort ? "" : "c"}, LLL dd, h:mma`);
};

export const getLuxonWeekDayFromMillis = (time, timeZone) => {
  return time ? DateTime.fromMillis(Number(time)).setZone(timeZone).weekday : null;
};

export const getTimeFromMilliSeconds = (timeInMilliSeconds, timeZone) => {
  return DateTime.fromMillis(Number(timeInMilliSeconds)).setZone(timeZone);
};

export const changeDateAndTimeZone = (date, timeInUTC, timeZone) => {
  const [year, month, day] = [date?.year, date?.month, date?.day];
  const timeObj = DateTime.fromISO(timeInUTC, {zone: "UTC"});
  const [hour, minute] = [timeObj?.hour, timeObj?.minute];

  return DateTime.fromObject({year, month, day, hour, minute, zone: timeZone});
};

const getShiftDiffInDays = (timing) => {
  return (
    new Date(timing?.endTime)?.getUTCDate() - new Date(timing?.startTime)?.getUTCDate() ||
    0
  );
};

export const getStartAndEndTimes = (date, timing, timeZone) => {
  const diffShiftTimingInDays = getShiftDiffInDays(timing);

  return {
    startTime: changeDateAndTimeZone(date, timing?.startTime, timeZone),
    endTime: changeDateAndTimeZone(
      date?.plus({day: diffShiftTimingInDays}),
      timing?.endTime,
      timeZone
    ),
  };
};

export const convertSecondsToTime = (totalSeconds) => {
  if (!Number.isFinite(totalSeconds) || totalSeconds < 0) {
    return null;
  } else {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.ceil((totalSeconds % 3600) / 60);
    return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  }
};

export const convertSecondsToMinutes = (totalSeconds) => {
  if (!Number.isFinite(totalSeconds) || totalSeconds < 0) {
    return null;
  } else {
    return Math.ceil(totalSeconds / 60);
  }
};
