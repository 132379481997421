import {createState} from "@hookstate/core";

interface IInitialState {
  customerAuthToken: string | null;
  customer: {
    firstName?: string;
    lastName?: string;
    id?: number;
  };
}

export const initialCustomerState: IInitialState = {
  customerAuthToken: null,
  customer: {},
};

const customerState = createState(initialCustomerState);

export default customerState;
