import {FC} from "react";
import {getPerUnitString} from "utils";
import {orderItemCategories} from "components/order-summary/constants";
import {ObjectValues} from "types/typeUtils";
import styles from "./priceTag.module.scss";

export type PricingType = ObjectValues<typeof orderItemCategories>;

export const PriceTag: FC<{
  price?: number;
  pricingType?: PricingType;
}> = ({price, pricingType}) => {
  return (
    <p className={styles.tag}>
      {price
        ? getPerUnitString({price, pricingType, serviceCategory: null, itemTotal: null})
        : "TBD"}
    </p>
  );
};
