import styles from "./deliveryShiftLabel.module.scss";
import {type FC} from "react";

export const DeliveryShiftLabel: FC<{shiftColor: string | null; shiftName: string}> = ({
  shiftColor,
  shiftName,
}) => {
  return (
    <div className={styles.labelContainer}>
      {shiftColor && <div style={{backgroundColor: shiftColor}}></div>}
      <span>{shiftName}</span>
    </div>
  );
};
