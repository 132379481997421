import {ServiceWithModifiers} from "components/common/sectionItems/LaundryItem/LaundryItem";
import {ISelectedService} from "state/redux/slices/order/types/orderBuilder";

export const formatServicesListFromCustomerSelectedServices = ({
  customerSelectedServices,
}: {
  customerSelectedServices: ISelectedService[];
}): ServiceWithModifiers[] => {
  return customerSelectedServices.map((service) => ({
    id: service.id,
    name: service.name,
    pricingType: service.pricingStructure.type,
    price: service.prices[0].storePrice,
    modifiers:
      service.customerSelectedModifiers?.map((serviceModifier) => ({
        name: serviceModifier.modifier.name,
        pricingType: serviceModifier.modifier.pricingType,
        price: serviceModifier.modifier.price,
      })) || [],
  }));
};
