import {createSlice} from "@reduxjs/toolkit";

import {FetchingStatus} from "types/common";
import {NAME_REDUCER, OFFSET_ITEMS} from "../constants/general";
import initialData from "../constants/initData";
import {getFullServiceOrdersFinishedList, getFullServiceOrdersActiveList} from "./thunks";

const Slice = createSlice({
  name: NAME_REDUCER,
  initialState: initialData,
  reducers: {
    clearListData: (state) => (state = initialData),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFullServiceOrdersFinishedList.pending, (state) => {
        state.finishedList.fetchingStatus = FetchingStatus.Pending;
      })
      .addCase(getFullServiceOrdersFinishedList.fulfilled, (state, action) => {
        state.finishedList.fetchingStatus = FetchingStatus.Fulfilled;
        state.finishedList.data.items =
          action.payload.params.offset === OFFSET_ITEMS
            ? action.payload.items || []
            : [...state.finishedList.data.items, ...action.payload.items];
        state.finishedList.data.totalCount = action.payload.totalCount;
        state.finishedList.data.offset = action.payload.params.offset;
      })
      .addCase(getFullServiceOrdersFinishedList.rejected, (state, action) => {
        const {error} = action;
        state.finishedList.fetchingStatus = FetchingStatus.Rejected;
        state.finishedList.error = {
          text: error.message,
          code: error.name,
        };
      })

      .addCase(getFullServiceOrdersActiveList.pending, (state) => {
        state.activeList.fetchingStatus = FetchingStatus.Pending;
      })
      .addCase(getFullServiceOrdersActiveList.fulfilled, (state, action) => {
        state.activeList.fetchingStatus = FetchingStatus.Fulfilled;
        state.activeList.data.items =
          action.payload.params.offset === OFFSET_ITEMS
            ? action.payload.items || []
            : [...state.activeList.data.items, ...action.payload.items];
        state.activeList.data.totalCount = action.payload.totalCount;
        state.activeList.data.offset = action.payload.params.offset;
      })
      .addCase(getFullServiceOrdersActiveList.rejected, (state, action) => {
        const {error} = action;
        state.activeList.fetchingStatus = FetchingStatus.Rejected;
        state.activeList.error = {
          text: error.message,
          code: error.name,
        };
      });
  },
});

export const actions = Slice.actions;
export const reducer = Slice.reducer;
