import {useTheme} from "@material-ui/core/styles";
import {useBranding} from "hooks/branding/useBranding";

/** @deprecated Will be replaced by useBranding */
export const useThemeValues = () => {
  const {primaryColor, translucentPrimaryColor, lightenPrimaryColor, borderRadius} =
    useBranding();
  const theme = useTheme();
  const filterClass = theme.filterClass || "filter-1";

  return {
    primaryColor,
    translucentPrimaryColor,
    lightenPrimaryColor,
    borderRadius,
    /** @deprecated Will by replaced with colorable inline SVGs */
    filterClass,
  };
};
