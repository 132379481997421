import {createAsyncThunk} from "@reduxjs/toolkit";
import {createCustomer as createCustomerRequest} from "api/customer";
import {AUTH_REDUCER_NAME, CREATE_CUSTOMER} from "../constants/actions";
import {type IUser} from "../types/initialData";

export interface ICreateCustomerResponse {
  success: boolean;
  customer: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
  customerAuthToken: string;
  id: number;
  latestOrderToken: string;
}

export interface ICreateCustomerBody {
  fullName: string;
  phoneNumber: IUser["phoneNumber"];
  email: IUser["email"];
  optOutMarketingSms: boolean;
}

export const createCustomer = createAsyncThunk(
  `${AUTH_REDUCER_NAME}/${CREATE_CUSTOMER}`,
  async ({
    fullName,
    phoneNumber,
    email,
    optOutMarketingSms,
  }: ICreateCustomerBody): Promise<ICreateCustomerResponse> => {
    const response = await createCustomerRequest({
      fullName,
      phoneNumber,
      email,
      optOutMarketingSms,
    });
    return response.data;
  }
);
