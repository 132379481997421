export const INTERVAL_DISPLAY = {
  1: "Weekly",
  2: "Every 2 weeks",
  3: "Every 3 weeks",
  4: "Every 4 weeks",
};

export const RECURRING_ORDER_INTERVALS = {
  1: "Weekly",
  2: "Every two weeks",
  4: "Every four weeks",
};
