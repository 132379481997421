import {toast} from "react-toastify";
import {
  ToastWarning,
  warningToastOptions,
} from "components/newOrder/common/Toast/ToastWarning";
import {useOrderConfig} from "hooks/newOrder/useOrderConfig";
import {useAppDispatch} from "state/redux/hooks";
import {orderActions} from "state/redux/slices/order";
import {MAXIMUM_BAGS_COUNT} from "constants/order";

export const useUpdateBagsCount = () => {
  const dispatch = useAppDispatch();
  const {isDoorDashOnlyStore} = useOrderConfig();

  const updateBagCount = (newBagsCount: number) => {
    dispatch(orderActions.setBagsCount(newBagsCount));
  };
  const onMinusBagCount = (newBagsCount: number) => {
    newBagsCount >= 1 && updateBagCount(newBagsCount);
  };
  const onPlusBagCount = (newBagsCount: number) => {
    if (isDoorDashOnlyStore) {
      newBagsCount <= MAXIMUM_BAGS_COUNT.DOORDASH_ONLY
        ? updateBagCount(newBagsCount)
        : toast.warn(
            <ToastWarning
              primaryMessage="DoorDash has a limit of&nbsp;"
              secondaryMessage={`${MAXIMUM_BAGS_COUNT.DOORDASH_ONLY} bags`}
            />,
            {
              ...warningToastOptions,
              toastId: "maxBagWarning",
            }
          );
    } else {
      newBagsCount <= MAXIMUM_BAGS_COUNT.GLOBAL && updateBagCount(newBagsCount);
    }
  };

  return {onMinusBagCount, onPlusBagCount};
};
