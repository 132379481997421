import {combineReducers} from "@reduxjs/toolkit";
import {fullServiceOrdersListReducer} from "features/allOrders/components/fullServiceList/redux";
import {selfServeOrdersListReducer} from "features/allOrders/components/selfServeList/redux";
import {availableCreditReducer} from "features/payment/availableCredit/redux";
import {referralReducer} from "components/referral/redux";
import {rewardsReducer} from "components/rewards/Rewards/redux";
import {authReducer} from "state/redux/slices/auth";
import {businessReducer} from "state/redux/slices/business";
import {customerReducer} from "state/redux/slices/customer";
import {orderReducer} from "state/redux/slices/order";
import {recurringOrdersReducer} from "state/redux/slices/recurringOrder";

const rootReducer = combineReducers({
  auth: authReducer,
  customer: customerReducer,
  business: businessReducer,
  order: orderReducer,
  selfServeOrdersList: selfServeOrdersListReducer,
  fullServiceOrdersList: fullServiceOrdersListReducer,
  availableCredit: availableCreditReducer,
  rewardsInfo: rewardsReducer,
  referralInfo: referralReducer,
  recurringOrders: recurringOrdersReducer,
});
export default rootReducer;
