import {InputWithNavigation} from "../common/ScheduleForm";
import {ScheduleFormInputTypes} from "../common/ScheduleFormPickupDeliveryInput";

export const DIRECTIONS: Record<ScheduleFormInputTypes, InputWithNavigation> = {
  pickup: {
    action: () => void 0,
    id: 0,
    isSkeleton: true,
    current: null,
    isOnDemandDelivery: false,
    isScheduleWhenReady: false,
    isAutoSchedule: false,
    price: null,
    duration: null,
    timing: {
      date: "",
      interval: "",
    },
  },
  delivery: {
    action: () => void 0,
    id: 0,
    isSkeleton: true,
    current: null,
    isOnDemandDelivery: false,
    isScheduleWhenReady: false,
    isAutoSchedule: false,
    price: null,
    duration: null,
    timing: {
      date: "",
      interval: "",
    },
  },
};

export const transformToInputProps = (
  data: Record<string, any>
): Partial<InputWithNavigation> => {
  if (data === null || (!data.key && !data.display)) {
    return {current: null, isOnDemandDelivery: false, isScheduleWhenReady: true};
  }

  return {
    id: data.id,
    current: data.key,
    isOnDemandDelivery: data.type !== "OWN_DRIVER",
    isScheduleWhenReady: false,
    price: data.display?.price,
    timing: {
      date: data.display?.displayDate,
      interval: `${data.display?.startTime}-${data.display?.endTime}`,
    },
    windowConflicts: data?.windowConflicts,
    maxStops: data?.maxStops,
    shiftName: data?.display?.shiftName,
    shiftColor: data?.display?.shiftColor,
  };
};

export const sanitizeHtml = (html: string) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};
