import {CSSProperties, FC} from "react";
import classNames from "classnames";
import {useBranding} from "hooks/branding/useBranding";
import {DEFAULT_PRIMARY_COLOR} from "constants/themes/defaultTheme";

export const Loader: FC<{
  fullScreen?: boolean;
  loaderColor?: string;
  style?: CSSProperties;
  width?: string;
  customHeader?: string;
  variant?: "primary" | "compact";
}> = ({
  loaderColor,
  style,
  width,
  customHeader,
  fullScreen = true,
  variant = "primary",
}) => {
  const {primaryColor} = useBranding();
  const finalLoaderColor = loaderColor || primaryColor || DEFAULT_PRIMARY_COLOR;

  return (
    <div
      style={
        fullScreen
          ? {
              height: "var(--app-height)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(255, 255, 255, 1)",
              position: "absolute",
              zIndex: 999999999,
              top: "0",
              left: "0",
              width: width || "100%",
              ...style,
            }
          : {width: width || "100%"}
      }
      className="loader"
    >
      <div
        className={classNames("spinner", {
          compact: variant === "compact",
        })}
      >
        <div className="rect1" style={{backgroundColor: finalLoaderColor}} />
        <div className="rect2" style={{backgroundColor: finalLoaderColor}} />
        <div className="rect3" style={{backgroundColor: finalLoaderColor}} />
        <div className="rect4" style={{backgroundColor: finalLoaderColor}} />
        <div className="rect5" style={{backgroundColor: finalLoaderColor}} />
      </div>
      {variant === "compact" ? (
        <></>
      ) : (
        <p
          className="color-transition"
          style={{color: finalLoaderColor, marginTop: "1rem"}}
        >
          {customHeader || "Loading"}
        </p>
      )}
    </div>
  );
};
