import {useAppSelector} from "state/redux/hooks";
import {getBusinessTheme, getStoreTheme} from "state/redux/slices/business/selectors";
import {getLightenColor, getTranslucentColor} from "utils/theme";
import {DEFAULT_THEME} from "constants/themes/defaultTheme";

export const DEFAULT_CENTS_LOGO = "Cents+LOGO.png";

/**
 * Each business and store can have its own personalized branding.
 * This hook returns all values required for application branding
 * based on the current state.
 */
export const useBranding = () => {
  const businessTheme = useAppSelector(getBusinessTheme);
  const storeTheme = useAppSelector(getStoreTheme);
  const brandingSource = storeTheme || businessTheme;

  const primaryColor = brandingSource.primaryColor || DEFAULT_THEME.primaryColor;
  const translucentPrimaryColor = getTranslucentColor(primaryColor);
  const lightenPrimaryColor = getLightenColor(primaryColor);

  const borderRadius = brandingSource.borderRadius || DEFAULT_THEME.borderRadius;

  const logoUrl = brandingSource.logoUrl || DEFAULT_CENTS_LOGO;

  return {
    primaryColor,
    translucentPrimaryColor,
    lightenPrimaryColor,
    borderRadius,
    logoUrl,
  };
};
