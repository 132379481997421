import {type FC, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {ROUTES} from "routes/constants";
import {Loader} from "components/common";
import {useServiceOrderId} from "api/queries/order/useServiceOrderId";

/**
 * This component is required for
 * legacy route thaw was used before `/order/:serviceOrderId` route,
 * an encrypted token was used for order identification.
 * Now we use `/order/:serviceOrderId`, so this route
 * gets the service order id from the token (to support old type links)
 * and redirects to the actual order page.
 */
export const OrderSummaryRedirect: FC = () => {
  const {orderToken} = useParams<{orderToken: string}>();
  const {data: serviceOrderId, isSuccess} = useServiceOrderId({orderToken});

  const history = useHistory();

  useEffect(() => {
    if (isSuccess) {
      history.push(`/${ROUTES.order}/${serviceOrderId}`);
    }
  }, [history, isSuccess, serviceOrderId]);

  return <Loader />;
};
