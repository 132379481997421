import {FC, useEffect, useRef, useState} from "react";
import {type StaticContext} from "react-router";
import {type RouteComponentProps, useHistory, useRouteMatch} from "react-router-dom";
import {AccountCarePreferencesForm} from "components/account/AccountCarePreferences/assets/AccountCarePreferencesForm";
import {
  IAccountCarePreferencesFormData,
  IAccountCarePreferencesFormOutput,
  IPreferenceItem,
} from "components/account/AccountCarePreferences/assets/AccountCarePreferencesForm/types";
import {useAccountCarePreferences} from "components/account/AccountCarePreferences/assets/useAccountCarePreferences";
import {StaticPageLayout} from "components/account/Layouts";
import {Loader} from "components/common";
import {useFullStoryEvent} from "hooks/fullstory/useFullStoryEvent";
import {useOrderPreferencesUpdating} from "hooks/orderPreferences/useOrderPreferencesUpdating";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderActions, orderSelectors} from "state/redux/slices/order";
import {getCarePreferences} from "state/redux/slices/order/selectors/carePreferencesSelectors";
import {getAllNotes} from "state/redux/slices/order/selectors/notesSelectors";
import {createErrorToast} from "utils/notifications/createErrorToast";
import {openView} from "utils/order/openView";
import {PREFERENCES_ARE_SUBMITTED} from "constants/fullStory/events/preferences";
import {VIEWS} from "constants/order";
import {EN_LOCALE} from "locales/en";

export const Preferences: FC<
  RouteComponentProps<
    {},
    StaticContext,
    {
      prevRoute?: string;
    }
  >
> = ({location}) => {
  const {
    data: {businessSettings},
  } = useAppSelector(orderSelectors.getOrderInitialData);
  const {submitEvent} = useFullStoryEvent();
  const {
    hangDryInstructions: hangDryCustomerInstructionsState,
    customerNotes: customerNotesState,
  } = useAppSelector(getAllNotes);
  const {isHangDrySelected: isHangDrySelectedState} = useAppSelector(getCarePreferences);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initialData, setInitialData] = useState<IAccountCarePreferencesFormData | null>(
    null
  );

  const accountCarePreferencesFormRef = useRef<HTMLFormElement>(null);

  const businessId = businessSettings?.businessId || null;
  const isHangDrySelected = isHangDrySelectedState || false;
  const customerNotes = customerNotesState || "";
  const hangDryCustomerInstructions = hangDryCustomerInstructionsState || "";

  const {storeId, businessCustomerPreferences, customerInfo, storePreferences} =
    useAccountCarePreferences({
      businessId: String(businessId),
      setIsLoading,
    });

  useEffect(() => {
    storeId &&
      storePreferences.length > 0 &&
      setInitialData({
        storeId: storeId || null,
        enabled: businessSettings?.isCustomPreferencesEnabled,
        preferences: (businessCustomerPreferences as Array<IPreferenceItem>) || [],
        hangDry: {
          isHangDrySelected: storePreferences[0].isHangDrySelected || isHangDrySelected,
          enabled: businessSettings?.isHangDryEnabled,
          instructions: businessSettings?.hangDryInstructions || "",
          hangDryCustomerInstructions:
            storePreferences[0].hangDryInstructions || hangDryCustomerInstructions,
        },
        customerNotes:
          customerInfo?.notes || customerNotes || storePreferences[0]?.notes || "",
      });
  }, [storeId, storePreferences]);

  const onSaveCarePreferences = (): void => {
    accountCarePreferencesFormRef.current?.dispatchEvent(new Event("submit"));
  };

  const {updateCarePreferences} = useOrderPreferencesUpdating({
    preferencesData: initialData,
    storeId: storeId || undefined,
    customerInfo,
    businessId,
  });

  const dispatch = useAppDispatch();
  const {url} = useRouteMatch();
  const history = useHistory();
  const onAccountCarePreferencesFormSubmit = async (
    data: Partial<IAccountCarePreferencesFormOutput>
  ): Promise<void> => {
    setIsLoading(true);
    try {
      await updateCarePreferences(data);
      if (typeof data.carePreferencesNote === "boolean") {
        dispatch(orderActions.setCustomerNotes(data.carePreferencesNote));
      }

      if (typeof data.isHangDrySelected === "boolean") {
        dispatch(orderActions.setIsHangDrySelected(data.isHangDrySelected));
      }
      if (typeof data.hangDryCustomerInstructions === "string") {
        dispatch(orderActions.setHangDryInstructions(data.hangDryCustomerInstructions));
      }

      const previousRoute = location?.state?.prevRoute;
      const defaultViewConfig = {
        history,
        url,
        viewPath: "schedule",
        prevViewPath: "preferences",
      };

      if (previousRoute) {
        openView({
          ...defaultViewConfig,
          viewPath: previousRoute,
          prevViewPath: "preferences",
        });
      } else {
        dispatch(orderActions.setStage(VIEWS.RECOMMENDED_PICKUP));
        openView(defaultViewConfig);
      }

      submitEvent(PREFERENCES_ARE_SUBMITTED);
    } catch (error) {
      createErrorToast({
        primaryMessage: EN_LOCALE.messages.genericError,
        toastId: "save-preferences-error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {!storeId || !initialData || isLoading ? <Loader /> : null}
      <StaticPageLayout
        bgLayout="white"
        headerText={EN_LOCALE.label.preferences}
        forceBack={true}
      >
        {storeId && initialData && (
          <AccountCarePreferencesForm
            forwardedRef={accountCarePreferencesFormRef}
            onSubmit={onAccountCarePreferencesFormSubmit}
            initialData={initialData}
            showVisibilityCheckbox={true}
          />
        )}
        <button onClick={onSaveCarePreferences}>
          {EN_LOCALE.button.savePreferences}
        </button>
      </StaticPageLayout>
    </>
  );
};
