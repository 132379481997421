import {FC, useEffect, useRef, useState} from "react";
import {Label} from "../Label";
import {togglePlaceholder} from "../assets/utils";
import {ITextarea} from "./types";

export const Textarea: FC<ITextarea> = ({
  name,
  placeholder = "",
  required = true,
  onInput,
  children,
  className,
  title = "",
  activePlaceholder = "",
  ...props
}) => {
  const defaultRef = useRef<HTMLTextAreaElement>(null);
  const textareaRef = props.forwardRef || defaultRef;
  const [isValid, setIsValid] = useState<boolean>(true);
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    if (props.disabled) {
      [textareaRef.current, textareaRef.current?.parentElement].map((element) => {
        element?.setAttribute("data-placeholder", textareaRef.current?.placeholder || "");
      });
    }
  }, [props.disabled]);

  const onInputHandler = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    textareaRef.current?.value
      ? setIsValid(!!textareaRef.current?.validity.valid)
      : setIsValid(true);
    onInput?.(event);
  };

  const outProps = {...props};
  delete outProps.forwardRef;
  delete outProps.rows;
  delete outProps.cols;

  return (
    <Label
      isValid={isValid}
      active={active}
      disabled={props.disabled}
      className={className}
      title={title}
    >
      <textarea
        {...outProps}
        ref={textareaRef}
        name={name}
        rows={props.rows || 3}
        cols={props.cols || 100}
        placeholder={placeholder}
        onFocus={(event): void => {
          event.target.getAttribute("placeholder") &&
            togglePlaceholder("show", event.target as HTMLTextAreaElement, setActive);
        }}
        onBlur={(event): void => {
          event.target.parentElement?.getAttribute("data-placeholder") &&
            togglePlaceholder("hide", event.target as HTMLTextAreaElement, setActive);
        }}
        required={required}
        onInput={onInputHandler}
        data-active-placeholder={activePlaceholder}
      />
      {children}
    </Label>
  );
};
