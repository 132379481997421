import {useEffect, useState, useCallback} from "react";
import AvailableCreditWrapper from "features/payment/availableCredit/components/wrapper";
import isEmpty from "lodash/isEmpty";
import {useHistory} from "react-router-dom";
import {Box, Flex, Image, Heading, Card, Button, Text} from "rebass/styled-components";
import {Loader} from "components/common";
import TimeCounter from "components/common/timeCounter/TimeCounter";
import type {DeviceStatusPusherResponse} from "components/self-order/types";
import {checkIfMachineIsGPIO} from "utils/machineUtil";
import {formatCurrency, priceToDollars} from "utils/payment";
import {UseAddCreditsPayload} from "api/queries/useAddCredits";
import {type SuccessCallbackPayload, useRunMachine} from "api/queries/useRunMachine";
import {SELF_ORDER_INFO_PATH} from "constants/paths";
import {EN_LOCALE} from "locales/en";
import {UnloadedWashingMachine} from "assets/images";
import {ICustomer} from "types/customer";
import {DRYER_PREFIX, WASHER_PREFIX} from "../../constants/selfServeGeneral";
import {DeviceStatuses, IMachine, TopOffModes} from "../../types";
import DryerCycleSettings from "../dryerCycleSettings";
import WasherCycleSettings from "../washerCycleSettings";
import styles from "./styles";

const DEFAULT_FUNDS = 0;

interface SelfServeOrderProps {
  machineDetails: IMachine;
  customer: Pick<ICustomer, "id" | "paymentMethods" | "addresses"> | null;
  deviceSettings: DeviceStatusPusherResponse | null;
  isModal?: boolean;
  /**Function that is provided only from SelfServeOrderModal and used to switch from self-serve to self-order page */
  openSelfOrder?: (turnId: number | null) => void;
  refetchBalance: UseAddCreditsPayload["refetch"];
  balanceInDollars?: number | null;
}

function SelfServeOrder({
  isModal,
  openSelfOrder,
  machineDetails,
  customer,
  deviceSettings,
  refetchBalance,
  balanceInDollars,
}: SelfServeOrderProps) {
  const {
    prefix,
    name,
    isAvailable,
    id,
    store,
    activeTurn,
    topOffData,
    isDumbDumb,
    deviceMode,
    coinValueInCents,
    secondsPerTurn,
    pricePerTurnInCents,
    business: {id: machineBusinessId},
  } = machineDetails;

  const history = useHistory();

  const [topOffQuantity, setTopOffQuantity] = useState<number>(0);
  const [baseTimeInMinutes, setBaseTimeInMinutes] = useState<number | null>(null);
  const [totalPrice, setTotalPrice] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [currAvailableCredit, setCurrAvailableCredit] = useState(DEFAULT_FUNDS);
  const [showModal, toggleShowModal] = useState(false);
  const [showMachineOffline, setShowMachineOffline] = useState(false);

  const handleRunMachineSuccess = useCallback(
    (payload: SuccessCallbackPayload) => {
      if (!isModal) {
        history.push(
          `${SELF_ORDER_INFO_PATH}/${null}?centsCustomerId=${
            payload.centsCustomerId
          }&machineId=${machineDetails.id}&businessId=${machineBusinessId}`
        );
      } else if (openSelfOrder) {
        openSelfOrder(null);
      }
    },
    [history, isModal, machineBusinessId, machineDetails.id, openSelfOrder]
  );

  const {isLoading: isRunMachineLoading, mutate: runMachine} = useRunMachine({
    onSuccessCallback: handleRunMachineSuccess,
  });

  const isGPIOMachine = checkIfMachineIsGPIO(deviceMode, isDumbDumb);

  useEffect(() => {
    setCurrAvailableCredit(balanceInDollars ?? DEFAULT_FUNDS);
  }, [balanceInDollars]);

  useEffect(() => {
    setBaseTimeInMinutes(
      isDumbDumb || isGPIOMachine
        ? Number(secondsPerTurn) / 60
        : deviceSettings?.cyclePrices?.basePricing?.minutes ?? null
    );
  }, [
    deviceSettings?.cyclePrices?.basePricing,
    isDumbDumb,
    isGPIOMachine,
    secondsPerTurn,
  ]);

  useEffect(() => {
    const turnToCheck = !isEmpty(activeTurn)
      ? activeTurn
      : deviceSettings?.activeTurn || deviceSettings?.lastCompletedTurn;

    if (
      (!isAvailable || deviceSettings?.status !== DeviceStatuses.ONLINE) &&
      turnToCheck?.id &&
      turnToCheck?.centsCustomerId &&
      customer?.id === turnToCheck.centsCustomerId
    ) {
      if (!isModal) {
        history.push(`${SELF_ORDER_INFO_PATH}/${turnToCheck.id}`);
      } else if (openSelfOrder) {
        openSelfOrder(turnToCheck.id);
      }
    }
  }, [
    isModal,
    history,
    activeTurn,
    isAvailable,
    deviceSettings,
    customer?.id,
    openSelfOrder,
  ]);

  const getWasherTotalPrice = useCallback(() => {
    if (isDumbDumb || isGPIOMachine) {
      return pricePerTurnInCents;
    }

    const totalPriceInCents =
      Number(deviceSettings?.vendRemaining) ||
      (Number(deviceSettings?.cyclePrices?.basePricing?.price) ?? 0) +
        (Number(deviceSettings?.cyclePrices?.modifierPricing?.price) ?? 0);

    return totalPriceInCents;
  }, [
    deviceSettings?.cyclePrices?.basePricing?.price,
    deviceSettings?.cyclePrices?.modifierPricing?.price,
    deviceSettings?.vendRemaining,
    isDumbDumb,
    isGPIOMachine,
    pricePerTurnInCents,
  ]);

  const getDryerBasePrice = useCallback(() => {
    if (isDumbDumb || isGPIOMachine) {
      return pricePerTurnInCents;
    }

    return (
      Number(deviceSettings?.vendRemaining) ||
      Number(deviceSettings?.cyclePrices?.basePricing?.price) ||
      0
    );
  }, [
    isDumbDumb,
    isGPIOMachine,
    pricePerTurnInCents,
    deviceSettings?.cyclePrices?.basePricing?.price,
    deviceSettings?.vendRemaining,
  ]);

  useEffect(() => {
    if (prefix === WASHER_PREFIX) {
      const totalPrice = getWasherTotalPrice();

      setTotalPrice(totalPrice);
    }
  }, [getWasherTotalPrice, prefix]);

  useEffect(() => {
    if (prefix === DRYER_PREFIX) {
      const basePrice = getDryerBasePrice();
      const totalPrice =
        Number(basePrice) + topOffQuantity * Number(topOffData?.topOffPriceInCents);
      setTotalPrice(totalPrice);
    }
  }, [
    baseTimeInMinutes,
    topOffQuantity,
    prefix,
    topOffData?.topOffPriceInCents,
    getDryerBasePrice,
  ]);

  const isAvailableToAddTime =
    baseTimeInMinutes &&
    prefix === DRYER_PREFIX &&
    [TopOffModes.PARTIAL, TopOffModes.FULLCYCLE].includes(
      topOffData?.topOffMode as TopOffModes
    ) &&
    (isDumbDumb || isGPIOMachine)
      ? pricePerTurnInCents && secondsPerTurn
      : deviceSettings?.cyclePrices?.basePricing?.price;

  const sendOrder = async () => {
    if (priceToDollars(totalPrice) > currAvailableCredit) {
      toggleShowModal(true);
      return;
    }
    if (id && totalPrice) {
      const basePriceInCents =
        isDumbDumb || isGPIOMachine
          ? Number(pricePerTurnInCents)
          : deviceSettings?.cyclePrices?.basePricing?.price ??
            Number(deviceSettings?.vendRemaining);

      await runMachine({
        machineId: id,
        topOffQuantity,
        topOffPriceInCents: topOffData?.topOffPriceInCents ?? null,
        basePriceInCents,
      });
    }
  };

  const checkDataForButtonDisplay = useCallback(() => {
    if (isDumbDumb) {
      return prefix === WASHER_PREFIX
        ? !!pricePerTurnInCents
        : !!coinValueInCents && !!secondsPerTurn;
    }

    const isMachineAvailable =
      isAvailable && deviceSettings?.status === DeviceStatuses.ONLINE && !activeTurn?.id;

    if (isGPIOMachine) {
      return Boolean(
        isMachineAvailable && !!totalPrice && !isEmpty(deviceSettings?.lmStatus)
      );
    }

    return Boolean(
      isMachineAvailable &&
        !!totalPrice &&
        (!!deviceSettings?.cyclePrices?.basePricing ||
          !!deviceSettings?.vendRemaining ||
          !!deviceSettings?.selectedCycle ||
          !isEmpty(deviceSettings?.lmStatus))
    );
  }, [
    activeTurn?.id,
    coinValueInCents,
    deviceSettings,
    isAvailable,
    isDumbDumb,
    isGPIOMachine,
    prefix,
    pricePerTurnInCents,
    secondsPerTurn,
    totalPrice,
  ]);

  useEffect(() => {
    let timer: any;
    const connectedSerially = checkDataForButtonDisplay();

    if (connectedSerially) {
      setLoading(false);
      setShowMachineOffline(false);
    } else {
      timer = setTimeout(() => {
        setLoading(false);
        setShowMachineOffline(true);
      }, 15000);
    }
    return () => clearTimeout(timer);
  }, [checkDataForButtonDisplay, totalPrice]);

  const onSetCurrAvailableCredit = useCallback(
    (credits: number) => {
      setCurrAvailableCredit(credits);
      if (refetchBalance) {
        refetchBalance();
      }
    },
    [refetchBalance]
  );

  return (
    <Flex sx={styles.wrapper}>
      {!isAvailable &&
      (isEmpty(deviceSettings?.lmStatus) ||
        deviceSettings?.lmStatus.LMState !== "IDLE") ? (
        <>
          <Box>
            <Flex flexDirection="column" alignItems="center">
              {prefix && name && (
                <Text sx={styles.machineName}>
                  {prefix}-{name}
                </Text>
              )}
              <Heading sx={styles.notAvailable}>This machine is not available.</Heading>
              <Image
                p="10px"
                src={UnloadedWashingMachine}
                alt={EN_LOCALE.label.unloadedWashingMachine}
              />
            </Flex>
            <Text sx={styles.description}>
              This machine is not currently available. Please scan an available machine to
              get started.
            </Text>
          </Box>
        </>
      ) : showMachineOffline ? (
        <Box>
          <Flex flexDirection="column" alignItems="center">
            {prefix && name && (
              <Text sx={styles.machineName}>
                {prefix}-{name}
              </Text>
            )}
            <Heading sx={styles.notAvailable}>Machine appears offline</Heading>
            <Image
              p="10px"
              src={UnloadedWashingMachine}
              alt={EN_LOCALE.label.unloadedWashingMachine}
            />
          </Flex>
          <Text sx={styles.description}>
            We&apos;re having trouble fetching the live status for this machine. Please
            try scanning another machine.
          </Text>
        </Box>
      ) : (
        <>
          <Box>
            <Card sx={styles.cardContainer}>
              <Flex justifyContent="space-between">
                <Box>
                  <Flex flexDirection="column" alignItems="start">
                    {prefix && name && (
                      <Heading>
                        {prefix}-{name}
                      </Heading>
                    )}
                    <Button sx={styles.buttonAvailable}>Available</Button>
                    {prefix === WASHER_PREFIX ? (
                      <WasherCycleSettings
                        cyclePrices={deviceSettings?.cyclePrices}
                        vendRemaining={deviceSettings?.vendRemaining ?? null}
                        selectedCycle={deviceSettings?.selectedCycle ?? null}
                        selectedModifier={deviceSettings?.selectedModifier ?? null}
                        isDumbDumb={isDumbDumb}
                        isGPIOMachine={isGPIOMachine}
                        pricePerTurnInCents={pricePerTurnInCents}
                      />
                    ) : (
                      <DryerCycleSettings
                        topOffData={topOffData}
                        basePricing={deviceSettings?.cyclePrices?.basePricing}
                        vendRemaining={deviceSettings?.vendRemaining ?? null}
                        selectedCycle={deviceSettings?.selectedCycle ?? null}
                        isDumbDumb={isDumbDumb}
                        isGPIOMachine={isGPIOMachine}
                        pricePerTurnInCents={pricePerTurnInCents}
                        secondsPerTurn={secondsPerTurn}
                        timeRemaining={deviceSettings?.timeRemaining ?? null}
                      />
                    )}
                    {isAvailableToAddTime && topOffData?.topOffTimeInMinutes && (
                      <TimeCounter
                        initialTimeInMinutes={baseTimeInMinutes as number}
                        addTimeInMinutes={topOffData.topOffTimeInMinutes}
                        onQuantityChange={setTopOffQuantity}
                      />
                    )}
                  </Flex>
                </Box>
                <Image
                  src={UnloadedWashingMachine}
                  alt={EN_LOCALE.label.unloadedWashingMachine}
                />
              </Flex>
            </Card>
            <AvailableCreditWrapper
              customer={customer}
              storeId={store.id}
              machine={machineDetails}
              setCurrAvailableCredit={onSetCurrAvailableCredit}
              showModal={showModal}
              toggleShowModal={toggleShowModal}
              currAvailableCredit={currAvailableCredit}
            />
          </Box>
          <Box>
            <Flex sx={styles.buttonWrapper}>
              <Button
                disabled={!checkDataForButtonDisplay()}
                variant="primary"
                sx={styles.button}
                onClick={sendOrder}
              >
                PAY {formatCurrency(totalPrice)} + ENABLE START
              </Button>
              <Text sx={styles.poweredByCents}>Powered by Cents</Text>
            </Flex>
          </Box>
        </>
      )}
      {(loading || isRunMachineLoading) && (
        <Loader
          style={
            isModal
              ? {
                  top: "67px",
                  height: "calc(var(--app-height) - 67px)",
                }
              : {}
          }
        />
      )}
    </Flex>
  );
}

export default SelfServeOrder;
