import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {EncodedStoreRoute} from "routes/newOrder/encodedStore";
import {NewOrderStoreProvider} from "components/common/providers/NewOrderStoreProvider";

export const NewOrderRoute = () => {
  const {path} = useRouteMatch();

  return (
    <Switch>
      <NewOrderStoreProvider>
        <Route path={`${path}/:encodedStoreLevelId`} component={EncodedStoreRoute} />
      </NewOrderStoreProvider>
      <Redirect to="/" />
    </Switch>
  );
};
