import {useEffect, useMemo, useRef, useState} from "react";
import cx from "classnames";
import {DeviceStatuses} from "features/order/self-serve/types";
import momenttz from "moment-timezone";
import {Channel} from "pusher-js";
import {useHistory} from "react-router-dom";
import {Box, Image, Text} from "rebass/styled-components";
import {BlockingLoader} from "components/common/BlockingLoader/BlockingLoader";
import {
  SelfServeOrderModalComponents,
  SelfServeOrderModal,
} from "components/common/SelfServeOrderModal/SelfServeOrderModal";
import usePusher from "hooks/usePusher";
import useToggle from "hooks/useToggle";
import {convertSecondsToMinutes} from "utils/date";
import type {UseAddCreditsPayload} from "api/queries/useAddCredits";
import {useRealtimeStatus} from "api/queries/useRealtimeStatus";
import {FetchRealtimeDeviceStatusResponse} from "api/statusDevice";
import {ERROR_MESSAGES, PUSHER_EVENTS} from "constants/constants";
import {MachineTypes, SelfServiceStatuses, TurnStatusesDisplay} from "constants/index";
import {SELF_ORDER_INFO_PATH} from "constants/paths";
import {EN_LOCALE} from "locales/en";
import {IconWasher, MachineCardCompleteIcon} from "assets/images";
import type {ICustomer} from "types/customer";
import styles from "./MachineStatusCard.module.scss";

const STARTED_DATE_FORMAT = "MM/DD/YYYY h:mm A";
const STARTED_TIME_DISPLAY_FORMAT = "hh:mm A";

const IN_PROGRESS_TURN_STATUSES = [
  SelfServiceStatuses.CREATED,
  SelfServiceStatuses.ENABLED,
  SelfServiceStatuses.STARTED,
  SelfServiceStatuses.RUNNING,
];

const getMachineNameWithPrefix = ({
  machineName,
  machineType,
}: {
  machineName: string | null;
  machineType: MachineTypes | null;
}) => {
  if (!machineName || !machineType) {
    return "";
  }

  return `${machineType[0]}-${machineName}`;
};

const getTurnStatusToDisplay = (turnStatus?: SelfServiceStatuses | null) => {
  return IN_PROGRESS_TURN_STATUSES.includes(turnStatus as SelfServiceStatuses)
    ? TurnStatusesDisplay.IN_PROGRESS
    : TurnStatusesDisplay.COMPLETE;
};

interface MachineStatusCardProps {
  turnId: number;
  machineId: number;
  isDumbDumb?: boolean;
  machineName?: string;
  turnStatus?: SelfServiceStatuses;
  machineType?: MachineTypes;
  turnTimeZone?: string;
  /** In "MM/DD/YYYY h:mma" format */
  startedDate?: string;
  selfDestroy: () => void;
  className?: string;
  isMyActivityTab?: boolean;
  balanceInDollars?: number | null;
  customerInfo: Pick<ICustomer, "id" | "paymentMethods" | "addresses"> | null;
  primaryColor?: string | null;
  refetchBalance: UseAddCreditsPayload["refetch"];
}

export const MachineStatusCard = ({
  machineId,
  turnId,
  selfDestroy,
  className,
  isMyActivityTab,
  balanceInDollars,
  customerInfo,
  primaryColor,
  refetchBalance,
  ...props
}: MachineStatusCardProps) => {
  const history = useHistory();
  const timeRemainingTimer = useRef<ReturnType<typeof setInterval> | null>(null);
  const [isDumbDumb, setIsDumbDumb] = useState(props.isDumbDumb ?? null);
  const [machineName, setMachineName] = useState(props.machineName ?? null);
  const [turnStatus, setTurnStatus] = useState(props.turnStatus ?? null);
  const [turnTimeZone, setTurnTimeZone] = useState(props.turnTimeZone ?? null);
  const [machineType, setMachineType] = useState(props.machineType ?? null);
  const [startedDate, setStartedDate] = useState(props.startedDate ?? null);
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const [timeRemainingSeconds, setTimeRemainingSeconds] = useState<number | null>(null);

  const {isOpen: showSelfServeOrderModal, toggle: toggleShowSelfServeOrderModal} =
    useToggle();

  const pusherClient = usePusher();

  // dumb-dumb self-destruction
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined;
    if (isDumbDumb && turnTimeZone && startedDate && !isMyActivityTab) {
      const selfDestructCheck = () => {
        const turnMoment = momenttz.tz(startedDate, STARTED_DATE_FORMAT, turnTimeZone);
        const nowMoment = momenttz.tz(turnTimeZone);

        const anHourHasPassed = nowMoment.diff(turnMoment, "hours", true) > 1;

        if (
          anHourHasPassed // if nowMoment is an hour later than turnMoment then we destroy card
        ) {
          selfDestroy();
        } else {
          timer = setTimeout(selfDestructCheck, 60000);
        }
      };

      selfDestructCheck();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isDumbDumb, turnTimeZone, startedDate, selfDestroy, isMyActivityTab]);

  const updateTime = () => {
    if (timeRemaining !== timeRemainingSeconds) {
      setTimeRemainingSeconds(timeRemaining);
    }

    if (timeRemaining) {
      timeRemainingTimer.current = setInterval(() => {
        setTimeRemainingSeconds((prevCount) => Number(prevCount) - 1);
      }, 1000);
    }
  };

  useEffect(() => {
    if (turnStatus === SelfServiceStatuses.RUNNING) {
      updateTime();
    } else {
      setTimeRemainingSeconds(timeRemaining);
    }

    return () => {
      if (timeRemainingTimer.current) {
        clearInterval(timeRemainingTimer.current);
      }
    };
  }, [timeRemaining, turnStatus]);

  useEffect(() => {
    if (timeRemainingTimer.current && Number(timeRemainingSeconds) <= 0) {
      clearInterval(timeRemainingTimer.current);
      timeRemainingTimer.current = null;
    }
  }, [timeRemainingSeconds]);

  useEffect(() => {
    let channel: Channel | null = null;

    if (pusherClient && machineId && !isDumbDumb) {
      channel = pusherClient.subscribe(`private-machine-${machineId}`);

      channel.bind("pusher:subscription_error", (error: any) => {
        console.warn("Could not subscribe to", channel?.name, error);
      });

      channel.bind(
        PUSHER_EVENTS.DEVICE_STATUS_UPDATED,
        ({
          activeTurn,
          lastCompletedTurn,
          timeRemaining,
          status,
        }: {
          lastCompletedTurn: FetchRealtimeDeviceStatusResponse["activeTurn"] | null;
          activeTurn: FetchRealtimeDeviceStatusResponse["activeTurn"];
          timeRemaining: number | null;
          status: DeviceStatuses;
        }) => {
          if (status === DeviceStatuses.ONLINE) {
            if (!isMyActivityTab) {
              selfDestroy();
            } else if (channel) {
              setTurnStatus(SelfServiceStatuses.COMPLETED);

              channel.unbind(PUSHER_EVENTS.DEVICE_STATUS_UPDATED);
              pusherClient.unsubscribe(channel.name);
              channel = null;
            }
          }

          setTimeRemaining(timeRemaining);
          const updatedTurnStatus = activeTurn?.status || lastCompletedTurn?.status;
          if (updatedTurnStatus) {
            setTurnStatus(updatedTurnStatus as SelfServiceStatuses);
          }
        }
      );
    }

    return () => {
      if (channel && pusherClient) {
        channel.unbind(PUSHER_EVENTS.DEVICE_STATUS_UPDATED);
        pusherClient.unsubscribe(channel?.name);
        channel = null;
      }
    };
  }, [pusherClient, machineId, isDumbDumb, selfDestroy, isMyActivityTab]);

  const {
    data: realtimeStatusData,
    isLoading,
    error: realtimeStatusError,
  } = useRealtimeStatus({machineId, isDumbDumb});

  useEffect(() => {
    if (!realtimeStatusData) {
      return;
    }

    const {isDumbDumb, activeTurn, lastStartedDate, timeZone, machineName, machineType} =
      realtimeStatusData;

    setIsDumbDumb(isDumbDumb);
    setMachineName(machineName);
    setMachineType(machineType);
    setTurnStatus(activeTurn?.status ?? null);
    setStartedDate(lastStartedDate);
    setTurnTimeZone(timeZone);
  }, [realtimeStatusData]);

  const machineNameWithPrefix = getMachineNameWithPrefix({
    machineName,
    machineType,
  });

  const shouldDisplayTimeRemaining =
    Number.isFinite(timeRemainingSeconds) &&
    IN_PROGRESS_TURN_STATUSES.includes(turnStatus as SelfServiceStatuses);

  const formattedStartedTime = useMemo(() => {
    if (!turnTimeZone || !startedDate) {
      return null;
    }

    return momenttz
      .tz(startedDate, STARTED_DATE_FORMAT, turnTimeZone)
      .format(STARTED_TIME_DISPLAY_FORMAT);
  }, [startedDate, turnTimeZone]);

  const handleCardClick = () => {
    if (isMyActivityTab) {
      history.push(`${SELF_ORDER_INFO_PATH}/${turnId}`);
    } else {
      toggleShowSelfServeOrderModal();
    }
  };

  const statusTextStyles = primaryColor ? {color: primaryColor} : {};

  return (
    <>
      <SelfServeOrderModal
        modalType={SelfServeOrderModalComponents.SELF_ORDER}
        isOpen={showSelfServeOrderModal}
        toggle={toggleShowSelfServeOrderModal}
        header={machineNameWithPrefix}
        turnId={turnId}
        balanceInDollars={balanceInDollars}
        customerInfo={customerInfo}
        refetchBalance={refetchBalance}
      />
      <Box onClick={handleCardClick} className={cx(className, styles.machineStatusCard)}>
        {isLoading && <BlockingLoader />}
        {realtimeStatusError ? (
          <Text className={styles.errorMessage}>
            {realtimeStatusError.response?.data?.error ?? ERROR_MESSAGES.REALTIME_STATUS}
          </Text>
        ) : (
          <>
            <Box className={styles.titleWithIcon}>
              <Image src={IconWasher} alt={EN_LOCALE.label.washingMachine} />
              <Text>{machineNameWithPrefix}</Text>
            </Box>
            {isDumbDumb ? (
              <Box className={styles.status}>
                <Text className={styles.statusText} style={statusTextStyles}>
                  Started {formattedStartedTime}
                </Text>
              </Box>
            ) : shouldDisplayTimeRemaining ? (
              <Box className={styles.timeRemainingContainer}>
                <Text className={styles.timeRemaining} style={statusTextStyles}>
                  <span className={styles.minutes}>
                    {convertSecondsToMinutes(timeRemainingSeconds)}
                  </span>{" "}
                  min{timeRemainingSeconds! / 60 > 1 ? "s" : ""}
                </Text>
              </Box>
            ) : turnStatus ? (
              <Box className={styles.status}>
                <Text className={styles.statusText} style={statusTextStyles}>
                  {getTurnStatusToDisplay(turnStatus)}
                </Text>
                {!IN_PROGRESS_TURN_STATUSES.includes(
                  turnStatus as SelfServiceStatuses
                ) ? (
                  <Image src={MachineCardCompleteIcon} alt={EN_LOCALE.label.checkMark} />
                ) : null}
              </Box>
            ) : null}
          </>
        )}
      </Box>
    </>
  );
};
