import {FC, useEffect, useRef, useState} from "react";
import {DEFAULT_CENTS_LOGO, useBranding} from "hooks/branding/useBranding";
import {useAppSelector} from "state/redux/hooks";
import {businessSelectors} from "state/redux/slices/business";
import "./styles.scss";

const enum ViewType {
  DEFAULT = "default",
  CUSTOM = "custom",
}

const CustomLogo: FC = () => {
  const {logoUrl} = useBranding();

  const businessName = useAppSelector(businessSelectors.getBusinessName);
  const storeName = useAppSelector(businessSelectors.getStoreName);

  const logoTitle: string = storeName || businessName || "";
  const [viewType, setViewType] = useState<ViewType>(ViewType.DEFAULT);
  const [loaded, setLoaded] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const delay = (ms: number): Promise<void> => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    setLoaded(false);

    if (logoUrl && !logoUrl?.includes(DEFAULT_CENTS_LOGO)) {
      setViewType(ViewType.CUSTOM);
    } else {
      setViewType(ViewType.DEFAULT);
    }
    delay(50).then(() => setLoaded(true));
  }, [logoUrl]);

  return (
    <div className="custom-logo-wrapper" ref={wrapperRef} data-loaded={loaded}>
      {viewType === ViewType.DEFAULT && logoTitle && (
        <span className="custom-logo">{logoTitle}</span>
      )}
      {viewType === ViewType.CUSTOM && logoUrl && (
        <img
          className="custom-logo"
          src={logoUrl}
          alt={logoTitle}
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
};

export default CustomLogo;
