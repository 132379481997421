import {Box, Text} from "rebass/styled-components";

import {formatCurrency} from "utils/payment";
import {secondsOrMinutesToTimeDisplay} from "utils/machineUtil";

import styles from "./styles";

interface IPriceSettingItemProps {
  label: string | null;
  price: number | null;
  text?: string | null;
  separator?: string | null;
  secondsOrMinutes?: number | null;
}

const PriceSettingItem = ({
  label,
  text,
  price,
  separator,
  secondsOrMinutes,
}: IPriceSettingItemProps) => {
  const parsedLabel = label?.split("/").join(" / ");
  const parsedPrice = price ? formatCurrency(price) : null;

  let formattedMinutes: string | null = null;

  if (secondsOrMinutes) {
    formattedMinutes = `${secondsOrMinutesToTimeDisplay(secondsOrMinutes)} ${
      separator ?? "/"
    }`;
  }

  const value = [text, formattedMinutes, parsedPrice].filter(Boolean).join(" ");

  return (
    <Box>
      {label && <Text>{parsedLabel}:</Text>}
      <Text sx={styles.priceBlock}>{value}</Text>
    </Box>
  );
};

export default PriceSettingItem;
