export const mapGoogleAddressResult = (
  result: google.maps.GeocoderResult,
  {isAddressValidationEnabled} = {isAddressValidationEnabled: true}
) => {
  const address: {
    googlePlacesId: string;
    address1?: string | null;
    city?: string | null;
    firstLevelSubdivisionCode?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
  } = {
    googlePlacesId: result.place_id,
    address1: null,
    city: null,
    firstLevelSubdivisionCode: null,
    postalCode: null,
    countryCode: null,
  };

  const address1Fields = [
    result.address_components.find((comp) => comp.types.includes("street_number"))
      ?.long_name,
    result.address_components.find((comp) => comp.types.includes("route"))?.long_name,
  ].filter((v) => v);

  // Both street_number and route are mandatory
  address.address1 = address1Fields.length === 2 ? address1Fields.join(" ") : null;

  if (!isAddressValidationEnabled && address1Fields.length === 1) {
    address.address1 = address1Fields[0];
  }

  address.city = result.address_components.find(
    (comp) =>
      comp.types.includes("locality") ||
      comp.types.includes("sublocality") ||
      comp.types.includes("administrative_area_level_3") ||
      comp.types.includes("administrative_area_level_2")
  )?.long_name;

  address.firstLevelSubdivisionCode = result.address_components.find((comp) =>
    comp.types.includes("administrative_area_level_1")
  )?.short_name;

  address.postalCode = result.address_components.find((comp) =>
    comp.types.includes("postal_code")
  )?.short_name;

  address.countryCode = result.address_components.find((comp) =>
    comp.types.includes("country")
  )?.short_name;

  return address;
};
