import httpClient from "../httpClient";
import {TEMPORARY_TOKEN_KEY} from "constants/auth";
import {type AxiosResponse} from "axios";

export type SignUpBody = {
  otp: string;
  email?: string | null;
  fullName: string;
  optOutMarketingSms: boolean;
};

export type SignUpResponse = {
  success: boolean;
  accessToken: string;
};

type SignUp = (data: SignUpBody) => Promise<AxiosResponse<SignUpResponse>>;

export const signUp: SignUp = ({otp, email, fullName, optOutMarketingSms}) => {
  return httpClient({
    method: "POST",
    url: "/live-status/auth/sign-up",
    data: {
      otp,
      email,
      fullName,
      optOutMarketingSms,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem(TEMPORARY_TOKEN_KEY)}`,
    },
    withCredentials: true,
  });
};
