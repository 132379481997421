const styles = {
  timeRemainingContainer: {
    bg: "primary",
    padding: "6px",
    justifyContent: "center",
    color: "white",
    textDecoration: "underline",
    alignItems: "center",
  },
  dateAndTimeIcon: {
    mr: "10px",
  },
  cardContainer: {
    padding: "25px 18px",
    borderRadius: "24px",
    boxShadow: "0px 2px 7px 0px rgba(0, 0, 0, 0.20);",
    margin: "30px 1.25rem",
    position: "relative",
  },
  machineName: {
    mb: "50px",
  },
  timeCounterLabel: {
    color: "primary",
    fontSize: "18px",
    mb: "16px",
  },
  buttonWrapper: {
    margin: "0 18px",
    padding: "24px 0 18px",
    flexDirection: "column",
  },
  button: {
    boxSizing: "border-box",
    margin: "0px",
    minWidth: "0px",
    padding: "18px",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  poweredByCents: {
    fontFamily: "Roboto Bold",
    width: "100%",
    textAlign: "center",
    paddingTop: "18.5px",
    fontSize: "0.75rem",
    color: "TEXT_GREY",
  },
};

export default styles;
