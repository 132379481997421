import {FC} from "react";
import classNames from "classnames";
import {OTPInput as Input} from "input-otp";
import type {SlotProps} from "input-otp";
import {OTP_CODE_LENGTH} from "constants/constants";
import {OTP_INPUT_ID} from "constants/formLabelId";
import styles from "./otpInput.module.scss";

export const OTPInput: FC<{
  value: string;
  onChange: (newValue: string) => void;
}> = ({value, onChange}) => {
  return (
    <Input
      value={value}
      onChange={onChange}
      maxLength={OTP_CODE_LENGTH}
      inputMode="numeric"
      containerClassName={styles.wrapper}
      autoFocus
      id={OTP_INPUT_ID}
      data-testid={OTP_INPUT_ID}
      render={({slots}) => {
        function Slot(props: SlotProps) {
          return (
            <div
              className={classNames(styles.slot, {
                [styles.active]: props.isActive,
              })}
            >
              {props.char !== null && <div>{props.char}</div>}
              {props.hasFakeCaret && <FakeCaret />}
            </div>
          );
        }

        function FakeCaret() {
          return (
            <div className={styles.caretWrapper}>
              <div className={styles.caret} />
            </div>
          );
        }

        function FakeDash() {
          return (
            <div className={styles.dashWrapper}>
              <div className={styles.dash} />
            </div>
          );
        }

        return (
          <>
            <div className={styles.group}>
              {slots.slice(0, 3).map((slot, idx) => (
                <Slot key={idx} {...slot} />
              ))}
            </div>

            <FakeDash />

            <div className={styles.group}>
              {slots.slice(3).map((slot, idx) => (
                <Slot key={idx} {...slot} />
              ))}
            </div>
          </>
        );
      }}
    />
  );
};
