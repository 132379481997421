import {FC, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import {OptOutInfoBanner, OptOutInfoBannerType} from "components/common/OptOutInfoBanner";
import {Button} from "components/common/buttons/Button";
import {BackButton} from "components/newOrder/common/BackButton";
import {useChooseTime} from "components/newOrder/hooks/useChooseTime";
import {useScheduleConfig} from "hooks/schedule/useScheduleConfig";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderActions, orderSelectors} from "state/redux/slices/order";
import {getScheduleDetails} from "state/redux/slices/order/selectors";
import {getBagsCount} from "state/redux/slices/order/selectors/servicesSelector";
import {FetchingStatus} from "types/common";
import {DICTIONARY} from "./assets/dictionary";
import {AvailableDatesView} from "./common/AvailableDatesView";
import {BagsCountEditDialog} from "./common/BagsCountEditDialog";
import {ScheduleFormInputTypes} from "./common/ScheduleFormPickupDeliveryInput";
import {useResetDayIndex} from "./hooks/useResetDayIndex/useResetDayIndex";
import {ScheduleWindow} from "./hooks/useScheduling/types";
import "./styles/TimeSelectionScreen.style.scss";
import "./styles/layout.scss";

export const TimeSelectionScreen: FC = () => {
  const {fetchingStatus: initialOrderStatus} = useAppSelector(
    orderSelectors.getOrderInitialData
  );
  const dispatch = useAppDispatch();
  const {url} = useRouteMatch();
  const bagsCount = useAppSelector(getBagsCount);
  const history = useHistory();
  const state = useSelector(orderSelectors.getOnlineOrderData);
  const {schedule} = state;
  const {timeSelectionType} = useParams() as {timeSelectionType: string};
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(true);
  const [isBugCountDialogVisible, setIsBugCountDialogVisible] = useState<boolean>(false);
  const dialogRef = useRef<HTMLDialogElement>(null);

  const {nearStoresNotFetched, isAutoSchedule} = useScheduleConfig();

  const isPickup: boolean = timeSelectionType === ScheduleFormInputTypes.PICKUP;
  const isDelivery: boolean = !isPickup;
  const {chooseTime = () => false, isEstimating = true} = useChooseTime({
    setIsMainButtonDisabled: setIsNextButtonDisabled,
    isAutoSchedule,
    isPickup,
  });

  const {resetDayIndex} = useResetDayIndex({isPickup});
  const {pickup, returnInfo} = useSelector(getScheduleDetails);
  const [candidateWindow, setCandidateWindow] = useState<ScheduleWindow | null>(
    isPickup ? pickup : returnInfo
  );

  const onScheduleWhenReady = (): void => {
    setCandidateWindow(null);
    chooseTime({} as ScheduleWindow);
    history.goBack();
  };

  const onSetTime = async () => {
    if (candidateWindow) {
      const isSuccessful = await chooseTime(candidateWindow);
      if (isSuccessful) {
        history.goBack();
      }
    }
  };

  useEffect(() => {
    (nearStoresNotFetched ||
      (!schedule.pickup && !schedule.returnInfo) ||
      initialOrderStatus !== FetchingStatus.Fulfilled) &&
      history.push(String(url).replace(timeSelectionType, ""));
  }, [
    nearStoresNotFetched,
    schedule,
    history,
    initialOrderStatus,
    timeSelectionType,
    url,
  ]);

  return (
    <>
      <main className="scheduling-screen time-selection">
        <section className="scheduling-screen__content">
          <header>
            <BackButton forceBack={true} additionAction={resetDayIndex} />
            <h2>
              {DICTIONARY.SCHEDULE} {timeSelectionType}
            </h2>
          </header>
          <OptOutInfoBanner type={OptOutInfoBannerType.stretch} />
          <section className="scheduling-screen__forms scrollable">
            {(schedule.pickup || schedule.returnInfo) && (
              <AvailableDatesView
                candidateWindow={candidateWindow}
                setCandidateWindow={setCandidateWindow}
                isPickup={isPickup}
                setIsMainButtonDisabled={setIsNextButtonDisabled}
                onBagCountLinkClick={() => setIsBugCountDialogVisible(true)}
                isAutoSchedule={isAutoSchedule}
              />
            )}
          </section>
          <aside className="scheduling-screen__actions sloppy">
            {isDelivery && (
              <button className="flat-text-button" onClick={onScheduleWhenReady}>
                {DICTIONARY.SCHEDULE_WHEN_READY}
              </button>
            )}

            <Button
              onClick={onSetTime}
              text={
                isAutoSchedule
                  ? DICTIONARY.SET_BOTH_TIME_TEXT
                  : DICTIONARY.SET_TIME_TEXT(timeSelectionType)
              }
              isLoading={isEstimating || isNextButtonDisabled}
            />
          </aside>
        </section>
        <BagsCountEditDialog
          forwardedRef={dialogRef}
          onCancel={() => setIsBugCountDialogVisible(false)}
          onApply={(value) => {
            setIsBugCountDialogVisible(false);
            dispatch(orderActions.setBagsCount(value));
          }}
          current={bagsCount}
          isVisible={isBugCountDialogVisible}
        />
      </main>
    </>
  );
};
