import {httpClient} from "api";

/**
 * Required to support legacy link types using token instead of id.
 * This is a forced measure, as according to FullStory,
 * almost every day users open links from SMS with the old token type.
 */
export const getServiceOrderId = (token: string) => {
  return httpClient({
    method: "GET",
    url: "/live-status/service-order/id",
    params: {
      token,
    },
  });
};
