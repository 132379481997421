const styles = {
  counterRow: {
    sx: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  circleButton: {
    width: "35px",
    height: "35px",
    cursor: "pointer",
  },
  actionIcon: {
    sx: {
      alignItems: "center",
      justifyContent: "center",
      color: "#FFFFFF",
      textAlign: "center",
      fontSize: "24px",
      fontFamily: "secondary",
    },
  },
  number: {
    sx: {
      textAlign: "center",
      fontFamily: "secondary",
      fontWeight: "600",
      minWidth: "85px",
    },
  },
};

export default styles;
