// navigation mappings object: from -> to
export const NAVIGATION_RULES: Record<string, string | string[] | number> = {
  service: "home?selectedFullService=true",
  checkout: "schedule",
  preferences: ["checkout", "service", "home"],
  schedule: ["checkout", "preferences", "service"],
  pickup: -1,
  delivery: -1,
};

export const PAGES_KEY = "LL:ORDER_SCREENS_WAY";
