import React, {useMemo} from "react";
import moment from "moment";
import {Text, Box} from "rebass/styled-components";
import type {MachineEntity} from "types";
import {MachineTypes, SelfServiceStatuses} from "../../constants";

interface StatusDescriptionProps {
  startedAt?: string | Date | null;
  status?: SelfServiceStatuses;
  machine?: MachineEntity;
  isWaitingForTurnCreation: boolean;
}

const statusTitles = (machine?: MachineEntity): Record<SelfServiceStatuses, string> => {
  const createdTitle = `Machine is pending to start`;
  const enabledTitle = `Press 'Start' on machine`;
  const runningTitle =
    machine?.type === MachineTypes.WASHER
      ? "Washing your laundry"
      : "Drying your laundry";
  const completedTitle = "Your laundry is ready!";

  return {
    [SelfServiceStatuses.CREATED]: createdTitle,
    [SelfServiceStatuses.ENABLED]: enabledTitle,
    [SelfServiceStatuses.RUNNING]: runningTitle,
    [SelfServiceStatuses.STARTED]: runningTitle,
    [SelfServiceStatuses.COMPLETED]: completedTitle,
  };
};

export const StatusDescription = ({
  status,
  machine,
  startedAt,
  isWaitingForTurnCreation,
}: StatusDescriptionProps) => {
  const startedDate = useMemo(
    () => moment(startedAt).format("hh:mma MM/DD/YYYY"),
    [startedAt]
  );

  const shouldDisplayTitle = (!machine?.isDumbDumb || isWaitingForTurnCreation) && status;

  return (
    <Box {...styles.container}>
      {shouldDisplayTitle ? (
        <Text {...styles.title}>{statusTitles(machine)[status]}</Text>
      ) : (
        <Text {...styles.dumbDescription}>Enabled {startedDate}</Text>
      )}
    </Box>
  );
};

const styles = {
  container: {
    margin: "18px 2px",
  },
  title: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "21px",
    fontStyle: "normal",
  },
  dumbDescription: {
    fontStyle: "italic",
    fontFamily: "secondary",
    color: "TEXT_GREY",
  },
};
