import {FC} from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  type OutlinedInputProps,
} from "@mui/material";
import {ExitIcon} from "assets/images";

export const InputWithReset: FC<
  {onReset: () => void; label: string; labelClassName?: string} & OutlinedInputProps
> = ({onReset, label, labelClassName, ...inputProps}) => {
  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor={label} className={labelClassName} error={inputProps.error}>
        {label}
      </InputLabel>
      <OutlinedInput
        id={label}
        type="text"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label={`Reset ${label}`}
              onClick={onReset}
              onMouseDown={onReset}
              edge="end"
            >
              <img src={ExitIcon} height="16px" alt={`Reset ${label}`} />
            </IconButton>
          </InputAdornment>
        }
        label={label}
        {...inputProps}
      />
    </FormControl>
  );
};
