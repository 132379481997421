import React from "react";
import {BottomSheet as BSLib, type BottomSheetRef} from "react-spring-bottom-sheet";

import "react-spring-bottom-sheet/dist/style.css";
import "./BottomSheet.scss";
import {paddingTopBottomSheet, DEFAULT_SNAP_HEIGHT} from "./constants";

interface BottomSheetProps {
  className?: string;
  defaultSnap?: number;
  ref: React.RefObject<BottomSheetRef>;
  children: React.ReactNode;
}

const getOpenSheetHeight = (maxHeight: number) => maxHeight - paddingTopBottomSheet;

export const BottomSheet: React.FC<BottomSheetProps> = ({
  defaultSnap = DEFAULT_SNAP_HEIGHT,
  className = "bottom-sheet",
  ref,
  children,
}) => {
  return (
    <BSLib
      ref={ref}
      defaultSnap={defaultSnap}
      blocking={false}
      open
      scrollLocking
      expandOnContentDrag
      skipInitialTransition
      snapPoints={({maxHeight}) => [defaultSnap, getOpenSheetHeight(maxHeight)]}
      className={className}
    >
      {children}
    </BSLib>
  );
};
