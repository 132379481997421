import * as Sentry from "@sentry/browser";
import type {QueryObserverResult} from "@tanstack/query-core";
import {useMutation} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {ICustomerInfo} from "state/redux/slices/customer/thunks/fetchCustomerInfoThunk";
import {
  processAdditionalVendOnMachine,
  type AdditionalVendOnMachineRequirements,
} from "api/machines";
import {EN_LOCALE} from "locales/en";
import type {ApiError} from "types/api";

export interface UseProcessAdditionalMachineVendPayload {
  refetch?: () => Promise<QueryObserverResult<ICustomerInfo | null>>;
}

/**
 * This hook is used to process additional vend on a machine, and on success, refetch the customer data
 */
export const useProcessAdditionalMachineVend = ({
  refetch,
}: UseProcessAdditionalMachineVendPayload) => {
  return useMutation<unknown, ApiError, AdditionalVendOnMachineRequirements>({
    mutationFn: async ({
      businessId,
      machineId,
      turnId,
      additionalVendAmount,
      storeCustomerId,
    }) => {
      if (
        !businessId ||
        !machineId ||
        !turnId ||
        !additionalVendAmount ||
        !storeCustomerId
      ) {
        return null;
      }

      await processAdditionalVendOnMachine({
        businessId,
        machineId,
        turnId,
        additionalVendAmount,
        storeCustomerId,
      });
    },
    onSuccess: async () => {
      if (refetch) {
        refetch(); // refetch the customer data
      }
      toast.success(
        "Success! Your additional payment has been processed and your machine is ready to start!"
      );
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast.error(
        error?.response?.data?.error ?? EN_LOCALE.machines.errors.additionalVendError
      );

      return error;
    },
  });
};
