import {DateTime} from "luxon";
import {DELIVERY_PROVIDERS, ORDER_DELIVERY_TYPES} from "constants/order";

const getSubsidy = (delivery, onDemandSubsidy) => {
  if (delivery?.type !== DELIVERY_PROVIDERS.ownDriver) {
    return onDemandSubsidy;
  }

  return 0;
};

const formatDoorDashEstimate = (doorDashEstimate) => {
  return doorDashEstimate
    ? {
        estimateId: doorDashEstimate?.estimateId,
        pickupAt: doorDashEstimate?.pickupTime,
        totalDeliveryCost: doorDashEstimate?.estimateFee,
      }
    : null;
};

export const getTotalDeliveryCost = (delivery, doorDashSubsidy) => {
  let totalCostInCents;

  if (delivery?.type === DELIVERY_PROVIDERS.ownDriver) {
    totalCostInCents = delivery?.deliveryFeeInCents / 2;
  } else {
    const priceDifference = delivery?.deliveryFeeInCents - doorDashSubsidy;
    totalCostInCents = priceDifference > 0 ? priceDifference : 0;
  }

  return totalCostInCents / 100;
};

export const formatOrderDelivery = ({
  schedule,
  pickupCourierTip,
  returnCourierTip,
  doorDashSubsidy,
  doorDashFee,
  pickupThirdPartyDeliveryId,
  returnThirdPartyDeliveryId,
}) => {
  const pickupTotalDeliveryCost = getTotalDeliveryCost(schedule.pickup, doorDashSubsidy);

  const returnTotalDeliveryCost = getTotalDeliveryCost(
    schedule.returnInfo,
    doorDashSubsidy
  );

  return {
    pickup: schedule?.pickup?.storeId
      ? {
          timingsId: schedule.pickup.id,
          storeId: schedule.pickup.storeId,
          type: ORDER_DELIVERY_TYPES.pickup,
          deliveryWindow: [
            DateTime.fromISO(schedule.pickup.startTime).valueOf(),
            DateTime.fromISO(schedule.pickup.endTime).valueOf(),
          ],
          deliveryProvider: schedule.pickup.type,
          doorDashEstimate: formatDoorDashEstimate(
            schedule.pickup?.doorDashEstimate || schedule.pickup?.estimate
          ),
          courierTip: pickupCourierTip,
          pickupAt: null,
          subsidyInCents: getSubsidy(schedule.pickup, doorDashSubsidy),
          thirdPartyDeliveryCostInCents:
            doorDashFee ||
            (schedule.pickup?.doorDashEstimate
              ? schedule.pickup.deliveryFeeInCents
              : 0) ||
            (schedule.pickup?.estimate ? schedule.pickup.estimate.estimateFee : 0),
          thirdPartyDeliveryId:
            pickupThirdPartyDeliveryId || schedule.pickup?.estimate?.estimateId || null,
          totalDeliveryCost: pickupTotalDeliveryCost,
        }
      : {},
    delivery: schedule?.returnInfo?.storeId
      ? {
          timingsId: schedule?.returnInfo?.id,
          storeId: schedule?.returnInfo?.storeId,
          type: ORDER_DELIVERY_TYPES.return,
          deliveryWindow: [
            DateTime.fromISO(schedule?.returnInfo?.startTime).valueOf(),
            DateTime.fromISO(schedule?.returnInfo?.endTime).valueOf(),
          ],
          deliveryProvider: schedule.returnInfo?.type,
          doorDashEstimate: formatDoorDashEstimate(
            schedule.returnInfo?.doorDashEstimate || schedule.returnInfo?.estimate
          ),
          courierTip: returnCourierTip,
          pickupAt: null,
          subsidyInCents: getSubsidy(schedule.returnInfo, doorDashSubsidy),
          thirdPartyDeliveryCostInCents:
            doorDashFee ||
            (schedule.returnInfo?.doorDashEstimate
              ? schedule.returnInfo.deliveryFeeInCents
              : 0) ||
            (schedule.returnInfo?.estimate
              ? schedule.returnInfo.estimate.estimateFee
              : 0),
          thirdPartyDeliveryId:
            returnThirdPartyDeliveryId ||
            schedule.returnInfo?.estimate?.estimateId ||
            null,
          totalDeliveryCost: returnTotalDeliveryCost,
        }
      : {},
  };
};
