import {httpClient} from "api";
import {INewPaymentMethod} from "types/customer";

export const getCustomerPaymentMethods = () => {
  return httpClient({
    method: "GET",
    url: `/live-status/customer/payment/payment-methods`,
  });
};

export const addCustomerPaymentMethod = (data: INewPaymentMethod) => {
  return httpClient({
    method: "POST",
    url: "/live-status/payment-methods/create",
    data,
  });
};

export const updateCustomerDefaultPaymentMethod = (paymentMethodToken: string) => {
  return httpClient({
    method: "PATCH",
    url: "/live-status/customer/payment/payment-methods/default",
    data: {
      paymentMethodToken,
    },
  });
};

export const deleteCustomerPaymentMethods = (paymentMethodTokens: string[]) => {
  return httpClient({
    method: "DELETE",
    url: "/live-status/customer/payment/payment-methods",
    data: {
      paymentMethodTokens,
    },
  });
};
