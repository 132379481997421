import {ServiceTypes, SelfServiceStatuses, AddTimeServiceTypes} from "constants/index";
import {IGenericReducer} from "types/common";

export interface ISelfServeOrder {
  machineId: number;
  topOffQuantity: number | null;
  topOffPriceInCents: number | null;
  basePriceInCents?: number | null;
}

export interface IAddTimePayload {
  machineId: number;
  deviceId: number;
  serviceType: AddTimeServiceTypes;
  topOffPriceInCents: number;
  topOffQuantity: number;
  topOffTimeInSeconds: number | null;
  turnId: number;
}

export enum DeviceStatuses {
  ONLINE = "ONLINE",
  IN_USE = "IN_USE",
  OFFLINE = "OFFLINE",
  PENDING_START = "PENDING_START",
  COMPLETED = "COMPLETED",
}

export enum LMStates {
  IDLE = "IDLE",
  START = "START",
  RUNNING = "RUNNING",
  COMPLETE = "COMPLETE",
  DOOR_OPEN = "DOOR_OPEN",
  ERROR = "ERROR",
}

export enum TopOffModes {
  PARTIAL = "PARTIAL",
  FULLCYCLE = "FULLCYCLE",
  DISABLE = "DISABLE",
}

export enum DeviceModes {
  DUMB = "DUMB",
  SERIAL = "SERIAL",
}

export interface TurnInfo {
  id: number;
  serviceType: ServiceTypes;
  centsCustomerId: number;
  status: SelfServiceStatuses;
}

export interface Device {
  id: number;
  name: string;
  isPaired: boolean;
  status: DeviceStatuses;
}

export interface IMachine {
  id: number | null;
  store: {
    id: number | null;
    address: string;
    name: string;
  };
  name: string;
  prefix: string;
  pricePerTurnInCents: number | null;
  turnTimeInMinutes: number | null;
  serialNumber: string | null;
  model: {
    capacity: string;
    manufacturer: string;
    modelName: string;
    type: string;
  };
  business: {
    id: number | null;
  };
  device?: Device;
  activeTurn?: TurnInfo;
  topOffData: ITopOffData | null;
  isAvailable: boolean;
  lastStartedDate: string;
  coinValueInCents: number | null;
  secondsPerTurn: number | null;
  isDumbDumb: boolean;
  deviceMode: DeviceModes | null;
}

export interface ITheme {
  id: number | null;
  businessName: string;
  businessId: number | null;
  primaryColor: string;
  secondaryColor: string;
  borderRadius: string;
  logoUrl: string;
  createdAt: string;
  updatedAt: string;
  normalFont: string;
  boldFont: string;
  active: boolean | null;
}

export interface IOrder {
  centsCustomerId: number | null;
}

export interface ISelfServe {
  theme: IGenericReducer<ITheme>;
}

export interface ITopOffData {
  topOffPriceInCents: number | null;
  topOffTimeInSeconds: number | null;
  topOffTimeInMinutes: number | null;
  topOffMode: TopOffModes;
}
