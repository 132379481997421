import {FetchingStatus} from "types/common";
import {IFullServiceList} from "../types";
import {LIMIT_ITEMS, OFFSET_ITEMS} from "./general";

const initialData: IFullServiceList = {
  finishedList: {
    data: {
      items: [],
      totalCount: 0,
      limit: LIMIT_ITEMS,
      offset: OFFSET_ITEMS,
    },
    fetchingStatus: FetchingStatus.Initial,
    error: {
      text: "",
      code: "",
    },
  },
  activeList: {
    data: {
      items: [],
      totalCount: 0,
      limit: LIMIT_ITEMS,
      offset: OFFSET_ITEMS,
    },
    fetchingStatus: FetchingStatus.Initial,
    error: {
      text: "",
      code: "",
    },
  },
};

export default initialData;
