import {Fragment} from "react";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {BrowserRouter as Router} from "react-router-dom";
import {RootRoute} from "./routes/RootRoute";
import {RECAPTCHA_KEY} from "./utils/config";

export const Routes = () => {
  return (
    <Fragment>
      <GoogleReCaptchaProvider
        reCaptchaKey={RECAPTCHA_KEY}
        scriptProps={{
          async: true,
        }}
      >
        <Router>
          <RootRoute />
        </Router>
      </GoogleReCaptchaProvider>
    </Fragment>
  );
};
