import {FC, useRef} from "react";
import {useTheme} from "@mui/material/styles";
import {useFlags} from "launchdarkly-react-client-sdk";
import {Box, Flex, Image, Text} from "rebass/styled-components";
import {A11yButton} from "components/common/buttons/A11yButton";
import {BackButton} from "components/newOrder/common/BackButton";
import {OrderDetailsData} from "components/orderManager/tuneUp/OrderDetails/orderDetails.types";
import useCustomerState from "hooks/useCustomerState";
import {ScrollDirection} from "hooks/useScrollDirection";
import {
  checkBusinessAssetsInSessionStorage,
  checkOOBO,
  getCurrentBusinessAssets,
  updateCurrentBusinessAssets,
} from "utils/ooboHelpers";
import {
  COMPLETED_OR_CANCELED_ORDER_STATUSES,
  ORDER_DELIVERY_UPDATABLE_STATUSES,
  ORDER_TYPES,
} from "constants/order";
import {EN_LOCALE} from "locales/en";
import {MobileMenu} from "assets/images";
import {styles, selfServeHeaderStyles} from "./Header.styles";
import {CustomLogo} from "./index";

const Header: FC<{
  onMenuClick?: () => void;
  onManageOrderClick?: () => void;
  order?: OrderDetailsData;
  isLogoNeeded?: boolean;
  headerName?: string;
  customLogoClass?: string;
  customHeaderClass?: string;
  isSelfServeHeader?: boolean;
  showSidebar?: boolean;
  showBackButton?: boolean;
  hideManageButton?: boolean;
  scrollDirection?: ScrollDirection;
  isTopOfThePage?: Boolean;
}> = ({
  onMenuClick,
  onManageOrderClick,
  order,
  isLogoNeeded = false,
  headerName = "",
  customLogoClass,
  customHeaderClass,
  isSelfServeHeader,
  showSidebar = true,
  showBackButton = false,
  hideManageButton = false,
  isTopOfThePage,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const {manageOrderUpdate} = useFlags();

  const {customerAuthToken: isLoggedIn} = useCustomerState();
  const canSaveReturnDelivery =
    !order?.delivery?.status ||
    ORDER_DELIVERY_UPDATABLE_STATUSES.includes(order?.delivery?.status);

  /** @deprecated Will be removed with manageOrderUpdate feature flag */
  const canManage =
    !manageOrderUpdate &&
    Object.keys(order || {})?.length &&
    (order?.orderType === ORDER_TYPES.residential ||
      (canSaveReturnDelivery &&
        !COMPLETED_OR_CANCELED_ORDER_STATUSES.includes(String(order?.status))));

  const headerStyles = isSelfServeHeader ? selfServeHeaderStyles : styles;

  const stickyStyleProp =
    /**
     * When it is an initial page state or the user scrolls all the way up the page,
     * there is no chance of overlapping white backgrounds at header and content,
     * so we can hide the box-shadow
     */
    typeof isTopOfThePage === "boolean"
      ? {
          style: {
            boxShadow: isTopOfThePage
              ? "" // if user is on the top of the page we don't need a box-shadow for header
              : "0 2px 4px 0 rgba(0, 0, 0, 0.1)", // if user is not on the top of the page we show a box-shadow for header
          },
        }
      : {};

  return (
    <Flex
      as="header"
      className={customHeaderClass}
      {...headerStyles.wrapper}
      ref={headerRef}
      {...stickyStyleProp}
    >
      {isLoggedIn && (
        <Box {...headerStyles.menu}>
          <A11yButton
            onAction={() => onMenuClick?.()}
            className="outline-distant"
            aria-label={EN_LOCALE.label.navigationMenu}
          >
            {isLoggedIn && showSidebar && (
              <Image
                data-testid="mobile-menu"
                height="18px"
                width="29px"
                src={MobileMenu}
                alt={EN_LOCALE.label.threeHorizontalLines}
              />
            )}
          </A11yButton>
          {showBackButton && <BackButton />}
        </Box>
      )}
      {isLogoNeeded && (
        <Flex {...headerStyles.logoWrapper} className={customLogoClass}>
          <CustomLogo />
        </Flex>
      )}

      {headerName && <Text {...headerStyles.headerName}>{headerName}</Text>}

      <Box {...headerStyles.rightContainer}>
        {!hideManageButton && canManage ? (
          <Text
            {...headerStyles.deliveryLink}
            color={theme?.palette?.primary?.main}
            onClick={() => {
              const isOoboManager = checkOOBO();
              if (isOoboManager) {
                const {orderNum} = getCurrentBusinessAssets();
                const isAssetsExist = checkBusinessAssetsInSessionStorage();
                if (orderNum && isAssetsExist) {
                  updateCurrentBusinessAssets({
                    orderNum: order?.orderCodeWithPrefix,
                  });
                }
              }
              onManageOrderClick?.();
            }}
          >
            Manage
          </Text>
        ) : null}
      </Box>
    </Flex>
  );
};

export default Header;
