import {toast} from "react-toastify";
import {ToastError} from "components/newOrder/common/Toast";
import {useAppDispatch} from "state/redux/hooks";
import {orderActions} from "state/redux/slices/order";
import {DELIVERY_TYPES} from "constants/deliveries/deliveryTypes";
import {DELIVERY_PROVIDERS, DELIVERY_TYPE_KEYS} from "constants/order";
import {getUnsuccessfulEstimateMessage} from "constants/errors/orderBuilder/scheduleErrors";
import {ScheduleWindow} from "components/newOrder/routes/schedule/hooks/useScheduling/types";

export type ProcessUnsuccessfulEstimate = (data: {
  windowData: Partial<ScheduleWindow>;
  isReturnWindowOfAutoSchedule: boolean;
}) => void;
type UseProcessUnsuccessfulEstimate = () => ProcessUnsuccessfulEstimate;

export const useProcessUnsuccessfulEstimate: UseProcessUnsuccessfulEstimate = () => {
  const dispatch = useAppDispatch();

  return ({windowData, isReturnWindowOfAutoSchedule}) => {
    const {isPickup} = windowData;

    dispatch(
      orderActions.filterDeliveryWindows({
        ...windowData,
        customWindowTypeToDelete:
          windowData.type === DELIVERY_PROVIDERS.ownDriver
            ? DELIVERY_TYPE_KEYS.OWN
            : DELIVERY_TYPE_KEYS.ON_DEMAND,
      })
    );
    toast.error(
      <ToastError
        message={getUnsuccessfulEstimateMessage({
          type:
            isPickup && !isReturnWindowOfAutoSchedule
              ? DELIVERY_TYPES.pickup
              : DELIVERY_TYPES.delivery,
        })}
      />
    );
  };
};
