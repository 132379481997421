import {createAsyncThunk} from "@reduxjs/toolkit";
import {
  verifyPhoneNumber,
  type PhoneNumberVerificationResponse,
} from "api/auth/verifyPhoneNumber";
import {
  AUTH_REDUCER_NAME,
  VERIFY_PHONE_NUMBER_AUTH2,
} from "state/redux/slices/auth/constants/actions";

export interface IVerifyPhoneNumberResponse {
  firstName: string;
  isVerified: boolean;
  lastName: string;
  optOutMarketingSms: boolean;
}

export const verifyPhoneNumberThunk = createAsyncThunk<
  PhoneNumberVerificationResponse,
  string
>(`${AUTH_REDUCER_NAME}/${VERIFY_PHONE_NUMBER_AUTH2}`, async (phoneNumber, thunkApi) => {
  try {
    const response = await verifyPhoneNumber({phoneNumber});
    return response?.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue({
      text: error?.response?.data?.error || error?.message || "Something went wrong",
      code: error?.response?.status || 500,
    });
  }
});
