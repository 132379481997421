import {ObjectValues} from "types/typeUtils";

export const PAYMENT_INTENT_STATUSES = {
  SUCCEEDED: "succeeded",
  CANCELED: "canceled",
  PROCESSING: "processing",
  FAILED: "failed",
  REQUIRES_PAYMENT_METHOD: "requires_payment_method",
  REQUIRES_CONFIRMATION: "requires_confirmation",
  REQUIRES_ACTION: "requires_action",
  REQUIRES_CAPTURE: "requires_capture",
  CARD_DECLINE_RATE_LIMIT_EXCEEDED: "card_decline_rate_limit_exceeded",
  INSUFFICIENT_FUNDS: "insufficient_funds",
  TRANSACTION_NOT_ALLOWED: "transaction_not_allowed",
} as const;
export type PaymentIntentStatuses = ObjectValues<typeof PAYMENT_INTENT_STATUSES>;
