import {ChangeEvent, FC, useCallback, useEffect, useMemo, useState} from "react";
import {Link} from "@material-ui/core";
import {Button, TextField} from "@mui/material";
import * as Sentry from "@sentry/browser";
import debounce from "lodash/debounce";
import {toast} from "react-toastify";
import {Box, Flex, Image, Text} from "rebass/styled-components";
import {DockModal} from "components/common";
import {BlockingLoader} from "components/common/BlockingLoader/BlockingLoader";
import QrReader from "components/common/QrReader/QrReader";
import {useValidateMachineCode} from "api/queries/useValidateMachineCode";
import {EN_LOCALE} from "locales/en";
import {ScanWithPhoneCamera} from "assets/images";
import styles from "./NewMachineModal.module.scss";

interface NewMachineModalProps {
  isOpen: boolean;
  businessId: number;
  toggle: () => void;
  onMachineAdd: (machineCode: string) => void;
}

const textFieldInputProps = {
  inputProps: {
    style: {
      height: "45px",
      lineHeight: "20px",
      fontSize: "16px",
      borderRadius: "24px",
      width: "90%",
      backgroundColor: "white",
    },
  },
};
const modalBackgroundColor = "#f1f4fa";

export const NewMachineModal: FC<NewMachineModalProps> = ({
  isOpen,
  businessId,
  toggle,
  onMachineAdd,
}) => {
  const [machineCode, setMachineCode] = useState<string | null>(null);
  const setMachineCodeDebounced = useMemo(() => debounce(setMachineCode, 2000), []);
  const [isScanMode, setIsScanMode] = useState<boolean>(true);
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false);
  const [inputMachineCodeText, setInputMachineCodeText] = useState<string | null>(null);

  const {
    data: {machineId} = {},
    isFetching: validateMachineCodeFetching,
    isError,
  } = useValidateMachineCode({
    code: machineCode,
    businessId,
  });

  const handleAddMachineByCode = () => {
    setIsScanMode(false);
  };
  const handleScan = useCallback((result: string) => {
    const urlParts = result.split("/");
    const lastUrlPart = urlParts[urlParts.length - 1];

    if (urlParts.length > 1 && lastUrlPart.length > 0) {
      setMachineCode(lastUrlPart);
    }
  }, []);

  const handleDecodeError = (error: string) => {
    Sentry.captureException(error);
    toast.error("Decoding error occurred");
  };

  const handleClose = () => {
    toggle();
    setIsScanMode(true);
  };

  const handleCodeSubmit = () => {
    setMachineCode(inputMachineCodeText);
  };

  const handleMachineCodeChange = ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
    setInputMachineCodeText(value);
    setShowSubmitButton(value.length >= 4);
  };

  useEffect(() => {
    if (!machineId && !machineCode) {
      return;
    }

    // make delay for machine code setting to avoid the same validation server requests
    if (machineCode && machineId) {
      onMachineAdd(machineCode);
      setMachineCodeDebounced(null);
    } else if (machineCode && isError) {
      setMachineCodeDebounced(null);
    }
  }, [machineId, machineCode, isError, onMachineAdd, setMachineCodeDebounced]);

  return (
    <DockModal
      header="New Machine"
      isOpen={isOpen}
      toggle={handleClose}
      fullWidth
      size={1}
      showExitIcon
      dockStyle={{backgroundColor: modalBackgroundColor}}
    >
      {validateMachineCodeFetching && <BlockingLoader />}
      <Box className={styles.mainContainer}>
        {isScanMode && (
          <>
            <Box className={styles.scanArea}>
              <QrReader onScan={handleScan} onDecodeError={handleDecodeError} />
            </Box>
            <Flex flexDirection="row" justifyContent="center">
              <Link
                underline="always"
                className={styles.machineByCodeLink}
                onClick={handleAddMachineByCode}
              >
                Use code to start
              </Link>
            </Flex>
          </>
        )}
        {!isScanMode && (
          <Flex flexDirection="column" alignItems="center" height="100%">
            <TextField
              className={styles.machineByCodeInput}
              margin="normal"
              variant="outlined"
              placeholder="Enter machine code"
              type="text"
              fullWidth
              InputProps={{...textFieldInputProps.inputProps}}
              inputProps={{maxLength: 25}}
              onChange={handleMachineCodeChange}
            />
            <Text className={styles.enterText}>
              Enter the 4 characters at the end of the URL underneath the machine’s QR
              code
            </Text>
            <Image
              src={ScanWithPhoneCamera}
              className={styles.codeImage}
              alt={EN_LOCALE.label.qrCode}
            />
            {showSubmitButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleCodeSubmit}
                sx={{
                  width: "90%",
                  marginTop: "auto",
                  marginBottom: "101px",
                  borderRadius: "24px",
                  height: "62px",
                  fontSize: "20px",
                  lineHeight: "28px",
                  textTransform: "none",
                }}
              >
                Submit
              </Button>
            )}
          </Flex>
        )}
      </Box>
    </DockModal>
  );
};
