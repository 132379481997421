import {FetchingStatus} from "types/common";
import {ISelfServeList} from "../types";
import {LIMIT_ITEMS, OFFSET_ITEMS} from "./general";

const initialData: ISelfServeList = {
  data: {
    items: [],
    totalCount: 0,
    limit: LIMIT_ITEMS,
    offset: OFFSET_ITEMS,
  },
  fetchingStatus: FetchingStatus.Initial,
  error: {
    text: "",
    code: "",
  },
};

export default initialData;
