import {useAppDispatch} from "state/redux/hooks";
import {orderActions} from "state/redux/slices/order";
import {useWindowsPerDay} from "./useWindowsPerDay";

export const useSetDay = (
  dayIndex: number,
  updateStateAfterChanges: (newDayIndex: number) => void,
  isSettingNextDay: boolean
) => {
  const dispatch = useAppDispatch();
  const {deliveryDays, isPickup} = useWindowsPerDay();

  let calculatedIndexDay = dayIndex - 1 === -1 ? 0 : dayIndex - 1;

  if (isSettingNextDay) {
    calculatedIndexDay =
      dayIndex + 1 === deliveryDays.length ? deliveryDays.length - 1 : dayIndex + 1;
  }

  const setDay = () => {
    dispatch(
      isPickup
        ? orderActions.setPickupDayIndex(calculatedIndexDay)
        : orderActions.setReturnDayIndex(calculatedIndexDay)
    );
    updateStateAfterChanges(calculatedIndexDay);
  };

  return setDay;
};
