import {FC} from "react";
import {IPaymentMethod} from "types/customer";
import CardItem from "./CardItem";

interface CardsListProps {
  currentPaymentMethods: IPaymentMethod[];
  handleDeletePaymentMethod: (paymentMethodToken: IPaymentMethod) => void;
  handlePaymentMethodSelection: (paymentMethodToken: string) => void;
}

const CardsList: FC<CardsListProps> = ({
  currentPaymentMethods,
  handleDeletePaymentMethod,
  handlePaymentMethodSelection,
}) => {
  return (
    <>
      {currentPaymentMethods?.length ? (
        currentPaymentMethods?.map((paymentMethod: IPaymentMethod) => (
          <CardItem
            key={`${paymentMethod.paymentMethodToken}-${paymentMethod.id}`}
            paymentMethod={paymentMethod}
            onSelection={() =>
              handlePaymentMethodSelection(paymentMethod.paymentMethodToken)
            }
            onDelete={() => handleDeletePaymentMethod(paymentMethod)}
          />
        ))
      ) : (
        <p className="no-cards">There are no saved credit cards</p>
      )}
    </>
  );
};

export default CardsList;
