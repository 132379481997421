import {type PayloadAction} from "@reduxjs/toolkit";
import {setCustomerSelectedServices} from "state/redux/slices/order/reducers/servicesReducers/setCustomerSelectedServices";
import {FETCHING_STATUS} from "constants/api";
import {DELIVERY_TYPE_KEYS} from "constants/order";
import {type ISubscription, type IPaymentMethod} from "types/customer";
import {ObjectValues} from "types/typeUtils";
import initialData from "../constants/initData";
import {type ISchedulePayload, type IOrderData, type IDeliveryDay} from "../types";
import {setAddressInput} from "./addressesReducers/setAddressInput";
import {setAddressToEstimate} from "./addressesReducers/setAddressToEstimate";
import {setIsAddressDetailsDrawerOpened} from "./addressesReducers/setIsAddressDetailsDrawerOpened";
import {setIsAddressSelectionActive} from "./addressesReducers/setIsAddressSelectionActive";
import {setIsHangDrySelected} from "./carePreferencesReducers/setIsHangDrySelected";
import {setCustomerNotes} from "./notesReducers/setCustomerNotes";
import {setHangDryInstructions} from "./notesReducers/setHangDryInstructions";
import {setOrderNotes} from "./notesReducers/setOrderNotes";
import {setPaymentMethodToken} from "./paymentDetailsReducers/setPaymentMethodToken";
import {setPromoCodeAmount} from "./promoReducers/setPromoCodeAmount";
import {setPromoCodeName} from "./promoReducers/setPromoCodeName";
import {setBagsCount} from "./servicesReducers/setBagsCount";
import {setIsDryCleaningCategorySelected} from "./servicesReducers/setIsDryCleaningCategorySelected";
import {setIsLaundryCategorySelected} from "./servicesReducers/setIsLaundryCategorySelected";
import {setIsServicesDrawerOpened} from "./servicesReducers/setIsServicesDrawerOpened";
import {setPossibleService} from "./servicesReducers/setPossibleService";
import {setSelectedModifiers} from "./servicesReducers/setSelectedModifiers";
import {setSelectedService} from "./servicesReducers/setSelectedService";
import {toggleModifiersDrawer} from "./servicesReducers/toggleModifiersDrawer";
import {setTransportationPickupDelivery} from "./transportationReducers/setTransportationPickupDelivery";
import {setTransportationReturnDelivery} from "./transportationReducers/setTransportationReturnDelivery";

export const orderReducers = {
  setDrawersLockStatus: (state: IOrderData, action: PayloadAction<boolean>) => {
    state.drawersStates.isDrawerLocked = action.payload;
  },
  setScheduleConfig: (state: IOrderData, action: PayloadAction<ISchedulePayload>) => {
    state.schedule = action.payload;
  },
  setPickupInfo: (state: IOrderData, action: PayloadAction<any>) => {
    state.schedule.pickup = action.payload;
  },
  setPickupDayIndex: (state: IOrderData, action: PayloadAction<number>) => {
    state.schedule.pickupDayIndex = action.payload;
  },
  setReturnDayIndex: (state: IOrderData, action: PayloadAction<number>) => {
    state.schedule.returnDayIndex = action.payload;
  },
  setReturnInfo: (state: IOrderData, action: PayloadAction<any>) => {
    state.schedule.returnInfo = action.payload;
  },
  setStage: (state: IOrderData, action: PayloadAction<any>) => {
    state.schedule.currentStage = action.payload;
  },
  setSubscription: (state: IOrderData, action: PayloadAction<ISubscription | any>) => {
    state.schedule.subscription = action.payload;
  },
  setGlobalTurnAround: (state: IOrderData, action: PayloadAction<number>) => {
    state.schedule.turnAround = action.payload;
  },
  setCurrentAddress: (
    state: IOrderData,
    action: PayloadAction<IOrderData["currentCustomerAddress"]>
  ) => {
    state.currentCustomerAddress = action.payload;
  },
  setEditableAddress: (
    state: IOrderData,
    action: PayloadAction<IOrderData["editableAddress"]>
  ) => {
    state.editableAddress = action.payload;
  },
  resetNearStoresData: (state: IOrderData) => {
    state.nearStoresData.fetchingStatus = FETCHING_STATUS.INITIAL;
    state.nearStoresData.error = {};
    state.nearStoresData.data = initialData.nearStoresData.data;
  },
  setDeliveryDays: (state: IOrderData, action: PayloadAction<IDeliveryDay[]>) => {
    state.nearStoresData.data.deliveryDays = action.payload;
  },
  setShowAddressDetailsModalFlag: (state: IOrderData, action: PayloadAction<boolean>) => {
    state.isShowingAddressDetailsModal = action.payload;
  },
  setShowVerificationModalFlag: (state: IOrderData, action: PayloadAction<boolean>) => {
    state.isShowingVerificationModal = action.payload;
  },
  filterDeliveryWindows: (
    state: IOrderData,
    action: PayloadAction<
      | {
          isPickup: boolean;
          customWindowTypeToDelete: ObjectValues<typeof DELIVERY_TYPE_KEYS>;
          dayIndex: number;
          day: string;
          key: string;
        }
      | any
    >
  ) => {
    const windowToDelete = action.payload;

    const {
      customWindowTypeToDelete, // for autoSchedule type, when pickup is own delivery
      isPickup,
      dayIndex,
      day: windowToFilterDay,
      key: windowToFilterKey,
    } = windowToDelete;

    const deliveryDaysSource = isPickup ? "nearStoresData" : "returnWindows";

    const deliveryDays = state[deliveryDaysSource].data.deliveryDays;

    const isDayIndexExisting = Boolean(dayIndex || dayIndex === 0);
    const dayIndexToRemove = isDayIndexExisting
      ? dayIndex
      : deliveryDays.findIndex(({dayOfWeek}) => String(dayOfWeek) === windowToFilterDay);

    if (dayIndexToRemove > -1) {
      const deliveryTypeKey: ObjectValues<typeof DELIVERY_TYPE_KEYS> =
        customWindowTypeToDelete || DELIVERY_TYPE_KEYS.ON_DEMAND;
      const dayToRemove = deliveryDays[dayIndexToRemove];
      const deliveryTypeToRemove: any = dayToRemove[deliveryTypeKey];
      const filteredDeliveryDays: any = deliveryTypeToRemove.filter(
        (possibleWindowToRemove: any) => possibleWindowToRemove.key !== windowToFilterKey
      ) as any;

      state[deliveryDaysSource].data.deliveryDays[dayIndexToRemove][deliveryTypeKey] =
        filteredDeliveryDays;
    }
  },
  resetOnlineOrderState: (_: IOrderData, action: PayloadAction<any>) => {
    return {...initialData, ...action.payload};
  },
  resetTransportationSchedulingState: (state: IOrderData) => {
    state.schedule = initialData.schedule;
    state.nearStoresData = initialData.nearStoresData;
    state.returnWindows = initialData.returnWindows;
    state.initialOrderData = initialData.initialOrderData;
    state.currentCustomerAddress = initialData.currentCustomerAddress;
    state.apiRequests.deliverySettings = initialData.apiRequests.deliverySettings;
  },

  resetTransportationWindows: (state: IOrderData) => {
    state.nearStoresData = initialData.nearStoresData;
    state.returnWindows = initialData.returnWindows;
  },

  resetReturnInfo: (state: IOrderData) => {
    state.schedule.returnInfo = initialData.schedule.returnInfo;
    state.schedule.returnDayIndex = 0;
  },
  setOOBOConflicts: (state: IOrderData, action: PayloadAction<string[]>) => {
    state.schedule.ooboConflicts = action.payload;
  },
  resetOOBOConflicts: (state: IOrderData) => {
    state.schedule.ooboConflicts = initialData.schedule.ooboConflicts;
  },
  setPaymentMethods: (state: IOrderData, action: PayloadAction<IPaymentMethod[]>) => {
    state.initialOrderData.data.paymentMethods = action.payload;
  },
  setPossibleService,
  setIsLaundryCategorySelected,
  setIsDryCleaningCategorySelected,
  setOrderNotes,
  /**
   * @deprecated Use src/state/redux/slices/order/reducers/servicesReducers/setCustomerSelectedServices.ts instead
   */
  setSelectedLaundryService: setSelectedService,
  /**
   * @deprecated Use src/state/redux/slices/order/reducers/servicesReducers/setCustomerSelectedServices.ts instead
   */
  setSelectedModifiers,
  setIsAddressSelectionActive,
  setIsAddressDetailsDrawerOpened,
  setAddressToEstimate,
  setAddressInput,
  toggleModifiersDrawer,
  setBagsCount,
  setCustomerNotes,
  setHangDryInstructions,
  setIsHangDrySelected,
  setPromoCodeAmount,
  setPromoCodeName,
  setTransportationPickupDelivery,
  setTransportationReturnDelivery,
  setPaymentMethodToken,
  setIsServicesDrawerOpened,
  setCustomerSelectedServices,
};
