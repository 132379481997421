import {type PayloadAction} from "@reduxjs/toolkit";
import {ServiceCategoryTypes, type IOrderData} from "state/redux/slices/order/types";
import {setCategorySelection} from "state/redux/slices/order/utils/setCategorySelection";

export const setIsLaundryCategorySelected = (
  state: IOrderData,
  action: PayloadAction<{
    newValue: boolean;
    shouldResetServiceData?: boolean;
  }>
) => {
  const {newValue, shouldResetServiceData = true} = action.payload;
  setCategorySelection({state, newValue, categoryType: ServiceCategoryTypes.LAUNDRY});

  if (!newValue && shouldResetServiceData) {
    state.orderBuilder.services.bagsCount = 1;
    state.orderBuilder.services.selectedService = null;
    state.orderBuilder.services.selectedModifiers = [];
  }
};
