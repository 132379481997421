import {httpClient} from "api";
import {type AxiosResponse} from "axios";
import {type TipSettings} from "state/redux/slices/business/types";
import {type TransportationPreferences} from "api/delivery/fetchGeneralDeliverySettings";

export interface Store {
  storeId: number;
  storeName: string;
  phoneNumber: string;
  address: string;
  googlePlaceId: string;
  theme: {
    primaryColor: string;
    borderRadius: string;
    logoUrl: string;
  };
  settings: {
    transportationPreference: TransportationPreferences;
    timeZone: string;
  };
  customMessage: {
    header: string | null;
    content: string;
  };
  tipSettings: TipSettings;
}

type FetchStore = (storeId: string) => Promise<AxiosResponse<Store>>;

export const fetchStore: FetchStore = (storeId: string) => {
  return httpClient({
    method: "GET",
    url: `/live-status/stores/${storeId}`,
  });
};
