import {FC} from "react";
import {Switch, Typography} from "@material-ui/core";
import {REMEMBER_TIP_ID} from "constants/formLabelId";
import {EN_LOCALE} from "locales/en";
import ToggleButton from "../ToggleButton";
import {tipSelectionStyles} from "./styles";

interface TipSelectionProps {
  tipOptions: Array<string>;
  selectedTipOption: string | null;
  isRecurringOrderEnabled?: boolean;
  isSubscriptionRemembered?: boolean;
  tipAmount: string | null;
  setSelectedTipOption: (value: string | null) => Promise<void>;
  toggleCustomTipModal: () => void;
  setSubscriptionRemembered?: (value: boolean) => void;
}

const TipSelection: FC<TipSelectionProps> = ({
  tipOptions,
  isRecurringOrderEnabled,
  isSubscriptionRemembered,
  tipAmount,
  selectedTipOption,
  setSelectedTipOption,
  toggleCustomTipModal,
  setSubscriptionRemembered,
}) => {
  const classes = tipSelectionStyles();

  const isTipCustom = !tipOptions?.includes(selectedTipOption || "") && !!tipAmount;

  const onCurrentTipClicked = async (tip: string) => {
    await setSelectedTipOption(tip === selectedTipOption ? null : tip);
  };

  return (
    <div className={classes.tipSelectionWrapper}>
      <div className={classes.flexBoxSpaceBetween}>
        <Typography className={classes.tipTextHeader}>
          {EN_LOCALE.messages.addTipForTheStaff}
        </Typography>
        <Typography className={classes.tipTextHeader}>{tipAmount}</Typography>
      </div>
      <div className={classes.tipsButtonsWrapper}>
        {tipOptions.map((tip) => (
          <ToggleButton
            key={tip}
            checked={selectedTipOption === tip}
            onClick={
              selectedTipOption === tip
                ? undefined
                : async () => await onCurrentTipClicked(tip)
            }
            aria-label={`${EN_LOCALE.label.tip} ${tip}`}
          >
            {tip}
          </ToggleButton>
        ))}
        <ToggleButton
          key="custom-tip"
          checked={isTipCustom}
          onClick={toggleCustomTipModal}
          aria-label={`${EN_LOCALE.label.custom} ${EN_LOCALE.label.tip}`}
        >
          {EN_LOCALE.label.custom}
        </ToggleButton>
      </div>
      {isRecurringOrderEnabled && (
        <div className={classes.flexBoxSpaceBetween}>
          <label htmlFor={REMEMBER_TIP_ID}>
            <Typography className={classes.recurringTipText}>
              {EN_LOCALE.label.rememberTipForRecurring}
            </Typography>
          </label>
          <Switch
            id={REMEMBER_TIP_ID}
            color="primary"
            size="medium"
            onChange={() => setSubscriptionRemembered?.(!isSubscriptionRemembered)}
            value={isSubscriptionRemembered}
            checked={isSubscriptionRemembered}
          ></Switch>
        </div>
      )}
    </div>
  );
};

export default TipSelection;
