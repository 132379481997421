import {ApiError} from "types/api";

export type ExceptionErrors = Record<string, string>;

export const formatRequestError = ({
  error,
  exceptionErrors,
  genericError,
}: {
  error: ApiError;
  exceptionErrors?: ExceptionErrors;
  genericError: string;
}) => {
  const errorMessage = error?.response?.data?.error || error.message;

  return (
    (errorMessage && exceptionErrors?.[errorMessage]) ||
    error?.response?.data?.error ||
    genericError
  );
};
