import httpClient from "../httpClient";
import {type AxiosResponse} from "axios";

export type PhoneNumberVerificationResponse = {
  success: boolean;
  temporaryToken: string;
  customer?: {
    firstName: string;
    lastName: string;
  };
};

type VerifyPhoneNumber = (data: {
  phoneNumber: string;
}) => Promise<AxiosResponse<PhoneNumberVerificationResponse>>;

export const verifyPhoneNumber: VerifyPhoneNumber = ({phoneNumber}) => {
  return httpClient({
    method: "POST",
    url: "/live-status/auth/verify-phone-number",
    data: {
      phoneNumber,
    },
  });
};
