import isEmpty from "lodash/isEmpty";
import {toast} from "react-toastify";
import {Box, Text} from "rebass/styled-components";
import {ToastError} from "components/common";
import type {CyclePricing} from "components/self-order/types";
import PriceSettingItem from "../priceSettingItem";
import styles from "./styles";

interface IPriceButtonProps {
  cyclePrices?: CyclePricing;
  vendRemaining: number | null;
  selectedCycle: string | null;
  selectedModifier: string | null;
  isDumbDumb: boolean;
  isGPIOMachine: boolean;
  pricePerTurnInCents: number | null;
}

const WasherCycleSettings = ({
  cyclePrices,
  vendRemaining,
  selectedCycle,
  selectedModifier,
  isDumbDumb,
  isGPIOMachine,
  pricePerTurnInCents,
}: IPriceButtonProps) => {
  if (
    !!cyclePrices &&
    !cyclePrices?.basePricing?.price &&
    !vendRemaining &&
    !pricePerTurnInCents
  ) {
    toast.error(<ToastError message={"Machine has incorrect prices"} />);
  }

  return (
    <Box sx={styles.settingsContainer}>
      {(!isEmpty(cyclePrices) ||
        selectedCycle ||
        vendRemaining ||
        pricePerTurnInCents) && (
        <>
          <Text sx={styles.settingsBlock}>Current settings:</Text>
          <Box sx={styles.pricesContainer}>
            {!isDumbDumb && (
              <>
                {isGPIOMachine ? (
                  <PriceSettingItem
                    label="Vend Price"
                    price={pricePerTurnInCents}
                    text={cyclePrices?.basePricing?.label || selectedCycle}
                  />
                ) : (
                  (!isEmpty(cyclePrices?.basePricing) ||
                    vendRemaining ||
                    selectedCycle) && (
                    <PriceSettingItem
                      label="Base"
                      price={cyclePrices?.basePricing?.price || vendRemaining}
                      text={cyclePrices?.basePricing?.label || selectedCycle}
                    />
                  )
                )}
                {(!isEmpty(cyclePrices?.modifierPricing) || selectedModifier) && (
                  <PriceSettingItem
                    label="Modifier"
                    price={cyclePrices?.modifierPricing?.price || null}
                    text={cyclePrices?.modifierPricing?.label || selectedModifier}
                  />
                )}
              </>
            )}
            {isDumbDumb && (
              <Text sx={styles.dumbComment}>
                Please make sure the machine is available.
              </Text>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default WasherCycleSettings;
