export const togglePlaceholder = (
  action: string,
  target: HTMLInputElement | HTMLTextAreaElement,
  setActive?: (arg: boolean) => void
): void => {
  switch (action) {
    case "show":
      [target, target.parentElement].map((element) => {
        element?.setAttribute("data-placeholder", target.placeholder);
      });
      target.placeholder = target.getAttribute("data-active-placeholder") || "";
      setActive?.(true);
      break;
    case "hide":
      target.placeholder = target.getAttribute("data-placeholder") as string;
      !target.value &&
        [target, target.parentElement].map((element) => {
          element?.removeAttribute("data-placeholder");
        });
      setActive?.(false);
      break;
  }
};

export const setLabelClassList = (
  isValid: boolean,
  active: boolean,
  disabled: boolean = false,
  className?: string,
  title?: string
): string => {
  const classList = ["formLabel"];
  className && classList.push(`${className}Label`);
  !isValid && classList.push("invalid");
  disabled && classList.push("disabled");
  active && classList.push("active");
  title && classList.push("withTitle");

  return classList.join(" ");
};
