import {useQuery} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {ERROR_MESSAGES} from "../../constants/constants";
import {ApiError} from "../../types/api";
import {validateMachineCode} from "../machines";

interface UseValidateMachineCode {
  code?: string | null;
  businessId: number;
}

interface UseValidateMachineCodeOutput {
  machineId: number;
}

export const useValidateMachineCode = ({code, businessId}: UseValidateMachineCode) => {
  return useQuery<unknown, ApiError, UseValidateMachineCodeOutput>({
    queryKey: ["useValidateMachineCode", {code, businessId}],
    queryFn: async () => {
      if (!code) {
        return null;
      }

      const {data} = await validateMachineCode(code, businessId);
      return data;
    },
    enabled: !!code,
    retry: false,
    onError: (error) => {
      toast.error(error?.response?.data?.error ?? ERROR_MESSAGES.VALIDATE_MACHINE_CODE);

      return error;
    },
  });
};
