import {ISelectedService} from "state/redux/slices/order/types/orderBuilder";
import {LaundryForm} from "types/orderBuilder/services/serviceSelector";

export const formatSelectedServices = (
  customerSelectedServices: ISelectedService[]
): LaundryForm["selectedServices"] =>
  customerSelectedServices.map((service) => ({
    id: service.id,
    selectedModifiersCount: service.customerSelectedModifiers?.length || 0,
  }));
