import {FC, Ref, useEffect, useRef} from "react";
import {InputBase} from "@mui/material";
import type {InputBaseProps} from "@mui/material/InputBase";
import classNames from "classnames";
import InputMask, {Props} from "react-input-mask";
import collapseSpaceBetweenChars from "utils/collapseSpaceBetweenChars";
import styles from "./MaskedInput.module.scss";

type MaskedInputProps = Omit<InputBaseProps, "style"> & {
  sx?: Record<string, string[]>;
  maskProps?: any;
  isFocused?: boolean;
  customRef?: Ref<HTMLInputElement>;
  maskChar?: string;
} & Omit<Props, "style" | "width">;

const MaskedInput: FC<MaskedInputProps> = ({
  onChange,
  mask,
  maskChar = "_",
  value = "",
  sx = {},
  maskProps = {},
  isFocused = true,
  customRef,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    isFocused ? inputRef?.current?.focus() : inputRef?.current?.blur();
  }, []);

  const beforeMaskedValueChange = (
    newState: {
      value: string;
      selection: {start: number; end: number} | null;
    },
    previousState: {value: string}
  ) => {
    const {value, selection} = newState;
    const {value: previousValue} = previousState;

    return collapseSpaceBetweenChars(
      maskChar,
      mask as string,
      value,
      selection,
      previousValue
    );
  };

  return (
    <InputMask
      alwaysShowMask
      onChange={onChange}
      beforeMaskedValueChange={beforeMaskedValueChange}
      value={value}
      mask={mask}
      maskChar={maskChar}
      {...maskProps}
    >
      <InputBase
        className={classNames(styles.maskedInput, styles.main)}
        sx={{...sx}}
        ref={customRef || inputRef}
        {...rest}
      />
    </InputMask>
  );
};

export default MaskedInput;
