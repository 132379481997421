import {FC} from "react";
import cx from "classnames";
import {ButtonVariant, Button} from "components/common/buttons/Button";
import {PriceTag} from "components/common/labels/PriceTag";
import {PricingType} from "components/common/labels/PriceTag/PriceTag";
import styles from "./laundryItem.module.scss";

type ServiceItem = {
  name: string;
  pricingType: PricingType;
  price: number;
};

export type ServiceWithModifiers = ServiceItem & {
  id?: number;
  modifiers?: ServiceItem[];
};

export const LaundryItem: FC<
  ServiceWithModifiers & {
    onEdit?: (id?: number) => void;
  }
> = ({id, name, pricingType, price, modifiers = [], onEdit}) => {
  return (
    <div className={styles.laundryItemWrapper}>
      <div>
        <h3>{name}</h3>
        <PriceTag pricingType={pricingType} price={price} />
      </div>
      {structuredClone(modifiers)
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((modifier) => (
          <div key={modifier.name}>
            <p className={styles.modifierName}>+ {modifier.name}</p>
            <PriceTag pricingType={modifier.pricingType} price={modifier.price} />
          </div>
        ))}
      {onEdit && (
        <Button
          variant={ButtonVariant.UNDERLINED}
          className={cx(styles.editButton, styles.serviceAction)}
          onClick={() => onEdit(id)}
        >
          Edit
        </Button>
      )}
    </div>
  );
};
