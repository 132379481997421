import {DateTime} from "luxon";

export const getWeekTimeFromMillis = (millis) => {
  const millisNumber = Number(millis);
  return DateTime.fromObject({
    weekNumber: 1,
    weekday: DateTime.fromMillis(millisNumber).weekday,
    hour: DateTime.fromMillis(millisNumber).hour,
    minute: DateTime.fromMillis(millisNumber).minute,
    second: 0,
    millisecond: 0,
  });
};

export const checkWeekTimeEquivalence = (firstTimeMillis, secondTimeMillis) => {
  const firstWeekTime = getWeekTimeFromMillis(firstTimeMillis);
  const secondWeekTime = getWeekTimeFromMillis(secondTimeMillis);

  const isEqual = firstWeekTime.toSeconds() === secondWeekTime.toSeconds();

  return isEqual;
};

export const areWindowsExists = (firstWindow, secondWindow) =>
  firstWindow?.length && secondWindow?.length;

export const areWindowsMissing = (firstWindow, secondWindow) =>
  !firstWindow?.length && !secondWindow?.length;

export const checkWeekTimeWindowsEquivalence = (firstWindow, secondWindow) => {
  if (areWindowsExists(firstWindow, secondWindow)) {
    const isStartTimeEqual = checkWeekTimeEquivalence(firstWindow[0], secondWindow[0]);
    const IsEndTimeEqual = checkWeekTimeEquivalence(firstWindow[1], secondWindow[1]);

    const isEqual = isStartTimeEqual && IsEndTimeEqual;
    return isEqual;
  }
  return areWindowsMissing(firstWindow, secondWindow);
};

export const checkSubscriptionEquivalence = ({
  existingSubscriptions,
  subscriptionIntent,
  currentCustomerAddress,
}) => {
  const isValidSubscription = !existingSubscriptions.some((existingSub) => {
    const {pickupWindow: pickupIntentWindow, returnWindow: returnIntentWindow} =
      subscriptionIntent;
    const {pickupWindow: existingPickupWindow, returnWindow: existingReturnWindow} =
      existingSub;

    const isPickupWindowsSame = checkWeekTimeWindowsEquivalence(
      pickupIntentWindow,
      existingPickupWindow
    );
    const isReturnWindowsSame = checkWeekTimeWindowsEquivalence(
      returnIntentWindow,
      existingReturnWindow
    );

    const isWindowsSame = isPickupWindowsSame && isReturnWindowsSame;

    const isAddressSame =
      existingSub?.centsCustomerAddress?.googlePlacesId ===
      currentCustomerAddress?.googlePlacesId;

    return isWindowsSame && isAddressSame;
  });

  return isValidSubscription;
};
