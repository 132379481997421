import httpClient from "../httpClient";
import {TEMPORARY_TOKEN_KEY} from "constants/auth";
import {type AxiosResponse} from "axios";

export type SignInBody = {
  otp: string;
};

export type SignInResponse = {
  success: boolean;
  accessToken: string;
};

type SignIn = (data: SignInBody) => Promise<AxiosResponse<SignInResponse>>;

export const signIn: SignIn = ({otp}) => {
  return httpClient({
    method: "POST",
    url: "/live-status/auth/sign-in",
    data: {
      otp,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem(TEMPORARY_TOKEN_KEY)}`,
    },
    withCredentials: true,
  });
};
