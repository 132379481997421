import {ChangeEvent} from "react";

export enum InputType {
  text = "text",
  radio = "radio",
  checkbox = "checkbox",
  password = "password",
  email = "email",
  number = "number",
  tel = "tel",
  search = "search",
  url = "url",
  color = "color",
  date = "date",
  datetimeLocal = "datetime-local",
  month = "month",
  time = "time",
  week = "week",
}

export interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: JSX.Element | Array<JSX.Element> | string;
  type?: InputType;
  name: string;
  placeholder?: string;
  activePlaceholder?: string;
  required?: boolean;
  className?: string;
  format?: string;
  min?: string;
  max?: string;
  pattern?: string;
  step?: string;
  value?: string;
  title?: string;
  onInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  forwardRef?: React.RefObject<HTMLInputElement>;
  disabled?: boolean;
  defaultValue?: string;
}
