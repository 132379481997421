export const deliveryProviders = {
  uber: "UBER",
  ownDriver: "OWN_DRIVER",
  doorDash: "DOORDASH",
  onDemand: "ON_DEMAND",
};

export const bufferRequiredForOrder = {
  [deliveryProviders.uber]: 10,
  [deliveryProviders.doorDash]: 10,
  [deliveryProviders.ownDriver]: 20,
};

export const orderDeliverySubFields = [
  "type",
  "timingsId",
  "deliveryProvider",
  "deliveryWindow",
  "thirdPartyDeliveryId",
  "totalDeliveryCost",
  "courierTip",
  "subsidyInCents",
  "thirdPartyDeliveryCostInCents",
];

export const onDemandDeliveryTypes = {
  pickup: "PICKUP",
  delivery: "DELIVERY",
  pickupAndDelivery: "PICKUP_AND_DELIVERY",
};

export const SERVICE_CATEGORY_TYPES = {
  DRY_CLEANING: "DRY_CLEANING",
  LAUNDRY: "LAUNDRY",
  ALTERATIONS: "ALTERATIONS",
};
