const cookieHelper = {
  getCookie: (name: string): string | undefined => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop()?.split(";").shift();
    }
  },
  setCookie: (name: string, value: string, days: number, domain: string): void => {
    if (!!value) {
      const expires = new Date(Date.now() + days * 864e5).toUTCString();
      document.cookie = `${name}=${value}; expires=${expires}; domain=${domain}; path=/;`;
    }
  },
  deleteCookie: (name: string): void => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  },
};

export default cookieHelper;
