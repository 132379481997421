import {type IOrderData, ServiceCategoryTypes} from "../types";

export const getTurnAroundsFromState = (state: IOrderData) => {
  const laundryTurnAround = Math.max(
    state.nearStoresData.data?.turnArounds?.ownDeliveryStore?.laundryTurnAroundInHours ||
      0,
    state.nearStoresData.data?.turnArounds?.onDemandDeliveryStore
      ?.laundryTurnAroundInHours || 0
  );
  const dryCleaningTurnAround = Math.max(
    state.nearStoresData.data?.turnArounds?.ownDeliveryStore
      ?.dryCleaningTurnAroundInHours || 0,
    state.nearStoresData.data?.turnArounds?.onDemandDeliveryStore
      ?.dryCleaningTurnAroundInHours || 0
  );

  return {
    [ServiceCategoryTypes.DRY_CLEANING]: dryCleaningTurnAround,
    [ServiceCategoryTypes.LAUNDRY]: laundryTurnAround,
  };
};
