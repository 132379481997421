import {type IOrderData, ServiceCategoryTypes} from "../types";
import {IOnlineOrderServices} from "./../thunks/fetchOnlineOrderServices";
import {setCategorySelection} from "./setCategorySelection";

type EvaluatePossibleService = (data: {
  state: IOrderData;
  payload: IOnlineOrderServices;
}) => void;

export const evaluatePossibleService: EvaluatePossibleService = ({state, payload}) => {
  const possibleSelectedService = state.orderBuilder.services.possibleSelectedService;

  switch (possibleSelectedService?.type) {
    case ServiceCategoryTypes.DRY_CLEANING:
      setCategorySelection({
        state,
        newValue:
          !!state.orderBuilder.services.categories.dryCleaning.availableForDelivery,
        categoryType: ServiceCategoryTypes.DRY_CLEANING,
      });
      break;
    case ServiceCategoryTypes.LAUNDRY: {
      const selectedService = payload.services.find(
        (service) =>
          service.id === possibleSelectedService.service?.id &&
          service.prices[0]?.id === possibleSelectedService.service.prices[0]?.id
      );
      if (!!selectedService?.id) {
        state.orderBuilder.services.customerSelectedServices = [
          {...selectedService, customerSelectedModifiers: null},
        ];
      }
      break;
    }
    default: {
      break;
    }
  }
};
