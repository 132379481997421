import {type PayloadAction} from "@reduxjs/toolkit";
import {type ICustomer} from "types/customer";
import {type ICustomerInitialData} from "../types/initialData";

export const setCustomerInfo = (
  state: ICustomerInitialData,
  action: PayloadAction<ICustomer>
) => {
  state.apiRequests.customerInfo.data = action.payload;
};
