export const DICTIONARY = {
  PICKUP_AND_DELIVERY: "Pickup & Delivery",
  ONE_WAY: "one way",
  SCHEDULE: "Schedule",
  NEXT: "Next",
  FREE: "Free",
  SCHEDULE_WHEN_READY: "Schedule when it’s ready",
  SCHEDULE_LATER_HEADER: "Schedule later",
  SET_BOTH_TIME_TEXT: "Schedule pickup & delivery",
  SET_TIME_TEXT: (type: string) => `Set ${type} time`,
  SCHEDULE_LATER_TEXT: (duration: string | number = 48): string =>
    `You’ll receive a text when it’s ready (est ${duration}hrs)`,
  BANNER_HEADER: (amnt: string | number = 15): string =>
    `Save ${amnt}% with a recurring order`,
  BANNER_TEXT: (amnt: string | number = 15): string =>
    `You’ll receive a reminder to get your laundry ready the night before we are scheduled to pick it up and get ${amnt}% off each time.`,
  UNSCHEDULED_HEADER: "Not yet selected",
  CAN_NOT_SET_TIME: (type: string) =>
    `Cannot set the time for ${type}, but you can try to set it manually.`,
  CAN_NOT_SET_TIME_FOR_PICKUP_AND_DELIVERY:
    "Cannot set the time for pickup and delivery, because the allowed number of bags for DoorDash service is exceeded.",
};
