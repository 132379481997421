import {useState, type FC} from "react";
import {Button as MUIButton, CircularProgress} from "@mui/material";
import type {ButtonOwnProps, ButtonProps as MUIButtonProps} from "@mui/material";
import cx from "classnames";
import {Unavailable} from "assets/images";
import styles from "./button.module.scss";

export enum ButtonVariant {
  CONTAINED = "contained",
  OUTLINED = "outlined",
  THIN_OUTLINED = "thinOutlined",
  TEXT = "text",
  UNDERLINED = "underlined",
  WITH_X = "withX",
  TOGGLE_INACTIVE = "toggleInactive",
  TOGGLE_ACTIVE = "toggleActive",
}

interface ButtonProps extends Omit<MUIButtonProps, "variant"> {
  onClick: () => void | Promise<void | unknown>;
  disabled?: boolean;
  isLoading?: boolean;
  text?: string;
  className?: string;
  variant?: ButtonVariant;
}

export const Button: FC<ButtonProps> = ({
  onClick,
  disabled,
  isLoading: isControlledLoading,
  className,
  text,
  variant = ButtonVariant.CONTAINED,
  children,
  ...otherProps
}) => {
  const [isAwaitingCallback, setIsAwaitingCallback] = useState(false);
  const handleClick = async () => {
    try {
      setIsAwaitingCallback(true);
      await onClick();
      setIsAwaitingCallback(false);
    } catch (error) {
      setIsAwaitingCallback(false);
    }
  };

  const isButtonLoading = isControlledLoading || isAwaitingCallback;

  let muiButtonVariant = variant as ButtonOwnProps["variant"];
  switch (variant) {
    case ButtonVariant.UNDERLINED:
    case ButtonVariant.WITH_X:
      muiButtonVariant = ButtonVariant.TEXT;
      break;
    case ButtonVariant.THIN_OUTLINED:
    case ButtonVariant.TOGGLE_ACTIVE:
    case ButtonVariant.TOGGLE_INACTIVE:
      muiButtonVariant = ButtonVariant.OUTLINED;
      break;
  }

  return (
    <MUIButton
      color="primary"
      variant={muiButtonVariant}
      onClick={isButtonLoading ? undefined : handleClick}
      className={cx(className, styles.button, {
        [styles.underlinedButton]: variant === ButtonVariant.UNDERLINED,
        [styles.withXButton]: variant === ButtonVariant.WITH_X,
        [styles.outlined]: variant === ButtonVariant.OUTLINED,
        [styles.contained]: variant === ButtonVariant.CONTAINED,
        [styles.toggleActive]: variant === ButtonVariant.TOGGLE_ACTIVE,
        [styles.toggleInactive]: variant === ButtonVariant.TOGGLE_INACTIVE,
      })}
      disabled={disabled || isButtonLoading}
      startIcon={isButtonLoading ? <CircularProgress color="inherit" size={14} /> : null}
      style={{
        textTransform: "unset",
      }}
      {...otherProps}
    >
      {variant === ButtonVariant.WITH_X && <Unavailable className={styles.cancelIcon} />}
      {text || children}
    </MUIButton>
  );
};
