import PropTypes from "prop-types";
import {BlackCheckIcon} from "assets/images";
import "./styles.scss";

const successToastOptions = {
  style: {
    background: "#EFF9EB",
    borderRadius: "12px",
    boxShadow:
      "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
  },
  icon: false,
  closeButton: false,
};

const ToastSuccess = ({
  primaryMessage = "Something went wrong",
  secondaryMessage = "",
}) => {
  return (
    <div className="toast-wrapper">
      <img src={BlackCheckIcon} alt="success_icon" />
      <span className="message-section">
        <p className="primary-message">{primaryMessage}</p>
        {secondaryMessage && <p className="secondary-message">{secondaryMessage}</p>}
      </span>
    </div>
  );
};

ToastSuccess.propTypes = {
  primaryMessage: PropTypes.string,
  secondaryMessage: PropTypes.string,
};

ToastSuccess.defaultProps = {
  primaryMessage: "Something went wrong",
  secondaryMessage: "",
};

export {ToastSuccess, successToastOptions};
