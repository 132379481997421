import {DateTime} from "luxon";
import {IDeliveryDay, IDeliveryWindow} from "state/redux/slices/order/types";
import {getIsToday} from "./getIsToday";
import {NAMED_DAYS} from "constants/order";

export const fixDisplayDay = (allWindows: IDeliveryWindow[], date: string) =>
  allWindows.map((window) => {
    window.display.displayDate = date;
    return window;
  });

export const formatDeliveryDays = (deliveryDays: IDeliveryDay[]) => {
  const clonedDeliveryDays: IDeliveryDay[] = JSON.parse(JSON.stringify(deliveryDays));
  return clonedDeliveryDays.map((day) => {
    const {timeZone: storeTimeZone, date, ownDelivery, onDemandDelivery} = day;

    const luxonDate = DateTime.fromISO(date);
    const isToday = getIsToday(luxonDate, storeTimeZone);
    const clientTimeZone = DateTime.local().toFormat("z");

    if (clientTimeZone !== storeTimeZone && !isToday) {
      const displayDate = luxonDate.setZone(storeTimeZone).toFormat("cccc, LLL d");
      const formattedDay = {
        ...day,
        ownDelivery: fixDisplayDay(ownDelivery, displayDate),
        onDemandDelivery: fixDisplayDay(onDemandDelivery, displayDate),
      };
      return formattedDay;
    } else if (isToday) {
      const formattedDay = {
        ...day,
        ownDelivery: fixDisplayDay(ownDelivery, NAMED_DAYS.today),
        onDemandDelivery: fixDisplayDay(onDemandDelivery, NAMED_DAYS.today),
      };
      return formattedDay;
    }

    return day;
  });
};
