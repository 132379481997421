import {createAsyncThunk} from "@reduxjs/toolkit";
import {
  ONLINE_ORDER_REDUCER,
  FETCH_ONLINE_ORDER_SERVICES,
} from "state/redux/slices/order/constants/general";
import {fetchOnlineOrderServices} from "api/service/services";
import {type ILaundryService} from "../types/orderBuilder";

export interface IOnlineOrderServices {
  serviceMultiselectEnabled: boolean;
  pricingTierId: number | null;
  services: ILaundryService[];
  turnAround: {
    turnAroundInHours: number | null;
    dryCleaningTurnAroundInHours: number | null;
    laundryTurnAroundInHours: number | null;
  };
  offerDryCleaningForDelivery: boolean;
  success: boolean;
}

export const fetchOnlineOrderServicesThunk = createAsyncThunk<
  IOnlineOrderServices,
  {
    storeId: number;
    zipCode?: string;
    isServiceFirstFlow: boolean;
  }
>(
  `${ONLINE_ORDER_REDUCER}/${FETCH_ONLINE_ORDER_SERVICES}`,
  async ({storeId, zipCode, isServiceFirstFlow}, thunkApi) => {
    try {
      const response = await fetchOnlineOrderServices({storeId, zipCode});
      return {
        ...response.data,
        isServiceFirstFlow,
      };
    } catch (error: any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);
