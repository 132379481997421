import * as Sentry from "@sentry/browser";
import {useQuery} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {getBusinessCustomerBalance} from "api/customer";
import {ERROR_MESSAGES} from "constants/constants";
import {ApiError} from "types/api";

interface UseCustomerBalancePayload {
  /** Encoded businessId */
  encodedBusinessId?: string | null;
}

export interface UseCustomerBalanceDataOutput {
  balanceInDollars: number | null;
}

export const useCustomerBalance = ({encodedBusinessId}: UseCustomerBalancePayload) => {
  return useQuery<unknown, ApiError, UseCustomerBalanceDataOutput>({
    queryKey: ["useCustomerBalance", {encodedBusinessId}],
    queryFn: async () => {
      if (!encodedBusinessId) {
        return null;
      }

      const {data} = await getBusinessCustomerBalance(encodedBusinessId);
      return data;
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast.error(error?.response?.data?.error ?? ERROR_MESSAGES.CUSTOMER_BALANCE);

      return error;
    },
  });
};
