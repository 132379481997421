import React from "react";
import cx from "classnames";
import styles from "./BlockingLoader.module.scss";

interface BlockingLoaderProps {
  className?: string;
}

export const BlockingLoader: React.FC<BlockingLoaderProps> = ({className}) => {
  return (
    <div className={cx(className, styles.loaderContainer)}>
      <div className={styles.loader}></div>
    </div>
  );
};
