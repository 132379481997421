import {createSelector} from "reselect";
import {RootState} from "state/redux/store";

export const getBusinessState = (state: RootState) => state.business;
export const getBusinessId = createSelector(
  getBusinessState,
  (state) => state.businessId
);
export const getBusinessName = createSelector(
  getBusinessState,
  (state) => state.businessName
);
export const getBusinessTheme = createSelector(getBusinessState, (state) => state.theme);
export const getBusinessThemeFetchingStatus = createSelector(
  getBusinessState,
  (state) => state.apiRequests.businessTheme.fetchingStatus
);
export const getBusinessSettingsFromState = createSelector(
  getBusinessState,
  (state) => state.apiRequests.businessSettings.data
);

export const getStoreId = createSelector(
  getBusinessState,
  (state) => state.store?.storeId
);

export const getStoreName = createSelector(
  getBusinessState,
  (state) => state.store?.storeName
);

export const getStoreAddress = createSelector(
  getBusinessState,
  (state) => state.store?.address
);

export const getStoreTheme = createSelector(
  getBusinessState,
  (state) => state.store?.theme
);

export const getStoreCustomMessage = createSelector(
  getBusinessState,
  (state) => state.store?.customMessage
);

export const getTipSettings = createSelector(
  getBusinessState,
  (state) => state.store?.tipSettings
);
