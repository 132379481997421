import {createAsyncThunk} from "@reduxjs/toolkit";
import {requestOtp as requestApiOtp} from "api/auth";
import {AUTH_REDUCER_NAME, REQUEST_OTP} from "../constants/actions";

export interface IRequestOTPResponse {
  customer: {
    firstName: string;
    lastName: string;
  };
  otpCode: string;
  phoneNumber: string;
  success: boolean;
}

export interface IRequestOTPBody {
  isAuthorized: boolean;
  storeId: string | null;
  phoneNumber: string;
}

export const requestOtp = createAsyncThunk(
  `${AUTH_REDUCER_NAME}/${REQUEST_OTP}`,
  async ({
    isAuthorized,
    phoneNumber,
    storeId,
  }: IRequestOTPBody): Promise<IRequestOTPResponse> => {
    const response = await requestApiOtp({isAuthorized, phoneNumber, storeId});
    return response.data;
  }
);
