import PropTypes from "prop-types";
import {Button, ButtonVariant} from "components/common/buttons/Button";
import ToggleButtonRadioDecoration from "./ToggleButtonRadioDecoration";

const ToggleButton = (props) => {
  const {onChange, checked, ...rest} = props;
  const render = ({withRadio, children, label, checked}) => {
    if (withRadio) {
      return (
        <div style={{display: "flex"}}>
          <ToggleButtonRadioDecoration filled={checked} />
          <div style={{paddingLeft: "9px", display: "flex"}}>{children || label}</div>
        </div>
      );
    }
    return children || label;
  };

  return (
    <Button
      onClick={async () => {
        if (onChange) {
          await onChange(!checked);
        }
      }}
      variant={checked ? ButtonVariant.TOGGLE_ACTIVE : ButtonVariant.TOGGLE_INACTIVE}
      aria-pressed={checked}
      {...rest}
    >
      {render(props)}
    </Button>
  );
};

ToggleButton.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.string,
  toggleOnColor: PropTypes.string,
  toggleOffColor: PropTypes.string,
  colorText: PropTypes.string,
  checked: PropTypes.bool,
  children: PropTypes.node,
  sx: PropTypes.object,
  className: PropTypes.string,
};

ToggleButton.defaultProps = {
  onChange: null,
  label: "Toggle",
  toggleOnColor: "primary",
  toggleOffColor: "secondary",
  colorText: "NEW_TEXT_PRIMARY_BLUE",
  checked: false,
  sx: {},
};

export default ToggleButton;
