import {httpClient} from "api";
import {
  IApplyRewardsPointsPayload,
  IEnrollCustomerPayload,
  IGetCustomerRewardsInfoPayload,
  IGetOrderLoyaltyHistoryDetailsPayload,
  IGetRewardsHistoryPayload,
  IRemoveRewardsPointsPayload,
} from "components/rewards/Rewards/redux/types";

export const getCustomerRewardsInfo = ({
  businessId,
  centsCustomerId,
}: IGetCustomerRewardsInfoPayload) => {
  return httpClient({
    method: "GET",
    url: `/common/loyalty/${businessId}/${centsCustomerId}`,
  });
};

export const postEnrollCustomer = (data: IEnrollCustomerPayload) => {
  return httpClient({
    method: "POST",
    url: `/common/loyalty/enroll`,
    data,
  });
};

export const getRewardsHistory = ({
  businessId,
  centsCustomerId,
}: IGetRewardsHistoryPayload) => {
  return httpClient({
    method: "GET",
    url: `/common/loyalty/history/${businessId}/${centsCustomerId}`,
  });
};

export const applyRewardsProgramPoints = (data: IApplyRewardsPointsPayload) => {
  return httpClient({
    method: "POST",
    url: "/common/loyalty/apply",
    data,
  });
};

export const getOrderRewardsHistoryDetails = (
  params: IGetOrderLoyaltyHistoryDetailsPayload
) => {
  return httpClient({
    method: "GET",
    url: "/common/loyalty/history/order",
    params,
  });
};

export const removeRewardsProgramPoints = (data: IRemoveRewardsPointsPayload) => {
  return httpClient({
    method: "POST",
    url: "/common/loyalty/remove",
    data,
  });
};
