import {UseApiQuery, useApiQuery} from "hooks/useApiQuery";
import {getServiceOrderId} from "api/order/getServiceOrderId";

interface QueryParams {
  orderToken: string;
}

/**
 * Required to support legacy link types using token instead of id.
 * This is a forced measure, as according to FullStory,
 * almost every day users open links from SMS with the old token type.
 */
export const useServiceOrderId: UseApiQuery<number, QueryParams> = ({
  orderToken,
  ...queryOptions
}) => {
  return useApiQuery({
    queryKey: ["useServiceOrderId", {orderToken}],
    queryFn: async () => {
      if (orderToken) {
        const {
          data: {serviceOrderId},
        } = await getServiceOrderId(orderToken);

        return serviceOrderId;
      }
    },
    // Infinity because for the same orderToken we always get the same performer
    staleTime: Infinity,
    ...queryOptions,
  });
};
