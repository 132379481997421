import {useEffect} from "react";
import {setContext} from "@sentry/browser";
import {useAppSelector} from "state/redux/hooks";
import {getBusinessId, getStoreId} from "state/redux/slices/business/selectors";
import {getCentsCustomer} from "state/redux/slices/customer/selectors/credentialsSelector";

export const useSentryContext = () => {
  const businessId = useAppSelector(getBusinessId);
  useEffect(() => {
    if (businessId) {
      setContext("Business", {
        businessId,
      });
    }
  }, [businessId]);

  const storeId = useAppSelector(getStoreId);
  useEffect(() => {
    if (storeId) {
      setContext("Store", {
        storeId,
      });
    }
  }, [storeId]);

  const centsCustomer = useAppSelector(getCentsCustomer);
  const centsCustomerId = centsCustomer?.id;
  useEffect(() => {
    if (centsCustomerId) {
      setContext("User", {
        centsCustomerId,
      });
    }
  }, [centsCustomerId]);
};
