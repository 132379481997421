import {createAsyncThunk} from "@reduxjs/toolkit";
import {
  RECURRING_ORDERS_REDUCER_NAME,
  GET_RECURRING_ORDER_DETAILS,
} from "state/redux/slices/recurringOrder/constants/actions";
import {
  getRecurringOrderDetails,
  IRecurringOrderDetails,
  RecurringOrderCentsNotification,
} from "api/recurringOrder/getRecurringOrderDetails";

export const setRecurringOrderDetailsThunk = createAsyncThunk(
  `${RECURRING_ORDERS_REDUCER_NAME}/${GET_RECURRING_ORDER_DETAILS}`,
  async ({
    recurringOrderId,
  }: {
    recurringOrderId: number;
  }): Promise<{
    recurringOrder: IRecurringOrderDetails;
    centsNotification: RecurringOrderCentsNotification;
  }> => {
    const response = await getRecurringOrderDetails({recurringOrderId});
    return response.data;
  }
);
