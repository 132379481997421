import {FC} from "react";
import {DryCleaningItem} from "components/common/sectionItems/DryCleaningItem";
import {LaundryItem} from "components/common/sectionItems/LaundryItem";
import {ServiceWithModifiers} from "components/common/sectionItems/LaundryItem/LaundryItem";

export const ServicesSectionItem: FC<{
  onDryCleaningRemove?: () => void;
  onDryCleaningEdit?: () => void;
  onServiceEdit?: (id?: number) => void;
  selectedServices: ServiceWithModifiers[];
  hasDryCleaning: boolean;
}> = ({
  onDryCleaningRemove,
  onDryCleaningEdit,
  onServiceEdit,
  selectedServices,
  hasDryCleaning,
}) => {
  return (
    <>
      {hasDryCleaning && (
        <DryCleaningItem
          onRemove={onDryCleaningRemove}
          isRemovingAvailable={Boolean(selectedServices.length)}
          onEdit={onDryCleaningEdit}
        />
      )}
      {selectedServices.map((service) => (
        <LaundryItem
          key={service.id}
          id={service.id}
          name={service.name}
          price={service.price}
          pricingType={service.pricingType}
          modifiers={service.modifiers}
          onEdit={onServiceEdit}
        />
      ))}
    </>
  );
};
