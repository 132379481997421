import {useRef} from "react";
import {isEmpty} from "lodash";
import {Box, Flex, Text} from "rebass/styled-components";
import {CustomerEntity, MachineEntity, OrderEntity} from "types";
import {toDollars} from "utils";
import {BottomSheet} from "components/common";
import {EN_LOCALE} from "locales/en";

interface SelfOrderDetailsProps {
  order: OrderEntity;
  customer: CustomerEntity;
  machine: MachineEntity;
  turnCodeWithPrefix: string;
}

export const SelfOrderDetails = ({
  order,
  turnCodeWithPrefix,
  customer,
  machine,
}: SelfOrderDetailsProps) => {
  const sheetRef = useRef(null);

  return (
    <Box>
      <BottomSheet ref={sheetRef}>
        <Box {...styles.contentWrapper}>
          <Box {...styles.mainInfo}>
            <Text {...styles.orderTitle}>{`Order #${turnCodeWithPrefix}`}</Text>
            <Box {...styles.customer}>
              <Text>{`${customer.firstName} ${customer.lastName}`}</Text>
              <Text>{customer.phoneNumber}</Text>
            </Box>
          </Box>
          <Flex {...styles.machineInfo}>
            <Text>{`${machine.prefix}-${machine.name}`}</Text>
            <Text>{toDollars(order.subtotal)}</Text>
          </Flex>
          <Box {...styles.paymentInfo}>
            <Flex justifyContent="space-between">
              <Text>{EN_LOCALE.label.subtotal}</Text>
              <Text>{toDollars(order.subtotal)}</Text>
            </Flex>
            {!isEmpty(order.promotion) && (
              <Flex justifyContent="space-between">
                <Text>
                  {EN_LOCALE.label.promoApplied} (${order.promotion?.promotionName})
                </Text>
                <Text>{`-${toDollars(order.promotion?.promotionAmount)}`}</Text>
              </Flex>
            )}
          </Box>
          <Flex {...styles.paidInfo}>
            <Text>{EN_LOCALE.label.totalPaid}</Text>
            <Text>{toDollars(order.totalPaid)}</Text>
          </Flex>
        </Box>
      </BottomSheet>
    </Box>
  );
};

const styles = {
  contentWrapper: {
    padding: "18px",
    height: "calc(100vh - 168px)",
    sx: {
      position: "relative",
    },
  },
  orderTitle: {
    color: "BLACK",
    fontSize: "24px",
    fontWeight: 700,
  },
  customer: {
    padding: "8px 0",
    fontFamily: "Roboto Regular",
    lineHeight: "16px",
    fontSize: "12px",
    fontWeight: 400,
  },
  mainInfo: {
    padding: "6px 0",
    minHeight: "90px",
    alignItems: "center",
    sx: {
      borderBottom: "1px solid",
      borderColor: "SEPERATOR_GREY",
    },
  },
  machineInfo: {
    fontSize: "16px",
    minHeight: "100px",
    alignItems: "center",
    justifyContent: "space-between",
    sx: {
      borderBottom: "1px solid",
      borderColor: "SEPERATOR_GREY",
    },
  },
  paymentInfo: {
    padding: "16px 0",
    lineHeight: "32px",
    fontFamily: "Roboto Regular",
    color: "TEXT_GREY",
    fontSize: "16px",
  },
  paidInfo: {
    width: "calc(100% + 24px)",
    padding: "0 52px 0 24px",
    fontSize: "20px",
    justifyContent: "space-between",
    lineHeight: 2,
    minHeight: "90px",
    alignItems: "center",
    sx: {
      position: "absolute",
      bottom: 0,
      left: 0,
      boxShadow: "-12px -5px 10px -12px",
    },
  },
};
