import {type PayloadAction} from "@reduxjs/toolkit";
import {type IOrderData} from "state/redux/slices/order/types";
import {type ILaundryService} from "state/redux/slices/order/types/orderBuilder";

/**
 * @deprecated Use src/state/redux/slices/order/reducers/servicesReducers/setCustomerSelectedServices.ts instead
 */
export const setSelectedService = (
  state: IOrderData,
  action: PayloadAction<ILaundryService | null>
) => {
  if (
    state.orderBuilder.services.selectedService?.id !== action.payload?.id &&
    state.orderBuilder.services.selectedModifiers !== null
  ) {
    state.orderBuilder.services.selectedModifiers = [];
  }
  state.orderBuilder.services.selectedService = action.payload;
};
