import {type PayloadAction, createSlice} from "@reduxjs/toolkit";
import {setPaymentMethods} from "state/redux/slices/customer/reducers/setPaymentMethods";
import {ICustomerInitialData} from "state/redux/slices/customer/types/initialData";
import {setRecurringOrderDetailsThunk} from "state/redux/slices/recurringOrder/thunks/setRecurringOrderDetailsThunk";
import {CentsCustomer} from "api/customer/fetchCentsCustomer";
import {FETCHING_STATUS} from "constants/api";
import {getOrderInitialData} from "../order/thunks";
import {CUSTOMER_REDUCER_NAME} from "./constants/actions";
import initialData from "./constants/initialData";
import {resetCustomer} from "./reducers/resetCustomer";
// deprecated
import {resetCustomerInfo} from "./reducers/resetCustomerInfo";
import {setAddressIdToUpdate} from "./reducers/setAddressIdToUpdate";
import {setAddressToCreate} from "./reducers/setAddressToCreate";
import {setCustomerInfo} from "./reducers/setCustomerInfo";
import {setIsPreferencePageShowing} from "./reducers/setIsPreferencePageShowing";
import {setSavedAddresses} from "./reducers/setSavedAddresses";
import {deleteAddress} from "./thunks/deleteAddress";
import {fetchCustomerInfoThunk} from "./thunks/fetchCustomerInfoThunk";
import {getPaymentMethodsThunk} from "./thunks/getPaymentMethodsThunk";
import {patchAddress} from "./thunks/patchAddress";

const customerSlice = createSlice({
  name: CUSTOMER_REDUCER_NAME,
  initialState: initialData,
  reducers: {
    setCustomerInfo, // deprecated
    resetCustomerInfo, // deprecated
    setIsPreferencePageShowing,
    setAddressIdToUpdate,
    setAddressToCreate,
    resetCustomer,
    setSavedAddresses,
    setPaymentMethods,
    setCentsCustomer: (
      state: ICustomerInitialData,
      action: PayloadAction<CentsCustomer>
    ) => {
      state.centsCustomer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerInfoThunk.pending, (state) => {
        state.apiRequests.customerInfo.fetchingStatus = FETCHING_STATUS.PENDING;
      })
      .addCase(fetchCustomerInfoThunk.fulfilled, (state, action) => {
        state.apiRequests.customerInfo.fetchingStatus = FETCHING_STATUS.FULFILLED;
        state.apiRequests.customerInfo.data = action.payload.customer;
      })
      .addCase(fetchCustomerInfoThunk.rejected, (state, action) => {
        const {error} = action;
        state.apiRequests.customerInfo.fetchingStatus = FETCHING_STATUS.REJECTED;
        state.apiRequests.customerInfo.error = {
          text: error.message,
          code: error.name,
        };
      })
      .addCase(getOrderInitialData.fulfilled, (state, action) => {
        // deprecated
        if (action.payload?.savedCustomerAddresses?.length) {
          state.addresses.savedAddresses = action.payload.savedCustomerAddresses;
        }
      })
      .addCase(patchAddress.pending, (state) => {
        state.apiRequests.putAddress.fetchingStatus = FETCHING_STATUS.PENDING;

        // deprecated
        state.apiRequests.customerInfo.fetchingStatus = FETCHING_STATUS.PENDING;
      })
      .addCase(patchAddress.fulfilled, (state, action) => {
        state.apiRequests.putAddress.fetchingStatus = FETCHING_STATUS.INITIAL;
        state.addresses.savedAddresses = action.payload.allCustomerAddresses;
        state.addresses.addressIdToUpdate = null;
        state.addresses.addressToCreate = null;

        // deprecated
        state.apiRequests.customerInfo.fetchingStatus = FETCHING_STATUS.FULFILLED;
      })
      .addCase(patchAddress.rejected, (state, action) => {
        const {error} = action;
        state.apiRequests.putAddress.fetchingStatus = FETCHING_STATUS.REJECTED;

        // deprecated
        state.apiRequests.customerInfo.fetchingStatus = FETCHING_STATUS.REJECTED;
        state.apiRequests.customerInfo.error = {
          text: error.message,
          code: error.name,
        };
      })
      .addCase(deleteAddress.pending, (state) => {
        state.apiRequests.deleteAddress.fetchingStatus = FETCHING_STATUS.PENDING;

        // deprecated
        state.apiRequests.customerInfo.fetchingStatus = FETCHING_STATUS.PENDING;
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        state.apiRequests.deleteAddress.fetchingStatus = FETCHING_STATUS.INITIAL;
        state.addresses.savedAddresses = action.payload.allCustomerAddresses;

        // deprecated
        state.apiRequests.customerInfo.fetchingStatus = FETCHING_STATUS.FULFILLED;
      })
      .addCase(deleteAddress.rejected, (state, action) => {
        const {error} = action;
        state.apiRequests.deleteAddress.fetchingStatus = FETCHING_STATUS.REJECTED;

        // deprecated
        state.apiRequests.customerInfo.fetchingStatus = FETCHING_STATUS.REJECTED;
        state.apiRequests.customerInfo.error = {
          text: error.message,
          code: error.name,
        };
      })
      .addCase(getPaymentMethodsThunk.pending, (state) => {
        state.apiRequests.paymentMethods.fetchingStatus = FETCHING_STATUS.PENDING;
      })
      .addCase(getPaymentMethodsThunk.fulfilled, (state, action) => {
        state.apiRequests.paymentMethods.fetchingStatus = FETCHING_STATUS.FULFILLED;
        state.paymentMethods = action.payload.paymentMethods;
      })
      .addCase(getPaymentMethodsThunk.rejected, (state, action) => {
        const {error} = action;

        state.apiRequests.paymentMethods.fetchingStatus = FETCHING_STATUS.REJECTED;
        state.apiRequests.paymentMethods.error = {
          text: error.message,
          code: error.name,
        };
      })
      .addCase(setRecurringOrderDetailsThunk.fulfilled, (state, action) => {
        const address = action.payload.recurringOrder.transportation.address;
        state.addresses.savedAddresses = [address];
        state.addresses.addressIdToUpdate = address.id;
      });
  },
});

export const customerActions = customerSlice.actions;
export const customerReducer = customerSlice.reducer;
