import * as Sentry from "@sentry/browser";
import type {QueryObserverResult} from "@tanstack/query-core";
import {useMutation} from "@tanstack/react-query";
import {fetchAddCredit} from "features/payment/availableCredit/api";
import {toast} from "react-toastify";
import type {UseCustomerBalanceDataOutput} from "api/queries/useCustomerBalance";
import {ERROR_MESSAGES} from "constants/constants";
import type {ApiError} from "types/api";

interface AddCreditsPayload {
  credits: number;
  paymentMethodToken: string;
  paymentMethodId?: number;
  businessId: number;
  storeId?: number;
}

export interface UseAddCreditsPayload {
  refetch?: () => Promise<QueryObserverResult<UseCustomerBalanceDataOutput | null>>;
}

export const useAddCredits = ({refetch}: UseAddCreditsPayload) => {
  return useMutation<unknown, ApiError, AddCreditsPayload>({
    mutationFn: async ({
      credits,
      paymentMethodToken,
      paymentMethodId,
      businessId,
      storeId,
    }) => {
      if (!credits || !paymentMethodToken || !businessId) {
        return null;
      }

      await fetchAddCredit({
        credits,
        paymentMethodToken,
        paymentMethodId,
        businessId,
        storeId,
      });
    },
    onSuccess: async () => {
      if (refetch) {
        await refetch();
      }
      toast.success("Success! You added new amount!");
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast.error(error?.response?.data?.error ?? ERROR_MESSAGES.ADD_CREDITS);

      return error;
    },
  });
};
