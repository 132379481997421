import {OPTS_PHONE_NUMBER} from "constants/index";

type DictionaryOutput = {
  header: string;
  firstLineText: (phoneNumber?: string) => string;
  secondLineText: string;
};

export const DICTIONARY: DictionaryOutput = {
  header: "You opted out of Order Updates",
  firstLineText: (phoneNumber = OPTS_PHONE_NUMBER) =>
    `Text START to ${phoneNumber} to opt in`,
  secondLineText:
    "Opting into messaging is required to receive verification codes and order updates",
} as const;
