import {FC} from "react";
import {ModifiersDrawer} from "components/common/drawers/ModifiersDrawer";
import {ServiceSelectionForm} from "components/common/forms/services/ServiceSelectionForm";
import {LaundryServiceIllustration} from "assets/images";
import {LaundryForm} from "types/orderBuilder/services/serviceSelector";
import styles from "./laundryCategoryForm.module.scss";

export interface LaundryCategoryFormProps extends LaundryForm {
  showBagsCounter: boolean;
  laundryCategoryTurnAround: number | null;
}

export const LaundryCategoryForm: FC<LaundryCategoryFormProps> = ({
  showBagsCounter,
  laundryCategoryTurnAround,
  selectedServices,
  serviceForModifiersDrawer,
  currentBagsCount,
  availableServices,
  isFetchingStore,
  isDoorDashOnlyStore,
  isModifiersDrawerOpened,
  onServiceToggle,
  onModifiersSave,
  onModifiersDrawerClose,
  onMinusBagCount,
  onPlusBagCount,
}) => {
  return (
    <div className={styles.categoryContainer}>
      <ServiceSelectionForm
        isLoading={isFetchingStore}
        category="Laundry"
        turnAround={laundryCategoryTurnAround ? String(laundryCategoryTurnAround) : ""}
        icon={LaundryServiceIllustration}
        isOnDemandOnlyStore={isDoorDashOnlyStore}
        selectedServices={selectedServices}
        availableServices={availableServices}
        onServiceClick={onServiceToggle}
        showBagsCounter={showBagsCounter}
        currentBagsCount={currentBagsCount}
        onMinusBagCount={onMinusBagCount}
        onPlusBagCount={onPlusBagCount}
      />
      <ModifiersDrawer
        service={serviceForModifiersDrawer}
        isOpen={isModifiersDrawerOpened}
        onSave={onModifiersSave}
        onModifiersDrawerClose={onModifiersDrawerClose}
      />
    </div>
  );
};
