import {createSlice} from "@reduxjs/toolkit";

import {FetchingStatus} from "types/common";
import {initialData} from "./initData";
import {REFERRAL_REDUCER} from "./constants";
import {getReferralProgramInfoThunk, getValidateReferralCodeThunk} from "./thunks";

const ReferralSlice = createSlice({
  name: REFERRAL_REDUCER,
  initialState: initialData,
  reducers: {
    setReferralPromoCodeData: (state, action) => {
      const {referredByCodeName, referralPromoCodeAmount} = action.payload;
      state.applyReferralInfo.referredByCodeName = referredByCodeName;
      state.applyReferralInfo.referralPromoCodeAmount = referralPromoCodeAmount;
    },
    resetReferralInfo: () => {
      return {...initialData};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReferralProgramInfoThunk.pending, (state) => {
        state.referralProgramInfo.fetchingStatus = FetchingStatus.Pending;
        state.referralProgramInfo.error = initialData.referralProgramInfo.error;
      })
      .addCase(getReferralProgramInfoThunk.fulfilled, (state, action) => {
        state.referralProgramInfo.data = action.payload;
        state.referralProgramInfo.fetchingStatus = FetchingStatus.Fulfilled;
        state.referralProgramInfo.error = initialData.referralProgramInfo.error;
      })
      .addCase(getReferralProgramInfoThunk.rejected, (state, action) => {
        const {text, code} = action.payload as {text: string; code: string};
        state.referralProgramInfo.fetchingStatus = FetchingStatus.Rejected;
        state.referralProgramInfo.error = {text, code};
      })
      .addCase(getValidateReferralCodeThunk.pending, (state) => {
        state.referralCodeValidation.fetchingStatus = FetchingStatus.Pending;
        state.referralCodeValidation.error = initialData.referralCodeValidation.error;
      })
      .addCase(getValidateReferralCodeThunk.fulfilled, (state, action) => {
        state.referralCodeValidation.data = action.payload;
        state.referralCodeValidation.fetchingStatus = FetchingStatus.Fulfilled;
        state.referralCodeValidation.error = initialData.referralCodeValidation.error;
      })
      .addCase(getValidateReferralCodeThunk.rejected, (state, action) => {
        const {text, code} = action.payload as {text: string; code: string};
        state.referralCodeValidation.fetchingStatus = FetchingStatus.Rejected;
        state.referralCodeValidation.error = {text, code};
      });
  },
});

export const referralActions = ReferralSlice.actions;
export const referralReducer = ReferralSlice.reducer;
