import {FC, useEffect, useState} from "react";
import cx from "classnames";
import {useHistory, useRouteMatch} from "react-router-dom";
import {toast} from "react-toastify";
import {Flex, Text} from "rebass/styled-components";
import {SectionActionButton} from "components/common/buttons/SectionActionButton";
import {ServicesDrawer} from "components/common/drawers/ServicesDrawer";
import {Section} from "components/common/layouts/Section";
import {CarePreferences} from "components/common/sectionItems/CarePreferences";
import {OrderNotes} from "components/common/sectionItems/OrderNotes";
import {ServicesSectionItem} from "components/common/sectionItems/ServicesSectionItem";
import {ApplyPromoWithDockModal} from "components/newOrder/common/ApplyPromo";
import OrderNotesDockModal from "components/newOrder/common/OrderNotesForm/OrderNotesDockModal";
import {SERVICE_CATEGORY_TYPES} from "components/online-order/constants";
import {ERROR_MESSAGES} from "components/online-order/online-order-form/errorMessages";
import {sectionStyles} from "components/online-order/online-order-form/finishing-up/styles.js";
import PickupAndDeliverySummary from "components/online-order/online-order-form/finishing-up/summary/PickupAndDeliverySummary";
import {referralSelectors} from "components/referral/redux";
import {useOrderConfig} from "hooks/newOrder/useOrderConfig";
import {usePromoCode} from "hooks/orderBuilder/promoCode/usePromoCode";
import {useSelectedServicesList} from "hooks/orderBuilder/services/useSelectedServicesList";
import {useServicesDrawer} from "hooks/orderBuilder/services/useServicesDrawer";
import useToggle from "hooks/useToggle";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {businessSelectors} from "state/redux/slices/business";
import {orderActions, orderSelectors} from "state/redux/slices/order";
import {formatCustomerPreferences} from "utils/formatters/formatCustomerPreferences";
import {openView} from "utils/order/openView";
import {fetchPreferences} from "api/online-order";
import {VIEWS} from "constants/order";
import {EN_LOCALE} from "locales/en";
import "../styles.scss";
import stylesModule from "./summarySection.module.scss";

export const SummarySection: FC<{
  isInvoicingOrder: boolean;
}> = ({isInvoicingOrder}) => {
  const history = useHistory();
  const {url} = useRouteMatch();
  const dispatch = useAppDispatch();
  const {dryCleaningEnabled, businessId} = useAppSelector(
    businessSelectors.getBusinessSettingsFromState
  );
  const {
    nearStoresData: {data: availableStores},
    schedule: {subscription},
    orderBuilder: {
      promo: {promoCodeAmount, promoCodeName},
      notes: {orderNotes, hangDryInstructions, customerNotes},
      services: {customerSelectedServices, selectedCategories},
    },
  } = useAppSelector(orderSelectors.getOnlineOrderData);
  const {referredByCodeName, referralPromoCodeAmount} = useAppSelector(
    referralSelectors.getApplyReferralInfo
  );

  const [selectedPreferenceNames, setSelectedPreferenceNames] = useState<string | null>(
    null
  );

  const {isOpen: showOrderNotesModal, toggle: toggleShowOrderNotesModal} = useToggle();

  const hasDryCleaning = selectedCategories.find(
    (category) => category.name === SERVICE_CATEGORY_TYPES.DRY_CLEANING
  );

  const isRecurringSubscriptionEnabled = !!subscription?.interval;

  const recurringSubscriptionDiscount =
    availableStores?.ownDeliveryStore?.recurringDiscountInPercent ||
    availableStores?.onDemandDeliveryStore?.recurringDiscountInPercent;

  const isShowingRecurringDiscount =
    isRecurringSubscriptionEnabled &&
    Boolean(recurringSubscriptionDiscount) &&
    !availableStores?.availableServices?.isCommercialPricingTier;

  const handleOrderNotesChange = (orderNotes: string) => {
    dispatch(orderActions.setOrderNotes(orderNotes));
    toggleShowOrderNotesModal();
  };

  const openPreferences = () => {
    dispatch(orderActions.setStage(VIEWS.PREFERENCES));
    openView({
      history,
      url,
      viewPath: "preferences",
      prevViewPath: "checkout",
      location: {
        prevRoute: "checkout",
      },
    });
  };

  const {validatePromoCode} = usePromoCode();

  useEffect(() => {
    // We should not send the validate request if this is the referral promo code
    if (customerSelectedServices.length && referredByCodeName !== promoCodeName) {
      if (promoCodeName) {
        validatePromoCode({
          promoName: promoCodeName,
          isServiceChanged: true,
        });
      }
    }
  }, [customerSelectedServices, promoCodeName, referredByCodeName, validatePromoCode]);

  useEffect(
    function checkReferralCode() {
      if (referredByCodeName && !promoCodeName) {
        dispatch(orderActions.setPromoCodeName(referredByCodeName));
      }
    },
    [promoCodeName, referredByCodeName, dispatch]
  );

  useEffect(() => {
    async function initPreferences() {
      try {
        const hangDryCustomerInstructions = hangDryInstructions;
        const customerPrefsResp = await fetchPreferences(businessId);
        if (customerPrefsResp.data.success) {
          const formattedPreferences = formatCustomerPreferences({
            preferences: customerPrefsResp.data.preferences,
            hangDryCustomerInstructions: hangDryCustomerInstructions || undefined,
            customerNotes: customerNotes || undefined,
          });

          setSelectedPreferenceNames(formattedPreferences);
        }
      } catch (e) {
        toast.error(ERROR_MESSAGES.GENERIC_ERROR_MSG);
      }
    }

    initPreferences();
  }, [businessId, customerNotes, hangDryInstructions]);

  const {servicesList} = useSelectedServicesList();
  const {maxLaundryTurnAround, maxDryCleaningTurnAround} = useOrderConfig();
  const {onServiceDrawerOpen, ...serviceDrawerProps} = useServicesDrawer();

  return (
    <Section
      title={EN_LOCALE.label.yourOrder}
      action={
        <SectionActionButton onClick={onServiceDrawerOpen}>
          {EN_LOCALE.button.edit}
        </SectionActionButton>
      }
    >
      <ServicesSectionItem
        hasDryCleaning={Boolean(hasDryCleaning && dryCleaningEnabled)}
        selectedServices={servicesList}
      />

      <OrderNotes notes={orderNotes || ""} onEdit={toggleShowOrderNotesModal} />

      <CarePreferences
        preferences={selectedPreferenceNames || ""}
        onEdit={openPreferences}
      />

      <div className={stylesModule.sectionItem}>
        <Flex {...styles.section.link.dataWrapper} pb="4px">
          <ApplyPromoWithDockModal
            currentPromoName={promoCodeName}
            onApply={validatePromoCode}
          />
        </Flex>
      </div>

      <div
        className={cx(stylesModule.sectionItem, stylesModule.transportationSectionItem)}
      >
        <PickupAndDeliverySummary />
      </div>

      {isShowingRecurringDiscount && (
        <Flex {...styles.promoSection.wrapper} {...styles.bottomPadding}>
          <Text {...styles.promoSection.text}>Recurring Discount</Text>
          <Text {...styles.promoSection.text}>{recurringSubscriptionDiscount}%</Text>
        </Flex>
      )}

      {promoCodeAmount && (
        <Flex {...styles.promoSection.wrapper} {...styles.bottomPadding}>
          <Text {...styles.promoSection.text}>Promo applied</Text>
          <Text {...styles.promoSection.text}>{promoCodeAmount}</Text>
        </Flex>
      )}

      {!!referredByCodeName && promoCodeName === referredByCodeName && (
        <Flex {...styles.promoSection.wrapper} {...styles.bottomPadding}>
          <Text {...styles.promoSection.text}>Discount applied</Text>
          <Text {...styles.promoSection.text}>-{referralPromoCodeAmount}</Text>
        </Flex>
      )}

      {!isInvoicingOrder && (
        <Text {...styles.noteText}>
          Your card will be charged when your order is ready for delivery or when you pick
          up in store. You will receive a text message with a link to view your order.
        </Text>
      )}

      <ServicesDrawer
        submitButtonText={EN_LOCALE.button.update}
        showBagsCounter={true}
        laundryCategoryTurnAround={maxLaundryTurnAround}
        dryCleaningTurnAround={maxDryCleaningTurnAround}
        {...serviceDrawerProps}
      />

      <OrderNotesDockModal
        isOpen={showOrderNotesModal}
        toggle={toggleShowOrderNotesModal}
        onOrderNotesChange={handleOrderNotesChange}
        orderNotes={orderNotes || ""}
        readonly={false}
      />
    </Section>
  );
};

const styles = {
  section: sectionStyles,
  bottomPadding: {
    pb: "16px",
  },
  noteText: {
    marginBottom: "14px",
    fontSize: "12px",
    color: "NEW_TEXT_GREY",
  },
  promoSection: {
    wrapper: {
      marginTop: "-10px",
      justifyContent: "space-between",
    },
    text: {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "16px",
      color: "var(--theme-color)",
    },
  },
};
