import React from "react";
import {Flex, Text} from "rebass/styled-components";

export const Footer = () => (
  <Flex {...styles.wrapper} height={"2.5rem"}>
    <Text {...styles.poweredByCents} textAlign="center">
      Powered by Cents
    </Text>
  </Flex>
);

const styles = {
  wrapper: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 1rem",
    mt: "auto",
    bg: "WHITE",
    sx: {
      position: "fixed",
      left: 0,
      bottom: 0,
      zIndex: 999,
    },
  },
  poweredByCents: {
    fontFamily: "Roboto Bold",
    width: "100%",
    color: "TEXT_GREY",
    py: ["0.6rem", "0.75rem"],
    fontSize: "0.75rem",
  },
};
