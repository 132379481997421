export enum CycleStatuses {
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  ACTIVE = "ACTIVE",
  INCOMING = "INCOMING",
}

export enum SelfServiceStatuses {
  CREATED = "CREATED",
  ENABLED = "ENABLED",
  STARTED = "STARTED",
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
}

export enum TurnStatusesDisplay {
  IN_PROGRESS = "In Progress",
  COMPLETE = "Complete",
}

export enum OrderableTypes {
  TURN = "Turn",
  SERVICE_ORDER = "ServiceOrder",
  INVENTORY_ORDER = "InventoryOrder",
  LAUNDRY_CARD = "LaundryCard",
}

export enum ServiceTypes {
  SELF_SERVICE = "SELF_SERVICE",
  FULL_SERVICE = "FULL_SERVICE",
  CUSTOMER_SERVICE = "CUSTOMER_SERVICE",
  TECHNICAL_SERVICE = "TECHNICAL_SERVICE",
}

export enum MachineTypes {
  DRYER = "DRYER",
  WASHER = "WASHER",
}

export enum MachineManufacturers {
  MIDAS = "ALLIANCE-MIDAS",
  CENT = "ALLIANCE-CENT",
  ACA = "ALLIANCE-ACA",
  MDC = "ALLIANCE-MDC",
  DEXTER = "DEXTER",
}

export enum AddTimeServiceTypes {
  ADD_TIME = "ADD_TIME",
  ADD_ADDITIONAL_VEND = "ADD_ADDITIONAL_VEND",
}

export const LOYALTY_POINTS_OPERATION_TYPES = {
  ADDED: "ADDED",
  REDEEMED: "REDEEMED",
} as const;

export enum TIP_TYPES {
  PERCENTAGE = "PERCENTAGE",
  DOLLAR_AMOUNT = "DOLLAR_AMOUNT",
}

export const SELF_SERVICE = "SELF_SERVICE";
export const SELF_SERVICE_ORDER = "SELF_SERVICE_ORDER";
export const GET_ORDER = "GET_ORDER";
export const ORIGIN = "LIVE_LINK";
export const MAX_ADD_TIME = 99;
export const OPTS_PHONE_NUMBER = import.meta.env.VITE_OPTS_PHONE_NUMBER;
