import {modifiersPricingTypeLabels} from "../../constants/order";

export {formatAddress, createAddressPayload} from "../../utils/address";

export const toDollars = (amount) => {
  return `$${amount?.toFixed(2) || "0.00"}`;
};

export const getPriceString = ({serviceCategory, prices}) => {
  const {storePrice} = prices[0];
  return `${toDollars(storePrice)} / ${
    serviceCategory.category === "PER_POUND" ? "lb" : "unit"
  }`;
};

export const getMinPriceString = ({prices}) => {
  const {minPrice} = prices[0];
  return toDollars(minPrice);
};

export const getModifierPriceString = ({price, pricingType}) => {
  const unitLabel = modifiersPricingTypeLabels[pricingType];

  return `${price?.toFixed(2)} / ${unitLabel}`;
};
