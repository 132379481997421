import React, {useMemo} from "react";
import {Button, makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {Flex, Text} from "rebass/styled-components";
import useWindowSize from "../../hooks/useWindowSize";
import {DockModal} from "./.";

const CancelSubscription = (props) => {
  const [height] = useWindowSize();
  const classes = useClasses();
  const {isOpen, toggle, dockProps, cancelSubscription} = props;

  const getSize = useMemo(() => {
    if (height >= 568) {
      return 250;
    } else {
      return 0.7 * height;
    }
  }, [height]);

  return (
    <DockModal
      {...dockProps}
      isOpen={isOpen}
      size={getSize}
      fixedSize
      provideBackOption={true}
      showExitIcon={true}
      header="Cancel Recurring Order"
      onBackClick={toggle}
    >
      <Flex className={classes.subscription}>
        <Text className={classes.subtext}>
          Are you sure you want to cancel this recurring order?
        </Text>
        <Flex className={classes.buttons}>
          <Button color="primary" className={classes.declineButton} onClick={toggle}>
            DON'T CANCEL
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.acceptButton}
            onClick={() => cancelSubscription(true)}
          >
            YES, CANCEL
          </Button>
        </Flex>
      </Flex>
    </DockModal>
  );
};

const useClasses = makeStyles({
  subscription: {
    margin: "0px 30px",
    flexDirection: "column",
    alignItems: "center",
  },
  subtext: {
    margin: "25px 0px",
    fontSize: "18px",
    fontWeight: 600,
    color: "#303651",
  },
  declineButton: {
    height: "60px",
    width: "calc(50% - 5px)",
    fontSize: "16px",
  },
  acceptButton: {
    height: "60px",
    width: "calc(50% - 5px)",
    fontSize: "16px",
    color: "#FFFFFF",
  },
  buttons: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    my: "18px",
  },
});

CancelSubscription.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  dockProps: PropTypes.object,
  cancelSubscription: PropTypes.func.isRequired,
};

CancelSubscription.defaultProps = {
  isOpen: false,
  toggle: () => {},
  dockProps: {},
};

export default CancelSubscription;
