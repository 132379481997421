import {DateTime} from "luxon";
import {getTimeFromMilliSeconds} from "utils/date";

export const isSameDay = (firstDate, secondDate, timeZone) => {
  return firstDate
    .setZone(timeZone)
    .startOf("day")
    .equals(secondDate.setZone(timeZone).startOf("day"));
};

export const isToday = (date, timeZone) => isSameDay(DateTime.local(), date, timeZone);

export const getEarliestDeliveryStartTime = (state) => {
  const {
    timeZone,
    isProcessingCompleted,
    intakeCompletedAtInMillis,
    turnAroundInHours,
    orderDelivery,
    pickupStartTimeInMillis,
  } = state;
  const {pickup} = orderDelivery || {};
  let minTimeWithTurnAround;
  if (isProcessingCompleted) {
    return DateTime.local().setZone(timeZone);
  }
  const minTime =
    pickupStartTimeInMillis ||
    pickup?.deliveryWindow?.[0] ||
    intakeCompletedAtInMillis ||
    null;
  if (!minTime) {
    return;
  }
  const minTimeObject = getTimeFromMilliSeconds(minTime, timeZone);
  minTimeWithTurnAround = minTimeObject.plus({
    hours: turnAroundInHours,
  });

  return minTimeWithTurnAround > DateTime.local().setZone(timeZone)
    ? minTimeWithTurnAround
    : DateTime.local().setZone(timeZone);
};
