import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchServicesAndModifiers} from "api/online-order";
import {
  ONLINE_ORDER_REDUCER,
  FETCH_SERVICE_AND_MODIFIERS,
} from "state/redux/slices/order/constants/general";
import {type ILaundryService} from "../types/orderBuilder";

export interface IDeliveryServices {
  services: ILaundryService[];
  success: boolean;
}

export const fetchDeliveryServices = createAsyncThunk<
  IDeliveryServices,
  {
    storeId: number | string;
    zipCode: string;
  }
>(
  `${ONLINE_ORDER_REDUCER}/${FETCH_SERVICE_AND_MODIFIERS}`,
  async ({storeId, zipCode}, thunkApi) => {
    try {
      const response = await fetchServicesAndModifiers(storeId, zipCode);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);
