export const enum PreferenceType {
  SINGLE = "single",
  MULTI = "multi",
}

export interface IPreferencesOption {
  id: number;
  businessCustomerPreferenceId: number;
  value: string;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  isDeleted: boolean | null;
  selected: boolean;
  selectionId?: number;
}

export interface IPreferenceItem {
  businessId: number;
  fieldName: string;
  id: number;
  options: Array<IPreferencesOption>;
  type: PreferenceType;
  isDeleted: boolean | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export interface IHangDry {
  enabled: boolean;
  isHangDrySelected: boolean;
  instructions: string;
  hangDryCustomerInstructions: string;
}

export interface IAccountCarePreferencesFormData {
  storeId: number | string | null;
  enabled: boolean;
  preferences: Array<IPreferenceItem>;
  hangDry: IHangDry;
  customerNotes: string;
}

export interface IAccountCarePreferencesForm {
  forwardedRef?: React.RefObject<HTMLFormElement>;
  onSubmit?: (data: Partial<IAccountCarePreferencesFormOutput>) => void;
  updatePreferenceAtomic?: (
    fieldname: string,
    filedValue: string,
    initialData: IAccountCarePreferencesFormData
  ) => Promise<void>;
  initialData: IAccountCarePreferencesFormData;
  showVisibilityCheckbox?: boolean;
}

export interface IAccountCarePreferencesFormOutput {
  storeId: string;
  hangDryCustomerInstructions: string;
  carePreferencesNote: string;
  isHangDrySelected: boolean;
  [key: string]: string | boolean;
}
