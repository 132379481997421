import {PREFIX} from "./index";

export const LAUNDRY_CATEGORY_SELECTED = `${PREFIX} Laundry category selected`;
export const LAUNDRY_CATEGORY_UNSELECTED = `${PREFIX} Laundry category unselected`;
export const LAUNDRY_SERVICE_AUTO_SELECT = `${PREFIX} Laundry service auto-select`;

export const DRY_CLEANING_CATEGORY_SELECTED = `${PREFIX} Dry Cleaning category selected`;
export const DRY_CLEANING_CATEGORY_UNSELECTED = `${PREFIX} Dry Cleaning category unselected`;

export const FORCED_MODIFIERS_SCREEN = `${PREFIX} Forcing the modifier screen to be displayed`;
