import theme from "constants/themes/theme";

const styles = {
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: "30px 0",
    gap: "8px",
  },
  button: {
    minWidth: "72px",
    height: "30px",
    textAlign: "center",
    padding: "0",
    color: theme.colors.NEW_TEXT_GREY,
    borderColor: theme.colors.NEW_TEXT_GREY,
  },
};

export default styles;
