import {Tooltip} from "@mui/material";
import {Label} from "components/common/labels/Label";
import styles from "./deliveryConflictLabel.module.scss";

export const DeliveryConflictLabel = () => {
  return (
    <Tooltip
      title="Customers are not able to view/schedule this window."
      followCursor
      classes={{tooltip: styles.tooltip}}
    >
      <div className={styles.labelContainer}>
        <Label>Internal view only</Label>
      </div>
    </Tooltip>
  );
};
