import {OrderStatuses} from "types";
import {PAYMENT_INTENT_STATUSES} from "constants/payments/paymentStatuses";
import {EN_LOCALE} from "locales/en";

export const TRANSITION_TIME = 300;

export const DELIVERY_TRACKING_ORDER_STATUSES = {
  submitted: "SUBMITTED",
  intentCreated: "INTENT_CREATED",
  scheduled: "SCHEDULED",
  enRouteToPickup: "EN_ROUTE_TO_PICKUP",
  enRouteToDropoff: "EN_ROUTE_TO_DROPOFF",
  completed: "COMPLETED",
  canceled: "CANCELLED",
};

export const VIEWS = {
  RECOMMENDED_PICKUP: "RECOMMENDED_PICKUP",
  ALL_WINDOWS_PICKUP: "ALL_WINDOWS_PICKUP",
  PREFERENCES: "PREFERENCES",
  RETURN_QUESTION: "RETURN_QUESTION",
  SUBSCRIPTION_OFFER: "SUBSCRIPTION_OFFER",
  RECOMMENDED_RETURN: "RECOMMENDED_RETURN",
  ALL_WINDOWS_RETURN: "ALL_WINDOWS_RETURN",
} as const;

export const DELIVERY_TYPE_KEYS = {
  OWN: "ownDelivery",
  ON_DEMAND: "onDemandDelivery",
} as const;

export const DELIVERY_TIMINGS_PREFERENCE_TYPES = {
  OWN_DRIVERS: "OWN_DRIVERS",
  ON_DEMAND: "ON_DEMAND",
  EARLIEST_AVAILABLE: "EARLIEST_AVAILABLE",
} as const;

export const DELIVERY_PROVIDERS = {
  uber: "UBER",
  ownDriver: "OWN_DRIVER",
  doorDash: "DOORDASH",
  onDemand: "ON_DEMAND",
};

export const ORDER_DELIVERY_TYPES = {
  pickup: "PICKUP",
  return: "RETURN",
} as const;

export const RETURN_METHODS = {
  delivery: "DELIVERY",
  inStorePickup: "IN_STORE_PICKUP",
};

export const ORDER_DELIVERY_UPDATABLE_STATUSES = [
  DELIVERY_TRACKING_ORDER_STATUSES.intentCreated,
  DELIVERY_TRACKING_ORDER_STATUSES.scheduled,
];

export const ORDER_TYPES = {
  residential: "RESIDENTIAL",
  service: "SERVICE",
  online: "ONLINE",
};

export enum DELIVERY_CONFLICTS_TYPES {
  MAX_STOPS = "MAX_STOPS",
  DELIVERY_MAX_STOPS = "DELIVERY_MAX_STOPS",
  PICKUP_MAX_STOPS = "PICKUP_MAX_STOPS",
  BUFFER_TIME = "BUFFER_TIME",
  DELIVERY_BUFFER_TIME = "DELIVERY_BUFFER_TIME",
  PICKUP_BUFFER_TIME = "PICKUP_BUFFER_TIME",
  DELIVERY_IN_PICKUP_ONLY = "DELIVERY_IN_PICKUP_ONLY",
  PICKUP_IN_DELIVERY_ONLY = "PICKUP_IN_DELIVERY_ONLY",
}

export const OOBO_CONFLICTS_TYPES = {
  [DELIVERY_CONFLICTS_TYPES.MAX_STOPS]: "Max stops for this window has been reached",
  [DELIVERY_CONFLICTS_TYPES.DELIVERY_MAX_STOPS]:
    "Max stops for Delivery window has been reached",
  [DELIVERY_CONFLICTS_TYPES.PICKUP_MAX_STOPS]:
    "Max stops for this Pickup window has been reached",
  [DELIVERY_CONFLICTS_TYPES.BUFFER_TIME]: "Buffer time for this window has passed",
  [DELIVERY_CONFLICTS_TYPES.DELIVERY_BUFFER_TIME]:
    "Buffer time for Delivery window has passed",
  [DELIVERY_CONFLICTS_TYPES.PICKUP_BUFFER_TIME]:
    "Buffer time for this Pickup window has passed",
  [DELIVERY_CONFLICTS_TYPES.DELIVERY_IN_PICKUP_ONLY]:
    "You are scheduling a Delivery in a Pickup only window",
  [DELIVERY_CONFLICTS_TYPES.PICKUP_IN_DELIVERY_ONLY]:
    "You are scheduling a Pickup in a Delivery only window",
} as const;

export const CONDITION_ORDER = {
  active: "ACTIVE",
  upcoming: "UPCOMING",
  finished: "FINISHED",
};

export const COMPLETED_OR_CANCELED_ORDER_STATUSES = ["COMPLETED", "CANCELLED"];

export const orderChoices = {
  currentOrder: "currentOrder",
  currentAndFutureOrders: "currentAndFutureOrders",
};

export const orderChoicesDisplay = {
  currentOrder: "This order only",
  currentAndFutureOrders: "This and future recurring orders",
};

export const TEXT_ME_WHEN_READY = "Text me when it's ready";
export const IN_STORE_PICKUP = "In-Store Pickup";

export const deliveryProviders = {
  uber: "UBER",
  ownDriver: "OWN_DRIVER",
  doorDash: "DOORDASH",
  onDemand: "ON_DEMAND",
};

export const SCHEDULE_TABS = {
  ourDrivers: "Our Drivers",
  flexPickup: "Flex Pickup",
};

export const NAMED_DAYS = {
  today: "Today",
  tomorrow: "Tomorrow",
};

export const CENTS_IN_DOLLAR = 100;

export const SCHEDULE = {
  pickupTitle: "Schedule Pickup",
  returnDelivery: "Return delivery",
  pickupSubTitle: "Schedule your laundry to be picked up:",
  returnDeliverySubTitle: "Schedule your laundry to be delivered:",
  availableTimesTitle: "More Available Times",
  setPickupTime: "Set pickup time",
  setReturnTime: "Set delivery time",
  setAutoScheduleTime: "Schedule pickup & delivery",
  recommended: "Recommended",
  moreTimes: "More Available Times",
  seeAllPickupTimes: "See all pickup times",
  seeAllDeliveryTimes: "See all delivery times",
  recurringOrder: "Recurring Order",
  pickup: "Pickup:",
  delivery: "Delivery:",
  pickupScheduled: "Pickup Scheduled:",
  selectPickupDate: "Select pickup date",
  selectReturnDate: "Select return date",
  returnDeliveryAuto: "Return Delivery:",
};

export const ORDER_MESSAGES = {
  scheduleNow: "I'll schedule my return delivery now",
  scheduleDeliveryNow: "Schedule delivery now",
  scheduleDeliveryLater: "Schedule delivery later",
  getTurnAroundInHours: (turnAroundInHours: number) => `est. ${turnAroundInHours} hrs`,
  getSubscriptionDiscount: (discount: number) => `SAVE ${discount}% ON EVERY ORDER`,
  recurringCall: "Save loads of time! Schedule your recurring pickup today.",
  pickupReminder:
    "You’ll receive a reminder to get your laundry ready the night before we are scheduled to pick it up. ",
  noTimes: "No Available times",
  trySelectFlex: "Try selecting Flex or a different day",
  trySelectOurDrivers: `Try selecting ${SCHEDULE_TABS.ourDrivers} or a different day`,
};

export const SCHEDULE_BUTTONS = {
  edit: "Edit",
  scheduleDeliveryNow: "Schedule delivery now",
  editScheduling: "Edit scheduling",
  notNow: "Not now",
  apply: "Apply",
  setPickup: "Set pickup time",
  confirm: "Confirm",
  cancel: "Cancel",
};

export const SEARCH_TITLES = {
  addAddress: "Add Address",
  editAddress: "Edit Address",
  savedAddresses: "Saved Addresses",
  newAddresses: "Suggested Addresses",
};

export const PLACE_HOLDER = {
  ADDRESS_DETAILS: "Address details",
  PICKUP_INSTRUCTIONS:
    "e.g. call upon arrival, entrance on right side of building, don’t ring bell, etc.",
};

export const LABEL_TEXT = {
  ADDRESS_DETAILS: "Apt / Suite / Unit",
  PICKUP_INSTRUCTIONS: "Pickup Instructions",
};

export const SUBTITLE_TEXT = {
  ADDRESS_DETAILS: "Confirm your address for pickup",
  PICKUP_INSTRUCTIONS: "Pickup Instructions",
};

export const CHECKBOX_LABEL = "Leave it at my door if I’m not home";

export const NOTES_TEXT = "We will send you updates about your pickup via text.";
export const SEARCH_ADDRESS = "Search Address";
export const NEW_ORDER = "New order";
export const NEW_ADDRESS = "Save & Continue";
export const SAVE_EDITED_ADDRESS = "Save";

export const ERRORS_MESSAGES = {
  canNotGetAddress: "Could not get selected address details",
  subscriptionExistMessage:
    "You already have an active recurring order scheduled at the same time.",
  subscriptionExistCTA: "Please choose a different time!",
  timeNotAvailable: "Sorry, this time is not available",
  chooseTime: "Please, choose another time",
  canNotSaveAddress: "An error occurred while saving your address.",
  canNotDeleteAddress: "An error occurred while deleting your address.",
  canNotDeleteAccount: "An error occurred while deleting your account.",
  canNotDeleteAccountDueToActiveOrder:
    "Active Order in Progress. Please try again after order is complete.",
};

export const modifierPricingTypes = {
  fixedPrice: "FIXED_PRICE",
  perPound: "PER_POUND",
} as const;

export const modifiersPricingTypeLabels = {
  [modifierPricingTypes.fixedPrice]: "unit",
  [modifierPricingTypes.perPound]: "lb",
};

export enum CANCELLATION_TYPES {
  NO_LONGER_NEEDED = "NO_LONGER_NEEDED",
  BY_MISTAKE = "BY_MISTAKE",
  DELIVERY_ISSUE = "DELIVERY_ISSUE",
  CUSTOM = "CUSTOM",
}

export enum OrderCancellationMessages {
  RecurringOrderSkip = "Recurring Order Skip",
}

export const HEADER_LEFT_ICON_TYPES = {
  CLOSE: "CLOSE",
  BACK: "BACK",
};

export const MAXIMUM_BAGS_COUNT = {
  GLOBAL: 20,
  DOORDASH_ONLY: 5,
} as const;

export const orderStatusesAfterProcessing = [
  OrderStatuses.READY_FOR_DRIVER_PICKUP,
  OrderStatuses.READY_FOR_PICKUP,
  OrderStatuses.COMPLETED,
];

export const ORDER_UPDATE_EXCEPTION_ERRORS = {
  [PAYMENT_INTENT_STATUSES.REQUIRES_ACTION]:
    EN_LOCALE.payment.errors.paymentIntentCaptureError,
};
