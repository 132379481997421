import {FC, ReactNode, useEffect} from "react";
import {useLDClient} from "launchdarkly-react-client-sdk";
import {useSentryContext} from "hooks/sentry/useSentryContext";
import {useAppSelector} from "state/redux/hooks";
import {getBusinessId} from "state/redux/slices/business/selectors";
import {getCentsCustomer} from "state/redux/slices/customer/selectors/credentialsSelector";
import Fullstory from "third-party/fullstory";
import Survicate from "third-party/survicate";

/**
 *
 * This provider is responsible for initializing and keeping up-to-date data
 * for Third Parties such as:
 * - LaunchDarkly (feature flags)
 * - Sentry (error tracking)
 * - Fullstory
 * - Survicate
 */
export const ThirdPartiesProvider: FC<{children: ReactNode}> = ({children}) => {
  const ldClient = useLDClient();

  const businessId = useAppSelector(getBusinessId);
  const centsCustomer = useAppSelector(getCentsCustomer);
  const centsCustomerId = centsCustomer?.id;

  useEffect(() => {
    if (businessId && Survicate?.sva) {
      Survicate.sva.setVisitorTraits({
        businessId,
      });

      Fullstory.setUserVars({
        businessId,
      });
    }
  }, [businessId]);

  useEffect(() => {
    if (Survicate?.sva && centsCustomer) {
      const {firstName, lastName, phoneNumber, email} = centsCustomer;

      Survicate.sva.setVisitorTraits({
        first_name: firstName,
        last_name: lastName,
        displayName: phoneNumber,
        phone: phoneNumber,
        uid: phoneNumber,
        email,
      });

      Fullstory.setUserVars({
        displayName: phoneNumber,
        uid: phoneNumber,
        phoneNumber,
        email,
      });
    }
  }, [centsCustomer]);

  useEffect(() => {
    if (centsCustomerId && businessId) {
      const user = {
        kind: "user",
        key: `${businessId}`,
        centsCustomerId,
        businessId: Number(businessId),
        custom: {
          businessId: Number(businessId),
        },
      };

      ldClient?.identify(user);
    } else if (businessId) {
      const user = {
        kind: "user",
        key: String(businessId),
        businessId: Number(businessId),
        custom: {
          businessId: Number(businessId),
        },
      };
      ldClient?.identify(user);
    }
  }, [businessId, centsCustomerId, ldClient]);

  useSentryContext();

  return <>{children}</>;
};
