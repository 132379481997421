import {Box, CircularProgress, Dialog, makeStyles, Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import {Button} from "components/common/buttons/Button";
import {useIsMobile} from "hooks/useIsMobile";
import {fonts} from "constants/themes/theme";

const useStyles = makeStyles(() => {
  return {
    backDrop: {
      backdropFilter: "blur(16px)",
      backgroundColor: "rgba(255, 255, 255, 0.15)",
    },
    dialogActions: {
      display: "flex",
      justifyContent: "space-around",
      padding: 0,
      margin: "0 16px 16px",
      gap: 16,
    },
    dialogTitle: {
      fontFamily: fonts["Inter"],
      fontWeight: "700",
      fontSize: 16,
      textAlign: "center",
      color: "#303651",
      width: "80%",
      margin: "16px auto 8px",
    },
    dialogContentText: {
      fontWeight: 400,
      fontSize: 14,
      textAlign: "center",
      color: "#303651",
      margin: "0 auto 24px",
    },
    dialogContent: {
      padding: 0,
      height: "50px",
      flex: "0 1 auto",
    },
    buttonCancel: {
      flexGrow: 1,
    },
    buttonSubmit: {
      flexGrow: 1,
    },
    dialogPaper: {
      maxWidth: "375px",
      height: "212px",
      overflowY: "inherit",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      borderRadius: "24px",
      boxShadow: "0px -1px 4px 0px #00000036",
    },
    dialogPaperMobile: {
      width: "100%",
      height: "212px",
      overflowY: "inherit",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      margin: 0,
      position: "absolute",
      bottom: 0,
      borderRadius: "0px 0px 24px 24px",
      boxShadow: "0px -1px 4px 0px #00000036",
    },
  };
});

export const CommonDialog = ({
  data,
  isLoading,
  isOpen,
  handleClose,
  handleSubmit,
  dialogTitle,
  dialogText,
  submitText,
  cancelText = "Cancel",
}) => {
  const isMobile = useIsMobile();
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{className: isMobile ? classes.dialogPaperMobile : classes.dialogPaper}}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography className={classes.dialogTitle}>{dialogTitle}</Typography>
      </Box>
      {dialogText && (
        <Box className={classes.dialogContent}>
          <Typography className={classes.dialogContentText}>{dialogText}</Typography>
        </Box>
      )}
      <div className={classes.dialogActions}>
        <Button onClick={handleClose} className={classes.buttonCancel} variant="outlined">
          {cancelText}
        </Button>
        <Button
          startIcon={isLoading ? <CircularProgress color="inherit" size={14} /> : null}
          onClick={() => handleSubmit(data)}
          className={classes.buttonSubmit}
        >
          {submitText}
        </Button>
      </div>
    </Dialog>
  );
};

CommonDialog.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  dialogText: PropTypes.string,
  submitText: PropTypes.string.isRequired,
};

CommonDialog.defaultProps = {
  data: {},
  isOpen: false,
  isLoading: false,
};

export default CommonDialog;
