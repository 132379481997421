import {
  IProductPrice,
  IServicePrice,
  PricingStructureTypes,
} from "state/redux/slices/order/types/services";

export const getPricingUnit = ({
  pricingStructureType,
  quantity,
}: {
  pricingStructureType?: IServicePrice["pricingStructureType"];
  quantity?: IProductPrice["quantity"];
}) => {
  if (pricingStructureType === PricingStructureTypes.FIXED_PRICE || quantity) {
    return "unit";
  } else if (pricingStructureType === PricingStructureTypes.PER_POUND) {
    return "pound";
  }
};
