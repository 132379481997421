import {IPreferenceItem} from "components/account/AccountCarePreferences/assets/AccountCarePreferencesForm/types";

type FormatCustomerPreferences = (data: {
  preferences: IPreferenceItem[];
  hangDryCustomerInstructions?: string;
  customerNotes?: string;
}) => string;

export const formatCustomerPreferences: FormatCustomerPreferences = ({
  preferences,
  hangDryCustomerInstructions,
  customerNotes,
}) => {
  const selectedPreferences = preferences.filter((preference) => {
    return preference.options.find((option) => option.selected);
  });
  const formattedPreferences = selectedPreferences

    .map((preference) => {
      const selectedOptionValues = preference.options
        .filter((option) => option.selected)
        .map((option) => option.value);
      return `${preference.fieldName}: ${selectedOptionValues.join(", ")}`;
    })
    .sort((a, b) => a.localeCompare(b));

  if (hangDryCustomerInstructions) {
    formattedPreferences.push(`Hang Dry: ${hangDryCustomerInstructions}`);
  }
  if (customerNotes) {
    formattedPreferences.push(`Preferences Notes: ${customerNotes}`);
  }

  return formattedPreferences.join(", ");
};
