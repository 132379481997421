import {useEffect, useMemo, useState} from "react";
import cx from "classnames";
import {Channel} from "pusher-js";
import {Text} from "rebass/styled-components";
import {MachineStatusCard} from "components/SelfServeHome/MachineStatusCard/MachineStatusCard";
import {BlockingLoader} from "components/common/BlockingLoader/BlockingLoader";
import usePusher from "hooks/usePusher";
import {MachineCard} from "api/machines";
import {UseAddCreditsPayload} from "api/queries/useAddCredits";
import {useMachineCards} from "api/queries/useMachineCards";
import {PUSHER_EVENTS} from "constants/constants";
import type {ICustomer} from "types/customer";
import styles from "./MachineCardsList.module.scss";

interface MachineCardsListProps {
  encodedBusinessId: string;
  centsCustomerId?: number;
  isMyActivityTab?: boolean;
  /** Callback function that is called when machine cards list fetched or updated */
  onMachineCardsChange?: (turnIds: number[]) => void;
  balanceInDollars?: number | null;
  customerInfo: Pick<ICustomer, "id" | "paymentMethods" | "addresses"> | null;
  primaryColor?: string | null;
  refetchBalance: UseAddCreditsPayload["refetch"];
}

export const MachineCardsList = ({
  encodedBusinessId,
  centsCustomerId,
  isMyActivityTab,
  onMachineCardsChange,
  balanceInDollars,
  customerInfo,
  primaryColor,
  refetchBalance,
}: MachineCardsListProps) => {
  const pusherClient = usePusher();
  const [excludedTurnIds, setExcludedTurnIds] = useState<number[]>([]);
  const [createdCards, setCreatedCards] = useState<{turnId: number; machineId: number}[]>(
    []
  );

  const {
    data: {machineCards} = {},
    isLoading,
    error,
  } = useMachineCards({
    encodedBusinessId,
  });

  const excludeCardByTurnId = (turnId: number) => {
    setExcludedTurnIds((prevExcludedTurnIds) => [...prevExcludedTurnIds, turnId]);
  };

  useEffect(() => {
    let channel: Channel | null = null;

    if (pusherClient && centsCustomerId) {
      channel = pusherClient.subscribe(`private-centsCustomer-${centsCustomerId}`);

      channel.bind("pusher:subscription_error", (error: any) => {
        console.warn("Could not subscribe to", channel?.name, error);
      });

      channel.bind(
        PUSHER_EVENTS.TURN_CREATED,
        ({machineId, turnId}: {machineId: number; turnId: number}) => {
          setCreatedCards((prevCards) => {
            if (prevCards.some((card) => card.turnId === turnId)) {
              return prevCards;
            }

            return [
              ...prevCards,
              {
                machineId,
                turnId,
              },
            ];
          });
        }
      );
    }

    return () => {
      if (channel && pusherClient) {
        channel.unbind(PUSHER_EVENTS.TURN_CREATED);
        pusherClient.unsubscribe(channel?.name);
        channel = null;
      }
    };
  }, [pusherClient, centsCustomerId]);

  const sortedMachineCards = useMemo<MachineCard[]>(() => {
    if (!machineCards?.length) {
      return [] as MachineCard[];
    }

    return machineCards
      .concat(createdCards as MachineCard[])
      .filter(({turnId}) => !excludedTurnIds.includes(turnId));
  }, [machineCards, excludedTurnIds, createdCards]);

  useEffect(() => {
    if (onMachineCardsChange) {
      const turnIds = sortedMachineCards.map(({turnId}) => turnId);
      onMachineCardsChange(turnIds);
    }
  }, [sortedMachineCards, onMachineCardsChange]);

  return (
    <div
      className={cx(
        isMyActivityTab && styles.machineCardListMyActivity,
        isMyActivityTab && !machineCards?.length && styles.machineCardListMyActivityEmpty,
        styles.machineCardList
      )}
    >
      {!error && (
        <>
          {isLoading && <BlockingLoader className={styles.cardsLoader} />}
          {sortedMachineCards.length ? (
            <Text
              className={cx(
                isMyActivityTab && styles.yourMachinesTextMyActivity,
                styles.yourMachinesText
              )}
            >
              {isMyActivityTab ? "Active" : "Your"} Machines
            </Text>
          ) : null}
          {sortedMachineCards.map(
            ({
              turnId,
              machineId,
              isDumbDumb,
              machineName,
              turnStatus,
              machineType,
              timeZone,
              startedDate,
            }) => (
              <MachineStatusCard
                key={turnId}
                turnId={turnId}
                machineId={machineId}
                isDumbDumb={isDumbDumb}
                machineName={machineName}
                turnStatus={turnStatus}
                machineType={machineType}
                turnTimeZone={timeZone}
                startedDate={startedDate}
                selfDestroy={() => excludeCardByTurnId(turnId)}
                isMyActivityTab={isMyActivityTab}
                className={styles.machineCard}
                balanceInDollars={balanceInDollars}
                customerInfo={customerInfo}
                primaryColor={primaryColor}
                refetchBalance={refetchBalance}
              />
            )
          )}
        </>
      )}
    </div>
  );
};
