import httpClient from "../httpClient";
import {TEMPORARY_TOKEN_KEY} from "constants/auth";

export const requestOtp = () => {
  return httpClient({
    method: "GET",
    url: "/live-status/auth/request-otp",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(TEMPORARY_TOKEN_KEY)}`,
    },
  });
};
