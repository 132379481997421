import {initialApiRequestState} from "state/redux/constants/initialApiRequestState";
import {FetchingStatus} from "types/common";
import {type ICustomerInitialData} from "../types/initialData";

const initialData: ICustomerInitialData = {
  centsCustomer: null,

  addresses: {
    savedAddresses: [],
    addressIdToUpdate: null,
    addressToCreate: null,
  },

  paymentMethods: [],

  apiRequests: {
    customerInfo: {
      ...initialApiRequestState,
      data: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        id: null,
        storePreferences: [],
        paymentMethods: [],
        addresses: [],
        availableCredits: 0,
        businessCustomer: null,
      },
    },
    putAddress: {
      fetchingStatus: FetchingStatus.Initial,
    },
    deleteAddress: {
      fetchingStatus: FetchingStatus.Initial,
    },
    paymentMethods: {
      ...initialApiRequestState,
      data: null,
    },
  },
};

export default initialData;
