import {createTheme} from "@material-ui/core/styles";

// A custom theme for this app
const muiTheme = createTheme({
  overrides: {
    MuiTextField: {
      root: {
        margin: "5px",
        backgroundColor: "#fff",
        width: "100%",
      },
    },
  },
});

export default muiTheme;
