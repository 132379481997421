import {FC, ReactNode} from "react";
import cx from "classnames";
import styles from "./box.module.scss";

export const Box: FC<{
  children: ReactNode;
  className?: string;
}> = ({children, className}) => {
  return <div className={cx(styles.box, className)}>{children}</div>;
};
