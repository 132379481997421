import {createSelector} from "reselect";
import {RootState} from "state/redux/store";
import {FetchingStatus} from "types/common";

export const getOnlineOrderData = (state: RootState) => state.order;

export const getStoreTimeZone = createSelector(
  getOnlineOrderData,
  (state) => state.nearStoresData.data.deliveryDays[0]?.timeZone
);
export const getOrderInitialData = createSelector(
  getOnlineOrderData,
  (state) => state.initialOrderData
);
export const getNearStoresData = createSelector(
  getOnlineOrderData,
  (state) => state.nearStoresData
);
export const getCurrentView = createSelector(
  getOnlineOrderData,
  (state) => state.schedule.currentStage
);

export const getScheduleDetails = createSelector(
  getOnlineOrderData,
  (state) => state.schedule
);

export const getReturnWindowsState = createSelector(
  getOnlineOrderData,
  (state) => state.returnWindows
);

export const getCurrentCustomerAddress = createSelector(
  getOnlineOrderData,
  (state) => state.currentCustomerAddress
);

export const getShowAddressDetailsModal = createSelector(
  getOnlineOrderData,
  (state) => state.isShowingAddressDetailsModal
);

export const getShowVerificationModal = createSelector(
  getOnlineOrderData,
  (state) => state.isShowingVerificationModal
);

export const getEditableAddress = createSelector(
  getOnlineOrderData,
  (state) => state.editableAddress
);

/** @deprecated Use state/redux/slices/customer/selectors/credentialsSelector instead */
export const getCentsCustomerCredentials = createSelector(
  getOnlineOrderData,
  (state) => state.initialOrderData.data?.centsCustomerCredentials
);

export const getIsAutoScheduleReturn = createSelector(
  getOnlineOrderData,
  (state) => state.nearStoresData.data?.autoScheduleReturnEnabled
);

export const getTurnAround = createSelector(
  getOnlineOrderData,
  (state) => state.schedule.turnAround
);

export const getPricingTierId = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.store.pricingTierId
);

export const getDrawerLockStatus = createSelector(
  getOnlineOrderData,
  (state) => state.drawersStates.isDrawerLocked
);

export const getTransportationWindowsFetchingStatus = createSelector(
  getOnlineOrderData,
  (state) =>
    state.nearStoresData.fetchingStatus === FetchingStatus.Pending ||
    state.returnWindows.fetchingStatus === FetchingStatus.Pending
);

export const getPickupWindowsFulfilledStatus = createSelector(
  getOnlineOrderData,
  (state) => state.nearStoresData.fetchingStatus === FetchingStatus.Fulfilled
);

export const getDeliveryWindowsFulfilledStatus = createSelector(
  getOnlineOrderData,
  (state) => state.returnWindows.fetchingStatus === FetchingStatus.Fulfilled
);

export const checkPickupWindowsAvailability = createSelector(
  getOnlineOrderData,
  (state) =>
    state.nearStoresData.data.ownDeliveryIsAvailable ||
    state.nearStoresData.data.onDemandIsAvailable
);

export const checkDeliveryWindowsAvailability = createSelector(
  getOnlineOrderData,
  (state) =>
    state.returnWindows.data.ownDeliveryIsAvailable ||
    state.returnWindows.data.onDemandIsAvailable
);

export const orderSelectors = {
  getOnlineOrderData,
  getStoreTimeZone,
  getOrderInitialData,
  getNearStoresData,
  getCurrentView,
  getScheduleDetails,
  getReturnWindowsState,
  getCurrentCustomerAddress,
  getShowAddressDetailsModal,
  getShowVerificationModal,
  getEditableAddress,
  /** @deprecated Use state/redux/slices/customer/selectors/credentialsSelector instead */
  getCentsCustomerCredentials,
  getIsAutoScheduleReturn,
  getTurnAround,
  getPricingTierId,
  getDrawerLockStatus,
};
