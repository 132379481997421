import {RWG_TTL_DAYS} from "utils";
import cookieHelper from "utils/cookieHelper";
import {ACTIONS, IRWGAction, IRWGEventPayload} from "./actions";

const TOKEN_COOKIE_VALIDITY = Number(RWG_TTL_DAYS);
const TOKEN_COOKIE_NAME = "_rwg_token";

const setRWGToken = (searchString: string): string | undefined => {
  const previousRwgToken = getRWGToken();
  const rwgToken = new URLSearchParams(searchString).get("rwg_token");
  if (rwgToken && rwgToken !== "false" && rwgToken !== previousRwgToken) {
    cookieHelper.setCookie(
      TOKEN_COOKIE_NAME,
      rwgToken,
      TOKEN_COOKIE_VALIDITY,
      window.location.hostname
    );
    return rwgToken;
  }
};

const getRWGToken = (): string | undefined => {
  return cookieHelper.getCookie(TOKEN_COOKIE_NAME);
};

const clearRWGToken = (): void => {
  cookieHelper.deleteCookie(TOKEN_COOKIE_NAME);
};

export const sendRWGEvent = async (payload: IRWGEventPayload = {}): Promise<boolean> => {
  const rwg_token = getRWGToken();

  if (rwg_token) {
    const conversion_partner_id = Number(import.meta.env.VITE_RWG_PARTNER_ID);
    let result = false;
    const url = import.meta.env.VITE_RWG_API_URL;
    const options = {
      method: "POST",
      body: JSON.stringify({
        conversion_partner_id,
        rwg_token,
        ...payload,
      }),
    };

    await fetch(url, options)
      .then(() => (result = true))
      .catch((error) => {
        console.error("Error:", error);
      });

    return result;
  }
  return false;
};

const reducer = (state: any, action: IRWGAction) => {
  switch (action.type) {
    case ACTIONS.SET_TOKEN:
      return {
        ...state,
        token: setRWGToken(action.payload as string),
      };
    case ACTIONS.GET_TOKEN:
      return {
        ...state,
        token: getRWGToken(),
      };
    case ACTIONS.CLEAR_TOKEN:
      return {
        ...state,
        token: clearRWGToken(),
      };
    case ACTIONS.SEND_EVENT:
      return {
        ...state,
        isEventSent: action.payload,
      };
    default:
      return {...state};
  }
};

export default reducer;
