import {createAsyncThunk} from "@reduxjs/toolkit";
import {deleteCustomerAddress} from "api/online-order";
import {CUSTOMER_REDUCER_NAME, DELETE_ADDRESS} from "../constants/actions";
import {type IAddress} from "types/customer";

export interface IDeleteAddressResponse {
  allCustomerAddresses: IAddress[];
  deletedAddress: IAddress;
  success: boolean;
}

export const deleteAddress = createAsyncThunk(
  `${CUSTOMER_REDUCER_NAME}/${DELETE_ADDRESS}`,
  async (id: number): Promise<IDeleteAddressResponse> => {
    const response = await deleteCustomerAddress(id);
    return response.data;
  }
);
