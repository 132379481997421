import {useCallback, useReducer} from "react";
import {ACTIONS, IRWGEventPayload, IRWGState} from "./actions";
import reducer, {sendRWGEvent} from "./reducer";

const useRWG = () => {
  const [state, dispatch] = useReducer(reducer, {
    token: null,
    isEventSent: false,
  } as IRWGState);

  const setRWGToken = useCallback(
    (payload: string) => dispatch({type: ACTIONS.SET_TOKEN, payload}),
    []
  );

  return {
    token: state.token,
    isEventSent: state.isEventSent,
    setRWGToken,
    getRWGToken: () => dispatch({type: ACTIONS.GET_TOKEN}),
    clearRWGToken: () => dispatch({type: ACTIONS.CLEAR_TOKEN}),
    sendEvent: () => {
      sendRWGEvent().then((payload) => dispatch({type: ACTIONS.SEND_EVENT, payload}));
    },
    sendExtendedEvent: (payload: IRWGEventPayload) =>
      dispatch({type: ACTIONS.SEND_EVENT, payload}),
  };
};

export default useRWG;
