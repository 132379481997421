export const FETCHING_STATUS = {
  INITIAL: 0,
  PENDING: 1,
  FULFILLED: 2,
  REJECTED: 3,
};

export const PARAMS_KEYS = {
  serviceOrderId: "service_order_id",
};
