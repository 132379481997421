import {createSlice, type PayloadAction} from "@reduxjs/toolkit";
import {Store} from "api/store/fetchStore";
import {FetchingStatus} from "types/common";
import {BUSINESS} from "./constants/actions";
import initialData from "./constants/initData";
import {setBusinessSettingsThunk, setBusinessThemeThunk} from "./thunks";
import {type IBusinessInitialData} from "./types";

const businessSlice = createSlice({
  name: BUSINESS,
  initialState: initialData,
  reducers: {
    setBusinessSettingsAction: (
      state: IBusinessInitialData,
      action: PayloadAction<any>
    ) => {
      state.apiRequests.businessSettings.data = action.payload;
    },
    setStore: (state: IBusinessInitialData, action: PayloadAction<Store | undefined>) => {
      state.store = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setBusinessSettingsThunk.pending, (state) => {
        state.apiRequests.businessSettings.fetchingStatus = FetchingStatus.Pending;
      })
      .addCase(setBusinessSettingsThunk.fulfilled, (state, action) => {
        state.apiRequests.businessSettings.fetchingStatus = FetchingStatus.Fulfilled;
        state.apiRequests.businessSettings.data = action.payload;
        state.businessId = action.payload.businessId;
      })
      .addCase(setBusinessSettingsThunk.rejected, (state, action) => {
        const {error} = action;
        state.apiRequests.businessSettings.fetchingStatus = FetchingStatus.Rejected;
        state.apiRequests.businessSettings.error = {
          text: error.message,
          code: error.name,
        };
      })
      .addCase(setBusinessThemeThunk.pending, (state) => {
        state.apiRequests.businessTheme.fetchingStatus = FetchingStatus.Pending;
      })
      .addCase(setBusinessThemeThunk.fulfilled, (state, action) => {
        state.apiRequests.businessTheme.fetchingStatus = FetchingStatus.Fulfilled;
        state.apiRequests.businessTheme.data = action.payload;
        state.businessId = action.payload.businessId;
        state.businessName = action.payload.businessName;
        state.theme = action.payload;
      })
      .addCase(setBusinessThemeThunk.rejected, (state, action) => {
        const {error} = action;
        state.apiRequests.businessTheme.fetchingStatus = FetchingStatus.Rejected;
        state.apiRequests.businessTheme.error = {
          text: error.message,
          code: error.name,
        };
      });
  },
});

export const businessActions = businessSlice.actions;
export const businessReducer = businessSlice.reducer;
