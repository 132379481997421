import {type IOrderData} from "state/redux/slices/order/types";

export const toggleModifiersDrawer = (state: IOrderData) => {
  state.drawersStates.isModifiersDrawerOpened =
    !state.drawersStates.isModifiersDrawerOpened;
  if (state.orderBuilder.services.selectedModifiers === null) {
    // needed to indicate that modifiers drawer was opened
    state.orderBuilder.services.selectedModifiers = [];
  }
};
