import moment from "moment";
import {useDispatch} from "react-redux";
import type {AxiosResponse} from "axios";
import {useProcessUnsuccessfulEstimate} from "./useProcessUnsuccessfulEstimate";
import {
  OnDemandEstimateResponse,
  getOnDemandDeliveryEstimate,
} from "api/nash/getDeliveryEstimate";
import {orderActions, orderSelectors} from "state/redux/slices/order";
import {ORDER_DELIVERY_TYPES} from "constants/order";
import {useAppSelector} from "state/redux/hooks";
import {ScheduleWindow} from "components/newOrder/routes/schedule/hooks/useScheduling/types";

export type ScheduleWindowWithPickup = {pickupWindow?: ScheduleWindow} & ScheduleWindow;

export type EstimateAndSetDelivery = (data: {
  windowData: ScheduleWindowWithPickup;
  timeZone: string;
  zipCode: string;
}) => Promise<{isSuccessful: boolean; estimate: OnDemandEstimateResponse | null}>;

export const useEstimateAndSetDelivery = ({
  setIsEstimating,
  customerAddressId,
}: {
  setIsEstimating?: React.Dispatch<React.SetStateAction<boolean>>;
  customerAddressId: number;
}) => {
  const dispatch = useDispatch();
  const processUnsuccessfulEstimate = useProcessUnsuccessfulEstimate();
  const {
    data: {autoScheduleReturnEnabled},
  } = useAppSelector(orderSelectors.getNearStoresData);

  const estimateAndSetDelivery: EstimateAndSetDelivery = async ({
    windowData,
    timeZone,
    zipCode,
  }) => {
    let isSuccessful = true;
    let estimate: AxiosResponse<OnDemandEstimateResponse> | null = null;
    setIsEstimating?.(true);
    const {isPickup} = windowData;
    const isReturnWindowOfAutoSchedule = Boolean(autoScheduleReturnEnabled && !isPickup);

    try {
      estimate = await getOnDemandDeliveryEstimate({
        storeId: Number(windowData.storeId),
        customerAddressId,
        deliveryWindow: [
          moment(windowData.startTime).valueOf(),
          moment(windowData.endTime).valueOf(),
        ],
        type: isPickup ? ORDER_DELIVERY_TYPES.pickup : ORDER_DELIVERY_TYPES.return,
      });

      if (estimate.data?.estimateId) {
        if (isPickup) {
          dispatch(
            orderActions.setPickupInfo({
              timeZone,
              zipCode,
              estimate: estimate.data,
              ...windowData,
            })
          );
        } else {
          dispatch(
            orderActions.setReturnInfo({
              timeZone,
              zipCode,
              estimate: estimate.data,
              ...windowData,
            })
          );
        }
      } else {
        processUnsuccessfulEstimate({
          windowData: isReturnWindowOfAutoSchedule
            ? (windowData.pickupWindow as ScheduleWindow)
            : windowData,
          isReturnWindowOfAutoSchedule,
        });
        isSuccessful = false;
      }
    } catch (error) {
      processUnsuccessfulEstimate({
        windowData: isReturnWindowOfAutoSchedule
          ? (windowData.pickupWindow as ScheduleWindow)
          : windowData,
        isReturnWindowOfAutoSchedule,
      });
      isSuccessful = false;
    } finally {
      setIsEstimating?.(false);
    }

    return {isSuccessful, estimate: estimate?.data || null};
  };

  return {estimateAndSetDelivery};
};
