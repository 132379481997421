import React, {FunctionComponent} from "react";
import type {InputBaseComponentProps} from "@mui/material/InputBase";
import theme from "constants/themes/theme";

type Props = InputBaseComponentProps & {
  component: FunctionComponent<InputBaseComponentProps>;
  options: Record<string, unknown>;
};

const customOptions = {
  style: {
    base: {
      fontSize: "18px",
      color: theme.colors.NEW_TEXT_PRIMARY_BLUE,
      "::placeholder": {
        color: theme.colors.TEXT_GREY,
      },
    },
    invalid: {
      color: theme.colors.INPUT_ERROR_COLOR,
    },
  },
};

const StripeInputComponent = React.forwardRef((props: Props, ref): JSX.Element => {
  const {component: Component, options, ...other} = props;
  const [mountNode, setMountNode] = React.useState<HTMLInputElement | null>(null);

  React.useImperativeHandle(
    ref,
    () => ({
      focus: () => {
        if (mountNode === null) {
          return;
        }

        mountNode.focus();
      },
    }),
    [mountNode]
  );

  return (
    <Component
      onReady={setMountNode}
      options={{
        ...options,
        ...customOptions,
      }}
      {...other}
    />
  );
});

export default StripeInputComponent;
