import {FC, ReactNode} from "react";
import {StoreProvider} from "components/common/providers/StoreProvider";
import {useAppSelector} from "state/redux/hooks";
import {getNewOrderStoreId} from "state/redux/slices/order/selectors/storeSelectors";

/**
 * We currently have business and store level branding,
 * and each level and entity has a separate links.
 * There may be cases when a customer opens the Store #1 link,
 * enters his address there and it turns out that this address
 * is served by Store #2 of the same business.
 * In such cases, according to the product requirement,
 * the page branding should change from Store #1 to Store #2.
 *
 * This is exactly what this Provider is responsible for.
 * It waits for the store that will serve the new order
 * and loads the data of this new store, including branding
 */
export const NewOrderStoreProvider: FC<{children: ReactNode}> = ({children}) => {
  const storeId = useAppSelector(getNewOrderStoreId);

  return <StoreProvider storeId={storeId || undefined}>{children}</StoreProvider>;
};
