import {FC} from "react";
import {Checkbox} from "components/common/checkboxes/Checkbox/Checkbox";
import {Label} from "components/common/labels/Label";
import styles from "./labeledCheckbox.module.scss";

export const LabeledCheckbox: FC<{
  title: string;
  label?: string | null;
  subTitle?: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}> = ({title, subTitle, label, checked, onChange}) => {
  return (
    <Checkbox checked={checked} onChange={() => onChange(!checked)}>
      <span className={styles.title}>{title}</span>
      <span className={styles.subTitle}>{subTitle}</span>
      {label && (
        <span className={styles.label}>
          <Label variant="bold">{label}</Label>
        </span>
      )}
    </Checkbox>
  );
};
