import {KeyboardEvent} from "react";

export type Event =
  | KeyboardEvent<HTMLDivElement>
  | KeyboardEvent<HTMLSpanElement>
  | KeyboardEvent<SVGSVGElement>
  | KeyboardEvent<HTMLInputElement>;

export const processActionByKeyboard = (
  event: Event,
  callback: (event: Event) => void | Promise<void>
) => {
  const activeElement = document.activeElement;
  if (
    activeElement === event.currentTarget &&
    (event.key === "Enter" || event.key === " " || event.code === "Space")
  ) {
    callback?.(event);
  }
};
