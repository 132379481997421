import {type PayloadAction} from "@reduxjs/toolkit";
import {type IOrderData} from "state/redux/slices/order/types";
import {type IPossibleSelectedService} from "state/redux/slices/order/types/orderBuilder";

export const setPossibleService = (
  state: IOrderData,
  action: PayloadAction<IPossibleSelectedService | null>
) => {
  state.orderBuilder.services.possibleSelectedService = action.payload;
};
