import {type PayloadAction} from "@reduxjs/toolkit";
import {type IOrderData} from "state/redux/slices/order/types";
import {type ILaundryServiceModifier} from "state/redux/slices/order/types/orderBuilder";

/**
 * @deprecated Use src/state/redux/slices/order/reducers/servicesReducers/setCustomerSelectedServices.ts instead
 */
export const setSelectedModifiers = (
  state: IOrderData,
  action: PayloadAction<ILaundryServiceModifier[]>
) => {
  state.orderBuilder.services.selectedModifiers = action.payload;
};
