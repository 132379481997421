import {useState, useEffect} from "react";
import {useLDClient} from "launchdarkly-react-client-sdk";
import type {Channel} from "pusher-js";
import {useHistory, useParams} from "react-router-dom";
import {Layout} from "components/common/layouts/Layout";
import type {DeviceStatusPusherResponse} from "components/self-order/types";
import VerifyUserModal from "components/verify-account/VerifyUserModal";
import useCustomerState from "hooks/useCustomerState";
import usePusher from "hooks/usePusher";
import {useAppSelector} from "state/redux/hooks";
import {getBusinessId, getBusinessName} from "state/redux/slices/business/selectors";
import {UseAddCreditsPayload} from "api/queries/useAddCredits";
import {useCustomerInfo} from "api/queries/useCustomerInfo";
import {useGetMachineDetailsByQrCode} from "api/queries/useGetMachineDetailsByQrCode";
import {useRealtimeStatus} from "api/queries/useRealtimeStatus";
import {PUSHER_EVENTS} from "constants/constants";
import {DEFAULT_PATH} from "constants/paths";
import {ICustomer} from "types/customer";
import {IMachine} from "../../types";
import SelfServeOrder from "../order";
import styles from "./styles.module.scss";

interface SelfServeWrapperProps {
  hash?: string;
  isModal?: boolean;
  /**Function that is provided only from SelfServeOrderModal and used to switch from self-serve to self-order page */
  openSelfOrder?: (turnId: number | null) => void;
  setMachineDetails?: (machineDetails: IMachine) => void;
  refetchBalance: UseAddCreditsPayload["refetch"];
  customerInfo?: Pick<ICustomer, "id" | "paymentMethods" | "addresses"> | null;
  balanceInDollars?: number | null;
}

const SelfServeWrapper: React.FC<SelfServeWrapperProps> = ({
  hash,
  isModal,
  openSelfOrder,
  setMachineDetails,
  refetchBalance,
  balanceInDollars,
  ...props
}) => {
  const ldClient = useLDClient();
  const history = useHistory();
  const pusherClient = usePusher();
  const {customerAuthToken} = useCustomerState();
  const businessId = useAppSelector(getBusinessId);
  const businessName = useAppSelector(getBusinessName);
  const params = useParams<{uniqueCode: string}>();

  const [showVerificationScreen, setShowVerificationScreen] = useState(
    !customerAuthToken
  );
  const [customerInfo, setCustomerInfo] = useState<
    ICustomer | Pick<ICustomer, "id" | "paymentMethods" | "addresses"> | null
  >(null);
  const [flagStatus, setFlagStatus] = useState(false);
  const [uniqueCode, setUniqueCode] = useState<string | null>(null);
  const [deviceSettings, setDeviceSettings] = useState<DeviceStatusPusherResponse | null>(
    null
  );

  const {
    data: machineDetails,
    isLoading: isMachineDetailsLoading,
    isError: isMachineDetailsError,
    refetch: fetchMachineDetailsByQrCode,
  } = useGetMachineDetailsByQrCode({hash: uniqueCode, enabled: false});

  const {
    data: fetchedCustomerInfo,
    isFetching: isFetchingCustomerInfo,
    error: isCustomerInfoError,
    refetch: fetchCustomerInformation,
  } = useCustomerInfo({storeId: machineDetails?.store.id, enabled: false});

  const {error: isRealtimeStatusError, refetch: fetchRealtimeStatus} = useRealtimeStatus({
    machineId: machineDetails?.id,
    enabled: false,
  });

  // called on init
  useEffect(() => {
    if (isModal) {
      setUniqueCode(hash!);
      setCustomerInfo(props.customerInfo!);
    } else {
      setUniqueCode(params.uniqueCode);
    }
  }, [isModal, hash, params.uniqueCode, props.customerInfo]);

  // fetch machine details when it's ready to fetch
  useEffect(() => {
    if (customerAuthToken && flagStatus && uniqueCode) {
      fetchMachineDetailsByQrCode();
    }
  }, [customerAuthToken, flagStatus, uniqueCode, fetchMachineDetailsByQrCode]);

  // called on machineDetails fetch
  useEffect(() => {
    if (machineDetails && setMachineDetails) {
      setMachineDetails(machineDetails);
    }
  }, [setMachineDetails, machineDetails]);

  // fetch customer info when it's ready to fetch
  useEffect(() => {
    if (machineDetails) {
      if (!isModal) {
        fetchCustomerInformation();
      }

      fetchRealtimeStatus();
    }
  }, [isModal, fetchCustomerInformation, fetchRealtimeStatus, machineDetails]);

  // called on customer info fetch
  useEffect(() => {
    if (!isModal && fetchedCustomerInfo) {
      setCustomerInfo(fetchedCustomerInfo.customer);
    }
  }, [isModal, fetchedCustomerInfo]);

  // redirect to default path if any error occured
  useEffect(() => {
    if (
      !isModal &&
      (isMachineDetailsError || isCustomerInfoError || isRealtimeStatusError)
    ) {
      history.push(DEFAULT_PATH);
    }
  }, [
    isModal,
    history,
    isMachineDetailsError,
    isCustomerInfoError,
    isRealtimeStatusError,
  ]);

  useEffect(() => {
    ldClient?.waitForInitialization().then(() => {
      setFlagStatus(true);
    });
  }, [ldClient]);

  useEffect(() => {
    let channel: Channel | null = null;

    if (pusherClient && machineDetails?.id) {
      channel = pusherClient.subscribe(`private-machine-${machineDetails?.id}`);

      channel.bind("pusher:subscription_error", (error: any) => {
        console.warn("Could not subscribe to", channel?.name, error);
      });

      channel.bind(
        PUSHER_EVENTS.DEVICE_STATUS_UPDATED,
        (pusherData: DeviceStatusPusherResponse) => {
          setDeviceSettings(pusherData);
        }
      );
    }

    return () => {
      if (channel && pusherClient) {
        channel.unbind(PUSHER_EVENTS.DEVICE_STATUS_UPDATED);
        pusherClient.unsubscribe(channel?.name);
        channel = null;
      }
    };
  }, [pusherClient, machineDetails?.id]);

  const commonLoading = isFetchingCustomerInfo || isMachineDetailsLoading;

  if (!flagStatus) {
    return <></>;
  }

  return (
    <Layout
      businessSettings={{businessId}}
      headerName={businessName || undefined}
      isSelfServeHeader
      withoutHeader={isModal}
    >
      <div className={styles.wrapper}>
        {!commonLoading && !isMachineDetailsError && machineDetails && (
          <SelfServeOrder
            isModal={isModal}
            deviceSettings={deviceSettings}
            customer={customerInfo}
            machineDetails={machineDetails}
            openSelfOrder={openSelfOrder}
            refetchBalance={refetchBalance}
            balanceInDollars={
              balanceInDollars ??
              (customerInfo as ICustomer)?.businessCustomer?.availableCredits ??
              (customerInfo as ICustomer)?.availableCredits
            }
          />
        )}
      </div>
      <VerifyUserModal
        isOpen={showVerificationScreen}
        fetchingSubscriptions={false}
        onSuccess={async () => setShowVerificationScreen((state) => !state)}
        businessId={businessId}
        storeId={null}
        provideBackOption={false}
      />
    </Layout>
  );
};

export default SelfServeWrapper;
