import {colord, extend} from "colord";
import mixPlugin from "colord/plugins/mix";
import {orderThemeColors} from "components/online-order/orderTheme";
import theme, {fonts} from "constants/themes/theme";
import {hexToRgba} from "./styles";

extend([mixPlugin]);

export const applyTheme = (theme, themeSettings) => {
  const {primaryColor, secondaryColor, boldFont, normalFont, borderRadius} =
    themeSettings || {};

  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: primaryColor || theme.colors.primary,
      secondary: secondaryColor || theme.colors.secondary,
      transparentPrimary: hexToRgba(primaryColor || theme.colors.primary, 0.2),
    },
    palette: {
      primary: {
        main: primaryColor || theme.colors.primary,
        contrastText: "#FFFFFF",
      },
      info: {
        main: orderThemeColors.textMain,
      },
      mode: "light",
    },
    fonts: {
      ...theme.fonts,
      body: fonts[boldFont] || theme.fonts.primary,
      heading: fonts[boldFont] || theme.fonts.primary,
      primary: fonts[boldFont] || theme.fonts.primary,
      secondary: fonts[normalFont] || theme.fonts.secondary,
    },
    buttons: {
      ...theme.buttons,
      primary: {
        ...theme.buttons.primary,
        borderRadius: borderRadius || theme.borderRadius,
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: borderRadius || theme.borderRadius,
        },
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: borderRadius || theme.borderRadius,
          },
        },
      },
    },
  };
};

export const getFilterClass = (color) => {
  switch (color) {
    case "#0c73e9":
      return "filter-1";
    case "#c05701":
      return "filter-2";
    case "#181818":
      return "filter-3";
    case "#3a7f2e":
      return "filter-4";
    case "#384fb2":
      return "filter-5";
    case "#291069":
      return "filter-6";
    case "#b30000":
      return "filter-7";
    case "#6a016e":
      return "filter-8";
    case "#016060":
      return "filter-9";
    case "#6626a7":
      return "filter-10";
    case "#ad0045":
      return "filter-11";
    case "#0a244a":
      return "filter-12";
    case "#3D5170":
      return "filter-13";
    case "#0567d5":
      return "filter-14";

    default:
      return "filter-1";
  }
};

/**
 *
 * @param {string} color Color string
 *
 * @returns {boolean} isValid
 */
export const isHexColorValid = (color) => {
  const hexRegEx = /^#[A-Fa-f0-9]{6}$/;
  return color.match(hexRegEx) ? true : false;
};

/**
 * @param {Array<string>} [colors] Array of hex colors
 *
 * @returns {Array<string>} Array of fixed colors if they were invalid
 */
export const validateAndFixHexColors = (colors = []) => {
  return colors.map((color) => {
    const trimmedColor = color.trim();
    return isHexColorValid(trimmedColor) ? trimmedColor : theme.colors.primary;
  });
};

/**
 * @param {object} theme Theme from the response
 *
 * @returns {object} updatedObject
 */
export const fixThemeSettings = (theme = {primaryColor: "", secondaryColor: ""}) => {
  const {primaryColor, secondaryColor} = theme;

  const [primaryFixedColor, secondaryFixedColor] = validateAndFixHexColors([
    primaryColor,
    secondaryColor,
  ]);

  return {
    ...theme,
    primaryColor: primaryFixedColor,
    secondaryColor: secondaryFixedColor,
  };
};

export const getTranslucentColor = (primaryColor) =>
  colord(primaryColor).mix("#fff", 0.9).toHex();

export const getLightenColor = (primaryColor) =>
  colord(primaryColor).mix("#fff", 0.6).toHex();
