import {type IModifier} from "types/models/Modifier";
import {IServiceModifier} from "types/models/ServiceModifier";
import {ServiceCategoryTypes} from "./index";

export enum LineItemTypes {
  SERVICE = "SERVICE",
  INVENTORY = "INVENTORY",
  MODIFIER = "MODIFIER",
}

export enum PricingStructureTypes {
  FIXED_PRICE = "FIXED_PRICE",
  PER_POUND = "PER_POUND",
}

export interface IServicePrice {
  priceId: number;
  price: number;
  storeId: number;
  lineItemName: string;
  hasMinPrice: boolean;
  minimumQuantity: number | null;
  minimumPrice: number | null;
  isTaxable: boolean;
  description: string | null;
  category: string;
  serviceCategoryId: number;
  turnAroundInHours: number;
  lineItemType: LineItemTypes.SERVICE;
  customerSelection: boolean;
  serviceId: number;
  pricingStructureId: number;
  pricingStructureType: PricingStructureTypes;
  serviceCategoryType: ServiceCategoryTypes;
  serviceCategoryTypeId: number;
  serviceModifiers: Array<{
    name: IModifier["name"];
    serviceModifierId: IServiceModifier["id"];
    description: IModifier["description"];
    price: IModifier["price"];
    modifierPricingType: IModifier["pricingType"];
    customerSelection: boolean;
  }>;
}

export interface IProductPrice {
  priceId: number;
  price: number;
  storeId: number;
  lineItemName: string;
  inventoryImage: string | null;
  description: string | null;
  inventoryCategory: string;
  isTaxable: boolean;
  lineItemType: LineItemTypes.INVENTORY;
  productId: number;
  isArchived: boolean;
  quantity: number;
}
