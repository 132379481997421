import {useEffect} from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import {Loader} from "components/common";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {businessSelectors, businessThunks} from "state/redux/slices/business";
import {getBusinessName} from "state/redux/slices/business/selectors";
import {FetchingStatus} from "types/common";
import {BusinessLevelParams} from "types/routes";

/**
 * Different components of the application are linked to data
 * that can be at the business or store level, i.e. at two different levels.
 * For example, a customer places an order in a particular store, so it is store level,
 * but Referral and Loyalty programs are at the business level.
 * And it is important for required level to ensure basic data availability
 * in presenting components.
 * This provider is responsible for getting the basic data for the business level
 * based on encrypted or personalized business level URL param
 *
 * If business.id is available, the following providers can be used instead:
 * - BusinessProvider
 */
export const EncodedBusinessLevelProvider = ({children}: {children: React.ReactNode}) => {
  const dispatch = useAppDispatch();
  const {encodedBusinessLevelId} = useParams<Partial<BusinessLevelParams>>();

  const businessName = useAppSelector(getBusinessName);
  const businessThemeFetchingStatus = useAppSelector(
    businessSelectors.getBusinessThemeFetchingStatus
  );

  useEffect(() => {
    const fetchBusinessData = async () => {
      const {businessId} = await dispatch(
        businessThunks.setBusinessThemeThunk(encodedBusinessLevelId)
      ).unwrap();

      dispatch(businessThunks.setBusinessSettingsThunk(businessId));
    };

    if (
      encodedBusinessLevelId &&
      businessThemeFetchingStatus === FetchingStatus.Initial
    ) {
      fetchBusinessData();
    }
  }, [dispatch, encodedBusinessLevelId, businessThemeFetchingStatus]);

  return businessThemeFetchingStatus === FetchingStatus.Fulfilled ? (
    <>
      <Helmet>
        <title>{businessName}</title>
      </Helmet>
      {children}
    </>
  ) : (
    <Loader />
  );
};
