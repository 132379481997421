import {type PayloadAction} from "@reduxjs/toolkit";
import {type ICustomerInitialData} from "../types/initialData";

export const setIsPreferencePageShowing = (
  state: ICustomerInitialData,
  action: PayloadAction<{businessCustomerId: number; isPreferencePageShowing: boolean}>
) => {
  const {businessCustomerId, isPreferencePageShowing} = action.payload;

  if (state.apiRequests.customerInfo.data?.businessCustomer?.id === businessCustomerId) {
    state.apiRequests.customerInfo.data.businessCustomer.isPreferencePageShowing =
      isPreferencePageShowing;
  }
};
