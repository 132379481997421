import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchBusinessSettings, fetchBusinessTheme} from "api/business";
import {
  BUSINESS,
  SET_BUSINESS_SETTINGS_THUNK,
  SET_BUSINESS_THEME_THUNK,
} from "./constants/actions";
import {type IBusinessSettingsData} from "./types";

export interface IBusinessThemeData {
  boldFont: string;
  borderRadius: string;
  businessId: number;
  businessName: string;
  createdAt: string;
  customUrl: string;
  id: number;
  logoUrl: string;
  name: string;
  normalFont: string;
  primaryColor: string;
  secondaryColor: string;
  updatedAt: string;
}

export const setBusinessSettingsThunk = createAsyncThunk<IBusinessSettingsData, any>(
  `${BUSINESS}/${SET_BUSINESS_SETTINGS_THUNK}`,
  async (payload) => {
    const response = await fetchBusinessSettings(payload);
    return response.data.businessSettings;
  }
);

export const setBusinessThemeThunk = createAsyncThunk<IBusinessThemeData, any>(
  `${BUSINESS}/${SET_BUSINESS_THEME_THUNK}`,
  async (businessId: number) => {
    const response = await fetchBusinessTheme(businessId);
    return response.data.theme;
  }
);
