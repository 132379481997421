import {FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import throttle from "lodash/throttle";
import QrScanner from "qr-scanner";
import {toast} from "react-toastify";
import {errorToastOptions, ToastError} from "components/newOrder/common/Toast";
import QrFrame from "assets/images/qrFrame.svg";
import styles from "./QrReader.module.scss";

interface QrReaderProps {
  onScan: (result: string) => void;
  onDecodeError: (error: string) => void;
}

const getFrameSize = () => (window.innerHeight < window.innerWidth ? 128 : 256);

const QrReader: FC<QrReaderProps> = ({onScan, onDecodeError}) => {
  const scanner = useRef<QrScanner | null>();
  const readerElement = useRef<HTMLDivElement>(null);
  const videoElement = useRef<HTMLVideoElement>(null);
  const qrBoxElement = useRef<HTMLDivElement>(null);
  const [frameSize, setFrameSize] = useState<number>(getFrameSize());
  const [isScannerOn, setIsScannerOn] = useState<boolean>(true);
  const [isLoadedData, setLoadedData] = useState<boolean>(false);

  const onScanSuccess = useCallback(
    (result: QrScanner.ScanResult) => {
      onScan(result.data);
    },
    [onScan]
  );

  const onScanThrottled = useMemo(() => throttle(onScanSuccess, 250), [onScanSuccess]);

  const handleDecodeError = useCallback(
    (error: string | Error) => {
      if (typeof error === "string" && error.includes("No QR code found")) {
        return;
      }

      onDecodeError(typeof error === "string" ? error : error.message);
    },
    [onDecodeError]
  );

  const onDecodeErrorThrottled = useMemo(
    () => throttle(handleDecodeError, 2000),
    [handleDecodeError]
  );

  const handleWindowResize = () => {
    setFrameSize(getFrameSize());
  };

  useEffect(() => {
    const element = videoElement.current;

    if (videoElement.current && !scanner.current) {
      scanner.current = new QrScanner(videoElement?.current, onScanThrottled, {
        onDecodeError: onDecodeErrorThrottled,
        // "environment" means back camera and "user" means front camera.
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxElement?.current || undefined,
      });
    }

    return () => {
      if (!element) {
        scanner.current?.destroy();
        scanner.current = null;
      }
    };
  }, [onScanThrottled, onDecodeErrorThrottled]);

  useEffect(() => {
    if (!scanner.current) {
      return;
    }

    scanner.current?.pause();

    const timer = setTimeout(() => {
      scanner.current
        ?.start()
        .then(() => setIsScannerOn(true))
        .catch((err) => {
          if (err) setIsScannerOn(false);
        });
    }, 50);

    return () => clearTimeout(timer);
  }, [frameSize]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (!isScannerOn)
      toast.error(
        <ToastError message="Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload." />,
        {...errorToastOptions}
      );
  }, [isScannerOn]);

  return (
    <div ref={readerElement} className={styles.qrReader}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video ref={videoElement} onLoadedData={() => setLoadedData(true)}></video>
      <div ref={qrBoxElement} className={styles.qrBox}>
        {isLoadedData && (
          <img
            src={QrFrame}
            alt="Qr Frame"
            width={frameSize}
            height={frameSize}
            className={styles.qrFrame}
          />
        )}
      </div>
    </div>
  );
};

export default QrReader;
