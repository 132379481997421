import {orderItemCategories} from "components/order-summary/constants";
import {
  DELIVERY_PROVIDERS,
  modifierPricingTypes,
  modifiersPricingTypeLabels,
  ORDER_DELIVERY_UPDATABLE_STATUSES,
} from "../constants/order";
import {numberToDollarString} from "./payment";

export const toDollars = (amount) => {
  return `$${Number(amount || 0)?.toFixed(2) || "0.00"}`;
};

export const toPercentage = (amount) => {
  return `${Math.floor(Number(amount))}%`;
};

export const toDollarsOrFree = (amount) => {
  return amount ? toDollars(amount) : "FREE";
};

export const isTipInDollars = (tip) => typeof tip === "string" && tip.includes("$");

const getDeliveryPriceFromLineItem = ({itemTotal}) => {
  return !itemTotal ? "Free" : toDollars(itemTotal);
};

export const getPerUnitString = ({serviceCategory, pricingType, price, itemTotal}) => {
  if (serviceCategory === "DELIVERY") {
    return getDeliveryPriceFromLineItem({itemTotal});
  }

  let quantityUnit = pricingType === orderItemCategories.fixedPriceItem ? "unit" : "lb";
  return `${toDollars(price)} / ${quantityUnit}`;
};

export const getPerUnitAndTotalString = ({
  serviceCategory,
  pricingType,
  price,
  itemTotal,
  count,
  isIntakeComplete,
}) => {
  if (serviceCategory === "DELIVERY") {
    return getDeliveryPriceFromLineItem({itemTotal});
  }

  const quantityUnit = pricingType === orderItemCategories.fixedPriceItem ? "unit" : "lb";
  const countString = isIntakeComplete
    ? getCountString({pricingType, count, isIntakeComplete})
    : "";
  const totalString = isIntakeComplete ? ` (${toDollars(itemTotal)})` : "";

  return `${countString ? `${countString} x ` : ""}${toDollars(
    price
  )} / ${quantityUnit}${totalString}`;
};

export const getCountString = ({pricingType, count, isIntakeComplete}) => {
  return pricingType === orderItemCategories.fixedPriceItem
    ? count
    : getLbsString({pricingType, isIntakeComplete, count});
};

export const getMinPriceString = ({serviceCategory, minimumPrice, hasMinPrice}) => {
  if (!hasMinPrice || serviceCategory === "DELIVERY") {
    return;
  }

  return toDollars(minimumPrice);
};

export const calculateItemTotal = ({itemTotal, serviceCategory}) => {
  if (serviceCategory === "DELIVERY") {
    return getDeliveryPriceFromLineItem({itemTotal});
  }

  return toDollars(itemTotal);
};

export const buildDeliveryDetails = (data) => {
  const {routeDelivery} = data;

  return {
    driver: {
      ...routeDelivery?.route?.driver,
      first_name: routeDelivery?.route?.driver?.firstName,
    },
    // eta is coming in secs. Convert to milli-seconds
    eta: routeDelivery?.eta * 1000,
    status: routeDelivery?.status,
    notes: routeDelivery?.notes,
    imageUrl: routeDelivery?.imageUrl,
  };
};

export const getFinalSubsidy = (laundrySubsidy, thirdPartyDeliveryCost) => {
  return laundrySubsidy > thirdPartyDeliveryCost
    ? thirdPartyDeliveryCost
    : laundrySubsidy;
};

export const getEachWayFeeString = (pickupFee, returnFee) => {
  if (pickupFee === returnFee) {
    return `${toDollars(pickupFee)} each way`;
  } else {
    return `${toDollars(pickupFee)}
       +
       ${toDollars(returnFee)}`;
  }
};

export const getEachWaySubsidyString = (pickupSubsidy, returnSubsidy) => {
  if (pickupSubsidy === returnSubsidy) {
    return `-${toDollars(pickupSubsidy)} each way`;
  } else {
    return `-${toDollars(pickupSubsidy)}, -
     ${toDollars(returnSubsidy)}`;
  }
};

export const canUpdateOrderDelivery = (status) =>
  ORDER_DELIVERY_UPDATABLE_STATUSES.includes(status);

export const isValidDeliveryProvider = (
  deliveryProvider,
  hasStandardSettings,
  hasDoordashSettings
) => {
  return deliveryProvider
    ? deliveryProvider === DELIVERY_PROVIDERS.ownDriver
      ? hasStandardSettings
      : hasDoordashSettings
    : false;
};

/*
 * Sorts order items as service first and pickup and delivery later.
 */
export const sortedOrderItems = (order) => {
  let sortedItems;
  sortedItems = order?.orderItems?.filter(
    (item) => item.category !== orderItemCategories.deliveryOrPickup
  );
  return sortedItems
    ?.concat(
      order?.orderItems?.filter(
        (item) => item.category === orderItemCategories.deliveryOrPickup
      )
    )
    ?.sort((a, b) => a.id - b.id);
};

export const formatModifierLineItem = (modifier) => {
  const totalPrice = modifier.quantity * modifier.unitCost;
  const unit = modifiersPricingTypeLabels[modifier.modifierPricingType];

  const quantityText =
    modifier.modifierPricingType === modifierPricingTypes.fixedPrice
      ? modifier.quantity + " x "
      : "";
  const unitCostText = numberToDollarString(modifier.unitCost);
  const totalPriceText = numberToDollarString(totalPrice);

  return `+ ${modifier.modifierName}: ${quantityText} ${unitCostText} / ${unit} ${totalPriceText}`;
};

export const isMinPriceVisible = ({
  hasMinPrice,
  minimumPrice,
  isIntakeComplete,
  count,
  price,
}) => {
  return (
    hasMinPrice &&
    minimumPrice !== 0 &&
    (!isIntakeComplete || minimumPrice > count * price)
  );
};

export const getLbsString = ({pricingType, isIntakeComplete = false, count}) => {
  return pricingType === orderItemCategories.perPoundItem && isIntakeComplete && count
    ? `${count?.toFixed(2)} lbs`
    : "";
};
