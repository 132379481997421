import React, {FC} from "react";
import {Flex, Text} from "rebass/styled-components";
import {A11yButton} from "components/common/buttons/A11yButton";
import {useBranding} from "hooks/branding/useBranding";
import {EN_LOCALE} from "locales/en";
import {MinusIconWithoutColor, PlusIconWithoutColor} from "assets/images";

export interface BagsCounterProps {
  onMinus: (value: number) => void;
  onPlus: (value: number) => void;
  value: number;
  max?: number;
  min?: number;
  step?: number;
}

export const BagsCounter: FC<BagsCounterProps> = ({
  onMinus,
  onPlus,
  value,
  max = 20,
  min = 1,
  step = 1,
}) => {
  const {primaryColor, translucentPrimaryColor} = useBranding();
  const isValueMinimal = value === min;
  const isValueMaximal = value === max;

  const stopDefaultBehavior = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Flex {...styles.wrapper} onClick={stopDefaultBehavior}>
      <Text {...styles.text}>How many bags?</Text>
      <Flex {...styles.counter}>
        <Flex {...styles.counterCursor(!isValueMinimal)}>
          <A11yButton
            onAction={() => {
              if (value - step) {
                onMinus(value - step);
              }
            }}
            className="outline-distant"
            aria-label={EN_LOCALE.button.decreaseBags}
          >
            <MinusIconWithoutColor
              fill={isValueMinimal ? translucentPrimaryColor : primaryColor}
              stroke={isValueMinimal ? translucentPrimaryColor : primaryColor}
              {...styles.icon}
            />
          </A11yButton>
        </Flex>
        <Text {...styles.counterValue}>{value}</Text>
        <Flex {...styles.counterCursor(!isValueMaximal)}>
          <A11yButton
            onAction={() => {
              onPlus(value + step);
            }}
            className="outline-distant"
            aria-label={EN_LOCALE.button.increaseBags}
          >
            <PlusIconWithoutColor
              fill={isValueMaximal ? translucentPrimaryColor : primaryColor}
              stroke={isValueMaximal ? translucentPrimaryColor : primaryColor}
              {...styles.icon}
            />
          </A11yButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

const styles = {
  wrapper: {
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    mt: "8px",
  },
  text: {
    margin: 0,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: "NEW_TEXT_PRIMARY_BLUE",
  },
  counter: {
    width: "100px",
    alignItems: "center",
    justifyContent: "space-around",
    sx: {
      "-webkit-touch-callout": "none" /* iOS Safari */,
      "-webkit-user-select": "none" /* Safari */,
      "-khtml-user-select": "none" /* Konqueror HTML */,
      "-moz-user-select": "none" /* Old versions of Firefox */,
      "-ms-user-select": "none" /* Internet Explorer/Edge */,
      "user-select": "none" /* Non-prefixed version */,
    },
  },
  counterCursor: (isAllowed: boolean) => ({
    sx: {
      cursor: isAllowed ? "pointer" : "not-allowed",
    },
    alignItems: "center",
  }),
  counterValue: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: "NEW_TEXT_PRIMARY_BLUE",
  },
  icon: {
    width: "24px",
    height: "24px",
  },
};
