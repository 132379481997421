import {useEffect, useState} from "react";
import {Button, ButtonVariant} from "components/common/buttons/Button";
import {OTPInput} from "components/common/forms/OTPInput/OTPInput";
import {extractOtpCode} from "components/verify-account/extractOtpCode";
import useSkipOTP from "hooks/useSkipOTP";
import {onlineOrderState} from "state/online-order";
import {formatAsUSAPhoneNumber} from "utils/formatAsUSAPhoneNumber/formatAsUSAPhoneNumber";
import {OTP_CODE_LENGTH} from "constants/constants";
import {OTP_INPUT_ID} from "constants/formLabelId";
import {EN_LOCALE} from "locales/en";
import {Phone} from "assets/images";
import styles from "./otpScreen.module.scss";

interface OtpScreenProps {
  phoneNumber: string;
  onSubmit: (otpCode: string) => void;
  requestOtp: () => void;
  otpCode: string;
}

export const OtpScreen = ({
  phoneNumber,
  onSubmit,
  requestOtp,
  otpCode,
}: OtpScreenProps) => {
  const isAuthorized = onlineOrderState.isAuthorized.get();
  const [otp, setOtp] = useState(isAuthorized ? otpCode : "");
  const [applyOTP, setApplyOTP] = useState<boolean>(false);
  const {isSkipOTPAsKnownUser, isSkipOTPAsAdmin} = useSkipOTP(true);

  const setOtpValue = (value: string) => {
    const otpCode = extractOtpCode(value);
    setOtp(otpCode);

    // Only trigger onSubmit if the prev otp value is not 6.
    if (String(otpCode).length === OTP_CODE_LENGTH) {
      onSubmit(otpCode);
    }
  };

  useEffect(() => {
    if (!isAuthorized) {
      requestOtp();
    }
  }, [isAuthorized, requestOtp]);

  useEffect(() => {
    if (isSkipOTPAsAdmin || isSkipOTPAsKnownUser) {
      setApplyOTP(true);
    }
  }, [otpCode, phoneNumber, isSkipOTPAsAdmin, isSkipOTPAsKnownUser]);

  useEffect(() => {
    otpCode && applyOTP && setOtpValue(otpCode);
  }, [applyOTP, otpCode]);

  const onHandleInputChange = (value: string) => {
    if (!isAuthorized) {
      setOtpValue(value);
    }
  };

  return (
    <div className={styles.wrapper}>
      <img src={Phone} alt={EN_LOCALE.label.phone} />
      <div className={styles.content}>
        {isAuthorized ? (
          <span className={styles.label}>{EN_LOCALE.button.ooboCTA}</span>
        ) : (
          <label htmlFor={OTP_INPUT_ID} className={styles.label}>
            {EN_LOCALE.messages.otpCTA}&nbsp;{formatAsUSAPhoneNumber(phoneNumber)}
          </label>
        )}
        <OTPInput value={otp} onChange={onHandleInputChange} />
        {isAuthorized ? (
          <Button onClick={() => onSubmit(String(otpCode))}>
            {EN_LOCALE.button.submitOTP}
          </Button>
        ) : null}
        {isAuthorized ? null : (
          <Button variant={ButtonVariant.UNDERLINED} onClick={requestOtp}>
            {EN_LOCALE.button.resendOTP}
          </Button>
        )}
      </div>
    </div>
  );
};
