import {useAppSelector} from "state/redux/hooks";
import {getCustomerInfo} from "state/redux/slices/customer/selectors";
import {IBusinessCustomer} from "types/customer";

export const useFindCurrentBusinessCustomer = () => {
  const {data: customerInfo} = useAppSelector(getCustomerInfo);

  const {businessCustomer = {}} = customerInfo ?? {};

  return {
    businessCustomer: (businessCustomer ?? {}) as
      | IBusinessCustomer
      | Record<string, number | boolean | string>,
  };
};
