import {FC} from "react";
import {Skeleton} from "@mui/material";
import cx from "classnames";
import {ServiceCheckbox} from "components/common/checkboxes/ServiceCheckbox";
import {ServiceCheckboxProps} from "components/common/checkboxes/ServiceCheckbox/ServiceCheckbox";
import {Box} from "components/common/data-display/Box";
import {BoxHeader} from "components/common/data-display/BoxHeader";
import {BoxHeaderProps} from "components/common/data-display/BoxHeader/BoxHeader";
import {BagsCounter} from "components/common/orderBuilder/BagsCounter";
import {BagsCounterProps} from "components/common/orderBuilder/BagsCounter/BagsCounter";
import {StoreServicesPricing} from "components/orderCreation/serviceFirstFlow/home/components/ServicesSection/components/StoreServicesPricing";
import {useAuthorization} from "hooks/auth/useAuthorization";
import {ILaundryService} from "state/redux/slices/order/types/orderBuilder";
import {MAXIMUM_BAGS_COUNT} from "constants/order";
import styles from "./serviceSelectionForm.module.scss";

type ServiceSelectionFormProps = {
  isLoading?: boolean;
  category: "Laundry";
  turnAround: BoxHeaderProps["subTitle"];
  icon: BoxHeaderProps["icon"];

  availableServices: ILaundryService[];
  selectedServices: Array<{
    id: ILaundryService["id"];
    selectedModifiersCount: number;
  }>;
  isOnDemandOnlyStore: boolean;
  onServiceClick: ServiceCheckboxProps["onClick"];

  showBagsCounter: boolean;
  currentBagsCount: BagsCounterProps["value"];
  onMinusBagCount: BagsCounterProps["onMinus"];
  onPlusBagCount: BagsCounterProps["onPlus"];
};

export const ServiceSelectionForm: FC<ServiceSelectionFormProps> = ({
  isLoading,
  category,
  turnAround,
  icon,
  onServiceClick,
  availableServices,
  selectedServices,
  isOnDemandOnlyStore,

  showBagsCounter,
  currentBagsCount,
  onMinusBagCount,
  onPlusBagCount,
}) => {
  const {isAuthorized} = useAuthorization();

  return isLoading ? (
    <Skeleton variant="rectangular" className={styles.skeleton} />
  ) : (
    <Box className={cx(styles.container, styles.borderless)}>
      <BoxHeader
        title={category}
        subTitle={turnAround ? `${turnAround}hr Turnaround` : ""}
        icon={icon}
      >
        {isAuthorized && <StoreServicesPricing />}
      </BoxHeader>

      {showBagsCounter && (
        <BagsCounter
          value={currentBagsCount}
          onMinus={onMinusBagCount}
          onPlus={onPlusBagCount}
          min={1}
          max={
            isOnDemandOnlyStore
              ? MAXIMUM_BAGS_COUNT.DOORDASH_ONLY
              : MAXIMUM_BAGS_COUNT.GLOBAL
          }
        />
      )}

      <div className={styles.listWrapper}>
        <h4>{category} services</h4>

        {availableServices.map((service) => {
          const selectionData = selectedServices.find(({id}) => id === service.id);
          const isSelected = Boolean(selectionData);
          const selectedModifiersCount = selectionData?.selectedModifiersCount || 0;

          return (
            <ServiceCheckbox
              key={service.id}
              service={service}
              isSelected={isSelected}
              onClick={onServiceClick}
              selectedModifiersCount={selectedModifiersCount}
            />
          );
        })}
      </div>
    </Box>
  );
};
