import {createAsyncThunk} from "@reduxjs/toolkit";
import {verifyOTP as verifyApiOtp} from "api/auth";
import {AUTH_REDUCER_NAME, VERIFY_OTP} from "../constants/actions";

export interface IVerifyOTPResponse {
  isNew: boolean; // true for new customer
  success: boolean;
  // only for existing customers
  customer?: {
    firstName: string;
    lastName: string;
  };
  customerAuthToken?: string;
  latestOrderToken?: string;
}

export interface IVerifyOTPBody {
  phoneNumber: string;
  otp: string;
}

export const verifyOtp = createAsyncThunk(
  `${AUTH_REDUCER_NAME}/${VERIFY_OTP}`,
  async ({otp, phoneNumber}: IVerifyOTPBody, thunkApi) => {
    try {
      const response = await verifyApiOtp({otp, phoneNumber});
      return response?.data as IVerifyOTPResponse;
    } catch (error: any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);
