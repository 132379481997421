import {createSelector} from "reselect";
import {RootState} from "state/redux/store";

const getRewardsInfo = (state: RootState) => state.rewardsInfo;

export const getCustomerRewardsInfo = createSelector(
  getRewardsInfo,
  (state) => state.customerRewardsInfo
);

export const getRewardsHistory = createSelector(
  getRewardsInfo,
  (state) => state.rewardsHistory
);

export const getOrderRewardsDetails = createSelector(
  getRewardsInfo,
  (state) => state.orderRewardsDetails
);
