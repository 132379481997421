import {type PayloadAction} from "@reduxjs/toolkit";
import {type IAddress} from "types/customer";
import {type ICustomerInitialData} from "../types/initialData";

export const setAddressToCreate = (
  state: ICustomerInitialData,
  action: PayloadAction<Pick<
    IAddress,
    | "address1"
    | "address2"
    | "city"
    | "countryCode"
    | "firstLevelSubdivisionCode"
    | "postalCode"
    | "instructions"
    | "leaveAtDoor"
    | "lat"
    | "lng"
    | "googlePlacesId"
  > | null>
) => {
  state.addresses.addressToCreate = action.payload;
};
