import {createAsyncThunk} from "@reduxjs/toolkit";

import {IRequestParams} from "types/common";

import {fetchSelfServeOrdersList} from "../api";
import {NAME_REDUCER, LIST_DATA_THUNK} from "../constants/general";

export const getSelfServeOrdersList = createAsyncThunk(
  `${NAME_REDUCER}/${LIST_DATA_THUNK}`,
  async (params: IRequestParams) => {
    const response = await fetchSelfServeOrdersList(params);
    return {...response.data, params};
  }
);
