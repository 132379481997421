import SVG from "react-inlinesvg";
import {Layout} from "components/common/layouts/Layout";
import {DEFAULT_PRIMARY_COLOR} from "constants/themes/defaultTheme";
import {ClothesPile} from "assets/images";

export const ElementNotFound = () => (
  <Layout>
    <div style={{marginTop: "10vh", textAlign: "center"}}>
      <SVG src={ClothesPile} {...styles.image} />
      <p style={{...styles.title, textAlign: "center"}}>Item Not Found</p>
    </div>
  </Layout>
);

const styles = {
  image: {
    width: "35%",
    minWidth: "200px",
  },
  title: {
    paddingTop: "24px",
    fontSize: "24px",
    color: DEFAULT_PRIMARY_COLOR,
  },
};
