import React, {Fragment, ReactNode, useMemo} from "react";
import {Box, Flex, Image} from "rebass/styled-components";
import {timelineCircleDimensions} from "constants/themes/theme";
import {EN_LOCALE} from "locales/en";
import {
  DeliveryTrackingStatusCompleteIcon,
  OrderCompleteTick,
  OrderCanceledX,
} from "../../../assets/images";
import {CycleStatuses} from "../../../constants";

export interface CycleProps {
  order: number;
  status: CycleStatuses;
}

interface LifeCycleTimelineProps {
  data: CycleProps[];
  status?: CycleStatuses;
}

const CycleStatusImages = (isLast: boolean): Record<CycleStatuses, ReactNode> => ({
  [CycleStatuses.COMPLETED]: (
    <Image
      src={DeliveryTrackingStatusCompleteIcon}
      alt={EN_LOCALE.label.markedCheckbox}
    />
  ),
  [CycleStatuses.CANCELED]: <Image src={OrderCanceledX} alt={EN_LOCALE.label.redX} />,
  [CycleStatuses.ACTIVE]: isLast ? (
    <Image
      src={OrderCompleteTick}
      {...timelineCircleDimensions.large}
      alt={EN_LOCALE.label.markedCheckbox}
    />
  ) : (
    <Box variant="deliveryTimelineCurrentCircle" />
  ),
  [CycleStatuses.INCOMING]: <Box variant="deliveryTimelineFutureCircle" />,
});

export const LifeCycleTimeline = ({data}: LifeCycleTimelineProps) => {
  const activeIndex = useMemo(
    () => data.findIndex((item) => item.status === CycleStatuses.ACTIVE),
    [data]
  );

  return (
    <Flex {...styles.timelineContainer}>
      {data.map((item, index) => (
        <Fragment key={item.order}>
          {CycleStatusImages(activeIndex === data.length - 1)[item.status]}
          {index !== data.length - 1 && (
            <Flex
              sx={{
                ...styles.divider(index < activeIndex),
              }}
            />
          )}
        </Fragment>
      ))}
    </Flex>
  );
};

const styles = {
  timelineContainer: {
    bg: "WHITE",
    sx: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    pt: "6px",
    mr: "16px",
    ml: "16px",
  },
  divider: (active: boolean) => ({
    flexGrow: 1,
    borderWidth: "1px",
    borderStyle: active ? "solid" : "dashed",
    borderColor: active ? "CENTS_BLUE" : "BACKGROUND_GREY",
  }),
};
