import React, {useMemo} from "react";
import {Box, Text} from "rebass/styled-components";

import {formatAddress} from "../../../utils";

import {TextField} from "@material-ui/core";
import AddressInstructionsForm from "../../../../common/order-sections/address-instructions/Form";

const ConfirmAddress = (props) => {
  const {localAddressObj, handleAddressChange, showInstructionsHeading} = props;

  const formattedAddress = useMemo(() => {
    return formatAddress(localAddressObj);
  }, [localAddressObj]);

  return (
    <>
      <Box mb="32px">
        <Text {...styles.address}>{formattedAddress}</Text>
        <TextField
          label="Apt/Suite/Unit"
          placeholder="Apt, Suite, Unit, etc. (Optional)"
          style={{marginTop: "16px"}}
          value={localAddressObj?.address2 || ""}
          onChange={(e) => handleAddressChange("address2", e.target.value)}
          fullWidth
          variant="outlined"
        />
      </Box>
      {showInstructionsHeading ? (
        <Text {...styles.headingText}>Pickup / Delivery Instructions</Text>
      ) : null}

      <Box>
        <AddressInstructionsForm
          instructions={localAddressObj?.instructions}
          leaveAtDoor={localAddressObj?.leaveAtDoor}
          handleChange={handleAddressChange}
        />
      </Box>
    </>
  );
};

const styles = {
  address: {
    pb: "12px",
    textAlign: "left",
    fontSize: "16px",
    color: "NEW_TEXT_PRIMARY_BLUE",
  },
  headingText: {
    fontSize: "18px",
    mb: "16px",
  },
};

export default ConfirmAddress;
