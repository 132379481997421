import isEmpty from "lodash/isEmpty";
import {toast} from "react-toastify";
import {Box, Text} from "rebass/styled-components";
import {ToastError} from "components/common";
import type {CyclePricing} from "components/self-order/types";
import type {ITopOffData} from "../../types";
import PriceSettingItem from "../priceSettingItem";
import styles from "./styles";

interface IPriceButtonProps {
  basePricing?: CyclePricing["basePricing"];
  vendRemaining: number | null;
  selectedCycle: string | null;
  topOffData: ITopOffData | null;
  isDumbDumb: boolean;
  isGPIOMachine: boolean;
  pricePerTurnInCents: number | null;
  secondsPerTurn: number | null;
  timeRemaining: number | null;
}

const DryerCycleSettings = ({
  basePricing,
  topOffData,
  vendRemaining,
  selectedCycle,
  isDumbDumb,
  isGPIOMachine,
  pricePerTurnInCents,
  secondsPerTurn,
  timeRemaining,
}: IPriceButtonProps) => {
  if (
    !!basePricing &&
    !basePricing?.price &&
    !vendRemaining &&
    (!pricePerTurnInCents || !secondsPerTurn)
  ) {
    toast.error(<ToastError message={"Machine has incorrect prices"} />);
  }

  return (
    <Box sx={styles.settingsContainer}>
      {(!isEmpty(basePricing) ||
        vendRemaining ||
        selectedCycle ||
        (pricePerTurnInCents && secondsPerTurn)) && (
        <>
          <Text sx={styles.settingsBlock}>Current settings:</Text>
          <Box sx={styles.pricesContainer}>
            {!isDumbDumb && (
              <>
                {isGPIOMachine ? (
                  <PriceSettingItem
                    label={"Vend Price"}
                    secondsOrMinutes={secondsPerTurn || null}
                    price={pricePerTurnInCents || null}
                  />
                ) : (
                  <PriceSettingItem
                    label={basePricing?.label || selectedCycle || null}
                    secondsOrMinutes={basePricing?.minutes || timeRemaining || null}
                    price={basePricing?.price || vendRemaining || null}
                  />
                )}
                {!isEmpty(topOffData) && (
                  <PriceSettingItem
                    label="Add Time"
                    price={topOffData.topOffPriceInCents}
                    secondsOrMinutes={topOffData.topOffTimeInMinutes}
                  />
                )}
              </>
            )}
            {isDumbDumb && (
              <Text sx={styles.dumbComment}>
                Please make sure the machine is available.
              </Text>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default DryerCycleSettings;
