import * as Sentry from "@sentry/browser";
import {useQuery} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {ICustomerInfo} from "state/redux/slices/customer/thunks/fetchCustomerInfoThunk";
import {fetchCustomerInfo} from "api/online-order";
import {ERROR_MESSAGES} from "constants/constants";
import {ApiError} from "types/api";

interface UseCustomerInfoPayload {
  storeId?: number | null;
  enabled?: boolean;
}

export const useCustomerInfo = ({storeId, enabled = true}: UseCustomerInfoPayload) => {
  return useQuery<unknown, ApiError, ICustomerInfo>({
    queryKey: ["useCustomerInfo", {storeId}],
    queryFn: async () => {
      if (!storeId) {
        return null;
      }

      const {data} = await fetchCustomerInfo(storeId);
      return data;
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast.error(error?.response?.data?.error ?? ERROR_MESSAGES.CUSTOMER_INFO);

      return error;
    },
    enabled,
  });
};
