import {FC, useEffect, useRef, useState} from "react";
import {delay} from "utils/delay";
import "../styles/BagsCountEditDialog.style.scss";

const DICTIONARY = {
  EDIT_NUMBER_OF_BAGS: "Edit number of bags",
  DOORDASH_LIMIT: (amnt: string | number) =>
    `DoorDash has a limit of <strong>${amnt} bags</strong>`,
  HOW_MANY_BAGS: "How many bags?",
  CANCEL: "Cancel",
  APPLY: "Apply",
};

const DEFAULTS = {
  LIMIT: 5,
  MAX: 20,
  MIN: 1,
};

interface IBagsCountEditDialog {
  current: number;
  isVisible: boolean;
  forwardedRef?: React.RefObject<HTMLDialogElement>;
  limit?: number;
  onCancel?: () => void;
  onApply?: (count: number) => void;
}

export const BagsCountEditDialog: FC<IBagsCountEditDialog> = ({
  current,
  isVisible,
  forwardedRef,
  limit = DEFAULTS.LIMIT,
  onCancel,
  onApply,
}) => {
  const [count, setCount] = useState<number>(current);
  const [isActive, setIsActive] = useState<boolean>(false);
  const countInputRef = useRef<HTMLInputElement>(null);
  const defaultRef = useRef<HTMLDialogElement>(null);
  const dialogRef = forwardedRef || defaultRef;

  const updateCurrentBagCount = (): void => {
    const {value, min, max} = countInputRef.current as HTMLInputElement;
    const parsedValue = parseInt(value);

    if (parsedValue > parseInt(min) || parsedValue < parseInt(max)) {
      return;
    }
    setCount(parsedValue);
  };

  const increaseCurrentBagCount = (): void => {
    const {max} = countInputRef.current as HTMLInputElement;

    if (count >= parseInt(max)) {
      return;
    }

    setCount((count) => count + 1);
  };

  const decreaseCurrentBagCount = (): void => {
    const {min} = countInputRef.current as HTMLInputElement;

    if (count <= parseInt(min)) {
      return;
    }

    setCount((count) => count - 1);
  };

  useEffect(() => {
    if (isVisible) {
      dialogRef.current?.showModal?.();
      delay(10).then(() => setIsActive(true));
    }
  }, [isVisible]);

  const handleOnCancel = (): void => {
    setCount(current);
    onCancel?.();
    setIsActive(false);
    delay(300).then(() => dialogRef.current?.close());
  };

  const handleOnApply = (): void => {
    onApply?.(count);
    setIsActive(false);
    delay(300).then(() => dialogRef.current?.close());
  };

  return (
    <dialog ref={dialogRef} data-role="bags-count-edit-dialog" data-active={isActive}>
      <h4>{DICTIONARY.EDIT_NUMBER_OF_BAGS}</h4>
      <p
        dangerouslySetInnerHTML={{
          __html: DICTIONARY.DOORDASH_LIMIT(limit),
        }}
      />
      <span className="bags-count-input-container">
        <strong>{DICTIONARY.HOW_MANY_BAGS}</strong>
        <span className="bags-count-input-wrapper">
          <button
            className="bags-count-controller"
            data-action="decrease"
            onClick={decreaseCurrentBagCount}
          >
            -
          </button>
          <span className="bags-count-input">{count}</span>
          <input
            type="hidden"
            ref={countInputRef}
            pattern="[0-9]*"
            value={count}
            min={DEFAULTS.MIN}
            max={DEFAULTS.MAX}
            onChange={updateCurrentBagCount}
          />
          <button
            className="bags-count-controller"
            data-action="increase"
            onClick={increaseCurrentBagCount}
          >
            +
          </button>
        </span>
      </span>
      <div className="bags-count-edit-dialog-actions">
        <button className="cancel" onClick={handleOnCancel}>
          {DICTIONARY.CANCEL}
        </button>
        <button className="apply" onClick={handleOnApply}>
          {DICTIONARY.APPLY}
        </button>
      </div>
    </dialog>
  );
};
