import {
  getCurrentView,
  getNearStoresData,
  getReturnWindowsState,
} from "state/redux/slices/order/selectors";
import {FETCHING_STATUS} from "constants/api";
import {
  DELIVERY_TIMINGS_PREFERENCE_TYPES,
  DELIVERY_TYPE_KEYS,
  VIEWS,
} from "constants/order";
import {isEmpty} from "lodash";
import {DateTime} from "luxon";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {formatDeliveryDays} from "utils/schedule/formatDeliveryDays";

const formatWindow = (window, isPickup) => ({...window, isRecommended: true, isPickup});

const getRecommendedWindow = (windows, isPickup) => {
  return formatWindow(windows.shift(), isPickup);
};

const getPriorWindow = (deliveryDays, isPickup, deliveryTimingsPreference) => {
  const firstPriorOwnWindowsIndex = deliveryDays.findIndex(
    (day) => day[DELIVERY_TYPE_KEYS.OWN].length
  );
  const firstPriorOnDemandWindowsIndex = deliveryDays.findIndex(
    (day) => day[DELIVERY_TYPE_KEYS.ON_DEMAND].length
  );

  const firstPriorOwnWindows =
    firstPriorOwnWindowsIndex > -1
      ? deliveryDays[firstPriorOwnWindowsIndex][DELIVERY_TYPE_KEYS.OWN]
      : [];
  const firstPriorOnDemandWindows =
    firstPriorOnDemandWindowsIndex > -1
      ? deliveryDays[firstPriorOnDemandWindowsIndex][DELIVERY_TYPE_KEYS.ON_DEMAND]
      : [];

  switch (deliveryTimingsPreference) {
    case DELIVERY_TIMINGS_PREFERENCE_TYPES.ON_DEMAND:
      if (firstPriorOnDemandWindows.length) {
        return getRecommendedWindow(firstPriorOnDemandWindows, isPickup);
      } else if (firstPriorOwnWindows.length) {
        // Look up for Own Delivery windows in case On-Demand windows are unavailable.
        return getRecommendedWindow(firstPriorOwnWindows, isPickup);
      }

      return null;

    case DELIVERY_TIMINGS_PREFERENCE_TYPES.EARLIEST_AVAILABLE:
      if (firstPriorOnDemandWindows.length && firstPriorOwnWindows.length) {
        const firstOwnDriverWindow = firstPriorOwnWindows[0];
        const firstOnDemandriverWindow = firstPriorOnDemandWindows[0];
        const ownIsEarlier =
          DateTime.fromISO(firstOwnDriverWindow.startTime).valueOf() <
          DateTime.fromISO(firstOnDemandriverWindow.startTime).valueOf();
        return ownIsEarlier
          ? getRecommendedWindow(firstPriorOwnWindows, isPickup)
          : getRecommendedWindow(firstPriorOnDemandWindows, isPickup);
      } else if (firstPriorOnDemandWindows.length) {
        return getRecommendedWindow(firstPriorOnDemandWindows, isPickup);
      } else if (firstPriorOwnWindows.length) {
        return getRecommendedWindow(firstPriorOwnWindows, isPickup);
      }

      return null;
    default:
      // default to own drivers
      if (firstPriorOwnWindows.length) {
        return getRecommendedWindow(firstPriorOwnWindows, isPickup);
      } else if (firstPriorOnDemandWindows.length) {
        // Look up for On-Demand Delivery windows in case Own Delivery windows are unavailable.
        return getRecommendedWindow(firstPriorOnDemandWindows, isPickup);
      }

      return null;
  }
};

const getNextWindow = (deliveryDays, isPickup) => {
  const nextOwnDayIndex = deliveryDays.findIndex(
    (day) => day[DELIVERY_TYPE_KEYS.OWN].length
  );
  const nextOnDemandDayIndex = deliveryDays.findIndex(
    (day) => day[DELIVERY_TYPE_KEYS.ON_DEMAND].length
  );

  if (nextOwnDayIndex < 0 && nextOnDemandDayIndex > -1) {
    return formatWindow(
      deliveryDays[nextOnDemandDayIndex][DELIVERY_TYPE_KEYS.ON_DEMAND].shift(),
      isPickup
    );
  } else if (nextOwnDayIndex > -1 && nextOnDemandDayIndex < 0) {
    return formatWindow(
      deliveryDays[nextOwnDayIndex][DELIVERY_TYPE_KEYS.OWN].shift(),
      isPickup
    );
  } else if (nextOwnDayIndex < 0 && nextOnDemandDayIndex < 0) {
    return null;
  }

  const nextOwnWindow = deliveryDays[nextOwnDayIndex][DELIVERY_TYPE_KEYS.OWN][0];
  const nextOnDemandWindow =
    deliveryDays[nextOnDemandDayIndex][DELIVERY_TYPE_KEYS.ON_DEMAND][0];

  const ownIsEarlier =
    DateTime.fromISO(nextOwnWindow.startTime).valueOf() <
    DateTime.fromISO(nextOnDemandWindow.startTime).valueOf();
  return ownIsEarlier
    ? formatWindow(
        deliveryDays[nextOwnDayIndex][DELIVERY_TYPE_KEYS.OWN].shift(),
        isPickup
      )
    : formatWindow(
        deliveryDays[nextOnDemandDayIndex][DELIVERY_TYPE_KEYS.ON_DEMAND].shift(),
        isPickup
      );
};

export const useRecommendedWindows = (toView) => {
  const defaultView = useSelector(getCurrentView);
  const currentView = toView || defaultView;
  const isPickup = [VIEWS.ALL_WINDOWS_PICKUP, VIEWS.RECOMMENDED_PICKUP].includes(
    currentView
  );

  const {
    data: {deliveryDays},
    fetchingStatus,
  } = useSelector(isPickup ? getNearStoresData : getReturnWindowsState);

  const {
    data: {deliveryTimingsPreference},
  } = useSelector(getNearStoresData);

  const [recommendedWindows, setRecommendedWindows] = useState({});

  useEffect(() => {
    if (isEmpty(recommendedWindows) && fetchingStatus === FETCHING_STATUS.FULFILLED) {
      const timeZonedDeliveryDays = formatDeliveryDays(deliveryDays);

      const recommendedWindows = {
        recommended: getPriorWindow(
          timeZonedDeliveryDays,
          isPickup,
          deliveryTimingsPreference
        ),
        other: [
          getPriorWindow(timeZonedDeliveryDays, isPickup, deliveryTimingsPreference),
          getNextWindow(timeZonedDeliveryDays, isPickup),
          getNextWindow(timeZonedDeliveryDays, isPickup),
        ],
      };
      setRecommendedWindows(recommendedWindows);
    }
  }, [
    deliveryDays,
    fetchingStatus,
    isPickup,
    recommendedWindows,
    deliveryTimingsPreference,
  ]);

  useEffect(() => {
    setRecommendedWindows({});
  }, [deliveryDays]);

  return recommendedWindows;
};
