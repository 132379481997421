import isEmpty from "lodash/isEmpty";
import {useAppSelector} from "state/redux/hooks";
import {orderSelectors} from "state/redux/slices/order";
import {getNewOrderWelcome} from "utils/customerAddress/getNewOrderWelcome";
import {FETCHING_STATUS} from "constants/api";

export const useOrderConfig = () => {
  const nearStoresData = useAppSelector(orderSelectors.getNearStoresData);
  const {
    data: {centsCustomerCredentials, customerAddress},
  } = useAppSelector(orderSelectors.getOrderInitialData);
  const currentCustomerAddress = useAppSelector(orderSelectors.getCurrentCustomerAddress);

  const {autoScheduleReturnEnabled} = nearStoresData.data || {};

  const welcomeMessage = getNewOrderWelcome(centsCustomerCredentials);
  const isPageJustOpened =
    nearStoresData.fetchingStatus === FETCHING_STATUS.INITIAL &&
    currentCustomerAddress?.lat &&
    currentCustomerAddress?.lng &&
    !isEmpty(customerAddress);

  const offersLaundry = nearStoresData.data?.availableServices?.hasLaundry;
  const offersDryCleaning =
    nearStoresData.data?.availableServices?.hasDryCleaning &&
    nearStoresData.data?.availableServices?.offerDryCleaningForDelivery;

  const maxDryCleaningTurnAround = Math.max(
    nearStoresData.data?.turnArounds?.ownDeliveryStore?.dryCleaningTurnAroundInHours ||
      null,
    nearStoresData.data?.turnArounds?.onDemandDeliveryStore
      ?.dryCleaningTurnAroundInHours || null
  );
  const maxLaundryTurnAround = Math.max(
    nearStoresData.data?.turnArounds?.ownDeliveryStore?.laundryTurnAroundInHours || null,
    nearStoresData.data?.turnArounds?.onDemandDeliveryStore?.laundryTurnAroundInHours ||
      null
  );
  const ownDeliveryIsAvailable = nearStoresData.data?.ownDeliveryIsAvailable;
  const onDemandIsAvailable = nearStoresData.data?.onDemandIsAvailable;
  const isDoorDashOnlyStore = onDemandIsAvailable && !ownDeliveryIsAvailable;
  const latestOrderDetails = nearStoresData.data?.latestOrderDetails;
  const isStoreFetched = nearStoresData.fetchingStatus === FETCHING_STATUS.FULFILLED;

  return {
    autoScheduleReturnEnabled,
    offersLaundry,
    offersDryCleaning,
    latestOrderDetails,
    /** @deprecated - Use state.orderBuilder.services.categories.dryCleaning.turnAround instead */
    maxDryCleaningTurnAround,
    /** @deprecated - Use state.orderBuilder.services.categories.laundry.turnAround instead */
    maxLaundryTurnAround,
    welcomeMessage,
    isPageJustOpened,
    ownDeliveryIsAvailable,
    onDemandIsAvailable,
    isDoorDashOnlyStore,
    isStoreFetched,
  };
};
