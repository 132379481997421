import {FC, ReactNode} from "react";
import classNames from "classnames";
import {BackButton} from "components/newOrder/common/BackButton";
import "./StaticPageLayout.style.scss";

interface StaticPageLayoutProps {
  children: ReactNode;
  headerText: string;
  bgLayout?: string;
  forceBack?: boolean;
}

export const StaticPageLayout: FC<StaticPageLayoutProps> = ({
  children,
  headerText,
  bgLayout,
  forceBack,
}) => {
  return (
    <main className={classNames("static-screen", bgLayout || "")}>
      <article>
        <header>
          <BackButton forceBack={forceBack} />
          <h2>{headerText}</h2>
        </header>
        <section className="content">{children}</section>
      </article>
    </main>
  );
};
