import {ICentsCustomerCredentials} from "../state/redux/slices/order/types";
import httpClient from "./httpClient";

interface ICreateCustomerPayload {
  fullName: string;
  phoneNumber: string | null;
  email: string | null;
  optOutMarketingSms: boolean;
}

interface IVerifyCustomerPayload {
  phoneNumber: string;
}

interface IUpdateCustomerNotesData {
  notes?: string;
  isHangDrySelected?: boolean;
  hangDryInstructions?: string;
}

export const createCustomer = ({
  fullName,
  phoneNumber,
  email,
  optOutMarketingSms,
}: ICreateCustomerPayload) => {
  return httpClient({
    method: "POST",
    url: "/live-status/customer",
    data: {fullName, phoneNumber, email, optOutMarketingSms},
  });
};

export const verifyCustomer = ({phoneNumber}: IVerifyCustomerPayload) => {
  return httpClient({
    method: "GET",
    url: "/live-status/customer/verify",
    params: {phoneNumber},
  });
};

export const updateCustomerNotes = (
  storeId: number,
  centsCustomerId: number,
  data: IUpdateCustomerNotesData
) => {
  return httpClient({
    method: "PATCH",
    url: `/live-status/customer/notes/${storeId}/${centsCustomerId}/update`,
    data,
  });
};

export const updateCustomerCredentials = (data: ICentsCustomerCredentials) => {
  return httpClient({
    method: "PATCH",
    url: "/live-status/customer/credentials/contactInfo",
    data,
  });
};

export const getCustomerOptOutMarketingSms = () => {
  return httpClient({
    method: "GET",
    url: "/live-status/customer/notifications",
  });
};

export const getCustomerOptOutUpdatesSms = () => {
  return httpClient({
    method: "GET",
    url: "/live-status/customer/notifications/orderUpdates",
  });
};

export const updateCustomerOptOutMarketingSms = (value: boolean) => {
  return httpClient({
    method: "PATCH",
    url: "/live-status/customer/notifications",
    data: {
      optOutMarketingSms: value,
    },
  });
};

export const getCustomerDeletionStatus = () => {
  return httpClient({
    method: "GET",
    url: "/live-status/customer/delete",
  });
};

export const deleteCustomer = () => {
  return httpClient({
    method: "POST",
    url: "/live-status/customer/delete",
  });
};

export const getBusinessCustomerBalance = (encodedBusinessId: string) => {
  return httpClient({
    method: "GET",
    url: `/live-status/customer/business/${encodedBusinessId}/balance`,
  });
};

export const getLastOrderTypeForCustomer = (businessId: number) => {
  return httpClient({
    method: "GET",
    url: `/live-status/customer/business/${businessId}/last-order`,
  });
};
