import * as Sentry from "@sentry/browser";
import {type AxiosResponse} from "axios";
import {createErrorToast} from "utils/notifications/createErrorToast";
import httpClient from "api/httpClient";
import {GENERIC_REQUEST_ERROR} from "constants/errors/requestErrors";

export interface IRecurringOrderListItem {
  recurringOrderId: number;
  interval: 1 | 2 | 4;
  address: string;
  /**
   * ISO date string
   */
  nextInstanceWeek: string;
  /**
   * ISO date strings
   */
  nextPickup: string[];
  timeZone: string;
  isSkipAvailable: boolean;
  activeOrder: {
    id: number;
    orderCode: string;
    /**
     * Milliseconds
     */
    pickupWindow: number[] | null;
    /**
     * Milliseconds
     */
    deliveryWindow: number[] | null;
    /**
     * ISO string
     */
    dueDate: string;
    isCancellationAvailable: boolean;
  } | null;
}

export type RecurringOrdersListData = {recurringOrders: IRecurringOrderListItem[]};

type GetRecurringOrdersList = (data: {
  businessId: number;
}) => Promise<AxiosResponse<RecurringOrdersListData>>;

export const getRecurringOrdersList: GetRecurringOrdersList = async ({businessId}) => {
  try {
    const result = await httpClient({
      method: "GET",
      url: "/live-status/recurring-orders",
      params: {businessId},
    });
    return result;
  } catch (error: any) {
    createErrorToast({
      primaryMessage: GENERIC_REQUEST_ERROR,
    });
    Sentry.captureException(error);
    throw new Error(GENERIC_REQUEST_ERROR);
  }
};
