import {createAsyncThunk} from "@reduxjs/toolkit";
import {verifyCustomer} from "api/customer";
import {AUTH_REDUCER_NAME, VERIFY_PHONE_NUMBER} from "../constants/actions";

export interface IVerifyPhoneNumberResponse {
  firstName: string;
  isVerified: boolean;
  lastName: string;
  optOutMarketingSms: boolean;
}

export const verifyPhoneNumber = createAsyncThunk<IVerifyPhoneNumberResponse, string>(
  `${AUTH_REDUCER_NAME}/${VERIFY_PHONE_NUMBER}`,
  async (phoneNumber: string, thunkApi) => {
    try {
      const response = await verifyCustomer({phoneNumber});
      return response?.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);
