import {useAppSelector} from "state/redux/hooks";
import {getSelectedServiceCategories} from "state/redux/slices/order/selectors/servicesSelector";
import {ServiceCategoryTypes} from "state/redux/slices/order/types";

export const useIsCategorySelected = ({category}: {category: ServiceCategoryTypes}) => {
  const selectedCategories = useAppSelector(getSelectedServiceCategories);
  return Boolean(
    selectedCategories.find((selectedCategory) => selectedCategory.name === category)
  );
};
