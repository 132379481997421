import {useFlags} from "launchdarkly-react-client-sdk";
import {useAppSelector} from "state/redux/hooks";
import {getTransportationPreference} from "state/redux/slices/order/selectors/transportationSelectors";
import {checkOOBO} from "utils/ooboHelpers";
import {TransportationPreferences} from "api/delivery/fetchGeneralDeliverySettings";

export const useTransportationPreference = () => {
  const {deliveryPreferences} = useFlags();
  const transportationPreference = useAppSelector(getTransportationPreference);

  const isAutoScheduleDeliveryPreference =
    transportationPreference === TransportationPreferences.AUTO_SCHEDULE &&
    /** We disable auto-schedule return delivery for business operator session */
    !checkOOBO();

  const isTextWhenReadyPreference =
    transportationPreference === TransportationPreferences.TEXT_WHEN_READY;

  return {
    isFeatureFlagEnabled: deliveryPreferences,
    transportationPreference,
    isAutoScheduleDeliveryPreference,
    isTextWhenReadyPreference,
  };
};
