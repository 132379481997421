import PropTypes from "prop-types";
import {TextInput} from "components/common/forms/TextInput";
import {EN_LOCALE} from "locales/en";
import "./styles.scss";

const OrderNotesForm = ({onOrderNotesChange, readonly, orderNotes}) => {
  return (
    <div className="notes-wrapper">
      <p className="notes-header">Any specific notes about this order?</p>
      <TextInput
        label={EN_LOCALE.label.orderNotes}
        placeholder={EN_LOCALE.label.orderNotesExample}
        minRows={3}
        maxRows={5}
        multiline
        value={orderNotes}
        onChange={onOrderNotesChange}
        disabled={readonly}
        maxLength={255}
        fullWidth
      />
    </div>
  );
};

OrderNotesForm.propTypes = {
  onOrderNotesChange: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  orderNotes: PropTypes.string.isRequired,
};

OrderNotesForm.defaultProps = {
  readonly: false,
};

export default OrderNotesForm;
