import {type PayloadAction} from "@reduxjs/toolkit";
import {type IOrderData} from "state/redux/slices/order/types";
import {type IAddressToEstimate} from "state/redux/slices/order/types/orderBuilder";

export const setAddressToEstimate = (
  state: IOrderData,
  action: PayloadAction<IAddressToEstimate>
) => {
  state.orderBuilder.addresses.addressToEstimate = action.payload;
};
