import classNames from 'classnames';
import Drawer from '@mui/material/Drawer';
import styles from './AdditionalPaymentDueDrawer.module.scss';
import {A11yButton} from "components/common/buttons/A11yButton";

interface AdditiomalPaymentDueDrawerProps {
    anchor: 'left' | 'right' | 'top' | 'bottom';
    open: boolean;
    children: React.ReactNode;
    drawerHeader: string;
    drawerText: string;
    icon: React.ReactNode;
    buttonText: string;
    onSubmit: () => void;
    disabled: boolean;
}

/**
 * Component designed and used for cases where the user requires a payment to be made in order to proceed.
 * 
 * The drawer here is not meant to be closed until some sort of action is taken, and children can be passed to the component
 * to display the various payment options to be used
 */
export const AdditionalPaymentDueDrawer: React.FC<AdditiomalPaymentDueDrawerProps> = (props) => {
    const { anchor, open, drawerHeader, drawerText, children, icon, buttonText, onSubmit, disabled } = props;
    
    return (
        <Drawer
            anchor={anchor}
            open={open}
        >
            <div className={styles.drawerHeaderContainer}>
                <span>{icon}</span>
                <h2 className={styles.drawerHeaderTitleText}>{drawerHeader}</h2>
                <p className={styles.drawerHeaderBodyText}>{drawerText}</p>
            </div>
            <div className={styles.drawerBodyContainer}>
                <p className={styles.paymentHeader}>Payment</p>
                {children}
            </div>
            <div className={styles.drawerFooterContainer}>
                <A11yButton
                    className={classNames(styles.nextButton, {
                        [styles.disabled]: disabled,
                      })}
                    onAction={onSubmit}
                    disabled={disabled}
                >
                    {buttonText}
                </A11yButton>
            </div>
        </Drawer>
    )
}
