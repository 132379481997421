import {useAppSelector} from "state/redux/hooks";
import {orderSelectors} from "state/redux/slices/order";

export const useIsDeliveryAvailable = () => {
  const nearStoresData = useAppSelector(orderSelectors.getNearStoresData);
  const ownDeliveryIsAvailable = nearStoresData.data?.ownDeliveryIsAvailable;
  const onDemandIsAvailable = nearStoresData.data?.onDemandIsAvailable;

  return onDemandIsAvailable || ownDeliveryIsAvailable;
};
