import {FC} from "react";
import {Button} from "components/common/buttons/Button";
import {Drawer} from "components/common/drawers/Drawer";
import {DryCleaningCategoryProps} from "components/common/forms/services/DryCleaningCategory/DryCleaningCategory";
import {LaundryCategoryFormProps} from "components/common/forms/services/LaundryCategory/LaundryCategoryForm";
import {ServiceCategories} from "components/common/forms/services/ServiceCategories";
import {SetNextModal} from "hooks/orderBuilder/services/useNextModal";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderActions} from "state/redux/slices/order";
import {getIsServicesDrawerOpened} from "state/redux/slices/order/selectors/servicesSelector";
import styles from "./servicesDrawer.module.scss";

export interface ServicesDrawerProps
  extends DryCleaningCategoryProps,
    LaundryCategoryFormProps {
  submitButtonText: string;
  showBagsCounter: boolean;
  isSubmitButtonDisabled: boolean;
  onServiceDrawerSubmit: () => void;
  setNextModal?: SetNextModal;
}

export const ServicesDrawer: FC<ServicesDrawerProps> = ({
  submitButtonText,
  showBagsCounter,
  isSubmitButtonDisabled,
  dryCleaningTurnAround,
  isDryCleaningCategorySelected,
  selectedServices,
  onServiceDrawerSubmit,
  onDryCleaningCategoryToggle,
  setNextModal,
  ...laundryCategoryProps
}) => {
  const dispatch = useAppDispatch();
  const isDrawerOpened = useAppSelector(getIsServicesDrawerOpened);
  const closeDrawer = () => {
    setNextModal?.(null);
    dispatch(orderActions.setIsServicesDrawerOpened(false));
  };

  return (
    <Drawer isShowingDrawer={isDrawerOpened} closeDrawer={closeDrawer} title={"Services"}>
      <div className={styles.servicesWrapper}>
        <div className={styles.servicesContent}>
          <ServiceCategories
            showBagsCounter={showBagsCounter}
            dryCleaningTurnAround={dryCleaningTurnAround}
            isDryCleaningCategorySelected={isDryCleaningCategorySelected}
            onDryCleaningCategoryToggle={onDryCleaningCategoryToggle}
            selectedServices={selectedServices}
            {...laundryCategoryProps}
          />
        </div>
        <Button
          disabled={isSubmitButtonDisabled}
          onClick={onServiceDrawerSubmit}
          className={styles.submitButton}
        >
          {submitButtonText}
        </Button>
      </div>
    </Drawer>
  );
};
