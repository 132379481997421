import PropTypes from "prop-types";
import {Box, Flex, Image, Text} from "rebass/styled-components";
import {Button} from "components/common/buttons/Button";
import theme from "constants/themes/theme";
import {EN_LOCALE} from "locales/en";
import {GLOBAL_STYLES} from "styles/globalStyles";
import {ExitIcon} from "assets/images";
import {Loader} from "./Loader";

const FullScreenModalForm = (props) => {
  const {
    onClose,
    header,
    btnLabel,
    bodyStyle,
    children,
    onSubmit,
    disabled,
    loading,
    variant = "deprecated",
  } = props;

  return (
    <Flex {...styles.wrapper}>
      <Image
        {...styles.closeIcon}
        src={ExitIcon}
        onClick={onClose}
        alt={EN_LOCALE.label.xIcon}
      />
      <Flex
        {...(variant === "deprecated" ? styles.header.wrapper : styles.header.container)}
      >
        <Text {...styles.header.text}>{header}</Text>
      </Flex>
      <Box {...styles.body.wrapper}>
        {loading && <Loader style={{height: "calc(var(--app-height) - 76px )"}} />}
        <Box
          {...(variant === "deprecated"
            ? styles.body.content
            : styles.body.contentContainer)}
          {...bodyStyle}
        >
          {children}
        </Box>
      </Box>
      <Flex {...styles.footer.wrapper}>
        <Button onClick={onSubmit} disabled={disabled}>
          {btnLabel}
        </Button>
      </Flex>
    </Flex>
  );
};

const styles = {
  wrapper: {
    height: "var(--app-height)",
    flexDirection: "column",
    sx: {
      position: "relative",
    },
    maxWidth: GLOBAL_STYLES.MAX_WIDTH_CONTAINER,
    margin: "0 auto",
  },
  header: {
    wrapper: {
      alignItems: "center",
      justifyContent: "center",
      height: "76px",
      sx: {
        borderBottom: "1px solid",
        borderColor: "SEPERATOR_GREY",
      },
    },
    container: {
      alignItems: "center",
      justifyContent: "center",
      height: "72px",
    },
    text: {
      fontWeight: 600,
      fontSize: "18px",
      color: theme.colors.NEW_TEXT_PRIMARY_BLUE,
      fontFamily: theme.fonts.Inter,
    },
  },
  closeIcon: {
    height: "28px",
    width: "28px",
    sx: {
      position: "absolute",
      top: "24px",
      left: "16px",
      cursor: "pointer",
    },
  },
  body: {
    wrapper: {
      sx: {
        position: "relative",
      },
      height: "calc(var(--app-height) - 76px - 96px)",
    },
    content: {
      p: "16px",
      height: "calc(var(--app-height) - 76px - 96px)",
      pb: 0,
    },
    contentContainer: {
      height: "calc(var(--app-height) - 76px - 96px)",
      p: 0,
    },
  },
  footer: {
    wrapper: {
      mt: "auto",
      height: "62px",
      px: "16px",
      alignItems: "center",
      justifyContent: "center",
      mb: "16px",
    },
  },
};

FullScreenModalForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  btnLabel: PropTypes.string,
  bodyStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  variant: PropTypes.string,
};

FullScreenModalForm.defaultProps = {
  btnLabel: "Apply",
  bodyStyle: {},
  disabled: false,
  loading: false,
};

export default FullScreenModalForm;
