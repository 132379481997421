import {DateTime} from "luxon";
import {NAMED_DAYS} from "constants/order";
import {getIsToday} from "./getIsToday";

export const getFormattedWeekDay = (
  luxonDate: DateTime,
  timeZone: string,
  {full = false} = {}
) =>
  getIsToday(luxonDate, timeZone)
    ? NAMED_DAYS.today
    : luxonDate.toFormat(full ? "cccc" : "ccc");
