import {ServiceWithModifiers} from "components/common/sectionItems/LaundryItem/LaundryItem";
import {OrderDetailsData} from "components/orderManager/tuneUp/OrderDetails/orderDetails.types";

export const formatServicesListFromOrderItems = ({
  orderItems,
}: {
  orderItems: OrderDetailsData["orderItems"];
}): ServiceWithModifiers[] => {
  return orderItems.map((service) => ({
    id: service.id,
    name: service.lineItemName,
    pricingType: service.pricingType,
    price: service.price,
    modifiers:
      service.modifierLineItems?.map((modifier) => ({
        name: modifier.modifierName,
        pricingType: modifier.modifierPricingType,
        price: modifier.unitCost,
      })) || [],
  }));
};
