import {type FC, type ReactNode} from "react";
import {SwipeableDrawer, type SwipeableDrawerProps} from "@mui/material";
import cx from "classnames";
import {ThemeProvider} from "components/common/providers/ThemeProvider";
import {useAppSelector} from "state/redux/hooks";
import {getDrawerLockStatus} from "state/redux/slices/order/selectors";
import {EN_LOCALE} from "locales/en";
import {XIcon} from "assets/images";
import styles from "./drawer.module.scss";

interface DrawerProps
  extends Omit<SwipeableDrawerProps, "onClose" | "onOpen" | "title" | "open"> {
  isShowingDrawer: boolean;
  children: ReactNode;
  title?: string | ReactNode;
  drawerClassName?: string;
  titleContainerClassName?: string;
  closeButtonClassName?: string;
  titleClassName?: string;
  fullHeight?: boolean;
  fullWidth?: boolean;
  isCloseButtonVisible?: boolean;
  closeDrawer?: () => void;
  /**
   * Please, be careful with disabled portals.
   * Accessibility of content can be affected when `disablePortal = true`
   */
  disablePortal?: boolean;
  whiteBackdrop?: boolean;
}

export const Drawer: FC<DrawerProps> = ({
  isShowingDrawer,
  children,
  title,
  drawerClassName,
  closeButtonClassName,
  titleClassName,
  titleContainerClassName,
  fullHeight = true,
  fullWidth,
  isCloseButtonVisible = true,
  closeDrawer,
  disablePortal,
  keepMounted,
  whiteBackdrop,
  ...drawerProps
}) => {
  const isLocked = useAppSelector(getDrawerLockStatus);
  const titleElement =
    typeof title === "string" ? (
      <h2 className={titleClassName || styles.drawerTitle}>{title}</h2>
    ) : (
      title
    );

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isShowingDrawer}
      onClose={() => !isLocked && closeDrawer?.()}
      onOpen={() => {}} // required prop
      disableSwipeToOpen={true}
      classes={{paper: drawerClassName || styles.drawer}}
      PaperProps={{
        style: {
          height: fullHeight ? "100%" : "auto",
          width: fullWidth ? "100%" : "auto",
          borderTopLeftRadius: fullHeight ? 0 : 12,
          borderTopRightRadius: fullHeight ? 0 : 12,
        },
      }}
      ModalProps={{
        keepMounted,
      }}
      BackdropProps={{className: styles.drawerBackdrop}}
      disablePortal={disablePortal}
      {...(whiteBackdrop
        ? {
            slotProps: {
              backdrop: {
                className: "drawer-white-backdrop",
              },
            },
          }
        : {})}
      {...drawerProps}
    >
      <ThemeProvider className={styles.drawerThemeProvider}>
        {title && (
          <div
            className={cx(styles.drawerHeader, "drawer-header", titleContainerClassName, {
              [styles.withCloseButton]: Boolean(closeDrawer && isCloseButtonVisible),
            })}
          >
            {titleElement}
            {isCloseButtonVisible && closeDrawer && (
              <button
                className={closeButtonClassName || styles.closeButton}
                onClick={isLocked ? undefined : closeDrawer}
                aria-label={EN_LOCALE.button.closeDrawer}
              >
                <img
                  src={XIcon}
                  alt={EN_LOCALE.label.cross}
                  width={28}
                  height={28}
                  className={styles.closeIcon}
                />
              </button>
            )}
          </div>
        )}
        {children}
      </ThemeProvider>
    </SwipeableDrawer>
  );
};
