import {createSlice} from "@reduxjs/toolkit";
import {setIsAuthDrawerOpened} from "./reducers/drawersReducers/setIsAuthDrawerOpened";
import {setPhoneNumber} from "./reducers/userReducers/setPhoneNumber";
import {setFirstName} from "./reducers/userReducers/setFirstName";
import {setLastName} from "./reducers/userReducers/setLastName";
import {setEmail} from "./reducers/userReducers/setEmail";
import {resetUser} from "./reducers/userReducers/resetUser";
import {verifyPhoneNumber} from "./thunks/verifyPhoneNumber";
import {requestOtp} from "./thunks/requestOtp";
import {verifyOtp} from "./thunks/verifyOtp";
import {createCustomer} from "./thunks/createCustomer";
import {verifyPhoneNumberThunk} from "./thunks/auth2/verifyPhoneNumberThunk";
import {requestOtpThunk} from "./thunks/auth2/requestOtpThunk";
import {signInThunk} from "./thunks/auth2/signInThunk";
import {signUpThunk} from "./thunks/auth2/signUpThunk";
import {createErrorToast} from "utils/notifications/createErrorToast";
import {FETCHING_STATUS} from "constants/api";
import {AUTH_REDUCER_NAME} from "./constants/actions";
import initialData from "./constants/initialData";
import {Stages} from "./types/stages";

const authSlice = createSlice({
  name: AUTH_REDUCER_NAME,
  initialState: initialData,
  reducers: {
    setIsAuthDrawerOpened,
    setPhoneNumber,
    resetUser,
    setFirstName,
    setLastName,
    setEmail,
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyPhoneNumber.pending, (state) => {
        // deprecated
        state.apiRequests.phoneNumberVerification.fetchingStatus =
          FETCHING_STATUS.PENDING;
        state.apiRequests.otpRequest.data = null;
      })
      .addCase(verifyPhoneNumber.fulfilled, (state, action) => {
        // deprecated
        state.apiRequests.phoneNumberVerification.fetchingStatus =
          FETCHING_STATUS.FULFILLED;
        state.apiRequests.phoneNumberVerification.data = action.payload;
        state.user.firstName = action.payload.firstName;
        state.user.lastName = action.payload.lastName;
        state.user.isVerified = action.payload.isVerified;
        state.user.optOutMarketingSms = action.payload.optOutMarketingSms;
      })
      .addCase(verifyPhoneNumber.rejected, (state, action) => {
        // deprecated
        const {error} = action;
        state.apiRequests.phoneNumberVerification.fetchingStatus =
          FETCHING_STATUS.REJECTED;
        state.apiRequests.phoneNumberVerification.error = {
          text: error.message,
          code: error.name,
        };
      })
      .addCase(verifyPhoneNumberThunk.pending, (state) => {
        state.apiRequests.phoneNumberVerification.fetchingStatus =
          FETCHING_STATUS.PENDING;
      })
      .addCase(verifyPhoneNumberThunk.fulfilled, (state, action) => {
        state.apiRequests.phoneNumberVerification.fetchingStatus =
          FETCHING_STATUS.FULFILLED;
        state.user.firstName = action.payload?.customer?.firstName || null;
        state.user.lastName = action.payload?.customer?.lastName || null;
        state.user.isVerified = Boolean(action.payload?.customer);
        state.user.optOutMarketingSms = false;
      })
      .addCase(requestOtpThunk.pending, (state) => {
        state.apiRequests.otpRequest.fetchingStatus = FETCHING_STATUS.PENDING;
        state.apiRequests.otpRequest.data = null;
      })
      .addCase(requestOtpThunk.fulfilled, (state, action) => {
        state.apiRequests.otpRequest.fetchingStatus = FETCHING_STATUS.FULFILLED;
        state.apiRequests.otpRequest.data = action.payload;
        state.currentStage = Stages.OTP;
      })
      .addCase(requestOtpThunk.rejected, (state, action) => {
        const {text} = action.payload as {text: string};
        createErrorToast({message: text});
      })
      .addCase(requestOtp.pending, (state) => {
        // deprecated
        state.apiRequests.otpRequest.fetchingStatus = FETCHING_STATUS.PENDING;
        state.apiRequests.otpRequest.data = null;
      })
      .addCase(requestOtp.fulfilled, (state, action) => {
        // deprecated
        state.apiRequests.otpRequest.fetchingStatus = FETCHING_STATUS.FULFILLED;
        state.apiRequests.otpRequest.data = action.payload;
        state.currentStage = Stages.OTP;
      })
      .addCase(requestOtp.rejected, (state, action) => {
        // deprecated
        const {error} = action;
        state.apiRequests.otpRequest.fetchingStatus = FETCHING_STATUS.REJECTED;
        state.apiRequests.otpRequest.error = {
          text: error.message,
          code: error.name,
        };
      })
      .addCase(signInThunk.pending, (state) => {
        state.apiRequests.verifyOtp.fetchingStatus = FETCHING_STATUS.PENDING;
        state.apiRequests.verifyOtp.data = null;
        state.currentStage = Stages.LOADING;
      })
      .addCase(signInThunk.fulfilled, (state) => {
        state.apiRequests.verifyOtp.fetchingStatus = FETCHING_STATUS.FULFILLED;
      })
      .addCase(signInThunk.rejected, (state, action) => {
        const {text} = action.payload as {text: string};
        state.currentStage = Stages.OTP;
        createErrorToast({message: text});
      })
      .addCase(signUpThunk.pending, (state) => {
        state.apiRequests.verifyOtp.fetchingStatus = FETCHING_STATUS.PENDING;
        state.apiRequests.verifyOtp.data = null;
        state.currentStage = Stages.LOADING;
      })
      .addCase(signUpThunk.fulfilled, (state) => {
        state.apiRequests.verifyOtp.fetchingStatus = FETCHING_STATUS.FULFILLED;
      })
      .addCase(signUpThunk.rejected, (state, action) => {
        const {text} = action.payload as {text: string};
        state.currentStage = Stages.OTP;
        createErrorToast({message: text});
      })
      .addCase(verifyOtp.pending, (state) => {
        // deprecated
        state.apiRequests.verifyOtp.fetchingStatus = FETCHING_STATUS.PENDING;
        state.apiRequests.verifyOtp.data = null;
        state.currentStage = Stages.LOADING;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        // deprecated
        state.apiRequests.verifyOtp.fetchingStatus = FETCHING_STATUS.FULFILLED;
        state.apiRequests.verifyOtp.data = action.payload;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        // deprecated
        const {text} = action.payload as {text: string};
        state.currentStage = Stages.OTP;
        createErrorToast({message: text});
      })
      .addCase(createCustomer.pending, (state) => {
        // deprecated
        state.apiRequests.createCustomer.fetchingStatus = FETCHING_STATUS.PENDING;
        state.apiRequests.createCustomer.data = null;
        state.currentStage = Stages.LOADING;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        // deprecated
        state.apiRequests.createCustomer.fetchingStatus = FETCHING_STATUS.FULFILLED;
        state.apiRequests.createCustomer.data = action.payload;
      })
      .addCase(createCustomer.rejected, (state, action) => {
        // deprecated
        const {text} = action.payload as {text: string};
        state.currentStage = Stages.OTP;
        createErrorToast({message: text});
      });
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
