import {useState} from "react";
import {useFlags} from "launchdarkly-react-client-sdk";
import {IBusinessSettingsData} from "state/redux/slices/business/types";
import {getNumericValueFromDollarString} from "utils/payment";
import {TIP_TYPES} from "constants/index";
import useToggle from "./useToggle";

const useTipSelect = ({
  isRecurringSubscription,
  businessSettings,
  defaultTipOption,
}: {
  isRecurringSubscription: boolean;
  businessSettings: IBusinessSettingsData;
  defaultTipOption?: string | null;
}) => {
  const flags = useFlags();
  const [selectedTipOption, setSelectedTipOption] = useState<string | null>(
    defaultTipOption || businessSettings?.tipSettings?.defaultOptionValue || null
  );
  const {isOpen: showCustomTipModal, toggle: toggleCustomTipModal} = useToggle(false);
  const [isSubscriptionRemembered, setSubscriptionRemembered] = useState(false);
  const isOnlineTippingAllowed: boolean =
    flags?.onlineTipping && businessSettings?.allowOnlineTip;
  const canIncludeTipToOrder = isOnlineTippingAllowed && !!selectedTipOption;
  const canIncludeTipToSubscription = canIncludeTipToOrder && isSubscriptionRemembered;
  const numericTipAmount =
    selectedTipOption?.startsWith("$") &&
    getNumericValueFromDollarString(selectedTipOption);
  const tipType = businessSettings?.tipSettings?.tipType;
  const selectedTipOptionType = !!selectedTipOption
    ? selectedTipOption?.startsWith("$")
      ? TIP_TYPES.DOLLAR_AMOUNT
      : TIP_TYPES.PERCENTAGE
    : null;
  const tipAmount =
    tipType === TIP_TYPES.PERCENTAGE && selectedTipOptionType === TIP_TYPES.PERCENTAGE
      ? "TBD"
      : selectedTipOption;
  const tipAmountForRequest =
    selectedTipOptionType === TIP_TYPES.PERCENTAGE ? null : numericTipAmount;

  return {
    ...(businessSettings?.tipSettings || {}),
    isRecurringOrderEnabled: isRecurringSubscription,
    isSubscriptionRemembered,
    selectedTipOption,
    setSelectedTipOption,
    toggleCustomTipModal,
    setSubscriptionRemembered,
    showCustomTipModal,
    isOnlineTippingAllowed,
    canIncludeTipToOrder,
    canIncludeTipToSubscription,
    numericTipAmount,
    tipType,
    tipAmount,
    tipAmountForRequest,
  };
};

export default useTipSelect;
