import {lazy} from "react";

export {default as PageNotFound} from "./page-not-found";
export {default as Landing} from "./landing";
export const OrderSummary = lazy(() => import("components/order-summary/new-index"));
export const VerifyAccount = lazy(() =>
  import("components/verify-account/VerifyAccount")
);
export const MySubscriptions = lazy(() => import("components/subscriptions"));
export const RecurringOrdersManager = lazy(() =>
  import("components/recurringOrders/tuneUp/RecurringOrdersManager")
);
export const RecurringOrderSeriesEditor = lazy(() =>
  import("components/recurringOrders/tuneUp/RecurringOrderSeriesEditor")
);
export const SelfOrder = lazy(() => import("components/self-order/SelfOrder"));
export const AllOrders = lazy(() => import("features/allOrders"));
export const SelfServeWrapper = lazy(() =>
  import("features/order/self-serve/components/wrapper/SelfServeWrapper")
);
export const ReferralValidationPage = lazy(() =>
  import("components/referral/ReferralValidationPage")
);
export {Account} from "./account";
