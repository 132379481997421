import {Flex, Text, Image} from "rebass/styled-components";
import NewCardForm from "components/account/AccountPayment/AddNewCard/components/NewCardForm";
import {Loader} from "components/common";
import {A11yButton} from "components/common/buttons/A11yButton";
import {Drawer} from "components/common/drawers/Drawer";
import {EN_LOCALE} from "locales/en";
import {GLOBAL_STYLES} from "styles/globalStyles";
import {IconSmallArrowLeft} from "assets/images";

const AddPaymentMethod = (props) => {
  const {isDockModal, goBack, isOpen, onSave, loading} = props;

  const renderHeader = () => {
    return (
      <Flex {...styles.headerRowContainer}>
        <Flex {...styles.headerColumnContainer}>
          <A11yButton onAction={goBack}>
            <Image
              {...styles.svgImage}
              src={IconSmallArrowLeft}
              alt={EN_LOCALE.label.arrowLeft}
            />
          </A11yButton>
          <Text {...styles.headerRowText}>Payment</Text>
        </Flex>
      </Flex>
    );
  };

  return isDockModal ? (
    <Drawer title="Add Credit Card" isShowingDrawer={isOpen} closeDrawer={goBack}>
      {loading ? (
        <Loader />
      ) : (
        <Flex {...styles.screenContainer} height="calc( 100% - 67px )">
          <Flex {...styles.bodyContainer}>
            <NewCardForm onSave={onSave} />
          </Flex>
        </Flex>
      )}
    </Drawer>
  ) : (
    <Flex {...styles.screenContainer}>
      <Flex {...styles.bodyContainer}>
        {loading && <Loader />}
        {renderHeader()}
        <NewCardForm onSave={onSave} />
      </Flex>
    </Flex>
  );
};

const styles = {
  screenContainer: {
    sx: {
      height: "calc(var(--app-height))",
      justifyContent: "space-between",
      flexDirection: "column",
      maxWidth: GLOBAL_STYLES.MAX_WIDTH_CONTAINER,
      margin: "0 auto",
      width: "100%",
    },
  },
  bodyContainer: {
    flex: 1,
    padding: "0 15px 20px",
    sx: {
      justifyContent: "space-between",
      flexDirection: "column",
    },
  },
  headerRowContainer: {
    sx: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      height: "67px",
    },
  },
  headerRowText: {
    sx: {
      fontSize: 18,
      fontWeight: 600,
      color: "NEW_TEXT_PRIMARY_BLUE",
    },
    margin: "0 auto",
  },
  headerColumnContainer: {
    sx: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
  },
};

export default AddPaymentMethod;
