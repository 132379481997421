import {type PayloadAction} from "@reduxjs/toolkit";
import {IAddress} from "types/customer";
import {type ICustomerInitialData} from "../types/initialData";

export const setSavedAddresses = (
  state: ICustomerInitialData,
  action: PayloadAction<IAddress[]>
) => {
  state.addresses.savedAddresses = action.payload;
};
