import {FC, ReactNode} from "react";
import {useParams} from "react-router-dom";
import {BusinessProvider} from "components/common/providers/BusinessProvider";
import {StoreProvider} from "components/common/providers/StoreProvider";
import {useStoreLevelPerformer} from "api/queries/store/useStoreLevelPerformer";
import {StoreLevelParams} from "types/routes";

/**
 * In the links for new orders creation distributed to customers,
 * in order not to compromise the number of stores directly in the URL,
 * we use a encoded `storeThemes.id` for store level branding page.
 * Also, we provide the option of personalized
 * (something custom instead of encoded `storeThemes.id`) links for store level.
 * At the same time, there are often cases when even before rendering functional content
 * we need to have business id, store id, for example to get feature flags.
 *
 * This provider is responsible for getting decoded business id and store id
 * on routes where we have encoded store level branding id.
 */
export const EncodedStoreLevelProvider: FC<{children: ReactNode}> = ({children}) => {
  const {encodedStoreLevelId} = useParams<StoreLevelParams>();

  const {data: performer} = useStoreLevelPerformer({encodedId: encodedStoreLevelId});

  return (
    <BusinessProvider businessId={performer?.businessId}>
      <StoreProvider storeId={performer?.storeId}>{children}</StoreProvider>
    </BusinessProvider>
  );
};
