import {PREFIX} from "./index";

export const UNATTENDED_ADDRESS_ESTIMATED = `${PREFIX} An unattended address estimated`;
export const ATTENDED_ADDRESS_ESTIMATED = `${PREFIX} An attended address estimated`;
export const SAVED_ADDRESS_ESTIMATION_STARTED = `${PREFIX} Saved address estimation started`;
export const NEW_ADDRESS_ESTIMATION_STARTED = `${PREFIX} New address estimation started`;
export const NEW_ADDRESS_CREATING = `${PREFIX} Address saving`;
export const UNATTENDED_ADDRESS_EMAIL_SENDING = `${PREFIX} Sending an e-mail about the unavailability of delivery`;
export const EXISTING_ADDRESS_UPDATE = `${PREFIX} Updating of existing address`;
export const DELIVERY_WINDOWS_ARE_SUBMITTED = `${PREFIX} Delivery windows are submitted`;
