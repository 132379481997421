import {type PayloadAction} from "@reduxjs/toolkit";
import initialData from "state/redux/slices/auth/constants/initialData";
import {Stages} from "state/redux/slices/auth/types/stages";
import {type IAuthInitialData} from "state/redux/slices/auth/types/initialData";

export const setIsAuthDrawerOpened = (
  state: IAuthInitialData,
  action: PayloadAction<boolean>
) => {
  state.drawersStates.isAuthDrawerOpened = action.payload;
  if (action.payload) {
    // of opens drawer
    state.currentStage = Stages.PHONE_NUMBER;
    state.user = initialData.user;
    state.apiRequests = initialData.apiRequests;
  }
};
