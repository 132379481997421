import React from "react";
import * as Sentry from "@sentry/browser";
import {Button} from "@material-ui/core";
import {LazyLoadImage} from "react-lazy-load-image-component";
import ErrorCardImage from "assets/images/ErrorCardImage.svg";
import Refresh from "assets/images/Refresh.svg";
/**
 * An HoC to capture and send every error to Sentry
 *
 * This is a class-based component because React does not currently support
 * functional error boundary components
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error, {componentStack}) {
    Sentry.captureException(error, {contexts: {react: {componentStack}}});
  }

  refreshPage() {
    window.location.reload(false);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary-wrapper">
          <LazyLoadImage
            height={132}
            width={330}
            effect="opacity"
            src={ErrorCardImage}
            alt="Error"
          />
          <h2 className="error-title">Something went wrong </h2>
          <p className="error-description">Please refresh the page</p>
          <Button
            startIcon={<img src={Refresh} alt="Refresh" />}
            variant="contained"
            onClick={this.refreshPage}
            color="primary"
          >
            Refresh
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
