import {FC, useEffect} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {A11yButton} from "components/common/buttons/A11yButton";
import {NAVIGATION_RULES, PAGES_KEY} from "components/newOrder/routes/navigationRules";
import {ReactComponent as BackIcon} from "assets/images/Icon_Back_TuneUp.svg";
import "./style.scss";

interface IBackButton {
  forceBack?: boolean;
  sx?: Record<string, string>;
  additionAction?: () => void;
  svgProps?: Record<string, string>;
  customWrapperClass?: string;
  backCallBack?: () => void;
}

const extractKeyFromUrl = (url: Array<string>) => {
  return url.at(-1) ? url.at(-1) : url.at(-2) || "";
};

const getDestination = (from: string, flow: Array<string>) => {
  const url = from.split("/");
  const urlKey = extractKeyFromUrl(url);

  switch (true) {
    case urlKey && Array.isArray(NAVIGATION_RULES[urlKey]):
      const target = (NAVIGATION_RULES[urlKey as string] as Array<string>).find(
        (target) => flow.includes(target)
      );
      return from.replace(urlKey as string, target ? (target as string) : "home");
    case urlKey && NAVIGATION_RULES[urlKey] === -1:
      return from.replace(`/${urlKey}`, "");
    case urlKey && !!NAVIGATION_RULES[urlKey]:
      return from.replace(urlKey as string, NAVIGATION_RULES[urlKey as string] as string);
    default:
      return "/";
  }
};

export const BackButton: FC<IBackButton> = ({
  forceBack,
  additionAction,
  sx,
  svgProps,
  customWrapperClass,
  backCallBack,
}) => {
  const {url} = useRouteMatch();
  const history = useHistory();
  const currentUrlKey = extractKeyFromUrl(url.split("/"));
  const isStartNewWay =
    currentUrlKey === "service" &&
    JSON.parse(sessionStorage.getItem(PAGES_KEY) || "[]").includes("checkout");
  const screens = new Set(JSON.parse(sessionStorage.getItem(PAGES_KEY) || "[]"));

  useEffect(() => {
    if (!screens.has(currentUrlKey) && screens.has("checkout")) {
      return;
    }
    screens.add(currentUrlKey);
    sessionStorage.setItem(
      PAGES_KEY,
      JSON.stringify(isStartNewWay ? [currentUrlKey] : Array.from(screens))
    );
  }, [url]);

  const onClickHandler = () => {
    additionAction?.();
    if (currentUrlKey === "checkout") {
      sessionStorage.setItem(
        PAGES_KEY,
        JSON.stringify(Array.from(screens).filter((item) => item !== "checkout"))
      );
    }
    if (forceBack) {
      history.push(getDestination(url, Array.from(screens) as Array<string>));
    } else {
      history.length > 2 ? history.goBack() : history.push("/");
    }
  };

  const onBack = backCallBack || onClickHandler;

  return (
    <A11yButton
      onAction={onBack}
      className={`back-button ${customWrapperClass || ""}`}
      style={sx ? sx : {}}
      aria-label="Back button"
    >
      <BackIcon {...(svgProps || {})} />
    </A11yButton>
  );
};
