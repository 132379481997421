import {useEffect, useState} from "react";
import * as Sentry from "@sentry/browser";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {useFlags} from "launchdarkly-react-client-sdk";
import isEmpty from "lodash/isEmpty";
import {useHistory} from "react-router-dom";
import {Loader} from "components/common";
import CustomTipDockModal from "components/common/TipSelection/CustomTipDockModal";
import {OrderScreenWrapper} from "components/newOrder/common/OrderScreenWrapper";
import {SummarySection} from "components/newOrder/routes/checkout/online-order-form/finishing-up/SummarySection";
import {DeliveryDetails} from "components/newOrder/routes/checkout/online-order-form/finishing-up/delivery-details";
import {PAGES_KEY} from "components/newOrder/routes/navigationRules";
import {
  deliveryProviders,
  onDemandDeliveryTypes,
  orderDeliverySubFields,
  SERVICE_CATEGORY_TYPES,
} from "components/online-order/constants";
import AddDeliveryTip from "components/online-order/online-order-form/finishing-up/add-delivery-tip";
import Payment from "components/online-order/online-order-form/finishing-up/payment";
import AddPaymentMethod from "components/payment/AddPaymentMethod";
import {referralActions} from "components/referral/redux";
import {useFullStoryEvent} from "hooks/fullstory/useFullStoryEvent";
import {useIsServiceFirstFlow} from "hooks/orderBuilder/useIsServiceFirstFlow";
import useRWG from "hooks/useRWG";
import useTipSelect from "hooks/useTipSelect";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {businessSelectors} from "state/redux/slices/business";
import {orderActions, orderSelectors} from "state/redux/slices/order";
import OrderValidator, {ORDER_VALIDATOR_CALLED_FROM} from "services/order-validator";
import {filterPropertiesInObject} from "utils/common";
import {STRIPE_PUBLIC_KEY} from "utils/config";
import {createErrorToast} from "utils/notifications/createErrorToast";
import {createSuccessToast} from "utils/notifications/createSuccessToast";
import {checkOOBO, getTeamMemberId} from "utils/ooboHelpers";
import {formatOrderServices} from "utils/order/formatOrderServices";
import {getInvoicingFlag} from "utils/order/getInvoicingFlag";
import {addNewCard, saveCardToState} from "utils/payment";
import {submitPickupOrder} from "api/online-order";
import {
  RWG_ORDER_CREATED,
  RWG_ORDER_CREATION_ERROR,
  TUNE_UP_ORDER_CREATED,
  TUNE_UP_ORDER_CREATION_ERROR,
} from "constants/fullStory/events/order";
import {HEADER_LEFT_ICON_TYPES, ORDER_TYPES, RETURN_METHODS} from "constants/order";
import {EN_LOCALE} from "locales/en";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export const FinishingUp = (props) => {
  const {
    generalDeliverySettings,
    ownDriverDeliverySettings,
    customer,
    dryCleaningEnabled,
    businessId,
  } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const {
    orderBuilder: {
      services: {
        selectedModifiers,
        selectedService,
        bagsCount,
        selectedCategories,
        customerSelectedServices,
      },
      transportation: {pickupDelivery, returnDelivery},
      store: {storeId},
      paymentDetails: {paymentMethodToken},
      notes: {orderNotes, customerNotes, hangDryInstructions},
      carePreferences: {isHangDrySelected},
      promo: {promoCodeName},
    },
    schedule: {subscription, turnAround: turnAroundInHoursState},
    currentCustomerAddress,
    nearStoresData: {
      data: {addressTimeZone},
    },
  } = useAppSelector(orderSelectors.getOnlineOrderData);
  const businessSettings = useAppSelector(businessSelectors.getBusinessSettingsFromState);
  const businessName = useAppSelector(businessSelectors.getBusinessName);

  const [error, setError] = useState();
  const [showNewPaymentMethod, setShowNewPaymentMethod] = useState();
  const [showPaymentMethodList, setShowPaymentMethodList] = useState();
  const [customerPaymentMethods, setCustomerPaymentMethods] = useState(
    customer?.paymentMethods || []
  );
  const [showDeliveryTipModal, setShowDeliveryTipModal] = useState(false);
  const flags = useFlags();
  const postalCode = currentCustomerAddress?.postalCode;

  const returnMethod = !!returnDelivery?.deliveryWindow
    ? RETURN_METHODS.delivery
    : RETURN_METHODS.inStorePickup;
  const isOoboManager = checkOOBO();

  const isInvoicingOrder = getInvoicingFlag({
    settings: generalDeliverySettings,
    customer,
  });
  const {sendEvent: trackOrderCreationEvent} = useRWG();
  const {submitEvent} = useFullStoryEvent();
  const isRWG = useIsServiceFirstFlow();

  const tipSettingsPayload = useTipSelect({
    isRecurringSubscription: !isEmpty(subscription),
    businessSettings,
  });
  const {
    canIncludeTipToOrder,
    canIncludeTipToSubscription,
    showCustomTipModal,
    toggleCustomTipModal,
    setSelectedTipOption,
    selectedTipOption,
    tipAmountForRequest,
  } = tipSettingsPayload;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changePaymentMethodToken = (token) => {
    dispatch(orderActions.setPaymentMethodToken(token));
  };

  /**
   * Store the payment method to the customer's payment methods on file
   *
   * @param {Object} paymentData
   */
  const saveNewCard = async (paymentData) => {
    setLoading(true);
    addNewCard(
      paymentData,
      currentCustomerAddress,
      customer.id,
      (response) => {
        setCustomerPaymentMethods(response);
        changePaymentMethodToken(paymentData.payment.id);
        setShowPaymentMethodList(true);
        setShowNewPaymentMethod(false);
        setLoading(false);
      },
      () => {
        setShowNewPaymentMethod(false);
        setLoading(false);
      }
    );
  };

  /**
   * Add the new card to the customerPaymentMethods state
   *
   * @param {Object} paymentData
   */
  const addNewCardToState = (paymentData) => {
    saveCardToState(
      paymentData,
      customer.id,
      customerPaymentMethods,
      (paymentMethods) => {
        changePaymentMethodToken(paymentData.payment.id);
        setCustomerPaymentMethods(paymentMethods);
        setShowPaymentMethodList(true);
        setShowNewPaymentMethod(false);
      }
    );
  };

  /**
   * Dynamically show delivery driver tip options based on deliveryProvider
   */
  const submitOnlineOrderForm = () => {
    if (!paymentMethodToken && !isInvoicingOrder) {
      setShowPaymentMethodList(true);
      return;
    }
    const deliveryProviderSelected = [
      pickupDelivery?.deliveryProvider,
      returnDelivery?.deliveryProvider,
    ];
    if (
      deliveryProviderSelected.every(
        (provider) => provider === deliveryProviders.ownDriver
      ) ||
      (returnMethod === RETURN_METHODS.inStorePickup &&
        deliveryProviderSelected[0] === deliveryProviders.ownDriver)
    ) {
      submitOrder();
    } else {
      if (
        !(
          Number(pickupDelivery?.courierTip) > 0 || Number(returnDelivery?.courierTip) > 0
        )
      ) {
        setShowDeliveryTipModal(true);
      } else {
        submitOrder();
      }
    }
  };

  const submitOrder = async (options) => {
    setError();
    try {
      const turnAroundInHours = dryCleaningEnabled
        ? turnAroundInHoursState
        : generalDeliverySettings?.turnAroundInHours;
      const servicePriceId = selectedService?.prices?.[0]?.id;
      const serviceModifiersIds = selectedModifiers?.map((modifier) => modifier.id);
      const hasDryCleaning = !!selectedCategories.find(
        (category) => category.name === SERVICE_CATEGORY_TYPES.DRY_CLEANING
      );
      const pickupPayload = filterPropertiesInObject(
        pickupDelivery,
        orderDeliverySubFields
      );
      const deliveryPayload = filterPropertiesInObject(
        returnDelivery,
        orderDeliverySubFields
      );
      const orderServices = formatOrderServices({customerSelectedServices});

      const tipPayload = {
        tipAmount: tipAmountForRequest,
        tipOption: selectedTipOption,
      };

      const orderDetails = {
        orderDelivery: {pickup: pickupPayload, delivery: deliveryPayload},
        zipCode: postalCode,
        turnAroundInHours,
        ...(canIncludeTipToOrder ? tipPayload : {}),
        ...(orderServices.length
          ? {orderServices}
          : {servicePriceId, serviceModifierIds: serviceModifiersIds}),
        customerNotes,
        isHangDrySelected,
        hangDryInstructions,
        orderNotes,
        customerAddressId: currentCustomerAddress?.id,
        paymentToken: paymentMethodToken,
        promoCode: promoCodeName,
        bagCount: bagsCount,
        returnMethod,
        hasDryCleaning,
      };

      if (options?.courierTip) {
        const tip = options?.courierTip;
        const deliveryTipFor = getDeliveryTipFor();
        const finalTip =
          deliveryTipFor === onDemandDeliveryTypes.pickupAndDelivery
            ? (tip / 2).toFixed(2)
            : tip;

        if (deliveryTipFor === onDemandDeliveryTypes.pickupAndDelivery) {
          orderDetails.orderDelivery.pickup.courierTip = finalTip;
          orderDetails.orderDelivery.delivery.courierTip = finalTip;
        } else {
          deliveryTipFor === onDemandDeliveryTypes.pickup
            ? (orderDetails.orderDelivery.pickup.courierTip = finalTip)
            : (orderDetails.orderDelivery.delivery.courierTip = finalTip);
        }
      }

      let validityError;

      const orderValidator = new OrderValidator(orderDetails, {
        orderType: ORDER_TYPES.online,
        timeZone: addressTimeZone,
        turnAroundInHours,
        bufferTimeInHours:
          ownDriverDeliverySettings?.deliveryWindowBufferInHours ||
          ownDriverDeliverySettings?.ownDeliverySettings?.deliveryWindowBufferInHours,
        isInvoicingOrder,
        isOoboManager,
      });
      validityError = orderValidator.invalidErrorMessage(
        ORDER_VALIDATOR_CALLED_FROM.CREATE_ONLINE_ORDER
      );

      if (validityError && !validityError.includes("unless a service is selected")) {
        setError(validityError);
        submitEvent(isRWG ? RWG_ORDER_CREATION_ERROR : TUNE_UP_ORDER_CREATION_ERROR, {
          validityError,
        });
      } else {
        setShowDeliveryTipModal(false);
        setLoading(true);
        const payload = {
          ...orderDetails,
          ...(isEmpty(subscription)
            ? {}
            : {
                subscription: {
                  ...subscription,
                  servicePriceId: orderDetails.servicePriceId,
                  modifierIds: orderDetails.serviceModifierIds,
                  ...(canIncludeTipToSubscription ? tipPayload : {}),
                  isRecurringOrderV2: flags.recurringSubscriptionsV2,
                },
              }),
          teamMemberId: getTeamMemberId() || null,
        };
        const orderResponse = await submitPickupOrder(storeId, payload);
        submitEvent(isRWG ? RWG_ORDER_CREATED : TUNE_UP_ORDER_CREATED, {
          payload,
          hasMultipleServices: orderServices?.length > 1,
        });
        createSuccessToast({primaryMessage: "Your order's in!"});
        trackOrderCreationEvent();
        sessionStorage.removeItem(PAGES_KEY);
        history.push(`/order/${orderResponse?.data?.serviceOrderId}`);
        dispatch(orderActions.resetOnlineOrderState({}));
        dispatch(referralActions.resetReferralInfo());
      }
    } catch (err) {
      Sentry.captureException(error);
      createErrorToast({
        primaryMessage: err?.response?.data?.error || EN_LOCALE.order.errors.submitError,
      });
    } finally {
      setLoading(false);
    }
  };

  const getDeliveryTipFor = () => {
    const pickupDeliveryProvider = pickupDelivery?.deliveryProvider;
    const returnDeliveryProvider = returnDelivery?.deliveryProvider;

    if (pickupDeliveryProvider !== deliveryProviders.ownDriver) {
      if (
        returnDeliveryProvider !== deliveryProviders.ownDriver &&
        returnMethod === RETURN_METHODS.delivery
      ) {
        return onDemandDeliveryTypes.pickupAndDelivery;
      }
      return onDemandDeliveryTypes.pickup;
    }
    return onDemandDeliveryTypes.delivery;
  };

  const setDeliveryTip = (tip) => {
    setShowDeliveryTipModal(false);
    submitOrder({courierTip: tip});
  };

  const isSubscriptionOrder = subscription?.interval;

  return (
    <div className="new-order-review">
      {!showNewPaymentMethod && (
        <OrderScreenWrapper
          header="Checkout"
          headerLeftIconType={HEADER_LEFT_ICON_TYPES.BACK}
          submitText="Submit Order"
          showPoweredByCents={true}
          onSubmit={submitOnlineOrderForm}
          error={error}
          loading={loading}
          businessId={businessId}
          tipSettings={tipSettingsPayload}
          onBack={() => history.goBack()}
        >
          {loading && <Loader style={{position: "fixed", left: "0"}} />}
          <SummarySection isInvoicingOrder={isInvoicingOrder} />
          <DeliveryDetails businessId={businessId} />
          <Payment
            customer={customer}
            customerPaymentMethods={customerPaymentMethods}
            onPaymentSelection={(paymentToken) => {
              changePaymentMethodToken(paymentToken);
            }}
            onShowNewPaymentMethod={() => {
              setShowNewPaymentMethod(true);
            }}
            setShowPaymentMethods={setShowPaymentMethodList}
            showPaymentMethods={showPaymentMethodList}
            paymentToken={paymentMethodToken}
            isInvoicingOrder={isInvoicingOrder}
            isSubscriptionOrder={isSubscriptionOrder}
            businessName={businessName}
          />

          <AddDeliveryTip
            deliveryTip={pickupDelivery.courierTip}
            onAddDeliveryTip={setDeliveryTip}
            deliveryTipFor={getDeliveryTipFor()}
            isNewOrder
            showDeliveryTipModal={showDeliveryTipModal}
            setShowDeliveryTipModal={setShowDeliveryTipModal}
          />

          <CustomTipDockModal
            isOpen={showCustomTipModal}
            toggle={toggleCustomTipModal}
            onUpdate={setSelectedTipOption}
          />
        </OrderScreenWrapper>
      )}
      {showNewPaymentMethod && (
        <div className="new-payment-method-view">
          <Elements stripe={stripePromise}>
            <AddPaymentMethod
              goBack={() => {
                window.scrollTo(0, 0);
                setShowNewPaymentMethod(false);
                setShowPaymentMethodList(true);
              }}
              onSave={(paymentInfo) => {
                if (paymentInfo.rememberPaymentMethod) {
                  saveNewCard(paymentInfo);
                } else {
                  addNewCardToState(paymentInfo);
                }
              }}
              isSubscriptionOrder={isSubscriptionOrder}
            />
          </Elements>
        </div>
      )}
    </div>
  );
};
