import {FC} from "react";
import {LabeledCheckbox} from "components/common/checkboxes/LabeledCheckbox";
import {getPriceString, getMinPriceString} from "components/online-order/utils";
import {ILaundryService} from "state/redux/slices/order/types/orderBuilder";
import pluralize from "utils/pluralize";

export type ServiceCheckboxProps = {
  service: ILaundryService;
  isSelected: boolean;
  selectedModifiersCount?: number;
  onClick: (payload: {service: ILaundryService; isSelected?: boolean}) => void;
};

export const ServiceCheckbox: FC<ServiceCheckboxProps> = ({
  service,
  isSelected,
  selectedModifiersCount,
  onClick,
}) => {
  const servicePrice = getPriceString(service);

  const subTitle = service.hasMinPrice
    ? `${servicePrice} (minimum ${getMinPriceString(service)})`
    : servicePrice;

  const label = selectedModifiersCount
    ? `+ ${pluralize("add-on", selectedModifiersCount)}`
    : null;

  const onChange = (isSelected: boolean) =>
    onClick({
      service,
      isSelected,
    });

  return (
    <LabeledCheckbox
      title={service.name}
      subTitle={subTitle}
      checked={isSelected}
      label={label}
      onChange={onChange}
    />
  );
};
