import {DateTime} from "luxon";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderActions} from "state/redux/slices/order";
import {orderSelectors} from "state/redux/slices/order/selectors";
import {useWindowsPerDay} from "hooks/schedule/useWindowsPerDay";

type UseResetDayIndex = (options: {isPickup: boolean}) => {resetDayIndex: () => void};

export const useResetDayIndex: UseResetDayIndex = ({isPickup}) => {
  const dispatch = useAppDispatch();
  const {deliveryDays} = useWindowsPerDay();
  const {pickup, returnInfo} = useAppSelector(orderSelectors.getScheduleDetails);
  const timeZone = useAppSelector(orderSelectors.getStoreTimeZone);

  const resetDayIndex = () => {
    let selectedDayIndex = deliveryDays.findIndex((possibleDay) =>
      possibleDay.date.includes(
        (isPickup
          ? DateTime.fromISO(String(pickup?.startTime)).setZone(timeZone)
          : DateTime.fromISO(String(returnInfo?.startTime)).setZone(timeZone)
        ).toFormat("yyyy-LL-dd")
      )
    );

    if (selectedDayIndex < 0) {
      selectedDayIndex = 0;
    }

    dispatch(
      isPickup
        ? orderActions.setPickupDayIndex(selectedDayIndex)
        : orderActions.setReturnDayIndex(selectedDayIndex)
    );
  };

  return {resetDayIndex};
};
