import {FC} from "react";
import cx from "classnames";
import {ButtonVariant, Button} from "components/common/buttons/Button";
import {PriceTag} from "components/common/labels/PriceTag/PriceTag";
import styles from "./dryCleaningItem.module.scss";

export const DryCleaningItem: FC<{
  onRemove?: () => void;
  onEdit?: () => void;
  isRemovingAvailable?: boolean;
  isEditAvailable?: boolean;
}> = ({onRemove, onEdit, isRemovingAvailable = false}) => {
  const buttonText = isRemovingAvailable ? "Remove" : "Edit";
  const onButtonClick = isRemovingAvailable ? onRemove : onEdit;

  return (
    <div className={styles.dryCleaningWrapper}>
      <div>
        <h3>Dry Cleaning</h3>
        <PriceTag />
      </div>
      {!!onButtonClick && (
        <Button
          variant={ButtonVariant.UNDERLINED}
          className={cx(styles.actionButton, styles.dryCleaningAction)}
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};
