import {httpClient} from "api";
import {type AxiosResponse} from "axios";

export interface Performer {
  storeId: number;
  businessId: number;
}

type FetchStoreLevelPerformer = (storeId: string) => Promise<AxiosResponse<Performer>>;

/**
 * Performer is combination of business.Id and store.Id
 */
export const fetchStoreLevelPerformer: FetchStoreLevelPerformer = (encodedId: string) => {
  return httpClient({
    method: "GET",
    url: `live-status/store-branding/${encodedId}/performer`,
  });
};
