import {useSelector} from "react-redux";
import {
  getNearStoresData,
  getCurrentView,
  getReturnWindowsState,
} from "state/redux/slices/order/selectors";
import {formatDeliveryDays} from "utils/schedule/formatDeliveryDays";
import {DELIVERY_TYPE_KEYS, VIEWS} from "constants/order";

export const filterDeliveryDays = (day) =>
  day[DELIVERY_TYPE_KEYS.OWN].length || day[DELIVERY_TYPE_KEYS.ON_DEMAND].length;

export const useWindowsPerDay = () => {
  const {
    data: {deliveryDays: nearStoresDeliveryDays, autoScheduleReturnEnabled},
  } = useSelector(getNearStoresData);
  const currentView = useSelector(getCurrentView);
  const isPickup = [
    VIEWS.ALL_WINDOWS_PICKUP,
    VIEWS.RECOMMENDED_PICKUP,
    VIEWS.RETURN_QUESTION,
    VIEWS.AVAILABLE_PICKUP_DATES,
    autoScheduleReturnEnabled ? VIEWS.SUBSCRIPTION_OFFER : "",
  ].includes(currentView);

  const isReturn = [
    VIEWS.ALL_WINDOWS_RETURN,
    VIEWS.RECOMMENDED_RETURN,
    VIEWS.SUBSCRIPTION_OFFER,
  ].includes(currentView);

  let {
    data: {deliveryDays},
  } = useSelector(isPickup ? getNearStoresData : getReturnWindowsState);

  if (!deliveryDays) {
    deliveryDays = nearStoresDeliveryDays;
  }

  let haveOwn = false;
  let haveOnDemand = false;
  let formattedDays = [];

  if (isPickup || isReturn) {
    haveOwn = deliveryDays?.reduce(
      (isHave, day) => isHave || day[DELIVERY_TYPE_KEYS.OWN].length,
      false
    );
    haveOnDemand = deliveryDays?.reduce(
      (isHave, day) => isHave || day[DELIVERY_TYPE_KEYS.ON_DEMAND].length,
      false
    );

    const filteredDays = deliveryDays.filter(filterDeliveryDays);

    formattedDays = formatDeliveryDays(filteredDays);
  }

  return {deliveryDays: formattedDays, haveOwn, haveOnDemand, isPickup, isReturn};
};
