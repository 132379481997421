import type {AxiosResponse} from "axios";
import {PricingType} from "components/common/labels/PriceTag/PriceTag";
import {IDeliveryWindow} from "state/redux/slices/order/types";
import httpClient from "api/httpClient";
import {DELIVERY_PROVIDERS} from "constants/order";
import {IAddress} from "types/customer";
import {ObjectValues} from "types/typeUtils";

type TransportationConfiguration = {
  isEstimateSuccessful: boolean;
  courierTip: number;
  formattedWindow: IDeliveryWindow["display"];
  provider: ObjectValues<typeof DELIVERY_PROVIDERS>;
  timingId: number;
  onDemandProviderAssets: {
    providerName: string;
    providerLogo: string;
  } | null;
  /**
   * ISO strings
   */
  window: [string, string];
};

export interface IRecurringOrderDetails {
  recurringOrderId: number;
  storeId: number;
  timeZone: string;
  interval: 1 | 2 | 4;
  transportation: {
    address: IAddress;
    pickup: TransportationConfiguration;
    delivery: TransportationConfiguration | null;
  };
  services: {
    turnAround: number;
    hasDryCleaning: boolean;
    laundry: Array<{
      servicePriceId: number;
      name: string;
      price: number;
      pricingType: PricingType;
      modifiers: Array<{
        serviceModifierId: number;
        name: string;
        price: number;
        pricingType: PricingType;
      }>;
    }> | null;
  };
  payment: {paymentMethodId: number; paymentMethodToken: string} | null;
  orderTipOption: string | null;
  orderNotes: string;
  /**
   * ISO string
   */
  nextActiveInstanceWeek: string;
  isSkipAvailable: boolean;
}

export type RecurringOrderDetailsData = {
  recurringOrder: IRecurringOrderDetails;
  centsNotification: RecurringOrderCentsNotification;
};

type GetRecurringOrderDetails = (data: {
  recurringOrderId: number;
}) => Promise<AxiosResponse<RecurringOrderDetailsData>>;

export interface RecurringOrderCentsNotification {
  subject: string;
  reason: string;
  isCancelButtonShowing: boolean;
  isRescheduleButtonShowing: boolean;
  originalPickupWindow: number[];
  currentPickupWindow?: number[];
  originalDeliveryWindow: number[];
  currentDeliveryWindow?: number[];
  centsNotificationId: number;
}

export const getRecurringOrderDetails: GetRecurringOrderDetails = ({
  recurringOrderId,
}) => {
  return httpClient({
    method: "GET",
    url: `/live-status/recurring-orders/${recurringOrderId}`,
  });
};
