export const BASE_URL = import.meta.env.VITE_BASE_URL;
export const GOOGLE_PLACES_API_KEY = import.meta.env.VITE_GOOGLE_PLACE_API;
export const GOOGLE_PLACES_AUTOCOMPLETE_URL =
  "https://maps.googleapis.com/maps/api/place/autocomplete";
export const GOOGLE_PLACES_FIND_URL =
  "https://maps.googleapis.com/maps/api/place/findplacefromtext";
export const GOOGLE_PLACES_FIND_TIMEZONE_URL =
  "https://maps.googleapis.com/maps/api/timezone/json";
export const STRIPE_PUBLIC_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY;
export const LAUNCHDARKLY_USER = "developer@trycents.com";
export const RECAPTCHA_KEY = "6LdxzUUaAAAAAJhAxkn1z6ZX2rF_8wzjNS1SN8Nh";
export const SENTRY_KEY =
  "https://e915a3bbe87749748c12a0cc77b7d7e7@o464187.ingest.sentry.io/6196282";

export const CUSTOMER_AUTH_TOKEN_KEY = "liveLink:customerAuthToken";
export const BUSINESS_OWNER_AUTH_TOKEN_KEY = "BUSINESS_OWNER_AUTH_TOKEN";
export const TEAM_MEMBER = "TEAM_MEMBER";
export const BUSINESS_ASSETS_KEY = "BUSINESS_ASSETS";
export const CUSTOMER_KEY = "liveLink:customer";
export const NOTIFY_KEY = "liveLink:notifyKey";
export const GTM_ID = import.meta.env.VITE_GTM_ID;
export const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;
export const PUSHER_APP_CLUSTER = "us2";
export const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY;
export const FULLSTORY_ORG_ID = "o-1KH4JQ-na1";
export const SENTRY_ORG_SLUG = "make-cents-technologies";
export const IS_TESTING_ENVIRONMENT = import.meta.env.VITE_TESTING_ENVIRONMENT;
export const RWG_TTL_DAYS = 30;
export const NODE_PATH = "src/";
