import {createAsyncThunk} from "@reduxjs/toolkit";
import {
  RECURRING_ORDERS_REDUCER_NAME,
  SET_LIST,
} from "state/redux/slices/recurringOrder/constants/actions";
import {
  RecurringOrdersListData,
  getRecurringOrdersList,
} from "api/recurringOrder/getRecurringOrdersList";

export const setRecurringOrdersListThunk = createAsyncThunk(
  `${RECURRING_ORDERS_REDUCER_NAME}/${SET_LIST}`,
  async ({businessId}: {businessId: number}): Promise<RecurringOrdersListData> => {
    const response = await getRecurringOrdersList({businessId});
    return response.data;
  }
);
