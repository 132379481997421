import {useEffect, useMemo, useRef, useState} from "react";
import type {ITopOffData} from "features/order/self-serve/types";
import moment from "moment";
import {Button, Flex, Text} from "rebass/styled-components";
import {MachineEntity} from "types";
import AddTimeModal from "components/newOrder/common/AddTimeModal/AddTimeModal";
import type {DeviceStatusPusherResponse} from "components/self-order/types";
import {convertSecondsToTime} from "utils/date";
import type {UseAddCreditsPayload} from "api/queries/useAddCredits";
import type {FetchRealtimeDeviceStatusResponse} from "api/statusDevice";
import {MachineManufacturers, SelfServiceStatuses} from "constants/index";
import {ICustomer} from "types/customer";

export interface FormattedRealtimeStatusData extends FetchRealtimeDeviceStatusResponse {
  name: string;
  prefix: string;
}

interface SelfOrderHeaderProps {
  createdAt?: string;
  status?: SelfServiceStatuses;
  machine?: MachineEntity | FormattedRealtimeStatusData | null;
  ableToAddTime?: boolean;
  topOffData?: ITopOffData | null;
  customer: Pick<ICustomer, "id" | "paymentMethods" | "addresses"> | null;
  storeId?: number;
  totalTurnTimeInMinutes?: number;
  manufacturer?: MachineManufacturers | null;
  turnId?: number;
  isGPIOMachine: boolean;
  startAgain: () => void;
  showAddTimeModal: boolean;
  toggleShowAddTimeModal: () => void;
  balanceInDollars?: number | null;
  refetchBalance: UseAddCreditsPayload["refetch"];
  deviceSettings: DeviceStatusPusherResponse | null;
}

export const SelfOrderHeader = ({
  createdAt,
  status,
  machine,
  ableToAddTime,
  topOffData,
  customer,
  storeId,
  totalTurnTimeInMinutes,
  manufacturer,
  turnId,
  isGPIOMachine,
  startAgain,
  showAddTimeModal,
  toggleShowAddTimeModal,
  balanceInDollars,
  refetchBalance,
  deviceSettings,
}: SelfOrderHeaderProps) => {
  const {timeRemaining} = deviceSettings || {};
  const timeRemainingTimer = useRef<any | null>(null);
  const [timeRemainingSeconds, setTimeRemainingSeconds] = useState<number>(
    timeRemaining || 0
  );
  const updateTime = () => {
    timeRemainingTimer.current = setInterval(() => {
      setTimeRemainingSeconds((prevCount) => prevCount - 1);
    }, 1000);
  };

  useEffect(() => {
    if (timeRemaining) {
      setTimeRemainingSeconds(timeRemaining);
      updateTime();
    } else {
      setTimeRemainingSeconds(0);
    }

    return () => {
      clearInterval(Number(timeRemainingTimer?.current));
    };
  }, [timeRemaining]);

  useEffect(() => {
    if (timeRemainingTimer.current && timeRemainingSeconds <= 0) {
      clearInterval(timeRemainingTimer.current);
      timeRemainingTimer.current = null;
    }
  }, [timeRemainingSeconds]);

  const data = useMemo(
    () => ({
      time: moment(createdAt).format("hh:mma"),
      date: moment(createdAt).format("ddd,MMM. Do"),
    }),
    [createdAt]
  );

  const getHeaderContent = () => {
    switch (status) {
      case SelfServiceStatuses.CREATED:
        return (
          <Flex {...styles.container}>
            <Text {...styles.time}>{data.time}</Text>
            <Text {...styles.date}>{data.date}</Text>
          </Flex>
        );
      case SelfServiceStatuses.ENABLED:
        return (
          <Text {...styles.time}>
            {machine?.prefix && machine?.name
              ? `${machine?.prefix}-${machine?.name}`
              : `Machine`}{" "}
            is ready to start
          </Text>
        );
      case SelfServiceStatuses.RUNNING:
        return (
          <>
            {timeRemainingSeconds ? (
              <Flex {...styles.container}>
                <Text {...styles.time}>{convertSecondsToTime(timeRemainingSeconds)}</Text>
                <Text {...styles.date}>Remaining</Text>
              </Flex>
            ) : (
              <Text {...styles.time}>
                {machine?.prefix}-{machine?.name} is running
              </Text>
            )}
          </>
        );
      case SelfServiceStatuses.COMPLETED:
        return (
          <Flex {...styles.container}>
            {!(machine as MachineEntity)?.isDumbDumb ? (
              <Text {...styles.time}>Complete</Text>
            ) : (
              <>
                <Text {...styles.time}>
                  {machine?.prefix}-{machine?.name}
                </Text>
                <Button
                  disabled={!(machine as MachineEntity)?.qrCode?.hash}
                  variant="primary"
                  onClick={startAgain}
                >
                  Start again
                </Button>
              </>
            )}
          </Flex>
        );
      default:
        return <></>;
    }
  };

  return (
    <Flex {...styles.container}>
      {getHeaderContent()}
      {ableToAddTime && (
        <>
          <Button
            {...styles.addTimeButton}
            variant="primary"
            onClick={toggleShowAddTimeModal}
          >
            Add time
          </Button>
          <AddTimeModal
            isOpen={showAddTimeModal}
            toggle={toggleShowAddTimeModal}
            timeRemainingSeconds={timeRemainingSeconds}
            machine={machine as MachineEntity}
            topOffData={topOffData}
            customer={customer}
            storeId={storeId}
            totalTurnTimeInMinutes={totalTurnTimeInMinutes}
            manufacturer={manufacturer}
            turnId={turnId}
            isGPIOMachine={isGPIOMachine}
            balanceInDollars={balanceInDollars}
            refetchBalance={refetchBalance}
            deviceSettings={deviceSettings}
          />
        </>
      )}
    </Flex>
  );
};

const styles = {
  container: {
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  time: {
    fontSize: "24px",
    fontWeight: 500,
    mr: "8px",
  },
  date: {
    fontSize: "14px",
    fontWeight: 400,
    color: "TEXT_GREY",
  },
  addTimeButton: {
    flexShrink: 0,
  },
};
