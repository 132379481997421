import {DeviceModes} from "features/order/self-serve/types";

/**
 * Check whether machine is GPIO based on deviceMode.
 */
export const checkIfMachineIsGPIO = (
  deviceMode?: DeviceModes | null,
  isDumbDumb?: boolean
) => {
  if (!deviceMode) {
    return false;
  }

  return !isDumbDumb && deviceMode === DeviceModes.DUMB;
};

/**
 * Util to convert seconds to MM:SS or minutes to HH:MM format
 */
export const secondsOrMinutesToTimeDisplay = (secondsOrMinutes: number) => {
  return `${Math.floor(secondsOrMinutes / 60)}:${(secondsOrMinutes % 60)
    .toString()
    .padStart(2, "0")}`;
};
