import * as Sentry from "@sentry/browser";
import {useQuery} from "@tanstack/react-query";
import {toast} from "react-toastify";
import type {SelfOrderEntity} from "types";
import {fetchSelfServiceOrder} from "api/order";
import {ERROR_MESSAGES} from "constants/constants";
import type {ApiError} from "types/api";

interface UseGetTurnDetailsPayload {
  turnId?: number | null;
  enabled?: boolean;
}

export const useGetTurnDetails = ({turnId, enabled = true}: UseGetTurnDetailsPayload) => {
  return useQuery<unknown, ApiError, SelfOrderEntity>({
    queryKey: ["useGetTurnDetails", {turnId}],
    queryFn: async () => {
      if (!turnId) {
        return null;
      }

      const {data} = await fetchSelfServiceOrder(turnId);
      return data;
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast.error(error?.response?.data?.error ?? ERROR_MESSAGES.TURN_DETAILS);

      return error;
    },
    enabled,
  });
};
