import {useCallback} from "react";
import {useHistory} from "react-router-dom";
import {referralActions} from "components/referral/redux";
import {onlineOrderState} from "state/online-order";
import {useAppDispatch} from "state/redux/hooks";
import {authActions} from "state/redux/slices/auth";
import {customerActions} from "state/redux/slices/customer";
import {orderActions} from "state/redux/slices/order";
import {logoutCustomer} from "utils/common";

export const useLogout = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const logout = useCallback(() => {
    logoutCustomer();
    history.push("/verify-account");
    dispatch(orderActions.resetOnlineOrderState({}));
    dispatch(authActions.resetUser());
    dispatch(referralActions.resetReferralInfo());
    dispatch(customerActions.resetCustomer());
    onlineOrderState.set({});
  }, [dispatch, history]);

  return {logout};
};
