import PropTypes from "prop-types";
import {orderThemeColors, defaultFontFamily} from "components/online-order/orderTheme";

const ToastError = ({message}) => {
  const error = typeof message === "string" ? message : "Something went wrong";
  return (
    <div style={{alignItems: "center", justifyContent: "flex-start", display: "flex"}}>
      <p style={{color: orderThemeColors.textMain, fontFamily: defaultFontFamily}}>
        {error}
      </p>
    </div>
  );
};

ToastError.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ToastError;
