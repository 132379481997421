import {httpClient} from "api";

export const fetchOnlineOrderServices = ({
  storeId,
  zipCode,
}: {
  storeId: number;
  zipCode?: string;
}) => {
  return httpClient({
    method: "GET",
    url: `/live-status/stores/${storeId}/online-order-services`,
    params: {zipCode},
  });
};
