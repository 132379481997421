import {type PayloadAction} from "@reduxjs/toolkit";
import {type IOrderData} from "state/redux/slices/order/types";
import {type ISelectedService} from "state/redux/slices/order/types/orderBuilder";

export const setCustomerSelectedServices = (
  state: IOrderData,
  action: PayloadAction<ISelectedService[]>
) => {
  state.orderBuilder.services.customerSelectedServices = action.payload;
};
