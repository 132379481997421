import {CycleStatuses, SelfServiceStatuses} from "../../constants";

export const convertDollarToCents = (value: number) => value / 100;

export const configureLifeCycleData = (
  status: SelfServiceStatuses | undefined,
  length: number
) => {
  const steps = [];
  for (let i = 0; i < length; i++) {
    steps.push({
      order: i,
      status: CycleStatuses.INCOMING,
    });
  }

  // If steps will changed we just need to set right indexes for active statuses
  switch (status) {
    case SelfServiceStatuses.CREATED:
    case SelfServiceStatuses.ENABLED:
      steps[0].status = CycleStatuses.ACTIVE;
      break;
    case SelfServiceStatuses.STARTED:
    case SelfServiceStatuses.RUNNING:
      steps[1].status = CycleStatuses.ACTIVE;
      break;
    case SelfServiceStatuses.COMPLETED:
      steps[2].status = CycleStatuses.ACTIVE;
      break;
  }

  const activeIndex = steps.findIndex((item) => item.status === CycleStatuses.ACTIVE);
  steps.map((item, index) => {
    if (index < activeIndex) {
      item.status = CycleStatuses.COMPLETED;
    }

    return item;
  });

  return steps;
};
