import {INashEstimate} from "state/redux/slices/order/types";
import {DELIVERY_CONFLICTS_TYPES, DELIVERY_PROVIDERS} from "constants/order";
import {ObjectValues} from "types/typeUtils";
import {InputWithNavigation} from "../../common/ScheduleForm";
import {ScheduleFormInputTypes} from "../../common/ScheduleFormPickupDeliveryInput";

export type ScheduleWindow = {
  dayIndex: number;
  deliveryFeeInCents: number;
  display: {
    displayDate: string;
    startTime: string;
    endTime: string;
    price: string;
  };
  endTime: string;
  id: number | string;
  isRecommended: boolean;
  key: string;
  maxStops: number | null;
  orderDeliveryCount: number;
  recurringSubscriptionCount: number;
  serviceType: string;
  startTime: string;
  storeId: number | string;
  type: ObjectValues<typeof DELIVERY_PROVIDERS>;
  windowConflicts: Array<DELIVERY_CONFLICTS_TYPES>;
  turnAround?: string | number | null;
  isPickup?: boolean;
  timeZone?: string;
  zipCode?: string;
  autoScheduleTiming?: Partial<ScheduleWindow>;
  doorDashEstimate?: {
    deliveryTime: number;
    deliveryFee: number;
  };
  estimate?: INashEstimate;
};

export interface IUseScheduling {
  setIsNextButtonDisabled: (isDisabled: boolean) => void;
  /** @deprecated Use transportationPreference instead */
  isAutoSchedule: boolean;
  bagsCount: number;
  pickup: ScheduleWindow;
  delivery: ScheduleWindow;
  selectedServices: Array<string>;
  turnAround: string | number | null;
  setPickupDeliveryData: React.Dispatch<
    React.SetStateAction<Record<ScheduleFormInputTypes, InputWithNavigation>>
  >;
  gotoPickup: () => void;
  gotoDelivery: () => void;
  form: HTMLFormElement;
}

export const enum STEPS {
  INITIAL = "INITIAL",
  SCHEDULE_PICKUP = "SCHEDULE_PICKUP",
  SKIP_DELIVERY = "SKIP_DELIVERY",
  APPLY_AUTO_SCHEDULE = "APPLY_AUTO_SCHEDULE",
  SCHEDULE_DELIVERY = "SCHEDULE_DELIVERY",
  DONE = "DONE",
}
