import httpClient from "./httpClient";

export const getAvailableNearStores = (nearStoresParams) => {
  const {
    businessId,
    timeZone,
    zipCode,
    lat,
    lng,
    googlePlacesId,
    turnAroundTime,
    storeId,
    startPickupDate,
  } = nearStoresParams;
  return httpClient({
    method: "GET",
    url: "/live-status/near-stores",
    params: {
      businessId,
      timeZone,
      zipCode,
      lat,
      lng,
      googlePlacesId,
      turnAroundTime,
      storeId,
      startPickupDate,
    },
  });
};

export const fetchServicesAndModifiers = (storeId, zipCode, serviceOrderId) => {
  return httpClient({
    method: "GET",
    url: `/live-status/stores/${storeId}/deliverable-services`,
    params: {zipCode, serviceOrderId},
  });
};

export const fetchFeaturedServices = (storeId, data) => {
  return httpClient({
    method: "GET",
    url: `/live-status/stores/${storeId}/featured-services`,
    params: data,
  });
};

export const saveAddressInfo = (placeId, data, storeId, businessId) => {
  return httpClient({
    method: "PUT",
    url: `/live-status/customer/address/${placeId}`,
    data,
    params: {storeId, businessId},
  });
};

export const deleteCustomerAddress = (id) => {
  return httpClient({
    method: "DELETE",
    url: `/live-status/customer/address/${id}`,
  });
};

export const fetchCustomerInfo = (storeId) => {
  return httpClient({
    method: "GET",
    url: `/live-status/customer/${storeId}/info`,
  });
};

export const fetchAvailableDeliverySettings = (storeId) => {
  return httpClient({
    method: "GET",
    url: `/live-status/stores/${storeId}/available-delivery-settings`,
  });
};

export const fetchOwnDriverDeliverySettings = (storeId, params) => {
  return httpClient({
    method: "GET",
    params,
    url: `/live-status/stores/${storeId}/own-driver-delivery-settings`,
  });
};

export const fetchOnDemandDeliverySettings = (storeId) => {
  return httpClient({
    method: "GET",
    url: `/live-status/stores/${storeId}/on-demand-delivery-settings`,
  });
};

export const submitPickupOrder = (storeId, data) => {
  return httpClient({
    method: "POST",
    url: `/live-status/stores/${storeId}/order`,
    data,
  });
};

export const fetchPreferences = (businessId) => {
  return httpClient({
    method: "GET",
    url: `/live-status/preference-choices/business/${businessId}`,
  });
};

export const updateCustomerOptionSelectionsScope = (data) => {
  return httpClient({
    method: "PATCH",
    url: `/live-status/preference-choices/selections/scope`,
    data,
  });
};

export const getBusinessByCustomUrl = (customUrl) => {
  return httpClient({
    method: "GET",
    url: `/live-status/business/custom/${customUrl}`,
  });
};

export const fetchInitialOrderData = (businessId, storeId) => {
  return httpClient({
    method: "GET",
    url: "/live-status/order-home-data",
    params: {business: businessId, store: storeId},
  });
};

export const fetchReturnWindows = (params) => {
  return httpClient({
    method: "GET",
    url: "/live-status/windows-since",
    params,
  });
};

export const patchIsPreferencePageShowing = (data) => {
  return httpClient({
    method: "PATCH",
    url: `/live-status/customer/preferences/preferenceShowing`,
    data,
  });
};
