import {createAsyncThunk} from "@reduxjs/toolkit";
import {getCustomerPaymentMethods} from "api/paymentMethods";
import {IPaymentMethod} from "types/customer";
import {GET_PAYMENT_METHODS, CUSTOMER_REDUCER_NAME} from "../constants/actions";

export interface IPaymentsResponse {
  success: true;
  paymentMethods: IPaymentMethod[];
}

export const getPaymentMethodsThunk = createAsyncThunk<IPaymentsResponse>(
  `${CUSTOMER_REDUCER_NAME}/${GET_PAYMENT_METHODS}`,
  async (_, thunkApi) => {
    try {
      const response = await getCustomerPaymentMethods();
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);
