import {CSSProperties, FC, ReactNode} from "react";
import SVG from "react-inlinesvg";
import {Flex, Text, Image, Box} from "rebass/styled-components";
import {processActionByKeyboard} from "utils/a11y";
import {EN_LOCALE} from "locales/en";
import {ColorablePlusIcon, ActiveCheckIcon} from "assets/images";
import moduleStyles from "./categorySelectionCard.module.scss";

type CategoryCardProps = {
  imageSource: string;
  title: string;
  subtitle: string;
  itemSelected: boolean;
  onClick?: (value: boolean) => void;
  children?: ReactNode;
  customStyle?: CSSProperties;
  isPreSelected?: boolean;
};

export const CategorySelectionCard: FC<CategoryCardProps> = ({
  imageSource,
  title,
  subtitle,
  itemSelected,
  onClick,
  children,
  customStyle,
  isPreSelected = false,
}) => {
  return (
    <Flex
      {...styles.cardContainer}
      style={customStyle}
      onClick={() => onClick?.(itemSelected)}
      aria-pressed={itemSelected}
      role="button"
      tabIndex={0}
      onKeyDown={(event) => {
        processActionByKeyboard(event, () => onClick?.(itemSelected));
      }}
      className={moduleStyles.container}
    >
      <Flex {...styles.content}>
        <Flex>
          {!isPreSelected && (
            <Flex {...styles.svgContainer}>
              {itemSelected ? (
                <SVG src={ActiveCheckIcon} {...styles.image} />
              ) : (
                <SVG src={ColorablePlusIcon} {...styles.image} />
              )}
            </Flex>
          )}

          <Box>
            <Text {...styles.titleText}>{title}</Text>
            <Text {...styles.turnaroundText}>{subtitle}</Text>
          </Box>
        </Flex>

        <Image src={imageSource} height={61} width={63} alt={EN_LOCALE.label.category} />
      </Flex>
      {children && <Flex {...styles.children}>{children}</Flex>}
    </Flex>
  );
};

const styles = {
  cardContainer: {
    sx: {
      width: "100%",
      p: "16px",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  svgContainer: {
    width: 40,
    height: 40,
    mr: "24px",
  },
  content: {
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
  },
  titleText: {
    color: "NEW_TEXT_PRIMARY_BLUE",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
  },
  turnaroundText: {
    color: "NEW_TEXT_PRIMARY_BLUE",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
  },
  image: {
    width: "40px",
    height: "40px",
    minwidth: "40px",
    minheight: "40px",
  },
  children: {
    pt: "8px",
    width: "100%",
  },
};
