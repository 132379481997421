import {Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import {A11yButton} from "components/common/buttons/A11yButton";
import {DeliveryConflictLabel} from "components/common/labels/DeliveryConflictLabel";
import {DeliveryShiftLabel} from "components/common/labels/DeliveryShiftLabel";
import {useBranding} from "hooks/branding/useBranding";
import {checkOOBO} from "utils/ooboHelpers";
import {DELIVERY_PROVIDERS} from "constants/order";
import DoorDashIcon from "assets/images/Icon_DoorDash_With_Title.svg";

export const TimeBox = ({
  boxData: {
    display: {displayDate: date, price, endTime, startTime, shiftName, shiftColor},
    type,
    key,
  },
  boxData,
  chooseTime,
  selectedTime,
}) => {
  const {primaryColor} = useBranding();

  const time = `${startTime}-${endTime}`;

  const selectAdditionalTime = () => {
    chooseTime(boxData);
  };

  const isOperatorSession = checkOOBO();
  const isSelectedTime = selectedTime === key;

  return (
    <>
      <A11yButton
        data-item="true"
        className="time-box window-box"
        onAction={selectAdditionalTime}
        style={isSelectedTime ? {borderColor: primaryColor} : {}}
        aria-pressed={isSelectedTime}
        aria-label={`${date} ${time} ${price}`}
      >
        <div className="time-details">
          <Typography variant="h4" className="day">
            {date}
          </Typography>
          <Typography variant="subtitle2" className="time text-primary">
            {time}
          </Typography>
          {isOperatorSession ? (
            <DeliveryShiftLabel
              shiftName={shiftName}
              shiftColor={type === DELIVERY_PROVIDERS.ownDriver ? shiftColor : null}
            />
          ) : null}
        </div>
        <div className="delivery-details">
          <Typography variant="h5" className="price">
            {price}
          </Typography>
          {boxData?.windowConflicts?.length > 0 || boxData?.maxStops === 0 ? (
            <DeliveryConflictLabel />
          ) : null}
          {type === DELIVERY_PROVIDERS.doorDash ? (
            <img src={DoorDashIcon} alt="DoorDash" />
          ) : null}
        </div>
      </A11yButton>
    </>
  );
};

TimeBox.propTypes = {
  boxData: PropTypes.shape({
    dayIndex: PropTypes.number,
    deliveryFeeInCents: PropTypes.number,
    display: PropTypes.shape({
      displayDate: PropTypes.string,
      endTime: PropTypes.string,
      price: PropTypes.string,
      startTime: PropTypes.string,
    }),
    endTime: PropTypes.string,
    id: PropTypes.number,
    isPickup: PropTypes.bool,
    isRecommended: PropTypes.bool,
    key: PropTypes.string,
    maxStops: PropTypes.number,
    oneWayFee: PropTypes.string,
    orderDeliveriesCount: PropTypes.number,
    recurringSubscriptionCount: PropTypes.number,
    serviceType: PropTypes.string,
    startTime: PropTypes.string,
    storeId: PropTypes.number,
    timingIndex: PropTypes.number,
    type: PropTypes.string,
  }).isRequired,
  chooseTime: PropTypes.func.isRequired,
};

TimeBox.defaultProps = {
  boxData: {
    dayIndex: 0,
    deliveryFeeInCents: 0,
    display: {
      displayDate: "",
      endTime: "",
      price: "",
      startTime: "",
    },
    endTime: "",
    id: 0,
    isPickup: false,
    isRecommended: false,
    key: "",
    maxStops: 0,
    oneWayFee: "",
    orderDeliveriesCount: 0,
    recurringSubscriptionCount: 0,
    serviceType: "",
    startTime: "",
    storeId: 0,
    timingIndex: 0,
    type: "",
  },
};
