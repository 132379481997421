import * as Sentry from "@sentry/browser";
import {useQuery} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {
  FetchRealtimeDeviceStatusResponse,
  fetchRealtimeDeviceStatus,
} from "api/statusDevice";
import {ERROR_MESSAGES} from "constants/constants";
import {ApiError} from "types/api";

interface UseRealtimeStatusPayload {
  machineId?: number | null;
  isDumbDumb?: boolean | null;
  enabled?: boolean;
}

export const useRealtimeStatus = ({
  machineId,
  isDumbDumb,
  enabled = true,
}: UseRealtimeStatusPayload) => {
  return useQuery<unknown, ApiError, FetchRealtimeDeviceStatusResponse>({
    queryKey: ["useRealtimeStatus", {machineId}],
    queryFn: async () => {
      if (!machineId || isDumbDumb) {
        return null;
      }

      const {data} = await fetchRealtimeDeviceStatus(machineId);
      return data;
    },
    onError: (error) => {
      Sentry.captureException(error);

      toast.error(error?.response?.data?.error ?? ERROR_MESSAGES.REALTIME_STATUS);

      return error;
    },
    enabled,
  });
};
