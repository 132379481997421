import {createAsyncThunk} from "@reduxjs/toolkit";
import {saveAddressInfo} from "api/online-order";
import {type IAddress} from "types/customer";
import {PATCH_ADDRESS, CUSTOMER_REDUCER_NAME} from "../constants/actions";

export interface IPatchAddressParams {
  data: Partial<
    Pick<
      IAddress,
      | "address1"
      | "address2"
      | "city"
      | "countryCode"
      | "firstLevelSubdivisionCode"
      | "postalCode"
      | "instructions"
      | "leaveAtDoor"
      | "lat"
      | "lng"
    >
  >;
  isNew?: boolean;
  storeId?: number;
  businessId?: number;
  googlePlacesId: string;
}

export interface IPatchAddressResponse {
  allCustomerAddresses: IAddress[];
  centsCustomerAddress: IAddress;
  isNew: boolean;
  lat: number;
  lng: number;
  success: boolean;
}

export const patchAddress = createAsyncThunk(
  `${CUSTOMER_REDUCER_NAME}/${PATCH_ADDRESS}`,
  async (sendData: IPatchAddressParams) => {
    const {googlePlacesId, data, isNew, storeId, businessId} = sendData;
    const response = await saveAddressInfo(googlePlacesId, data, storeId, businessId);
    return {
      ...response.data,
      isNew,
      lat: data.lat,
      lng: data.lng,
    } as IPatchAddressResponse;
  }
);
