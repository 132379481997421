import {ChangeEvent, FC, ReactNode} from "react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  Checkbox,
  type CheckboxProps,
  FormControlLabel,
  type FormControlLabelProps,
} from "@mui/material";
import {ReactComponent as ColorableCheckbox} from "assets/images/svg/colorable/Checkbox.svg";

export interface FormCheckboxProps
  extends Omit<FormControlLabelProps, "control" | "label" | "onChange"> {
  checked: boolean;
  name?: string;
  children: ReactNode;
  checkboxProps?: Omit<CheckboxProps, "checked" | "onChange" | "name">;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked?: boolean) => void;
}

export const FormCheckbox: FC<FormCheckboxProps> = ({
  checked,
  name,
  onChange,
  children,
  checkboxProps,
  ...props
}) => {
  return (
    <FormControlLabel
      {...props}
      control={
        <Checkbox
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<ColorableCheckbox />}
          onChange={onChange}
          checked={checked}
          name={name}
          {...checkboxProps}
        />
      }
      label={children}
    />
  );
};
