import {OrderDetailsData} from "components/orderManager/tuneUp/OrderDetails/orderDetails.types";
import {useAppSelector} from "state/redux/hooks";
import {getCustomerSelectedServices} from "state/redux/slices/order/selectors/servicesSelector";
import {ISelectedService} from "state/redux/slices/order/types/orderBuilder";
import {formatServicesListFromCustomerSelectedServices} from "utils/order/formatServicesListFromCustomerSelectedServices";
import {formatServicesListFromOrderItems} from "utils/order/formatServicesListFromOrderItems";

interface UseSelectedServicesListParam {
  orderItems?: OrderDetailsData["orderItems"];
}

export const useSelectedServicesList = ({
  orderItems,
}: UseSelectedServicesListParam = {}) => {
  const customerSelectedServices = useAppSelector(getCustomerSelectedServices);

  const servicesList = orderItems
    ? formatServicesListFromOrderItems({
        orderItems,
      })
    : formatServicesListFromCustomerSelectedServices({
        customerSelectedServices,
      });

  const getServiceById = (id: ISelectedService["id"]) => {
    return customerSelectedServices.find((service) => service.id === id) || null;
  };

  return {servicesList, getServiceById};
};
