import {type PayloadAction} from "@reduxjs/toolkit";
import {type IAddress} from "types/customer";
import {type ICustomerInitialData} from "../types/initialData";

export const setAddressIdToUpdate = (
  state: ICustomerInitialData,
  action: PayloadAction<IAddress["id"] | null>
) => {
  state.addresses.addressIdToUpdate = action.payload;
};
