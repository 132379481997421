import {FC, ReactNode} from "react";
import {toast} from "react-toastify";

import "./styles.scss";

const warningToastOptions = {
  style: {
    background: "#FFF5E5",
    borderRadius: "12px",
    boxShadow:
      "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
  },
  closeButton: false,
  position: toast.POSITION.BOTTOM_CENTER,
};

interface ToastWarningProps {
  primaryMessage?: string;
  secondaryMessage?: string;
  children?: ReactNode;
}

const ToastWarning: FC<ToastWarningProps> = ({
  primaryMessage = "Something went wrong",
  secondaryMessage = "",
  children,
}) => {
  return (
    <div className="toast-wrapper">
      <span className="message-section">
        {children || (
          <>
            <p className="primary-message">{primaryMessage}</p>
            <p className="secondary-message">{secondaryMessage}</p>
          </>
        )}
      </span>
    </div>
  );
};

export {ToastWarning, warningToastOptions};
