import {useHistory, useParams} from "react-router-dom";
import {Loader} from "components/common";

export const ShortOrderRoute = () => {
  const {serviceOrderId} = useParams<{serviceOrderId: string}>();
  const history = useHistory();
  history.push(`/order/${serviceOrderId}`);

  return <Loader />;
};
