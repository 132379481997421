import {useEffect} from "react";
import {Helmet} from "react-helmet";
import {Loader} from "components/common";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {businessSelectors, businessThunks} from "state/redux/slices/business";
import {getBusinessName} from "state/redux/slices/business/selectors";
import {FetchingStatus} from "types/common";

/**
 * Different components of the application are linked to data
 * that can be at the business or store level, i.e. at two different levels.
 * For example, a customer places an order in a particular store, so it is store level,
 * but Referral and Loyalty programs are at the business level.
 * And it is important for required level to ensure basic data availability
 * in presenting components.
 * This provider is responsible for getting the basic data for the business level
 * based on business.id.
 *
 * If business.id is not available, but an encrypted or personalized
 * business level URL param is available, the following providers can be used instead:
 * - EncodedBusinessLevelProvider
 */
export const BusinessProvider = ({
  children,
  businessId,
}: {
  children: React.ReactNode;
  businessId?: number;
}) => {
  const dispatch = useAppDispatch();
  const businessName = useAppSelector(getBusinessName);
  const businessThemeFetchingStatus = useAppSelector(
    businessSelectors.getBusinessThemeFetchingStatus
  );

  useEffect(() => {
    const fetchBusinessData = () => {
      dispatch(businessThunks.setBusinessThemeThunk(businessId));
      dispatch(businessThunks.setBusinessSettingsThunk(businessId));
    };

    if (businessId && businessThemeFetchingStatus === FetchingStatus.Initial) {
      fetchBusinessData();
    }
  }, [dispatch, businessId, businessThemeFetchingStatus]);

  return businessThemeFetchingStatus === FetchingStatus.Fulfilled ? (
    <>
      <Helmet>
        <title>{businessName}</title>
      </Helmet>
      {children}
    </>
  ) : (
    <Loader />
  );
};
