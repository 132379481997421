import {createSelector} from "reselect";
import {RootState} from "state/redux/store";

const getReferralInfoState = (state: RootState) => state.referralInfo;

export const getReferralProgramInfo = createSelector(
  getReferralInfoState,
  (state) => state.referralProgramInfo
);

export const getReferralCodeValidation = createSelector(
  getReferralInfoState,
  (state) => state.referralCodeValidation
);

export const getApplyReferralInfo = createSelector(
  getReferralInfoState,
  (state) => state.applyReferralInfo
);
