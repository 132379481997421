import {type FC, type ReactNode} from "react";
import cx from "classnames";
import {ObjectValues} from "types/typeUtils";
import styles from "./label.module.scss";

const VARIANTS = {
  default: "default",
  medium: "medium",
  bold: "bold",
} as const;

export const Label: FC<{
  variant?: ObjectValues<typeof VARIANTS>;
  children: ReactNode;
  className?: string;
}> = ({variant = VARIANTS.default, className, children}) => {
  return (
    <span
      className={cx(
        styles.label,
        {
          [styles.default]: variant === VARIANTS.default,
          [styles.medium]: variant === VARIANTS.medium,
          [styles.bold]: variant === VARIANTS.bold,
        },
        className
      )}
    >
      {children}
    </span>
  );
};
