import {toast} from "react-toastify";
import {ToastSuccess, successToastOptions} from "components/newOrder/common/Toast";

type CreateSuccessToast = (params: {
  primaryMessage: string;
  secondaryMessage?: string;
  toastId?: string;
}) => void;

export const createSuccessToast: CreateSuccessToast = ({
  primaryMessage,
  secondaryMessage,
  toastId,
}) =>
  toast.error(
    <ToastSuccess primaryMessage={primaryMessage} secondaryMessage={secondaryMessage} />,
    {
      ...successToastOptions,
      toastId,
    }
  );
