import React from "react";
import {Flex, Image} from "rebass/styled-components";
import {EN_LOCALE} from "locales/en";
import {
  LoadedClosedMachine,
  LoadedOpenMachine,
  UnloadingMachine,
} from "../../assets/images";
import {SelfServiceStatuses} from "../../constants";

interface StatusImageProps {
  status?: SelfServiceStatuses;
  isDumbDumb?: boolean;
  isWaitingForTurnCreation: boolean;
}

const StatusImages = {
  [SelfServiceStatuses.CREATED]: (
    <Image src={LoadedOpenMachine} alt={EN_LOCALE.label.loadedOpenWashingMachine} />
  ),
  [SelfServiceStatuses.ENABLED]: (
    <Image src={LoadedOpenMachine} alt={EN_LOCALE.label.loadedOpenWashingMachine} />
  ),
  [SelfServiceStatuses.STARTED]: (
    <Image src={LoadedClosedMachine} alt={EN_LOCALE.label.loadedClosedWashingMachine} />
  ),
  [SelfServiceStatuses.RUNNING]: (
    <Image src={LoadedClosedMachine} alt={EN_LOCALE.label.loadedClosedWashingMachine} />
  ),
  [SelfServiceStatuses.COMPLETED]: (
    <Image src={UnloadingMachine} alt={EN_LOCALE.label.washingMachineUnloading} />
  ),
};

export const StatusImage = ({
  status,
  isDumbDumb,
  isWaitingForTurnCreation,
}: StatusImageProps) => {
  const turnStatusToDisplay =
    !isDumbDumb && !isWaitingForTurnCreation && status
      ? status
      : SelfServiceStatuses.CREATED;

  return <Flex {...styles.container}>{StatusImages[turnStatusToDisplay]}</Flex>;
};

const styles = {
  container: {
    justifyContent: "center",
    margin: "16px",
  },
};
