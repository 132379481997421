import {FC} from "react";
import {IProductPrice, IServicePrice} from "state/redux/slices/order/types/services";
import {getPricingUnit} from "utils/order/getPricingUnit";
import styles from "./pricingEntry.module.scss";

export const PricingEntry: FC<{
  service: {
    lineItemName: string;
    price: IServicePrice["price"];
    pricingStructureType?: IServicePrice["pricingStructureType"];
    quantity?: IProductPrice["quantity"];
  };
}> = ({service}) => {
  const pricingUnit = getPricingUnit({
    pricingStructureType: service?.pricingStructureType,
    quantity: service?.quantity,
  });

  return (
    <div className={styles.entryContainer}>
      <h5 className={styles.title}>{service.lineItemName}</h5>
      <span className={styles.price}>
        ${Number(service.price).toFixed(2)} / {pricingUnit}
      </span>
    </div>
  );
};
