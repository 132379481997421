import {FC} from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  type OutlinedInputProps,
} from "@mui/material";

export const TextInput: FC<
  {label: string; labelClassName?: string} & OutlinedInputProps
> = ({label, labelClassName, className, ...inputProps}) => {
  return (
    <FormControl variant="outlined" className={className}>
      <InputLabel htmlFor={label} className={labelClassName} error={inputProps.error}>
        {label}
      </InputLabel>
      <OutlinedInput id={label} type="text" label={label} {...inputProps} />
    </FormControl>
  );
};
