import {FC, HTMLAttributes, ReactNode} from "react";
import classNames from "classnames";
import {useFlags} from "launchdarkly-react-client-sdk";
import styles from "./SectionLayout.module.scss";

interface SectionLayoutProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const SectionLayout: FC<SectionLayoutProps> = ({
  children,
  className,
  ...props
}) => {
  const {manageOrderV2} = useFlags();

  return (
    <div
      className={classNames({[styles.sectionsWrapper]: manageOrderV2}, className)}
      {...props}
    >
      {children}
    </div>
  );
};
