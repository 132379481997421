import {Dispatch, FC, SetStateAction, useCallback, useMemo} from "react";
import {Drawer} from "@mui/material";
import {useFlags} from "launchdarkly-react-client-sdk";
import {useHistory} from "react-router-dom";
import {Box, Flex, Image, Link, Text} from "rebass/styled-components";
import {ROUTES} from "routes/order/business/constants";
import {A11yButton} from "components/common/buttons/A11yButton";
import {useLogout} from "hooks/auth/useLogout";
import useCustomerState from "hooks/useCustomerState";
import {useAppSelector} from "state/redux/hooks";
import {IBusinessSettingsData} from "state/redux/slices/business/types";
import {getCentsCustomer} from "state/redux/slices/customer/selectors/credentialsSelector";
import {incrementalIdEncrypt} from "utils/encryption/encodedIdDecrypt";
import {useGetBusinessCapabilities} from "api/queries/useGetBusinessCapabilities";
import {EN_LOCALE} from "locales/en";
import {
  IconSideBarAccount,
  IconSideBarCalendar,
  IconSideBarMyActivity,
  IconSideBarStar,
  IconSideBarReferral,
  logo,
  SmallDeliveryTruckIcon as TruckIcon,
  SmallWashingMachineIcon,
} from "assets/images";
import {ICustomer} from "types/customer";
import SidebarStyles from "./Sidebar.module.scss";
import {styles} from "./Sidebar.styles";

export const Sidebar: FC<{
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  termsOfServiceUrl?: string;
  businessId?: IBusinessSettingsData["businessId"];
  businessSettings?: IBusinessSettingsData | Record<string, unknown>;
}> = ({
  sidebarOpen = false,
  setSidebarOpen,
  termsOfServiceUrl,
  businessId,
  businessSettings,
}) => {
  const history = useHistory();
  const {customer} = useCustomerState() as unknown as {
    customer: ICustomer;
  };
  const centsCustomer = useAppSelector(getCentsCustomer);
  const flags = useFlags();
  const {logout} = useLogout();

  const customerFirstName = centsCustomer?.firstName || customer.firstName || "customer";

  const getTosUrl = useMemo(() => {
    return termsOfServiceUrl || "https://www.trycents.com/template/wdf-tos";
  }, [termsOfServiceUrl]);

  const onRouteTextClicked = useCallback(
    (path: string) => {
      setSidebarOpen(false);
      history.push(path);
    },
    [history, setSidebarOpen]
  );

  const {data: {hasDelivery, hasSelfServe} = {}} = useGetBusinessCapabilities({
    businessId: Number(businessId),
    centsCustomerId: centsCustomer?.id || null,
  });

  const routeStates = useMemo(
    () => [
      {
        name: "Pickup & Delivery",
        key: "newOrder",
        path: `/order/business/${incrementalIdEncrypt(
          businessId
        )}?selectedFullService=true`,
        disabled: !businessId || !hasDelivery,
        icon: TruckIcon,
      },
      {
        name: "Machines",
        key: "machines",
        path: `/business/${incrementalIdEncrypt(businessId)}/home`,
        disabled: !businessId || !hasSelfServe,
        icon: SmallWashingMachineIcon,
      },
      {
        name: "My Activity",
        key: "orders",
        path: `/order/business/${incrementalIdEncrypt(businessId)}/orders`,
        disabled: !businessId,
        icon: IconSideBarMyActivity,
      },
      {
        name: "Recurring Orders",
        key: "subscriptions",
        path: `/order/business/${incrementalIdEncrypt(businessId)}/${
          flags.recurringSubscriptionsV2 ? ROUTES.recurringOrders : "subscriptions"
        }`,
        disabled: !businessId || !hasDelivery,
        icon: IconSideBarCalendar,
      },
      {
        name: "Rewards",
        key: "rewards",
        path: `/order/business/${incrementalIdEncrypt(businessId)}/rewards`,
        disabled:
          !flags.automatedLoyalty ||
          !businessId ||
          !businessSettings?.enableAutomatedLoyalty,
        icon: IconSideBarStar,
      },
      {
        name: "Account",
        key: "account",
        path: `/order/business/${incrementalIdEncrypt(businessId)}/account`,
        disabled: !businessId,
        icon: IconSideBarAccount,
      },
      {
        name: "Refer a Friend",
        key: "referAFriend",
        path: `/order/business/${incrementalIdEncrypt(businessId)}/refer`,
        disabled: !businessId || !businessSettings?.isReferralProgramActive,
        icon: IconSideBarReferral,
      },
    ],
    [
      businessId,
      flags.recurringSubscriptionsV2,
      flags.automatedLoyalty,
      businessSettings?.enableAutomatedLoyalty,
      businessSettings?.isReferralProgramActive,
      hasDelivery,
      hasSelfServe,
    ]
  );

  const footerRoutes = [
    {name: "Privacy Policy", href: "https://www.trycents.com/privacy-policy"},
    {name: "Terms Of Use", href: getTosUrl},
  ];

  return (
    <Drawer
      open={sidebarOpen}
      onClose={() => setSidebarOpen(!sidebarOpen)}
      slotProps={{
        backdrop: {className: SidebarStyles.backdrop},
      }}
      PaperProps={{
        className: SidebarStyles.drawer,
      }}
    >
      <Flex {...styles.contentWrapper}>
        <Text {...styles.customerName}>Hi {customerFirstName}!</Text>
        {routeStates
          .filter(({disabled}) => !disabled)
          .map((route) => (
            <A11yButton
              key={route.key || route.name}
              onAction={() => onRouteTextClicked(route.path)}
            >
              <Flex {...styles.routeContainer}>
                <Image
                  src={route.icon}
                  alt={EN_LOCALE.label.navigationListItem}
                  {...styles.routeIcon}
                />
                <Text {...styles.route}>{route.name}</Text>
              </Flex>
            </A11yButton>
          ))}

        <Box {...styles.separator} />
        {footerRoutes.map((route) => (
          <Link
            {...styles.footerRoutes}
            key={route.name}
            href={route.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {route.name}
          </Link>
        ))}
        <button className={SidebarStyles.logoutButton} onClick={logout}>
          Log Out
        </button>
        <Flex {...styles.poweredByCents}>
          <Text mr="2">powered by</Text>
          <Image src={logo} width={82} alt={EN_LOCALE.label.logo} />
        </Flex>
      </Flex>
    </Drawer>
  );
};
