export const AUTH_REDUCER_NAME = "AUTH";

export const VERIFY_PHONE_NUMBER = "VERIFY_PHONE_NUMBER";
export const REQUEST_OTP = "REQUEST_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";

export const VERIFY_PHONE_NUMBER_AUTH2 = "VERIFY_PHONE_NUMBER_AUTH2";
export const REQUEST_OTP_AUTH_2 = "REQUEST_OTP_AUTH_2";
export const SIGN_IN = "SIGN_IN";
export const SIGN_UP = "SIGN_UP";
