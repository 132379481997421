import {FC, useEffect, useRef, useState} from "react";
import "../assets/Forms.style.scss";
import {setLabelClassList} from "../assets/utils";

interface ILabel {
  children: React.ReactNode;
  isValid: boolean;
  active: boolean;
  disabled?: boolean;
  className?: string;
  title?: string;
}

export const Label: FC<ILabel> = ({
  children,
  isValid,
  active,
  disabled,
  className,
  title,
}) => {
  const titleRef = useRef<HTMLSpanElement>(null);
  const [inputTopPadding, setInputTopPadding] = useState<number>(0);

  const updateLabelWhenResize = (): void => {
    setInputTopPadding(titleRef.current?.clientHeight || 20);
  };

  useEffect(() => {
    setInputTopPadding(titleRef.current?.clientHeight || 20);
    window.addEventListener("resize", updateLabelWhenResize);

    return () => window.removeEventListener("resize", updateLabelWhenResize);
  }, [titleRef.current, title]);

  return (
    <label
      className={setLabelClassList(isValid, active, disabled, className, title)}
      style={{
        marginTop: title ? `${inputTopPadding + 20}px` : "",
      }}
    >
      {title && (
        <span ref={titleRef} className={`title${className ? ` ${className}-title` : ""}`}>
          {title}
        </span>
      )}
      {children}
    </label>
  );
};
