import {lazy} from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {EncodedStoreLevelProvider} from "components/common/providers/EncodedStoreLevelProvider";
import {Checkout} from "components/newOrder/routes/checkout";
import {Schedule, TimeSelectionScreen} from "components/newOrder/routes/schedule";
import {ServiceFirstOrderCreation} from "components/orderCreation/serviceFirstFlow";
import {Preferences} from "components/orderCreation/serviceFirstFlow/preferences";
import styles from "components/orderCreation/serviceFirstFlow/serviceFirstOrderCreation.module.scss";
import {ROUTES} from "./constants";

const Home = lazy(() => import("components/orderCreation/serviceFirstFlow/home"));

export const EncodedStoreRoute = () => {
  const {path} = useRouteMatch();

  return (
    <EncodedStoreLevelProvider>
      <div className={styles.serviceFirstOrderCreationContainer}>
        <Switch>
          <Route path={`${path}/`} exact component={ServiceFirstOrderCreation} />
          <Route path={`${path}/${ROUTES.home}`} exact component={Home} />
          <Route path={`${path}/${ROUTES.preferences}`} exact component={Preferences} />
          <Route path={`${path}/${ROUTES.schedule}`} exact component={Schedule} />
          <Route
            path={`${path}/${ROUTES.schedule}/:timeSelectionType`}
            exact
            component={TimeSelectionScreen}
          />
          <Route path={`${path}/${ROUTES.checkout}`} exact component={Checkout} />
          <Redirect to="/" />
        </Switch>
      </div>
    </EncodedStoreLevelProvider>
  );
};
