import {useCallback, useEffect, useMemo, useState} from "react";
import {Tab, Tabs, Typography} from "@material-ui/core";
import {Dialog} from "@mui/material";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {A11yButton} from "components/common/buttons/A11yButton";
import {Button, ButtonVariant} from "components/common/buttons/Button";
import {AvailableDatesList} from "components/common/orderBuilder/AvailableDatesList";
import {ScheduleCalendar} from "components/common/orderBuilder/ScheduleCalendar";
import {useSetDay} from "hooks/schedule/useSetDay";
import {useWindowsPerDay} from "hooks/schedule/useWindowsPerDay";
import {useIsMobile} from "hooks/useIsMobile";
import {useThemeValues} from "hooks/useThemeValues";
import {useAppSelector} from "state/redux/hooks";
import {getNearStoresData, getScheduleDetails} from "state/redux/slices/order/selectors";
import {getBagsCount} from "state/redux/slices/order/selectors/servicesSelector";
import {getFormattedWeekDay} from "utils/schedule/getFormattedWeekDay";
import {getTimeZonedLuxonFromISO} from "utils/schedule/getTimeZonedLuxonFromISO";
import {DELIVERY_PROVIDERS, DELIVERY_TYPE_KEYS, SCHEDULE_TABS} from "constants/order";
import {EN_LOCALE} from "locales/en";
import Calendar from "assets/images/Calendar.svg";
import Chevron from "assets/images/Date_selector_back.svg";
import {AutoScheduleNotification} from "../../../common/AutoScheduleNotification";

const DICTIONARY = {
  MAX_BAG_COUNT_INFO_HEADER: "Exceeded bag limit",
  MAX_BAG_COUNT_INFO_CONTENT:
    "DoorDash has a limit of <strong>5 bags</strong>. Schedule Our Drivers or edit the number of bags you have.",
  MAX_BAG_COUNT_INFO_LINK: "Edit number of bags",
};

export const AvailableDatesView = ({
  isPickup,
  setIsMainButtonDisabled,
  onBagCountLinkClick,
  candidateWindow,
  setCandidateWindow,
  isAutoSchedule,
}) => {
  const bagsCount = useAppSelector(getBagsCount);
  const isMobile = useIsMobile();
  const {deliveryDays, haveOwn, haveOnDemand} = useWindowsPerDay();
  const {filterClass} = useThemeValues();
  const {pickupDayIndex, returnDayIndex, pickup, returnInfo} =
    useSelector(getScheduleDetails);
  const {data: nearStoresData} = useSelector(getNearStoresData);
  const nashDeliveryLogo = nearStoresData?.onDemandProviderDetails?.providerLogo;
  const nashDeliveryProviderName = nearStoresData?.onDemandProviderDetails?.providerName;
  const dayIndex = isPickup ? pickupDayIndex : returnDayIndex;
  const currentDay = deliveryDays[dayIndex];
  const [tabName, setTabName] = useState();
  const chosenDate = getTimeZonedLuxonFromISO(currentDay.date, currentDay.timeZone);
  const windows = useMemo(
    () => (tabName ? currentDay[tabName] : []),
    [currentDay, tabName]
  );

  const chooseTimeAfterDayUpdate = useCallback(
    (type, dayIndex, windowIndex) => {
      const isDisabledWithMaxBags =
        bagsCount > 5 && type === DELIVERY_TYPE_KEYS.ON_DEMAND;
      if (deliveryDays[dayIndex][type].length && !isDisabledWithMaxBags) {
        setCandidateWindow(deliveryDays[dayIndex][type][windowIndex || 0]);
        setIsMainButtonDisabled(false);
      } else {
        setIsMainButtonDisabled(true);
      }
    },
    [setCandidateWindow, deliveryDays, setIsMainButtonDisabled, bagsCount]
  );

  const updateStateAfterDayChange = (dayIndex) => {
    const chosenTab = deliveryDays[dayIndex][DELIVERY_TYPE_KEYS.OWN].length
      ? DELIVERY_TYPE_KEYS.OWN
      : DELIVERY_TYPE_KEYS.ON_DEMAND;
    setTabName(chosenTab);

    chooseTimeAfterDayUpdate(chosenTab, dayIndex);
  };

  const setNextDay = useSetDay(dayIndex, updateStateAfterDayChange, true);
  const setPreviousDay = useSetDay(dayIndex, updateStateAfterDayChange, false);

  const changeTab = (event, newValue) => {
    setTabName(newValue);
    chooseTimeAfterDayUpdate(newValue, dayIndex);
  };

  useEffect(() => {
    if (!tabName) {
      const selectedWindow = isPickup ? pickup : returnInfo;
      let windowIndex = null;
      let tabWithWindows = deliveryDays[dayIndex][DELIVERY_TYPE_KEYS.OWN].length
        ? DELIVERY_TYPE_KEYS.OWN
        : DELIVERY_TYPE_KEYS.ON_DEMAND;

      if (selectedWindow?.type) {
        const type =
          selectedWindow?.type === DELIVERY_PROVIDERS.ownDriver
            ? DELIVERY_TYPE_KEYS.OWN
            : DELIVERY_TYPE_KEYS.ON_DEMAND;
        const deliveryWindows = deliveryDays[dayIndex][type];
        if (deliveryWindows?.length) {
          const possibleIndex = deliveryDays[dayIndex][type]?.findIndex(
            ({key: possibleKey}) => possibleKey === selectedWindow.key
          );
          if (possibleIndex >= 0) {
            tabWithWindows = type;
            windowIndex = possibleIndex;
          }
        }
      }

      setTabName(tabWithWindows);
      chooseTimeAfterDayUpdate(tabWithWindows, dayIndex, windowIndex);
    }
  }, [
    dayIndex,
    haveOwn,
    chooseTimeAfterDayUpdate,
    tabName,
    deliveryDays,
    isPickup,
    pickup,
    returnInfo,
  ]);

  useEffect(() => {
    if (bagsCount > 5 && tabName === DELIVERY_TYPE_KEYS.ON_DEMAND) {
      setIsMainButtonDisabled(true);
    } else {
      setIsMainButtonDisabled(false);
    }
  }, [bagsCount, tabName]);

  const weekDay = getFormattedWeekDay(chosenDate, currentDay?.timeZone);

  const [isCalendarShowing, setIsCalendarShowing] = useState(false);
  const closeCalendar = useCallback(() => {
    setIsCalendarShowing(false);
    setTabName(null);
  }, []);
  const openCalendar = useCallback(() => {
    setIsCalendarShowing(true);
  }, [setIsCalendarShowing]);

  const isLeftChevronDisabled = dayIndex === 0;
  const isRightChevronDisabled = dayIndex === deliveryDays.length - 1;
  const isBothDriversAvailable = !!haveOwn && !!haveOnDemand;

  return (
    <>
      {chosenDate && deliveryDays && (
        <div className="available-dates-container">
          <div className="date-controls-container">
            <div className="controls">
              <A11yButton
                onAction={setPreviousDay}
                disabled={isLeftChevronDisabled}
                aria-label={EN_LOCALE.label.previousDay}
              >
                <img
                  src={Chevron}
                  className={`${
                    isLeftChevronDisabled ? "disabled-filter" : filterClass
                  } controls-img`}
                  alt={EN_LOCALE.label.chevron}
                />
              </A11yButton>
              <A11yButton className="date-title-container" onAction={openCalendar}>
                <img src={Calendar} alt="Calendar" className={filterClass} />
                <div className="date-title">
                  <Typography variant="subtitle1" align="center" component="h2">
                    {weekDay}
                  </Typography>
                  <Typography variant="subtitle1" align="center" className="date">
                    {chosenDate.toFormat("LLL dd")}
                  </Typography>
                </div>
              </A11yButton>
              <A11yButton
                onAction={setNextDay}
                disabled={isRightChevronDisabled}
                aria-label={EN_LOCALE.label.nextDay}
              >
                <img
                  src={Chevron}
                  className={`${
                    isRightChevronDisabled ? "disabled-filter" : filterClass
                  } controls-img forward-img`}
                  alt={EN_LOCALE.label.chevron}
                />
              </A11yButton>
            </div>
          </div>
          <div className="windows-container">
            {isBothDriversAvailable && (
              <Tabs
                value={tabName || DELIVERY_TYPE_KEYS.OWN}
                indicatorColor="primary"
                textColor="primary"
                onChange={changeTab}
                className="tabs-container on-demand-tabs"
                variant="fullWidth"
              >
                <Tab label={SCHEDULE_TABS.ourDrivers} value={DELIVERY_TYPE_KEYS.OWN} />
                <Tab
                  label={SCHEDULE_TABS.flexPickup}
                  value={DELIVERY_TYPE_KEYS.ON_DEMAND}
                />
              </Tabs>
            )}
            {isAutoSchedule && (
              <AutoScheduleNotification currentWindow={candidateWindow} />
            )}
            {!!haveOnDemand && !haveOwn && (
              <div className="on-demand-logo-container">
                <img
                  src={nashDeliveryLogo}
                  alt="Delivery provider logo"
                  width={24}
                  height={24}
                />
                <span>{nashDeliveryProviderName}</span>
              </div>
            )}
            {isBothDriversAvailable && tabName === DELIVERY_TYPE_KEYS.ON_DEMAND && (
              <div className="on-demand_logo-container-with-tabs">
                <img
                  src={nashDeliveryLogo}
                  alt="Delivery provider logo"
                  width={24}
                  height={24}
                />
                <span>{nashDeliveryProviderName}</span>
              </div>
            )}
            <div
              className={`window-selection-container ${
                !haveOnDemand && haveOwn ? "window-selection-container-only-own" : ""
              } ${isAutoSchedule ? "auto-schedule-variant" : ""} ${
                isBothDriversAvailable && isAutoSchedule
                  ? "both-delivery-providers-auto-schedule-variant"
                  : ""
              }${
                tabName === DELIVERY_TYPE_KEYS.OWN ? " own-with-on-demand-variant" : ""
              }`}
            >
              {bagsCount > 5 && tabName === DELIVERY_TYPE_KEYS.ON_DEMAND && (
                <div className="max-bag-count-informer">
                  <h3>{DICTIONARY.MAX_BAG_COUNT_INFO_HEADER}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DICTIONARY.MAX_BAG_COUNT_INFO_CONTENT,
                    }}
                  />
                  <p>
                    <Button
                      onClick={onBagCountLinkClick}
                      variant={ButtonVariant.UNDERLINED}
                    >
                      <span>{DICTIONARY.MAX_BAG_COUNT_INFO_LINK}</span>
                    </Button>
                  </p>
                </div>
              )}
              {((bagsCount <= 5 && tabName === DELIVERY_TYPE_KEYS.ON_DEMAND) ||
                tabName === DELIVERY_TYPE_KEYS.OWN) && (
                <AvailableDatesList
                  windows={windows}
                  chooseTime={setCandidateWindow}
                  selectedTime={String(candidateWindow ? candidateWindow.key : 0)}
                  tabName={tabName}
                />
              )}
            </div>
          </div>
          <Dialog
            open={isCalendarShowing}
            onClose={closeCalendar}
            PaperProps={{
              className: "dialog-container",
              sx: {margin: isMobile ? 0 : "15px"},
            }}
            BackdropProps={{className: "dialog-backdrop"}}
            fullScreen={isMobile}
          >
            <ScheduleCalendar
              onClose={closeCalendar}
              currentDate={currentDay.date}
              isPickup={isPickup}
            />
          </Dialog>
        </div>
      )}
    </>
  );
};

AvailableDatesList.propTypes = {
  candidateWindow: PropTypes.string,
  setCandidateWindow: PropTypes.func,
  setIsMainButtonDisabled: PropTypes.func,
  setIsCalendarShowing: PropTypes.func,
  onBagCountLinkClick: PropTypes.func,
  isAutoSchedule: PropTypes.bool,
};

AvailableDatesList.defaultProps = {
  candidateWindow: "",
};
