import {captureException} from "@sentry/browser";
import {ENVIRONMENT} from "utils/config";
import {ExceptionErrors, formatRequestError} from "utils/formatters/formatRequestError";
import {createErrorToast} from "utils/notifications/createErrorToast";
import {EN_LOCALE} from "locales/en";
import {ApiError} from "types/api";

export const handleQueryError = (
  error: ApiError,
  {exceptionErrors}: {exceptionErrors?: ExceptionErrors} = {}
) => {
  const isProduction = ENVIRONMENT === "production";

  captureException(error);
  createErrorToast({
    message: formatRequestError({
      error,
      exceptionErrors,
      genericError: isProduction ? EN_LOCALE.messages.genericError : error.message,
    }),
  });

  if (!isProduction) {
    console.error(error);
  }

  return error;
};
