import {
  ServiceCategoryTypes,
  Views,
  type IOrderData,
} from "state/redux/slices/order/types";
import {FetchingStatus} from "types/common";

const initialApiRequest = {
  data: null,
  fetchingStatus: FetchingStatus.Initial,
  error: {
    text: "",
    code: "",
  },
};

const initialData: IOrderData = {
  orderBuilder: {
    store: {
      businessId: null,
      storeId: null,
      storeAddress: null,
      storeName: null,
      pricingTierId: null,
    },
    services: {
      categories: {
        dryCleaning: {
          name: ServiceCategoryTypes.DRY_CLEANING,
          servicesPrices: null,
          availableForDelivery: null,
          turnAround: null,
        },
        laundry: {
          name: ServiceCategoryTypes.LAUNDRY,
          availableLaundryServices: [],
          servicesPrices: null,
          turnAround: null,
        },
      },
      possibleSelectedService: null, // the service that was selected by the user who is not authorized in the RWG flow
      selectedCategories: [],
      customerSelectedServices: [],

      /**
       * @deprecated Details: https://cents.atlassian.net/browse/DEL-725
       * Use `getCustomerSelectedServices` instead
       */
      selectedService: null,

      /**
       * @deprecated Details: https://cents.atlassian.net/browse/DEL-725
       * Use `getCustomerSelectedServices` instead
       */
      selectedModifiers: null,
      bagsCount: 1,
      serviceMultiselectEnabled: false,
    },
    products: {
      servicesPrices: null,
    },
    addresses: {
      addressToEstimate: null, // address, that was use to estimate delivery availability and price
      addressInput: null, // address, that was typed in address input
    },
    notes: {
      orderNotes: null,
      hangDryInstructions: null,
      customerNotes: null,
    },
    carePreferences: {
      isHangDrySelected: false,
    },
    customMessageForCustomer: {},
    promo: {
      promoCodeName: null,
      promoCodeAmount: null,
    },
    transportation: {
      pickupDelivery: null,
      returnDelivery: null,
      settings: {
        transportationPreference: null,
      },
    },
    paymentDetails: {
      paymentMethodToken: null,
    },
  },
  apiRequests: {
    servicesPrices: initialApiRequest,
    deliveryServices: initialApiRequest,
    deliverySettings: initialApiRequest,
    onlineOrderServices: initialApiRequest,
  },
  drawersStates: {
    isDrawerLocked: false,
    isModifiersDrawerOpened: false,
    isAddressSelectionDrawerOpened: false,
    isAddressDetailsDrawerOpened: false,
    isServicesDrawerOpened: false,
  },

  //Comes from Initial order data request
  initialOrderData: {
    data: {
      //comes from order-home-data
      businessId: null, //comes from order-home-data
      businessSettings: {
        allowOnlineTip: false,
        allowInStoreTip: false,
        businessId: null,
        convenienceFeeName: "",
        createdAt: null,
        dryCleaningEnabled: false,
        hangDryInstructions: null,
        hasConvenienceFee: false,
        id: null,
        isBagTrackingEnabled: false,
        isCustomPreferencesEnabled: false,
        isCustomUrl: false,
        isHangDryEnabled: false,
        isInStoreOnlinePaymentsEnabled: false,
        isWeightAfterProcessing: false,
        isWeightBeforeProcessing: false,
        isWeightDuringIntake: false,
        isWeightReceivingAtStore: false,
        isWeightUpOnCompletion: false,
        receiptFooterMessage: "",
        requiresEmployeeCode: false,
        requiresRack: false,
        salesWeight: null,
        termsOfServiceUrl: null,
        updatedAt: null,
        enableAutomatedLoyalty: false,
        automatedLoyaltyPointsCoef: "0",
        automatedLoyaltyCreditsCoef: "0",
        automatedLoyaltyRedemptionMilestone: 0,
        autoStartProcessing: false,
        isReferralProgramActive: false,
      },
      customerAddress: {}, //latestAddress is the same as customerAddress that comes from BE
      subscriptions: [],
      theme: {
        active: null,
        boldFont: "",
        borderRadius: "",
        businessId: null,
        businessName: "",
        createdAt: "",
        id: null,
        logoUrl: "",
        normalFont: "",
        primaryColor: "",
        secondaryColor: "",
        updatedAt: "",
      },
      savedCustomerAddresses: [],
      centsCustomerCredentials: {},
      paymentMethods: [],
    },
    fetchingStatus: FetchingStatus.Initial,
    error: {
      text: "",
      code: "",
    },
  },

  //Comes from nearStores request
  nearStoresData: {
    data: {
      onDemandDeliveryStore: {},
      ownDeliveryStore: {ownDeliverySettings: {}},
      recentCompletedStandardOrder: {},
      latestOrderDetails: {},
      addressTimeZone: null,
      deliveryDays: [],
      turnArounds: {},
      address: {},
      ownDeliveryIsAvailable: false,
      onDemandIsAvailable: false,
      customer: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        id: null,
        storePreferences: [],
        paymentMethods: [],
        addresses: [],
        businessCustomer: null,
        availableCredits: 0,
      },
      storeAddress: "",
    },
    fetchingStatus: FetchingStatus.Initial,
    error: {
      text: "",
      code: "",
    },
  },

  returnWindows: {
    data: {
      deliveryDays: [],
      onDemandIsAvailable: false,
      ownDeliveryIsAvailable: false,
    },
    fetchingStatus: FetchingStatus.Initial,
    error: {
      text: "",
      code: "",
    },
  },

  //Schedule state
  schedule: {
    currentStage: Views.RECOMMENDED_PICKUP,
    pickup: null,
    returnInfo: null,
    pickupDayIndex: 0,
    returnDayIndex: 0,
    ooboConflicts: [],
    turnAround: 0,
  },

  currentCustomerAddress: null,
  editableAddress: null,

  isShowingAddressDetailsModal: false,
  isShowingVerificationModal: false,
};

export default initialData;
