import {useEffect} from "react";
import {Helmet} from "react-helmet";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {businessActions} from "state/redux/slices/business";
import {getStoreName} from "state/redux/slices/business/selectors";
import {useStore} from "api/queries/store/useStore";

/**
 * Different components of the application are linked to data
 * that can be at the business or store level, i.e. at two different levels.
 * For example, a customer places an order in a particular store, so it is store level,
 * but Referral and Loyalty programs are at the business level.
 * And it is important for required level to ensure basic data availability
 * in presenting components.
 * This provider is responsible for getting the basic data for the store level
 * based on store.id.
 *
 * If store.id is not available, but an encrypted or personalized
 * store level URL param is available, the following providers can be used instead:
 * - EncodedStoreLevelProvider
 * - NewOrderStoreProvider
 */
export const StoreProvider = ({
  children,
  storeId,
}: {
  children: React.ReactNode;
  storeId?: number;
}) => {
  const dispatch = useAppDispatch();
  const storeName = useAppSelector(getStoreName);

  const {data: storeData} = useStore({storeId: storeId ? String(storeId) : undefined});

  useEffect(() => {
    if (storeData) {
      dispatch(businessActions.setStore(storeData));
    }
  }, [dispatch, storeData]);

  useEffect(() => {
    return () => {
      dispatch(businessActions.setStore(undefined));
    };
  }, [dispatch]);

  return (
    <>
      {storeName && (
        <Helmet>
          <title>{storeName}</title>
        </Helmet>
      )}
      {children}
    </>
  );
};
