import {type PayloadAction, createSlice} from "@reduxjs/toolkit";
import {RECURRING_ORDERS_REDUCER_NAME} from "state/redux/slices/recurringOrder/constants/actions";
import initialData from "state/redux/slices/recurringOrder/constants/initialData";
import {IRecurringOrdersInitialData} from "state/redux/slices/recurringOrder/types";
import {FetchingStatus} from "types/common";
import {setRecurringOrderDetailsThunk} from "./thunks/setRecurringOrderDetailsThunk";
import {setRecurringOrdersListThunk} from "./thunks/setRecurringOrdersListThunk";

const recurringOrdersSlice = createSlice({
  name: RECURRING_ORDERS_REDUCER_NAME,
  initialState: initialData,
  reducers: {
    setRecurringOrderConfiguration: (
      state,
      action: PayloadAction<
        IRecurringOrdersInitialData["recurringOrderEditor"]["configuration"]
      >
    ) => {
      state.recurringOrderEditor.configuration = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setRecurringOrdersListThunk.pending, (state) => {
        state.apiRequests.recurringOrdersList.fetchingStatus = FetchingStatus.Pending;
      })
      .addCase(setRecurringOrdersListThunk.fulfilled, (state, action) => {
        state.apiRequests.recurringOrdersList.fetchingStatus = FetchingStatus.Fulfilled;
        state.apiRequests.recurringOrdersList.data = action.payload;
        state.list = action.payload.recurringOrders;
      })
      .addCase(setRecurringOrdersListThunk.rejected, (state, action) => {
        const {error} = action;
        state.apiRequests.recurringOrdersList.fetchingStatus = FetchingStatus.Rejected;
        state.apiRequests.recurringOrdersList.error = {
          text: error.message,
          code: error.name,
        };
      })
      .addCase(setRecurringOrderDetailsThunk.pending, (state) => {
        state.apiRequests.recurringOrderSeriesDetails.fetchingStatus =
          FetchingStatus.Pending;
      })
      .addCase(setRecurringOrderDetailsThunk.fulfilled, (state, action) => {
        state.apiRequests.recurringOrderSeriesDetails.fetchingStatus =
          FetchingStatus.Fulfilled;
        state.apiRequests.recurringOrderSeriesDetails.data = action.payload;
        state.recurringOrderEditor.configuration = {
          ...action.payload.recurringOrder,
          recurringOrderId: action.meta.arg.recurringOrderId,
        };
        state.recurringOrderEditor.centsNotification = action.payload.centsNotification;
      })
      .addCase(setRecurringOrderDetailsThunk.rejected, (state, action) => {
        const {error} = action;
        state.apiRequests.recurringOrderSeriesDetails.fetchingStatus =
          FetchingStatus.Rejected;
        state.apiRequests.recurringOrderSeriesDetails.error = {
          text: error.message,
          code: error.name,
        };
      });
  },
});

export const recurringOrdersActions = recurringOrdersSlice.actions;
export const recurringOrdersReducer = recurringOrdersSlice.reducer;
