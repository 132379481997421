import isEmpty from "lodash/isEmpty";
import {DateTime} from "luxon";
import {getNumberSuffix} from "utils/formatters/getNumberSuffix";
import {isPaymentMethodTokenValidForStripe} from "utils/payments";
import {
  getParsedLocalStorageData,
  setStringifiedLocalStorageData,
} from "../../utils/common";
import {toDollars} from "../../utils/order-utils";
import {
  ORDER_TYPES,
  CONDITION_ORDER,
  RESIDENTIAL_ORDER_STATUSES,
  SERVICE_ORDER_STATUSES,
  DELIVERY_ORDER_STATUSES,
  ONLINE_ORDER_STATUSES,
  ORDER_STATUSES,
  PAYMENT_STATUSES,
  TIMELINE_COMPLETED_STATUS,
  TIMELINE_CANCELED_STATUS,
} from "./constants";

export {toDollars} from "utils/order-utils";

export const displayPromotionAmount = ({promotionType, discountValue}) => {
  return promotionType === "percentage-discount"
    ? `${discountValue}%`
    : toDollars(discountValue);
};

export const getCurrentTimelineStatus = (order) => {
  const {status, orderType, delivery} = order;

  const objStatus = {
    orderType,
    status,
  };

  if (status === ORDER_STATUSES.CANCELLED) {
    return {
      ...objStatus,
      statusOrder: TIMELINE_CANCELED_STATUS,
      conditionOrder: CONDITION_ORDER.finished,
    };
  }

  if (status === ORDER_STATUSES.COMPLETED) {
    return {
      ...objStatus,
      statusOrder: TIMELINE_COMPLETED_STATUS,
      conditionOrder: CONDITION_ORDER.finished,
    };
  }

  if (orderType === ORDER_TYPES.residential) {
    switch (status) {
      case "READY_FOR_PICKUP": // Residential Intake Complete
      case "DESIGNATED_FOR_PROCESSING_AT_HUB": // Reached store?
      case "IN_TRANSIT_TO_HUB": // Hub: Enroute to Hub
      case "DROPPED_OFF_AT_HUB": // Hub: Dropped off at Hub
        return {
          ...objStatus,
          statusOrder: RESIDENTIAL_ORDER_STATUSES.submitted,
          conditionOrder: CONDITION_ORDER.upcoming,
        };

      case "RECEIVED_AT_HUB_FOR_PROCESSING": // Hub: Received at hub??
      case "READY_FOR_PROCESSING": // Hub: Intake is completed and ready for processing
      case "HUB_PROCESSING_ORDER": // Hub: processing
      case "HUB_PROCESSING_COMPLETE": // Hub: processing complete/Driver Picked up
        return {
          ...objStatus,
          statusOrder: RESIDENTIAL_ORDER_STATUSES.processing,
          conditionOrder: CONDITION_ORDER.active,
        };

      case "IN_TRANSIT_TO_STORE": // Hub: Enroute to Residential Address
      case "DROPPED_OFF_AT_STORE": // Residential: Dropped off at Residential Address
        return {
          ...objStatus,
          statusOrder: RESIDENTIAL_ORDER_STATUSES.delivering,
          conditionOrder: CONDITION_ORDER.active,
        };

      default:
        return;
    }
  } else if (orderType === ORDER_TYPES.online) {
    switch (status) {
      case "SUBMITTED": // Customer created order.
      case "DRIVER_PICKED_UP_FROM_CUSTOMER": // Driver picked up from customer.
      case "READY_FOR_INTAKE": // Driver dropped off at store.
        return {
          ...objStatus,
          statusOrder: ONLINE_ORDER_STATUSES.submitted,
          conditionOrder: CONDITION_ORDER.upcoming,
        };

      case "DESIGNATED_FOR_PROCESSING_AT_HUB":
      case "READY_FOR_PROCESSING":
      case "IN_TRANSIT_TO_HUB":
      case "RECEIVED_AT_HUB_FOR_PROCESSING":
      case "DROPPED_OFF_AT_HUB":
      case "PAYMENT_REQUIRED":
      case "PROCESSING":
      case "HUB_PROCESSING_ORDER":
      case "HUB_PROCESSING_COMPLETE":
      case "IN_TRANSIT_TO_STORE":
        return {
          ...objStatus,
          statusOrder: ONLINE_ORDER_STATUSES.processing,
          conditionOrder: CONDITION_ORDER.active,
        };

      case "READY_FOR_PICKUP":
      case "DROPPED_OFF_AT_STORE":
        return {
          ...objStatus,
          statusOrder: isEmpty(delivery)
            ? ONLINE_ORDER_STATUSES.readyForPickup
            : ONLINE_ORDER_STATUSES.processing,
          conditionOrder: CONDITION_ORDER.active,
        };

      case "READY_FOR_DRIVER_PICKUP":
      case "EN_ROUTE_TO_CUSTOMER":
        return {
          ...objStatus,
          statusOrder: ONLINE_ORDER_STATUSES.delivering,
          conditionOrder: CONDITION_ORDER.active,
        };

      default:
        return;
    }
  } else {
    switch (status) {
      case "READY_FOR_INTAKE": // Quick intake at store.
        return {
          ...objStatus,
          statusOrder: ONLINE_ORDER_STATUSES.submitted,
          conditionOrder: CONDITION_ORDER.upcoming,
        };

      case "READY_FOR_PROCESSING": // Order created.
      case "DESIGNATED_FOR_PROCESSING_AT_HUB": // Order created - Assigned to Hub.
        return {
          ...objStatus,
          statusOrder: SERVICE_ORDER_STATUSES.submitted,
          conditionOrder: CONDITION_ORDER.active,
        };

      case "PROCESSING": // In-Store: processing
      case "IN_TRANSIT_TO_HUB": // Hub: In Transit
      case "DROPPED_OFF_AT_HUB": // Hub: Dropped off
      case "RECEIVED_AT_HUB_FOR_PROCESSING": // Hub: Ready for processing
      case "HUB_PROCESSING_ORDER": // Hub: processing
      case "HUB_PROCESSING_COMPLETE": // Hub: processing complete/Ready for driver pick-up
      case "IN_TRANSIT_TO_STORE": // Hub: Transit back to store
      case "DROPPED_OFF_AT_STORE": // Hub: Driver dropped the order at store
        return {
          ...objStatus,
          statusOrder: SERVICE_ORDER_STATUSES.processing,
          conditionOrder: CONDITION_ORDER.active,
        };

      case "READY_FOR_PICKUP": // Customer: ready for pick-up
        return {
          ...objStatus,
          statusOrder: SERVICE_ORDER_STATUSES.readyForPickup,
          conditionOrder: CONDITION_ORDER.active,
        };

      case "EN_ROUTE_TO_CUSTOMER": // Order is with delivery driver on the way to customer
      case "READY_FOR_DRIVER_PICKUP": // Delivery has been scheduled but is still at the store
        return {
          ...objStatus,
          statusOrder: DELIVERY_ORDER_STATUSES.delivering,
          conditionOrder: CONDITION_ORDER.active,
        };

      default:
        return;
    }
  }
};

export const isUnpaidOnlineOrder = (order) => {
  return (
    order.orderType === ORDER_TYPES.online &&
    (order.paymentStatus === PAYMENT_STATUSES.balanceDue ||
      order.paymentStatus === PAYMENT_STATUSES.pending)
  );
};

export const isInProgressOrder = (order) =>
  ![ORDER_STATUSES.COMPLETED, ORDER_STATUSES.CANCELLED].includes(order.status);

export const isInvoiceRelatedOrder = (order) => {
  return [
    PAYMENT_STATUSES.invoicing,
    PAYMENT_STATUSES.invoiced,
    PAYMENT_STATUSES.invoice_paid,
  ].includes(order.paymentStatus);
};

export const initPaymentDetails = (paymentMethods, latestPayment) => {
  const customerPaymentMethods = paymentMethods || [];
  const isLatestPaymentMethodExistAndValid = isPaymentMethodTokenValidForStripe(
    latestPayment?.paymentMethod?.paymentMethodToken
  );
  const latestPaymentMethodAmongCurrentPaymentMethods = customerPaymentMethods.find(
    (pm) => pm.paymentMethodToken === latestPayment?.paymentMethod?.paymentMethodToken
  );
  const defaultPaymentMethod = customerPaymentMethods.find((pm) => pm.isDefault);
  const firstValidCustomerPaymentMethod = customerPaymentMethods.find((pm) =>
    isPaymentMethodTokenValidForStripe(pm.paymentMethodToken)
  );

  // The priority of payment method is:
  // latestPaymentMethod > defaultPaymentMethod > firstValidCustomerPaymentMethod
  const currentPaymentMethod = isLatestPaymentMethodExistAndValid
    ? latestPaymentMethodAmongCurrentPaymentMethods || {
        ...latestPayment?.paymentMethod,
        notSaved: true,
      }
    : defaultPaymentMethod ?? (firstValidCustomerPaymentMethod || null);

  return {
    customerPaymentMethods: currentPaymentMethod?.notSaved
      ? [currentPaymentMethod, ...customerPaymentMethods]
      : customerPaymentMethods,
    currentPaymentMethod,
  };
};

export const shiftDetails = (orderDelivery, timeZone) => {
  if (isEmpty(orderDelivery)) return;

  const startTime = DateTime.fromMillis(Number(orderDelivery?.deliveryWindow[0])).setZone(
    timeZone
  );
  const endTime = DateTime.fromMillis(Number(orderDelivery?.deliveryWindow[1])).setZone(
    timeZone
  );
  const startTimeDayWithSuffix = `${startTime.day}${getNumberSuffix(startTime.day)}`;

  return {
    day: startTime.toFormat("EEE"),
    month: startTime.monthLong,
    date: startTimeDayWithSuffix,
    startTime: startTime.toFormat("hh:mma").toLowerCase(),
    endTime: endTime.toFormat("hh:mma").toLowerCase(),
  };
};

export const setLocalStorageItemWithExpiry = (key, orderToken, ttl) => {
  const now = new Date();
  const item = {
    value: orderToken,
    expiry: now.getTime() + ttl,
  };
  setStringifiedLocalStorageData(key, item);
};

export const getLocalStorageItemWithExpiry = (key) => {
  const localStorageData = getParsedLocalStorageData(key);
  if (isEmpty(localStorageData)) {
    return null;
  }
  const now = new Date();
  // compare the expiry time of the key with the current time
  if (now.getTime() > localStorageData.expiry) {
    localStorage.removeItem(key);
    return null;
  } else {
    return localStorageData;
  }
};

export const resolveOrderDateString = ({
  orderType,
  intakeCompletedAt,
  createdAt,
  timeZone = "America/New_York",
}) => {
  const firstWord =
    orderType === ORDER_TYPES.online && !intakeCompletedAt ? "Order" : "Intake";

  const date = intakeCompletedAt || createdAt || "";
  const formattedDate = date
    ? DateTime.fromISO(date).setZone(timeZone).toFormat("MM/dd/yyyy")
    : "";

  return firstWord && date ? `${firstWord} Date: ${formattedDate}` : "";
};
