/**
 * Get positions of mask characters in mask
 */
const getMaskablePositions = (mask: string, formatChars: string[]) => {
  const positions = [];

  for (let i = 0; i < mask.length; i++) {
    for (let j = 0; j < formatChars.length; j++) {
      if (mask[i] === formatChars[j]) {
        positions.push(i);
        break;
      }
    }
  }

  return positions;
};

const getCollapsedValue = (
  valueChars: string[],
  maskablePositions: number[],
  maskChar: string
) => {
  const editableValue = maskablePositions.map((index) => valueChars[index]).join("");
  return editableValue.replaceAll(maskChar, "");
};

/**
 * Removed empty chars for mask value to fix incorrect caret position https://cents.atlassian.net/browse/RTB-1239
 * For instance (123)93_-_1__ will be converted to (123)931-____
 * Keep value and caret position unchanged if some character was deleted
 * @param maskChar mask char
 * @param mask mask
 * @param value current value
 * @param currentSelection current selection
 * @param previousValue previous value
 */
const collapseSpaceBetweenChars = (
  maskChar: string,
  mask: string,
  value: string,
  currentSelection: {start: number; end: number} | null,
  previousValue: string
) => {
  const formatChars = ["9", "a", "*"];
  const maskablePositions = getMaskablePositions(mask, formatChars);
  const valueChars = value.split("");
  const previousValueChars = previousValue.split("");

  const collapsedValue = getCollapsedValue(valueChars, maskablePositions, maskChar);
  const collapsedPreviousValue = getCollapsedValue(
    previousValueChars,
    maskablePositions,
    maskChar
  );

  // if some character was deleted we keep value and caret position unchanged
  if (collapsedPreviousValue.length > collapsedValue.length) {
    return {
      value,
      selection: currentSelection,
    };
  }

  let newSelection = null;

  for (let i = 0; i < maskablePositions.length; i++) {
    const index = maskablePositions[i];

    if (collapsedValue[i]) {
      valueChars[index] = collapsedValue[i];
    } else {
      valueChars[index] = maskChar;
      newSelection = newSelection || {start: index, end: index};
    }
  }

  const newValue = valueChars.join("");
  const shouldSelectionBeChanged = value !== newValue;

  if (shouldSelectionBeChanged) {
    return {
      value: newValue,
      selection: newSelection,
    };
  }

  return {
    value,
    selection: currentSelection,
  };
};

export default collapseSpaceBetweenChars;
