import {FC, ReactNode, useEffect, useState} from "react";
import {useLogout} from "hooks/auth/useLogout";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {customerActions} from "state/redux/slices/customer";
import {getCentsCustomer} from "state/redux/slices/customer/selectors/credentialsSelector";
import {fetchCentsCustomer} from "api/customer/fetchCentsCustomer";
import {getCustomerAuthToken} from "api/httpClient";

/**
 * This provider is required to fetch basic customer data
 * into the application state as soon as it is possible.
 */
export const CentsCustomerProvider: FC<{
  children: ReactNode;
}> = ({children}) => {
  const dispatch = useAppDispatch();
  const {logout} = useLogout();
  const centsCustomer = useAppSelector(getCentsCustomer);
  const currentToken = getCustomerAuthToken();

  const [
    /**
     * There are can be cases, when token is changed (OOBO, MOBO)
     * so we need to store fetched token and if it is changed
     * re-fetch customer data
     */
    fetchedToken,
    setFetchedToken,
  ] = useState<string | null>(null);
  const isCustomerAvailableToFetch = !centsCustomer && currentToken;

  useEffect(
    function getCentsCustomerData() {
      if (isCustomerAvailableToFetch || currentToken !== fetchedToken) {
        fetchCentsCustomer()
          .then((response) => {
            dispatch(customerActions.setCentsCustomer(response.data));
            setFetchedToken(fetchedToken);
          })
          .catch(logout);
      }
    },
    [dispatch, isCustomerAvailableToFetch, logout, fetchedToken, currentToken]
  );

  return <>{children}</>;
};
