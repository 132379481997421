import {useQuery, type UseQueryOptions, type UseQueryResult} from "@tanstack/react-query";
import {queryClient} from "App";
import {handleQueryError} from "utils/queries/handleQueryError";
import {ApiError} from "types/api";

type AppQueryOptions<QueryResponseData> = UseQueryOptions<
  QueryResponseData | undefined,
  ApiError,
  QueryResponseData
>;

type UseApiQueryParams<QueryResponseData, QueryParams> =
  AppQueryOptions<QueryResponseData> & QueryParams;

export type UseApiQuery<QueryResponseData, QueryParams> = typeof useApiQuery<
  QueryResponseData,
  QueryParams
>;

export type SetQueryData<QueryResponseData> = (data: QueryResponseData) => void;

/**
 * Additional React Query wrapper for all Api Queries,
 * in order to have one single place to modify various logic
 * such as error handling and adding new methods such as `setQueryData`
 */
export const useApiQuery = <QueryResponseData, QueryParams>({
  queryKey,
  ...queryParams
}: UseApiQueryParams<QueryResponseData, QueryParams>): UseQueryResult<
  QueryResponseData,
  ApiError
> & {
  setQueryData: (data: QueryResponseData) => void;
} => {
  const queryResult = useQuery({
    onError: handleQueryError,
    queryKey,
    ...(queryParams as AppQueryOptions<QueryResponseData>),
  });

  /**
   * Necessary to avoid an additional request to the back-end
   * when we have all updated values available
   * and we just need to update the cached data.
   */
  const setQueryData: SetQueryData<QueryResponseData> = (data) => {
    if (queryKey) {
      queryClient.setQueryData(queryKey, data);
    }
  };

  return {setQueryData, ...queryResult};
};
