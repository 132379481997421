import {createAsyncThunk} from "@reduxjs/toolkit";
import {requestOtp} from "api/auth/requestOtp";
import {
  AUTH_REDUCER_NAME,
  REQUEST_OTP_AUTH_2,
} from "state/redux/slices/auth/constants/actions";

export const requestOtpThunk = createAsyncThunk(
  `${AUTH_REDUCER_NAME}/${REQUEST_OTP_AUTH_2}`,
  async (_data, thunkApi) => {
    try {
      const response = await requestOtp();
      return response?.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);
