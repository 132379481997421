import {FetchingStatus} from "types/common";
import {IRecurringOrdersInitialData} from "../types";

const initialData: IRecurringOrdersInitialData = {
  list: [],
  recurringOrderEditor: {
    configuration: null,
    centsNotification: null,
  },
  apiRequests: {
    recurringOrdersList: {
      data: {
        recurringOrders: [],
      },
      fetchingStatus: FetchingStatus.Initial,
      error: {
        text: "",
        code: "",
      },
    },
    recurringOrderSeriesDetails: {
      data: {recurringOrder: null},
      fetchingStatus: FetchingStatus.Initial,
      error: {
        text: "",
        code: "",
      },
    },
  },
};

export default initialData;
