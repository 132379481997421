import {useCallback} from "react";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderActions} from "state/redux/slices/order";
import {getScheduleDetails} from "state/redux/slices/order/selectors";
import {DELIVERY_CONFLICTS_TYPES} from "constants/order";

export const useDeliveryConflicts = () => {
  const dispatch = useAppDispatch();
  const {pickup, returnInfo} = useAppSelector(getScheduleDetails);

  const getDeliveryConflicts = useCallback(() => {
    dispatch(orderActions.resetOOBOConflicts());
    const conflictsTypes: DELIVERY_CONFLICTS_TYPES[] = [];
    const pickupWindowConflictsTypes = pickup?.windowConflicts ?? [];
    const returnWindowConflictsTypes = returnInfo?.windowConflicts ?? [];
    conflictsTypes.push(
      ...pickupWindowConflictsTypes.map((type) =>
        type === DELIVERY_CONFLICTS_TYPES.MAX_STOPS
          ? DELIVERY_CONFLICTS_TYPES.PICKUP_MAX_STOPS
          : type
      ),
      ...returnWindowConflictsTypes.map((type) =>
        type === DELIVERY_CONFLICTS_TYPES.MAX_STOPS
          ? DELIVERY_CONFLICTS_TYPES.DELIVERY_MAX_STOPS
          : type
      )
    );

    return {conflictsTypes};
  }, [dispatch, pickup?.windowConflicts, returnInfo?.windowConflicts]);

  return {getDeliveryConflicts};
};
