import React, {FC, useEffect, useRef, useState} from "react";
import {Label} from "../Label";
import {ReactComponent as Checkmark} from "../assets/checkmark.svg";
import {togglePlaceholder} from "../assets/utils";
import {IInput} from "./types";

export const Input: FC<IInput> = ({
  type = "text",
  name,
  placeholder = "",
  required = true,
  onInput,
  children,
  className,
  title = "",
  activePlaceholder = "",
  ...props
}) => {
  const [isValid, setIsValid] = useState<boolean>(true);
  const [active, setActive] = useState<boolean>(false);
  const defaultRef = useRef<HTMLInputElement>(null);
  const inputRef = props.forwardRef || defaultRef;

  useEffect(() => {
    if (props.disabled) {
      [inputRef.current, inputRef.current?.parentElement].map((element) => {
        element?.setAttribute("data-placeholder", inputRef.current?.placeholder || "");
      });
    }
  }, [props.disabled]);

  const onInputHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    inputRef.current?.value
      ? setIsValid(!!inputRef.current?.validity.valid)
      : setIsValid(true);
    onInput?.(event);
  };

  const outProps = {...props};
  delete outProps.forwardRef;

  return (
    <Label
      isValid={isValid}
      active={active}
      disabled={props.disabled}
      className={className}
      title={title}
    >
      {title && (
        <span className={`title ${className ? `${className}InputTitle` : ""}`}>
          &nbsp;{title}
        </span>
      )}
      <input
        {...outProps}
        className={`${className ? className : ""}${
          isValid ? "" : " invalid"
        } outline-distant`}
        type={type}
        name={name}
        ref={inputRef}
        onFocus={(event): void => {
          event.target.getAttribute("placeholder") &&
            togglePlaceholder("show", event.target as HTMLInputElement, setActive);
        }}
        onBlur={(event): void => {
          event.target.parentElement?.getAttribute("data-placeholder") &&
            togglePlaceholder("hide", event.target as HTMLInputElement, setActive);
        }}
        onInput={onInputHandler}
        required={required}
        placeholder={placeholder}
        data-active-placeholder={activePlaceholder}
      />
      {type === "checkbox" && <Checkmark />}
      {children}
    </Label>
  );
};
