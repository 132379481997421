import {Flex} from "rebass/styled-components";

import {sectionStyles} from "../styles";
import useToggle from "hooks/useToggle";
import {useAppSelector} from "state/redux/hooks";
import {orderSelectors} from "state/redux/slices/order";

import {CADriverFeeInfoPopup, PickupDeliveryOrderSummary} from "components/common";

const PickupAndDeliverySummary = () => {
  const {
    nearStoresData,
    orderBuilder: {
      transportation: {pickupDelivery, returnDelivery},
    },
  } = useAppSelector(orderSelectors.getOnlineOrderData);
  const storeState =
    nearStoresData?.data.ownDeliveryStore?.state ||
    nearStoresData?.data.onDemandDeliveryStore?.state;

  const {isOpen: showCADeliveryPopup, toggle: toggleCADriverFeePopup} = useToggle();

  return (
    <>
      <Flex {...styles.section.link.wrapper} {...styles.section.link.lastWrapper}>
        <Flex {...styles.section.link.dataWrapper}>
          <PickupDeliveryOrderSummary
            pickup={pickupDelivery}
            delivery={returnDelivery}
            pickupDeliveryFee={pickupDelivery?.totalDeliveryCost}
            returnDeliveryFee={returnDelivery?.totalDeliveryCost}
            pickupDeliveryTip={
              pickupDelivery?.courierTip ? Number(pickupDelivery?.courierTip) : 0
            }
            returnDeliveryTip={
              returnDelivery?.courierTip ? Number(returnDelivery?.courierTip) : 0
            }
            storeLocation={storeState}
            toggleCADriverFeePopup={toggleCADriverFeePopup}
          />
        </Flex>
      </Flex>
      <CADriverFeeInfoPopup isOpen={showCADeliveryPopup} close={toggleCADriverFeePopup} />
    </>
  );
};

const styles = {
  section: sectionStyles,
};

export default PickupAndDeliverySummary;
