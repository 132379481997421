import * as Sentry from "@sentry/browser";
import {useQuery} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {type GetMachineCardsResponse, getMachineCards} from "api/machines";
import {ERROR_MESSAGES} from "constants/constants";
import type {ApiError} from "types/api";

interface UseMachineCardsPayload {
  /** Encoded businessId */
  encodedBusinessId?: string | null;
}

export const useMachineCards = ({encodedBusinessId}: UseMachineCardsPayload) => {
  return useQuery<unknown, ApiError, GetMachineCardsResponse>({
    queryKey: ["useMachineCards", {encodedBusinessId}],
    queryFn: async () => {
      if (!encodedBusinessId) {
        return null;
      }

      const {data} = await getMachineCards(encodedBusinessId);
      return data;
    },
    onError: (error) => {
      Sentry.captureException(error);

      toast.error(error?.response?.data?.error ?? ERROR_MESSAGES.MACHINE_CARDS);

      return error;
    },
  });
};
