import {useEffect, useState, type FC} from "react";
import CommonDialog from "components/common/Dialog/Dialog";
import {Button, ButtonVariant} from "components/common/buttons/Button";
import {Drawer} from "components/common/drawers/Drawer";
import ModifierButton from "components/newOrder/common/ServiceModifiers/ModifierButton";
import {getModifierPriceString, getPriceString} from "components/online-order/utils";
import {useBranding} from "hooks/branding/useBranding";
import {ISelectedService} from "state/redux/slices/order/types/orderBuilder";
import styles from "./modifiersDrawer.module.scss";

export const ModifiersDrawer: FC<{
  isOpen: boolean;
  service: ISelectedService | null;
  onSave: (modifiersToUpdate: ISelectedService["serviceModifiers"]) => void;
  onModifiersDrawerClose: () => void;
  onServiceRemove?: (id: ISelectedService["id"]) => void;
}> = ({isOpen, service, onSave, onModifiersDrawerClose, onServiceRemove}) => {
  const [isRemoveModalOpened, setRemoveModalOpened] = useState(false);

  const {translucentPrimaryColor} = useBranding();
  const servicePrice =
    service?.serviceCategory && service?.prices
      ? getPriceString({
          serviceCategory: service.serviceCategory,
          prices: service.prices,
        })
      : "";

  const [modifiersToUpdate, setModifiersToUpdate] = useState<
    ISelectedService["serviceModifiers"]
  >(service?.customerSelectedModifiers || []);
  useEffect(() => {
    if (typeof service?.customerSelectedModifiers?.length === "number") {
      setModifiersToUpdate(service.customerSelectedModifiers);
    }
  }, [service]);

  const modifiersList = service?.serviceModifiers
    ?.map((modifier) => ({
      id: modifier.id,
      name: modifier.modifier.name,
      price: modifier.modifier.price,
      pricingType: modifier.modifier.pricingType,
      isChecked: modifiersToUpdate?.map(({id}) => id)?.includes(modifier.id),
    }))
    ?.sort((a, b) => (a.name > b.name ? 1 : -1));

  const onModifierToggle = (modifierId: number) => {
    const currentModifier = service?.serviceModifiers?.find(({id}) => id === modifierId);

    if (currentModifier) {
      setModifiersToUpdate((prevModifiers) => {
        const shouldRemoveModifier = prevModifiers?.find(({id}) => id === modifierId);

        return shouldRemoveModifier
          ? prevModifiers.filter(({id}) => id !== modifierId)
          : [...prevModifiers, currentModifier];
      });
    }
  };

  const resetCustomerSelection = () => {
    setModifiersToUpdate([]);
    setRemoveModalOpened(false);
  };

  const onSubmit = () => {
    onSave(modifiersToUpdate);
    resetCustomerSelection();
  };

  const onClose = () => {
    onModifiersDrawerClose();
    resetCustomerSelection();
  };

  return (
    <Drawer isShowingDrawer={isOpen} closeDrawer={onClose} title="Laundry Service">
      <div className={styles.drawer}>
        <div className={styles.serviceHeaderContainer}>
          <div className={styles.serviceTitleContainer}>
            <h4 className={styles.serviceTitle}>{service?.name}</h4>
            <h4 className={styles.servicePrice}>{servicePrice}</h4>
          </div>

          {service?.description && (
            <p className={styles.serviceDescription}>{service.description}</p>
          )}
        </div>
        {modifiersList?.length ? (
          <div style={{backgroundColor: translucentPrimaryColor}}>
            <h2 className={styles.addonsHeader}>Choose Add-Ons</h2>
          </div>
        ) : (
          <></>
        )}

        <div className={styles.addonsContainer}>
          {modifiersList?.map(({name, price, pricingType, id, isChecked}) => (
            <ModifierButton
              key={id}
              price={`+ $${getModifierPriceString({price, pricingType})}`}
              name={name}
              checked={isChecked}
              onClick={() => onModifierToggle(id)}
            />
          ))}
          {onServiceRemove && !!service && (
            <Button
              variant={ButtonVariant.WITH_X}
              onClick={() => setRemoveModalOpened(true)}
              className={styles.serviceRemoveButton}
            >
              Remove Service
            </Button>
          )}
        </div>

        <div className={styles.buttonContainer}>
          <Button onClick={onSubmit}>Save</Button>
        </div>
      </div>
      <CommonDialog
        isOpen={isRemoveModalOpened}
        dialogTitle={`Are you sure you want to remove ${service?.name} Service?`}
        cancelText="No"
        submitText="Yes"
        handleSubmit={() => {
          service?.id && onServiceRemove?.(service.id);
          setRemoveModalOpened(false);
        }}
        handleClose={() => setRemoveModalOpened(false)}
      />
    </Drawer>
  );
};
