// Sentry (https://sentry.io/welcome/) is used to capture and alert on errors so that we can be more proactive about finding and fixing issues
import * as Sentry from "@sentry/browser";
// we couple sentry with fullstory so that our fullstory sessions include sentry errors and our sentry errors link to fullstory sessions 🙏
import SentryFullStory from "@sentry/fullstory";
import {SENTRY_KEY, SENTRY_ORG_SLUG} from "utils/config";
import Fullstory, {initFullstory} from "third-party/fullstory";

export function initSentryWithFullstory() {
  initFullstory();
  Sentry.init({
    dsn: SENTRY_KEY,
    integrations: [
      new Sentry.BrowserTracing(),
      new SentryFullStory(SENTRY_ORG_SLUG, {client: Fullstory}),
    ],
    tracesSampleRate: 0.5,
    environment: import.meta.env.VITE_ENVIRONMENT,
    release: import.meta.env.VITE_COMMIT_HASH,
  });
}
