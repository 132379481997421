import {BUSINESS_ASSETS_KEY, BUSINESS_OWNER_AUTH_TOKEN_KEY, TEAM_MEMBER} from "./config";

interface BusinessAssets {
  storeName?: string;
  customerName?: string | null;
  customerPhone?: string | null;
  orderNum?: string | null;
  businessId?: string | undefined;
}

/**
 * Get the current business assets from the session storage
 * @returns {BusinessAssets} The current business assets from the session storage
 * @example
 * const businessAssets = getCurrentBusinessAssets();
 */
const getCurrentBusinessAssets = (): BusinessAssets => {
  const assets: string = decodeURIComponent(
    atob(sessionStorage.getItem(BUSINESS_ASSETS_KEY) || "")
  );
  return assets ? JSON.parse(assets) : {};
};

/**
 *
 * @returns {boolean} Result of check current business assets in the session storage
 * @example
 * const isExistBusinessAssets = checkBusinessAssetsInSessionStorage();
 */
const checkBusinessAssetsInSessionStorage = (): boolean => {
  return Boolean(Object.keys(getCurrentBusinessAssets()).length > 0);
};

/**
 * Set the current business assets to the session storage
 * @example
 * setCurrentBusinessAssets(base64EncodedStringifyObjectOrString);
 */
const setCurrentBusinessAssets = (businessAssets: string): void => {
  businessAssets && sessionStorage.setItem(BUSINESS_ASSETS_KEY, businessAssets);
};

/**
 * Update the current business assets in the session storage
 * @example
 * updateCurrentBusinessAssets({orderNum: null});
 */
const updateCurrentBusinessAssets = (businessAssets: BusinessAssets): void => {
  const currentBusinessAssets: BusinessAssets = getCurrentBusinessAssets();
  if (currentBusinessAssets === null) {
    return;
  }

  const newBusinessAssets: BusinessAssets = {...currentBusinessAssets, ...businessAssets};
  setCurrentBusinessAssets(btoa(encodeURIComponent(JSON.stringify(newBusinessAssets))));
};

/**
 * Get the parameter from url search string
 * @example
 * const businessAssets = getSearchParameterByName("assets");
 */
const getSearchParameterByName = (name: string): string => {
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
  return urlParams.get(name) || "";
};

/**
 * Check if the OOBO/MOBO session (or banner should be displayed)
 * @example
 * const isOOBOSession = checkOOBO(flag.featureIsEnabled);
 */
const checkOOBO = (): boolean => {
  const assets = sessionStorage.getItem(BUSINESS_ASSETS_KEY) || null;
  const bo = sessionStorage.getItem(BUSINESS_OWNER_AUTH_TOKEN_KEY) || null;

  return assets !== null && bo !== null;
};

/**
 * Check if the current data exists in the session storage
 * @example
 * const isUserInSession = checkOOBOUser();
 */
const checkOOBOUser = (): boolean => {
  const assets = getCurrentBusinessAssets();
  return Boolean(
    assets &&
      Object.keys(assets).length > 0 &&
      assets?.customerName &&
      assets?.customerPhone
  );
};

export const getTeamMemberId = (): number | undefined =>
  Number(sessionStorage?.getItem(TEAM_MEMBER)) ||
  Number(localStorage?.getItem(TEAM_MEMBER)) ||
  undefined;

export {
  getCurrentBusinessAssets,
  setCurrentBusinessAssets,
  updateCurrentBusinessAssets,
  getSearchParameterByName,
  checkOOBO,
  checkOOBOUser,
  checkBusinessAssetsInSessionStorage,
};
