import {type PayloadAction} from "@reduxjs/toolkit";
import {type IOrderData} from "state/redux/slices/order/types";
import {ITransportationDeliveryInfo} from "../../types/orderBuilder";

export const setTransportationReturnDelivery = (
  state: IOrderData,
  action: PayloadAction<ITransportationDeliveryInfo>
) => {
  state.orderBuilder.transportation.returnDelivery = action.payload;
};
