import {FC, ReactNode} from "react";
import classNames from "classnames";
import {A11yButton} from "components/common/buttons/A11yButton";
import styles from "./section.module.scss";

export const Section: FC<{
  title: string;
  children: ReactNode;
  separators?: boolean;
  action?: ReactNode;
  onAction?: () => void;
  variant?: "deprecated" | "primary" | "compact";
}> = ({title, children, separators, action, variant = "deprecated", onAction}) => {
  return (
    <section
      className={classNames(styles.section, {
        [styles.tuneUp]: variant === "primary" || variant === "compact",
      })}
    >
      <A11yButton
        onAction={onAction}
        className={classNames(styles.titleContainer, {
          [styles.tuneUp]: variant === "primary" || variant === "compact",
        })}
      >
        <h2
          className={classNames(styles.title, {
            [styles.tuneUp]: variant === "primary" || variant === "compact",
          })}
        >
          {title}
        </h2>
        {action}
      </A11yButton>
      <div
        className={classNames(styles.content, {
          [styles.separators]: separators,
          [styles.tuneUp]: variant === "primary",
          [styles.compact]: variant === "compact",
        })}
      >
        {children}
      </div>
    </section>
  );
};
