import {useState} from "react";
import {Flex, Text} from "rebass/styled-components";
import {useBranding} from "hooks/branding/useBranding";
import pluralize from "utils/pluralize";
import {MAX_ADD_TIME} from "constants/index";
import defaultTheme from "constants/themes/theme";
import {MinusIconWithoutColor, PlusIconWithoutColor} from "assets/images";
import styles from "./styles";

interface ITimeCounterProps {
  initialTimeInMinutes: number;
  addTimeInMinutes: number;
  withoutBaseTime?: boolean;
  onQuantityChange(quantity: number): void;
}
export const TimeCounter = ({
  initialTimeInMinutes,
  addTimeInMinutes,
  withoutBaseTime,
  onQuantityChange,
}: ITimeCounterProps) => {
  const [quantity, setQuantity] = useState(withoutBaseTime ? 1 : 0);
  const {primaryColor} = useBranding();

  const initTime = withoutBaseTime ? 0 : initialTimeInMinutes;
  const timeInMinutes = Number((addTimeInMinutes * quantity + initTime).toFixed(2));

  const minusDisabled = quantity === (withoutBaseTime ? 1 : 0);
  const plusDisabled = timeInMinutes + addTimeInMinutes > MAX_ADD_TIME;

  const onButtonClick = (shift: number): void => {
    const isAbleToClick = shift > 0 ? !plusDisabled : !minusDisabled;
    if (isAbleToClick) {
      onQuantityChange(quantity + shift);
      setQuantity(quantity + shift);
    }
  };

  return (
    <Flex {...styles.counterRow}>
      <MinusIconWithoutColor
        style={{
          ...styles.circleButton,
          pointerEvents: minusDisabled ? "none" : "auto",
          stroke: minusDisabled
            ? defaultTheme.colors.DISABLED_BUTTON_BACKGROUND
            : primaryColor,
          userSelect: "none",
        }}
        onClick={() => onButtonClick(-1)}
      />
      <Text {...styles.number} px={"12px"}>
        {pluralize("min", timeInMinutes)}
      </Text>
      <PlusIconWithoutColor
        style={{
          ...styles.circleButton,
          pointerEvents: plusDisabled ? "none" : "auto",
          stroke: plusDisabled
            ? defaultTheme.colors.DISABLED_BUTTON_BACKGROUND
            : primaryColor,
          userSelect: "none",
        }}
        onClick={() => onButtonClick(1)}
      />
    </Flex>
  );
};

export default TimeCounter;
