import {UseApiQuery, useApiQuery} from "hooks/useApiQuery";
import {fetchStoreLevelPerformer, Performer} from "api/store/fetchStoreLevelPerformer";

interface QueryParams {
  encodedId: string;
}

/**
 * Performer is combination of business.Id and store.Id
 */
export const useStoreLevelPerformer: UseApiQuery<Performer, QueryParams> = ({
  encodedId,
}) =>
  useApiQuery({
    enabled: !!encodedId,
    queryKey: ["useStoreLevelPerformer", {encodedId}],
    queryFn: async () => {
      if (encodedId) {
        const {data} = await fetchStoreLevelPerformer(encodedId);
        return data;
      }
    },
    // Infinity because for the same encodedId we always get the same performer
    staleTime: Infinity,
  });
