import {FC, ReactNode} from "react";
import cx from "classnames";
import styles from "./checkbox.module.scss";

export const Checkbox: FC<{
  checked: boolean;
  onChange: (value: boolean) => void;
  children: ReactNode;
}> = ({checked, children, onChange}) => {
  return (
    <button
      className={cx(styles.wrapper, {
        [styles.active]: checked,
      })}
      onClick={() => onChange(!checked)}
      aria-pressed={checked}
    >
      {children}
    </button>
  );
};
