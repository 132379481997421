import {toast} from "react-toastify";
import {ToastError, errorToastOptions} from "components/newOrder/common/Toast";

type CreateErrorToast = (params: {
  primaryMessage?: string;
  secondaryMessage?: string;
  message?: string;
  toastId?: string;
}) => void;

export const createErrorToast: CreateErrorToast = ({
  primaryMessage,
  secondaryMessage,
  message,
  toastId,
}) =>
  toast.error(
    <ToastError
      primaryMessage={primaryMessage}
      secondaryMessage={secondaryMessage}
      message={message}
    />,
    {
      ...errorToastOptions,
      toastId,
    }
  );
