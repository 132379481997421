import {FC} from "react";
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
} from "@material-ui/core/styles";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import cn from "classnames";
import {ThemeProvider as StyledThemeProvider} from "styled-components";
import {typography} from "components/online-order/orderTheme";
import {useBranding} from "hooks/branding/useBranding";
import {applyTheme, getFilterClass} from "utils/theme";
import materialTheme from "constants/themes/muiTheme";
import defaultTheme from "constants/themes/theme";
import styles from "./themeProvider.module.scss";

interface ThemeProviderProps {
  children: React.ReactNode;
  className?: string;
}

/**
 * This provider is required to correctly apply branding to the application
 * based on the current state of the application,
 * based on the current business and store for customer.
 */
export const ThemeProvider: FC<ThemeProviderProps> = ({children, className}) => {
  const {primaryColor, translucentPrimaryColor, borderRadius} = useBranding();

  const filterClass = getFilterClass(primaryColor);
  const joinedTheme = applyTheme(defaultTheme, {
    primaryColor,
    borderRadius,
  });
  const muiTheme = createTheme({
    ...materialTheme,
    ...joinedTheme,
    ...typography,
    filterClass,
  });

  return (
    <div
      style={{
        // @ts-expect-error because of the CSS variable and style type incompatibility
        "--theme-color": primaryColor,
        "--theme-secondary-color": translucentPrimaryColor,
        "--theme-border-radius": borderRadius,
        // @ts-check
      }}
      className={cn(styles.wrapper, className)}
    >
      <MaterialThemeProvider theme={materialTheme}>
        <MaterialThemeProvider theme={muiTheme}>
          <MuiThemeProvider theme={muiTheme}>
            <StyledThemeProvider theme={joinedTheme}>{children}</StyledThemeProvider>
          </MuiThemeProvider>
        </MaterialThemeProvider>
      </MaterialThemeProvider>
    </div>
  );
};
