import {ISelectedService} from "state/redux/slices/order/types/orderBuilder";

export const formatOrderServices = ({
  customerSelectedServices,
}: {
  customerSelectedServices: ISelectedService[];
}) =>
  customerSelectedServices.map((service) => ({
    servicePriceId: service.prices[0].id,
    serviceModifierIds: service.customerSelectedModifiers?.map((modifier) => modifier.id),
  }));
