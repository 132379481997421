import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Flex, Text, Image, Box} from "rebass/styled-components";
import {useBranding} from "hooks/branding/useBranding";
import {EN_LOCALE} from "locales/en";
import {DownArrow} from "../../assets/images";

const Dropdown = (props) => {
  const {list, selectedListItem, onListItemClick} = props;
  const {translucentPrimaryColor} = useBranding();
  const [open, setOpen] = useState(false);
  const selectedListItemDisplay = list.find(
    (item) => item.value === selectedListItem
  )?.label;

  const close = () => setOpen(false);

  useEffect(() => {
    window.addEventListener("click", close);
    return () => {
      window.removeEventListener("click", close);
    };
  }, []);

  return (
    <Box {...styles.wrapper}>
      <Flex
        {...styles.mainWrapper}
        onClick={(e) => {
          e.stopPropagation();
          setOpen((state) => !state);
        }}
      >
        <Text {...styles.normalText}>{selectedListItemDisplay}</Text>
        <Image src={DownArrow} alt={EN_LOCALE.label.arrowDown} />
      </Flex>
      {open && list.length && (
        <Box {...styles.children}>
          {list.map((item, index) => {
            let itemStyles = {...styles.childrenItem};
            if (selectedListItem === item.value) {
              itemStyles = {
                ...styles.selectedChildrenItem,
                bg: translucentPrimaryColor,
              };
            }

            return (
              <Text
                {...itemStyles}
                {...styles.normalText}
                key={index}
                onClick={() => onListItemClick(item)}
              >
                {item.label}
              </Text>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

const styles = {
  wrapper: {
    flexDirection: "column",
    width: "100%",
  },
  mainWrapper: {
    width: "180px",
    flexDirection: "row",
    justifyContent: "space-between",
    pb: "4px",
    sx: {
      borderBottomWidth: "1px",
      borderBottomColor: "NEW_TEXT_PRIMARY_BLUE",
      borderBottomStyle: "solid",
    },
  },
  normalText: {
    fontSize: "16px",
    color: "NEW_TEXT_PRIMARY_BLUE",
    paddingRight: "3px",
  },
  children: {
    minWidth: "180px",
    marginLeft: "20px",
    padding: "5px 0px",
    bg: "WHITE",
    sx: {
      left: 0,
      position: "absolute",
      boxShadow: "0 2px 6px 0 rgba(0,0,0,0.25)",
      borderRadius: "4px",
    },
  },
  childrenItem: {
    padding: "10px",
    cursor: "default",
  },
  selectedChildrenItem: {
    padding: "10px",
    bg: "#E9F1FB",
    cursor: "default",
  },
};

Dropdown.propTypes = {
  list: PropTypes.array.isRequired,
  selectedListItem: PropTypes.any.isRequired,
  onListItemClick: PropTypes.any.isRequired,
};

export default Dropdown;
