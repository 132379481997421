export enum ACTIONS {
  SET_TOKEN = "setToken",
  GET_TOKEN = "getToken",
  CLEAR_TOKEN = "clearToken",
  SEND_EVENT = "sendEvent",
}

export interface IRWGState {
  token: string | null;
  isEventSent: boolean;
}

export interface IRWGEventPayload {
  [key: string]: unknown;
}

export interface IRWGAction {
  type: ACTIONS;
  payload?: string | boolean | IRWGEventPayload;
}

const actions = {
  setToken: (payload: string) => ({
    type: ACTIONS.SET_TOKEN,
    payload,
  }),
  getToken: () => ({
    type: ACTIONS.GET_TOKEN,
  }),
  clearToken: () => ({
    type: ACTIONS.CLEAR_TOKEN,
  }),
  sendEvent: (payload: IRWGEventPayload) => ({
    type: ACTIONS.SEND_EVENT,
    payload,
  }),
};

export default actions;
