import {createAsyncThunk} from "@reduxjs/toolkit";
import {captureException} from "@sentry/browser";
import {
  ONLINE_ORDER_REDUCER,
  FETCH_DELIVERY_SETTING,
} from "state/redux/slices/order/constants/general";
import {fetchGeneralDeliverySettings} from "api/delivery/fetchGeneralDeliverySettings";

export const fetchDeliverySettingsThunk = createAsyncThunk(
  `${ONLINE_ORDER_REDUCER}/${FETCH_DELIVERY_SETTING}`,
  async (
    {
      storeId,
    }: {
      storeId: number | string;
    },
    thunkApi
  ) => {
    try {
      const response = await fetchGeneralDeliverySettings({storeId: Number(storeId)});
      return response.data;
    } catch (error: any) {
      captureException(error);
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);
