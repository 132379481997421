import {createSlice} from "@reduxjs/toolkit";

import {FetchingStatus} from "types/common";
import {REWARDS_REDUCER} from "./constants";
import {initialData} from "./initData";
import {
  enrollCustomerThunk,
  getCustomerRewardsInfoThunk,
  getOrderRewardsHistoryDetailsThunk,
  getRewardsHistoryThunk,
} from "./thunks";

const RewardsSlice = createSlice({
  name: REWARDS_REDUCER,
  initialState: initialData,
  reducers: {
    clearRewardsInfo: (state) => {
      state.customerRewardsInfo = initialData.customerRewardsInfo;
      state.rewardsHistory = initialData.rewardsHistory;
      state.orderRewardsDetails = initialData.orderRewardsDetails;
    },
    setCurrentPoints: (state, action) => {
      state.customerRewardsInfo.data.currentPoints = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerRewardsInfoThunk.pending, (state) => {
        state.customerRewardsInfo.fetchingStatus = FetchingStatus.Pending;
        state.customerRewardsInfo.error = initialData.customerRewardsInfo.error;
      })
      .addCase(getCustomerRewardsInfoThunk.fulfilled, (state, action) => {
        state.customerRewardsInfo.data = action.payload;
        state.customerRewardsInfo.fetchingStatus = FetchingStatus.Fulfilled;
        state.customerRewardsInfo.error = initialData.customerRewardsInfo.error;
      })
      .addCase(getCustomerRewardsInfoThunk.rejected, (state, action) => {
        const {text, code} = action.payload as {text: string; code: string};
        state.customerRewardsInfo.fetchingStatus = FetchingStatus.Rejected;
        state.customerRewardsInfo.error = {text, code};
      })
      .addCase(enrollCustomerThunk.pending, (state) => {
        state.customerRewardsInfo.fetchingStatus = FetchingStatus.Pending;
        state.customerRewardsInfo.error = initialData.customerRewardsInfo.error;
      })
      .addCase(enrollCustomerThunk.fulfilled, (state) => {
        state.customerRewardsInfo.data.isEnrolled = true;
        state.customerRewardsInfo.fetchingStatus = FetchingStatus.Fulfilled;
        state.customerRewardsInfo.error = initialData.customerRewardsInfo.error;
      })
      .addCase(enrollCustomerThunk.rejected, (state, action) => {
        const {text, code} = action.payload as {text: string; code: string};
        state.customerRewardsInfo.fetchingStatus = FetchingStatus.Rejected;
        state.customerRewardsInfo.error = {text, code};
      })
      .addCase(getRewardsHistoryThunk.pending, (state) => {
        state.rewardsHistory.fetchingStatus = FetchingStatus.Pending;
        state.rewardsHistory.error = initialData.rewardsHistory.error;
      })
      .addCase(getRewardsHistoryThunk.fulfilled, (state, action) => {
        state.rewardsHistory.data = action.payload;
        state.rewardsHistory.fetchingStatus = FetchingStatus.Fulfilled;
        state.rewardsHistory.error = initialData.rewardsHistory.error;
      })
      .addCase(getRewardsHistoryThunk.rejected, (state, action) => {
        const {text, code} = action.payload as {text: string; code: string};
        state.rewardsHistory.fetchingStatus = FetchingStatus.Rejected;
        state.rewardsHistory.error = {text, code};
      })
      .addCase(getOrderRewardsHistoryDetailsThunk.pending, (state) => {
        state.orderRewardsDetails.fetchingStatus = FetchingStatus.Pending;
        state.orderRewardsDetails.error = initialData.orderRewardsDetails.error;
      })
      .addCase(getOrderRewardsHistoryDetailsThunk.fulfilled, (state, action) => {
        state.orderRewardsDetails.data = action.payload;
        state.orderRewardsDetails.fetchingStatus = FetchingStatus.Fulfilled;
        state.orderRewardsDetails.error = initialData.orderRewardsDetails.error;
      })
      .addCase(getOrderRewardsHistoryDetailsThunk.rejected, (state, action) => {
        const {text, code} = action.payload as {text: string; code: string};
        state.orderRewardsDetails.fetchingStatus = FetchingStatus.Rejected;
        state.orderRewardsDetails.error = {text, code};
      });
  },
});

export const rewardsActions = RewardsSlice.actions;
export const rewardsReducer = RewardsSlice.reducer;
