import {useCallback} from "react";
import * as FullStory from "@fullstory/browser";
import {useIsServiceFirstFlow} from "hooks/orderBuilder/useIsServiceFirstFlow";
import {useAppSelector} from "state/redux/hooks";
import {
  getBusinessId,
  getBusinessName,
  getStoreId,
  getStoreName,
} from "state/redux/slices/business/selectors";
import {getCentsCustomer} from "state/redux/slices/customer/selectors/credentialsSelector";
import {checkOOBO} from "utils/ooboHelpers";

export const useFullStoryEvent = () => {
  const businessId = useAppSelector(getBusinessId);
  const businessName = useAppSelector(getBusinessName);
  const storeId = useAppSelector(getStoreId);
  const storeName = useAppSelector(getStoreName);
  const centsCustomer = useAppSelector(getCentsCustomer);
  const isOperatorSession = checkOOBO();
  const isRWGFlow = useIsServiceFirstFlow();

  const submitEvent = useCallback(
    (type: string, data: Record<string, any> = {}) => {
      FullStory.event(type, {
        businessId,
        businessName,
        storeId,
        storeName,
        centsCustomerId: centsCustomer?.id,
        customerPhoneNumber: centsCustomer?.phoneNumber,
        isOperatorSession,
        isRWGFlow,
        ...data,
      });
    },
    [
      businessId,
      businessName,
      centsCustomer,
      isOperatorSession,
      isRWGFlow,
      storeId,
      storeName,
    ]
  );

  return {submitEvent};
};
