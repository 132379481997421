import {FetchingStatus} from "types/common";
import {IReferralInitialData} from "./types";

export const initialData: IReferralInitialData = {
  referralProgramInfo: {
    data: {
      isActive: false,
      creditEarned: 0,
      creditGiven: 0,
      creditType: null,
      referralCode: "",
      referralLink: "",
    },
    fetchingStatus: FetchingStatus.Initial,
    error: {
      text: "",
      code: "",
    },
  },
  referralCodeValidation: {
    data: {
      orderCount: 0,
      creditGiven: 0,
      creditType: null,
    },
    fetchingStatus: FetchingStatus.Initial,
    error: {
      text: "",
      code: "",
    },
  },
  applyReferralInfo: {
    referredByCodeName: "",
    referralPromoCodeAmount: "",
  },
};
