import {type History, type LocationState} from "history";

type OpenView = (options: {
  history: History<LocationState>;
  url: string;
  location?: LocationState;
  viewPath: string;
  prevViewPath: string;
  search?: string;
}) => void;

export const openView: OpenView = ({
  history,
  url,
  location,
  viewPath,
  prevViewPath,
  search,
}) => {
  if (prevViewPath) {
    url = url.replace(`/${prevViewPath}`, "");
  }

  let updatedUrl = `${
    url.endsWith("/") ? url.slice(0, url.length - 1) : url
  }/${viewPath}`;

  if (search) {
    updatedUrl = updatedUrl.concat(search);
  }

  history.push(updatedUrl, location);
};
