import {UseApiQuery, useApiQuery} from "hooks/useApiQuery";
import {fetchStore, Store} from "api/store/fetchStore";

interface QueryParams {
  storeId?: string | undefined;
}

export const useStore: UseApiQuery<Store, QueryParams> = ({storeId, ...queryOptions}) =>
  useApiQuery({
    enabled: !!storeId,
    queryKey: ["useStore", {storeId}],
    queryFn: async () => {
      if (storeId) {
        const {data: store} = await fetchStore(storeId);
        return store;
      }
    },
    // Stale time is 10 minutes because from Live Link
    // we cannot change values of the store data
    // so for the same storeId we always get the same response
    staleTime: 1000 * 60 * 10,
    ...queryOptions,
  });
