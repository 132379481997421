import {FC} from "react";
import cx from "classnames";
import styles from "./Divider.module.scss";

interface DividerProps {
  className?: string;
}

const Divider: FC<DividerProps> = ({className}) => {
  return <div className={cx(styles.divider, className)} />;
};

export default Divider;
