import {createSelector} from "reselect";
import {getOnlineOrderData} from "../selectors";

export const getAllNotes = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.notes
);

export const getOrderNotes = createSelector(
  getOnlineOrderData,
  (state) => state.orderBuilder.notes.orderNotes
);
