import {AppDispatch} from "state/redux/store";
import {DELIVERY_PROVIDERS} from "constants/order";
import {EstimateAndSetDelivery} from "components/newOrder/hooks/nash/useEstimateAndSetDelivery";
import {OnDemandEstimateResponse} from "api/nash/getDeliveryEstimate";
import {ScheduleWindow} from "components/newOrder/routes/schedule/hooks/useScheduling/types";

type ValidateNashDeliveryWindows = ({
  pickup,
  returnInfo,
  dispatch,
  estimateAndSetDelivery,
  timeZone,
  zipCode,
  isAutoSchedule,
}: {
  pickup: ScheduleWindow;
  returnInfo: ScheduleWindow | null;
  dispatch: AppDispatch;
  timeZone: string;
  zipCode: string;
  estimateAndSetDelivery: EstimateAndSetDelivery;
  isAutoSchedule: boolean;
}) => Promise<{
  isSuccessful: boolean;
  pickupEstimate: OnDemandEstimateResponse | null;
  returnEstimate: OnDemandEstimateResponse | null;
}>;

export const validateNashDeliveryWindows: ValidateNashDeliveryWindows = async ({
  pickup,
  returnInfo,
  estimateAndSetDelivery,
  timeZone,
  zipCode,
}) => {
  let isPickupEstimateSuccessful = true;
  let isReturnEstimateSuccessful = true;
  let pickupEstimate = null;
  let returnEstimate = null;

  await Promise.all([
    (async () => {
      if (pickup.type === DELIVERY_PROVIDERS.onDemand) {
        const {isSuccessful, estimate} = await estimateAndSetDelivery({
          windowData: pickup,
          timeZone,
          zipCode,
        });
        isPickupEstimateSuccessful = isSuccessful;
        pickupEstimate = estimate;
      }
    })(),
    (async () => {
      if (returnInfo?.type === DELIVERY_PROVIDERS.onDemand) {
        const {isSuccessful, estimate} = await estimateAndSetDelivery({
          windowData: returnInfo as ScheduleWindow,
          timeZone,
          zipCode,
        });

        isReturnEstimateSuccessful = isSuccessful;
        returnEstimate = estimate;
      }
    })(),
  ]);

  return {
    isSuccessful: isPickupEstimateSuccessful && isReturnEstimateSuccessful,
    returnEstimate,
    pickupEstimate,
  };
};
