import {useCallback, useEffect, useState} from "react";
import {captureException} from "@sentry/browser";
import get from "lodash/get";
import {useHistory, useRouteMatch} from "react-router-dom";
import {toast} from "react-toastify";
import {Loader} from "components/common";
import ToastError from "components/common/ToastError";
import {FinishingUp} from "components/newOrder/routes/checkout/online-order-form/finishing-up";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {businessSelectors} from "state/redux/slices/business";
import {getCustomerInfo} from "state/redux/slices/customer/selectors";
import {customerActions} from "state/redux/slices/customer/slice";
import {orderActions} from "state/redux/slices/order";
import {getOrderInitialData, orderSelectors} from "state/redux/slices/order/selectors";
import {fetchDeliverySettingsThunk} from "state/redux/slices/order/thunks/fetchDeliverySettingsThunk";
import {getInvoicingFlag} from "utils/order/getInvoicingFlag";
import {
  fetchCustomerInfo,
  fetchOwnDriverDeliverySettings,
  fetchServicesAndModifiers,
} from "api/online-order";
import {FetchingStatus} from "types/common";

export const Checkout = () => {
  const history = useHistory();
  const {
    data: {businessId},
  } = useAppSelector(orderSelectors.getOrderInitialData);

  const businessSettings = useAppSelector(businessSelectors.getBusinessSettingsFromState);
  const {data: customer} = useAppSelector(getCustomerInfo);
  const dispatch = useAppDispatch();
  const orderInitialData = useAppSelector(getOrderInitialData);
  const initialOrderStatus = orderInitialData?.fetchingStatus;
  const newOrderFlowBusinessSettings = orderInitialData.data?.businessSettings;
  const postalCode = useAppSelector(orderSelectors.getCurrentCustomerAddress)?.postalCode;
  const storeId = useAppSelector(orderSelectors.getNearStoresData).data?.availableServices
    ?.storeId;
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState();
  const [generalDeliverySettings, setGeneralDeliverySettings] = useState();
  const [ownDriverDeliverySettings, setOwnDriverDeliverySettings] = useState();
  const [ownDeliverySettingsLoading, setOwnDeliverySettingsLoading] = useState();

  const fetchDetails = useCallback(async () => {
    try {
      setLoading(true);
      const servicesPromise = fetchServicesAndModifiers(storeId, postalCode);
      const customerInfoPromise = fetchCustomerInfo(storeId);
      const [servicesResp, customerResp, {generalDeliverySettings}] = await Promise.all([
        servicesPromise,
        customerInfoPromise,
        dispatch(fetchDeliverySettingsThunk({storeId})).unwrap(),
      ]);

      if (servicesResp?.data?.success) {
        setServices(servicesResp.data.services);
      }

      if (customerResp?.data?.success) {
        const paymentMethods = customerResp?.data?.customer?.paymentMethods;
        const defaultPaymentMethodToken = paymentMethods?.length
          ? (
              paymentMethods.find((paymentMethod) => paymentMethod.isDefault) ||
              paymentMethods[0]
            )?.paymentMethodToken
          : null;

        const isInvoicingOrder = getInvoicingFlag({
          settings: generalDeliverySettings,
          customer: customerResp.data.customer,
        });
        if (!isInvoicingOrder) {
          dispatch(orderActions.setPaymentMethodToken(defaultPaymentMethodToken));
        }

        dispatch(customerActions.setCustomerInfo(customerResp.data.customer));
      }

      if (generalDeliverySettings) {
        setGeneralDeliverySettings(generalDeliverySettings);
      }
    } catch (error) {
      toast.error(
        <ToastError
          message={get(error, "response.data.error", "Something went wrong!")}
        />
      );
      captureException(error);
    } finally {
      setLoading(false);
    }
  }, [postalCode, storeId, dispatch]);

  const fetchAndSetOwnDeliverySettings = async (storeId, zipCode) => {
    try {
      setOwnDeliverySettingsLoading(true);
      const res = await fetchOwnDriverDeliverySettings(storeId, {zipCode});
      setOwnDriverDeliverySettings(res?.data?.ownDriverDeliverySettings);
    } catch (error) {
      toast.error(
        <ToastError
          message={get(error, "response.data.error", "Something went wrong!")}
        />
      );
    } finally {
      setOwnDeliverySettingsLoading(false);
    }
  };

  useEffect(() => {
    if (storeId) {
      fetchDetails();
    }
  }, [fetchDetails, storeId]);

  useEffect(() => {
    if (storeId && postalCode) {
      fetchAndSetOwnDeliverySettings(storeId, postalCode);
    }
  }, [postalCode, storeId]);

  const {url} = useRouteMatch();

  useEffect(() => {
    if (initialOrderStatus !== FetchingStatus.Fulfilled) {
      history.push(url.replace("/checkout", ""));
    }
  }, []);

  return loading || ownDeliverySettingsLoading ? (
    <Loader />
  ) : (
    <FinishingUp
      services={services}
      generalDeliverySettings={generalDeliverySettings}
      ownDriverDeliverySettings={ownDriverDeliverySettings}
      customer={customer}
      dryCleaningEnabled={
        businessSettings?.id
          ? businessSettings?.dryCleaningEnabled
          : newOrderFlowBusinessSettings?.dryCleaningEnabled
      }
      businessId={businessId}
    />
  );
};
