import {useLayoutEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {toast} from "react-toastify";
import {ROUTES} from "routes/order/business/constants";
import {IPreferenceItem} from "components/account/AccountCarePreferences/assets/AccountCarePreferencesForm/types";
import {ToastError, errorToastOptions} from "components/newOrder/common/Toast";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {fetchCustomerInfoThunk} from "state/redux/slices/customer/thunks/fetchCustomerInfoThunk";
import {getNewOrderStoreId} from "state/redux/slices/order/selectors/storeSelectors";
import {fetchPreferences} from "api/online-order";
import {EmptyObject} from "types/common";
import {ICustomer} from "types/customer";

const ERROR_MESSAGES: Record<string, string> = {
  SELECTION_ERROR: "Something went wrong while saving information",
  FETCH_ERROR: "Something went wrong while fetching data",
  SELECT_SERVICEABLE_ADDRESS_1: "Could not load preferences.",
  SELECT_SERVICEABLE_ADDRESS_2: "You must first select an address.",
};

interface IUseAccountCarePreferences {
  businessId: string;
  setIsLoading?: (arg: boolean) => void;
  storeId?: number;
}

export interface IBusinessCustomerInfo extends Record<string, number | boolean | string> {
  availableCredits: number;
}

export interface IStorePreference {
  notes: string | null;
  isHangDrySelected: boolean;
  hangDryInstructions: string | null;
}

export const useAccountCarePreferences = ({
  businessId,
  storeId,
  setIsLoading,
}: IUseAccountCarePreferences) => {
  const dispatch = useAppDispatch();
  const {url} = useRouteMatch();
  const history = useHistory();
  const stateStoreId = useAppSelector(getNewOrderStoreId);
  const [customerInfo, setCustomerInfo] = useState<ICustomer | EmptyObject>({});
  const [businessCustomerPreferences, setBusinessCustomerPreferences] = useState<
    IPreferenceItem[]
  >([]);

  const forceBack = (steps: number = -1, appendix?: string): void =>
    history.push(`${url.split("/").slice(0, steps).join("/")}${appendix || ""}`);

  const initPreferences = async (
    stateStoreId: string,
    businessId: string
  ): Promise<void> => {
    try {
      const customerPrefsResp = await fetchPreferences(businessId);
      const {customer, success: customerInfoRespSuccess} = await dispatch(
        fetchCustomerInfoThunk({storeId: stateStoreId})
      ).unwrap();

      if (customerPrefsResp.data.success && customerInfoRespSuccess) {
        setBusinessCustomerPreferences(customerPrefsResp.data.preferences);
        setCustomerInfo(customer);
        setIsLoading?.(false);
      } else {
        toast.error(
          <ToastError primaryMessage={ERROR_MESSAGES.FETCH_ERROR} />,
          errorToastOptions
        );
        forceBack();
      }
    } catch (e) {
      toast.error(
        <ToastError primaryMessage={ERROR_MESSAGES.FETCH_ERROR} />,
        errorToastOptions
      );
      forceBack();
    }
  };

  useLayoutEffect(() => {
    if ((storeId || stateStoreId) && businessId) {
      initPreferences(String(storeId || stateStoreId), businessId);
    } else {
      toast.error(
        <ToastError
          primaryMessage={ERROR_MESSAGES.SELECT_SERVICEABLE_ADDRESS_1}
          secondaryMessage={ERROR_MESSAGES.SELECT_SERVICEABLE_ADDRESS_2}
        />
      );
      if (url.includes(`/${ROUTES.account}`)) {
        forceBack(-2, "/home");
      } else {
        forceBack(-1, "/home");
      }
    }
  }, [stateStoreId, businessId, storeId]);

  return {
    forceBack,
    storeId: stateStoreId,
    customerInfo,
    businessCustomerPreferences,
    storePreferences: customerInfo?.storePreferences || [],
    refetchPreferences: initPreferences,
  };
};
