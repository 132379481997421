const styles = {
  settingsContainer: {
    position: "relative",
    minHeight: "150px",
  },
  settingsBlock: {fontSize: ["14px", "16px", "18px"]},
  pricesContainer: {margin: "10px 10px 20px 10px"},
  dumbComment: {
    fontStyle: "italic",
    fontFamily: "secondary",
    color: "TEXT_GREY",
  },
};

export default styles;
