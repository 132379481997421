import {FetchingStatus} from "types/common";

export const initialApiRequestState = {
  data: null,
  fetchingStatus: FetchingStatus.Initial,
  error: {
    text: "",
    code: "",
  },
};
