import {logoutCustomer} from "utils/common";

type HandleLogout = (data?: {token: string}) => void;

export const handleLogout: HandleLogout = (options) => {
  const token = options?.token;
  logoutCustomer();
  window.location.href = [
    "/verify-account",
    `?destination=${encodeURIComponent(window.location.pathname)}`,
    token ? `&orderToken=${token}` : "",
  ].join("");
};
