import {FC} from "react";
import {
  FormCheckbox,
  FormCheckboxProps,
} from "components/common/checkboxes/FormCheckbox/FormCheckbox";
import {GLOBAL_DICTIONARY} from "constants/dictionary";
import styles from "./optInMarketingSmsSwitcher.module.scss";

export const OptInMarketingSmsSwitcher: FC<
  Pick<FormCheckboxProps, "checked" | "onChange" | "name">
> = ({checked, onChange, name}) => {
  return (
    <>
      <FormCheckbox
        onChange={onChange}
        checked={checked}
        name={name}
        classes={{root: styles.formCheckbox}}
      >
        <span className={styles.label}>
          {GLOBAL_DICTIONARY.ACCOUNT.MARKETING_OPT_IN_TEXT}
        </span>
      </FormCheckbox>
    </>
  );
};
