import {FC} from "react";
import styles from "./boxHeader.module.scss";

export type BoxHeaderProps = {
  title: string;
  subTitle?: string;
  icon: string;
  children?: React.ReactNode;
};

export const BoxHeader: FC<BoxHeaderProps> = ({title, subTitle, icon, children}) => {
  return (
    <div className={styles.container}>
      <div className={styles.titles}>
        <h2>{title}</h2>
        {subTitle && <h3>{subTitle}</h3>}
        {children}
      </div>

      <img src={icon} alt="Icon" height={65} />
    </div>
  );
};
