import {createAsyncThunk} from "@reduxjs/toolkit";
import {type AxiosError} from "axios";
import {type ICentsCustomerCredentials} from "state/redux/slices/order/types";
import {
  type INearStoresParams,
  type IOrderInitialData,
} from "state/redux/slices/order/types/thunkParams";
import {logoutCustomer} from "utils/common";
import {fixThemeSettings} from "utils/theme";
import {updateCustomerCredentials} from "api/customer";
import {getTimezoneFromLatLng} from "api/google";
import {
  fetchInitialOrderData,
  fetchReturnWindows,
  getAvailableNearStores,
} from "api/online-order";
import {fetchSubscriptions} from "api/subscriptions";
import {
  GET_CUSTOMER_SUBSCRIPTIONS_LIST,
  GET_NEAR_STORES,
  GET_ORDER_INITIAL_DATA,
  GET_RETURN_WINDOWS,
  ONLINE_ORDER_REDUCER,
  PATCH_CUSTOMER_CREDENTIALS,
} from "../constants/general";

export const getOrderInitialData = createAsyncThunk(
  `${ONLINE_ORDER_REDUCER}/${GET_ORDER_INITIAL_DATA}`,
  async ({businessId, storeId}: IOrderInitialData) => {
    const response = await fetchInitialOrderData(businessId, storeId);

    const fixedTheme = fixThemeSettings(response.data.theme);

    const {centsCustomerCredentials} = response.data;
    if (!centsCustomerCredentials) {
      logoutCustomer();
    }

    return {
      ...response.data,
      theme: {
        ...fixedTheme,
      },
    };
  }
);

export const getCustomerSubscriptionsList = createAsyncThunk(
  `${ONLINE_ORDER_REDUCER}/${GET_CUSTOMER_SUBSCRIPTIONS_LIST}`,
  async () => {
    const response = await fetchSubscriptions();
    return response.data.subscriptions;
  }
);

export const getReturnWindows = createAsyncThunk(
  `${ONLINE_ORDER_REDUCER}/${GET_RETURN_WINDOWS}`,
  async ({selectedWindow, ...params}: Record<string, any>) => {
    const response = await fetchReturnWindows(params);
    return {data: response.data, selectedWindow};
  }
);
/**
 * @deprecated Will be restructured in terms of 
 * https://cents.atlassian.net/wiki/spaces/ED/pages/504758289/Live+Link+Refactoring+DRAFT#%2Fnear-stores-endpoint
 */
export const getNearStores = createAsyncThunk(
  `${ONLINE_ORDER_REDUCER}/${GET_NEAR_STORES}`,
  async ({selectedWindow, saveSelectedServices, ...params}: INearStoresParams) => {
    if ((params?.lat && params?.lng) || params?.isManageOrder) {
      let {timeZone} = params;
      if (!timeZone && params.lat && params.lng) {
        const timeZoneRes = await getTimezoneFromLatLng(params);
        timeZone = timeZoneRes?.data?.timeZoneId;
      }
      const {data} = await getAvailableNearStores({...params, timeZone});
      const fixedTheme = fixThemeSettings(data.theme);

      const result = {
        ...data,
        addressTimeZone: timeZone,
        selectedWindow,
        isManageOrder: Boolean(params?.isManageOrder),
      };

      if (data.theme) {
        result.theme = {...fixedTheme};
      }
      return result;
    } else {
      throw Error("Invalid /near-stores request (lat, lng missing)");
    }
  }
);

export const patchCustomerCredentials = createAsyncThunk(
  `${ONLINE_ORDER_REDUCER}/${PATCH_CUSTOMER_CREDENTIALS}`,
  async (sendData: Partial<ICentsCustomerCredentials>, thunkApi) => {
    try {
      const response = await updateCustomerCredentials(sendData);
      return response.data;
    } catch (error: AxiosError | any) {
      return thunkApi.rejectWithValue({
        text: error?.response?.data?.error || error?.message || "Something went wrong",
        code: error?.response?.status || 500,
      });
    }
  }
);
