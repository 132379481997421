import {useEffect, useState} from "react";
import {Button, Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import {useAppSelector} from "state/redux/hooks";
import {orderSelectors} from "state/redux/slices/order";
import {OOBO_CONFLICTS_TYPES} from "constants/order";

const DeliveryConflictPopupModal = ({onCancel, onConfirm, isPickup}) => {
  const [stopsInfo, setStopsInfo] = useState({totalStops: null, maxStops: null});
  const {ooboConflicts, pickup, returnInfo} = useAppSelector(
    orderSelectors.getScheduleDetails
  );
  const formattedConflicts = Array.from(new Set(ooboConflicts));
  const isMaxStopsInConflicts = ooboConflicts.some((conflict) =>
    [
      OOBO_CONFLICTS_TYPES.MAX_STOPS,
      OOBO_CONFLICTS_TYPES.PICKUP_MAX_STOPS,
      OOBO_CONFLICTS_TYPES.DELIVERY_MAX_STOPS,
    ].includes(conflict)
  );
  const returnStopWord = (stops) => (Number(stops) === 1 ? "stop" : "stops");

  useEffect(() => {
    if (!isMaxStopsInConflicts) {
      return;
    }

    const currentStageData = isPickup ? pickup : returnInfo;

    const {orderDeliveriesCount, recurringSubscriptionCount, maxStops} = currentStageData;

    setStopsInfo({
      totalStops: orderDeliveriesCount + recurringSubscriptionCount,
      maxStops,
    });
  }, [isMaxStopsInConflicts, isPickup, pickup, returnInfo]);

  return (
    <div className="schedule-conflict-modal-wrapper">
      <div className="conflict-modal-box">
        <div className="conflict-modal-headers">
          {formattedConflicts.map((conflict) => (
            <Typography key={conflict} className="conflict-modal-header-text">
              {conflict}
            </Typography>
          ))}
        </div>

        <div className="conflict-modal-body">
          {isMaxStopsInConflicts && (
            <Typography className="conflict-modal-body-text">
              There are{" "}
              <span className="bold-text">
                {stopsInfo.totalStops} {returnStopWord(stopsInfo.totalStops)}
              </span>{" "}
              of <span className="bold-text">Maximum {stopsInfo.maxStops}</span>{" "}
              {returnStopWord(stopsInfo.maxStops)} in this window
            </Typography>
          )}
          <Typography className="conflict-modal-body-text">
            Are you sure you want to schedule this window?
          </Typography>
        </div>

        <div className="conflict-modal-actions">
          <Button
            variant="outlined"
            color="primary"
            className="conflict-cancel-button"
            onClick={onCancel}
          >
            <Typography className="conflict-action-buttons-text">Cancel</Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="conflict-confirm-button"
            onClick={onConfirm}
          >
            <Typography className="conflict-action-buttons-text">Confirm</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

DeliveryConflictPopupModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isPickup: PropTypes.bool.isRequired,
};

export default DeliveryConflictPopupModal;
