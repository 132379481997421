import {FetchingStatus} from "types/common";
import {IRewardsInitialData} from "./types";

export const initialData: IRewardsInitialData = {
  customerRewardsInfo: {
    data: {
      currentPoints: 0,
      requiredPoints: 0,
      awardValue: 0,
      expirationDate: "",
      pointsExpiring: 0,
      pointsPerDollar: "0",
      lifetimePoints: 0,
      customerEmailAddress: "",
      isEnrolled: false,
    },
    fetchingStatus: FetchingStatus.Initial,
    error: {
      text: "",
      code: "",
    },
  },
  rewardsHistory: {
    data: [],
    fetchingStatus: FetchingStatus.Initial,
    error: {
      text: "",
      code: "",
    },
  },
  orderRewardsDetails: {
    data: {
      id: null,
      amount: null,
      orderId: null,
      businessId: null,
      createdAt: "",
      updatedAt: "",
      operationType: "",
      orderableType: "",
      centsCustomerId: null,
    },
    fetchingStatus: FetchingStatus.Initial,
    error: {
      text: "",
      code: "",
    },
  },
};
