import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "state/redux/store";

export const fullServiceOrdersList = (state: RootState) => state.fullServiceOrdersList;

export const activeOrdersList = createSelector(
  fullServiceOrdersList,
  (state) => state.activeList
);

export const finishedOrdersList = createSelector(
  fullServiceOrdersList,
  (state) => state.finishedList
);
