import {createAsyncThunk} from "@reduxjs/toolkit";

import {IRequestParams} from "types/common";

import {
  fetchFullServiceOrdersFinishedList,
  fetchFullServiceOrdersActiveList,
} from "../api";
import {
  NAME_REDUCER,
  FINISHED_LIST_DATA_THUNK,
  ACTIVE_LIST_DATA_THUNK,
} from "../constants/general";

export const getFullServiceOrdersFinishedList = createAsyncThunk(
  `${NAME_REDUCER}/${FINISHED_LIST_DATA_THUNK}`,
  async (params: IRequestParams) => {
    const response = await fetchFullServiceOrdersFinishedList(params);
    return {...response.data, params};
  }
);

export const getFullServiceOrdersActiveList = createAsyncThunk(
  `${NAME_REDUCER}/${ACTIVE_LIST_DATA_THUNK}`,
  async (params: IRequestParams) => {
    const response = await fetchFullServiceOrdersActiveList(params);
    return {...response.data, params};
  }
);
