import * as Sentry from "@sentry/browser";
import {useQuery} from "@tanstack/react-query";
import {fetchMachineDataByUniqueCode} from "features/order/self-serve/api/selfServe";
import {IMachine} from "features/order/self-serve/types";
import {toast} from "react-toastify";
import {ERROR_MESSAGES} from "constants/constants";
import {ApiError} from "types/api";

interface UseGetMachineDetailsByQrCodePayload {
  /** QR-code hash */
  hash?: string | null;
  enabled?: boolean;
}

export const useGetMachineDetailsByQrCode = ({
  hash,
  enabled = true,
}: UseGetMachineDetailsByQrCodePayload) => {
  return useQuery<unknown, ApiError, IMachine>({
    queryKey: ["useGetMachineDetailsByQrCode", {hash}],
    queryFn: async () => {
      if (!hash) {
        return null;
      }

      const {data} = await fetchMachineDataByUniqueCode(hash);
      return data;
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast.error(error?.response?.data?.error ?? ERROR_MESSAGES.MACHINE_DETAILS);

      return error;
    },
    enabled,
  });
};
