import {TextField, makeStyles} from "@material-ui/core";
import StripeInputComponent from "components/common/StripeInput";
import {FC} from "react";
import theme from "constants/themes/theme";

const textFieldInputProps = {
  labelProps: {
    shrink: true,
    style: {color: theme.colors.TEXT_GREY, fontWeight: 600},
  },
  inputProps: {
    style: {
      height: "56px",
      lineHeight: "22px",
      fontSize: "18px",
      fontWeight: 400,
      padding: 0,
      color: theme.colors.NEW_TEXT_PRIMARY_BLUE,
    },
    maxLength: 25,
  },
};

const inputTypeNumberFixClass = makeStyles(() => ({
  inputTypeNum: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

interface StripeFormTextFieldProps {
  component: React.FunctionComponent;
  startIcon: string;
  className?: string;
  label: string;
}

interface FormTextFieldProps {
  startIcon: string;
  className?: string;
  label: string;
  value: string;
  setValue: (value: string) => void;
  error: boolean;
  type?: string;
  inputMode?: "decimal";
}

export const StripeFormTextField: FC<StripeFormTextFieldProps> = ({
  component,
  startIcon,
  className = "",
  label,
}) => {
  return (
    <TextField
      variant="outlined"
      color="primary"
      label={label}
      className={`textfield-input ${className}`}
      fullWidth
      InputLabelProps={{...textFieldInputProps.labelProps}}
      InputProps={{
        inputComponent: StripeInputComponent,
        inputProps: {component},
        startAdornment: <img src={startIcon} alt="user" className="input-adornment" />,
        style: {height: "100%"},
      }}
      inputProps={{...textFieldInputProps.inputProps}}
    />
  );
};

export const FormTextField: FC<FormTextFieldProps> = ({
  startIcon,
  className,
  label,
  value = "",
  setValue,
  error,
  type,
  inputMode,
}) => {
  return (
    <TextField
      variant="outlined"
      label={label}
      placeholder={label}
      color="primary"
      className={`textfield-input ${className} ${inputTypeNumberFixClass().inputTypeNum}`}
      fullWidth
      InputLabelProps={{...textFieldInputProps.labelProps}}
      InputProps={{
        startAdornment: <img src={startIcon} alt="user" className="input-adornment" />,
      }}
      inputProps={{...textFieldInputProps.inputProps, inputMode}}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      error={error}
      type={type || "text"}
    />
  );
};

export default FormTextField;
